import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { SharedDocumentsIcon } from 'assets/img/icons';
import { DocumentsIcon } from 'assets/img/menu';
import { PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { Tabs } from 'designSystem';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import React, { FC, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DocumentLibraryTabs } from 'types/enums';

/**
 * Tabs which are shown in the document library
 * @param onlyForBuyer - if true, the tab is only shown for partners
 */
const DOCUMENT_TABS: (ITab<DocumentLibraryTabs> & {
  onlyForBuyer?: boolean;
})[] = [
  {
    key: DocumentLibraryTabs.INTERNAL,
    label: 'Internal',
    startIcon: <DocumentsIcon height={12} />,
  },
  {
    key: DocumentLibraryTabs.SHARED,
    label: 'Shared with me',
    startIcon: <SharedDocumentsIcon height={12} />,
    onlyForBuyer: true,
  },
];

export const DeleteIcon = styled(Trash)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const DocumentsLayout: FC = () => {
  usePageTitle('Documents');
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isPartner } = useCompanyPlanContext();

  const selectedTab: DocumentLibraryTabs = pathname.split('/')[2] as DocumentLibraryTabs;

  const handleChangeTab = (tab: DocumentLibraryTabs) => {
    if (tab === DocumentLibraryTabs.INTERNAL) {
      navigate(`/documents/internal${location.search}`);
    } else {
      navigate(`/documents/${tab}${location.search}`);
    }
  };

  const tabs = useMemo(
    () => DOCUMENT_TABS.filter(({ onlyForBuyer }) => !onlyForBuyer || (onlyForBuyer && !isPartner)),
    [isPartner]
  );

  return (
    <PageContainer>
      <PageTitle title="Documents" />

      <Box mt={3} mb={1}>
        <Tabs tabs={tabs} selectedTab={selectedTab} onChange={handleChangeTab} />
      </Box>

      <Outlet />
    </PageContainer>
  );
};

export default DocumentsLayout;
