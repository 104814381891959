import { useMutation } from '@apollo/client';
import { Box, DialogContent, Grid, Typography } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { MediaField, TextFieldLimited } from 'components/Forms';
import { useMessages } from 'components/hooks';
import { SlidesDialog } from 'components/SliderForm';
import { FastField as Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { CREATE_CERTIFICATE_CATALOG_ITEM } from 'graphql/mutations';
import React, { FC, Fragment } from 'react';
import * as Yup from 'yup';
import { TagFill, FileEarmarkCheck } from '@styled-icons/bootstrap';
import { styled } from '@mui/material/styles';
import { ImageVariant } from 'types/commonTypes';
import {
  FieldWrapper,
  CardContainer,
  ThemeTypography,
  ThemeButton,
  DialogTitle,
} from 'designSystem';
import { createMediaSchema } from 'constants/schemas/shared';
import { IDefaultDialogProps } from 'types/dialog.types';

const DocumentType = styled(Typography)(() => ({
  fontSize: 11,
}));

const DocumentIcon = styled(FileEarmarkCheck)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

const TagIcon = styled(TagFill)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const FormWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(10),
}));

const ButtonFooter = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(3),
}));

const ImageUploadWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(5),
}));

const CertificateCatalogItemSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
  image: createMediaSchema('', false),
});

interface ValueTypes {
  title: string;
  description: string;
  image: ImageVariant | undefined;
}

export interface IRequestCertificateDialogProps extends IDefaultDialogProps {
  documentTitle?: string;
}

const RequestCertificateDialog: FC<IRequestCertificateDialogProps> = ({
  open,
  onClose,
  documentTitle = '',
}) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [createCertificateCatalogItem, { loading: isSubmitting }] = useMutation(
    CREATE_CERTIFICATE_CATALOG_ITEM,
    {
      onCompleted: () => {
        setSuccessMessage('Certificate successfully requested. We will get back to you.');
        onClose?.();
      },
      onError: () => {
        setErrorMessage('There was an error submitting your certification request');
      },
    }
  );

  const onSubmit = async ({ title, description, image }: ValueTypes) => {
    await createCertificateCatalogItem({
      variables: {
        input: {
          imageId: image?.id,
          title,
          description,
        },
      },
    });
  };

  const initialValues: ValueTypes = {
    title: '',
    description: '',
    image: undefined,
  };

  return (
    <SlidesDialog fullWidth={true} maxWidth="md" open={!!open} onClose={onClose}>
      <Box p={2}>
        <DialogTitle icon={<Plus size={16} />}>New certification request</DialogTitle>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={CertificateCatalogItemSchema}
        onSubmit={onSubmit}
        data-cy="request-cert-form"
      >
        {({ handleSubmit }) => (
          <Fragment>
            <DialogContent>
              <FormWrapper>
                <Box width="100%" mb={5}>
                  <Box mb={1}>
                    <ThemeTypography variant="TITLE_SMALL">
                      You have uploaded an unlisted certificate
                    </ThemeTypography>
                  </Box>
                  <ThemeTypography variant="BODY_LARGE">
                    If you have any more details of this certificate, please add them here, so we
                    can add it to the platform as soon as possible.
                  </ThemeTypography>
                  {documentTitle && (
                    <CardContainer mt={3} py={1} px={2}>
                      <Box display="flex" alignItems="center" minWidth={430}>
                        <DocumentIcon size={30} />
                        <Box>
                          <ThemeTypography variant="ITEM_TITLE" color="BLACK">
                            {documentTitle}
                          </ThemeTypography>
                          <Box display="flex" alignItems="center">
                            <TagIcon size={11} />
                            <DocumentType variant="body2">Certification</DocumentType>
                          </Box>
                        </Box>
                      </Box>
                    </CardContainer>
                  )}
                </Box>
                <Box display="flex">
                  <ImageUploadWrapper>
                    <FieldWrapper label="Certificate Logo">
                      <Field
                        component={MediaField}
                        fullWidth
                        // Prevent media library from clean up files since it would clean up the uploaded documents as well
                        preventCleanFiles
                        name="image"
                        legacyImage={false}
                        styles={{
                          width: 180,
                          height: 180,
                          maxWidth: '100%',
                        }}
                        data-cy="image-field"
                      />
                    </FieldWrapper>
                  </ImageUploadWrapper>

                  <Grid container xs={12}>
                    <Grid item xs={5}>
                      <Box mr={1}>
                        <FieldWrapper label="Title">
                          <Field
                            component={TextField}
                            name="title"
                            fullWidth
                            placeholder="my certificate"
                            variant="outlined"
                            data-cy="title-field"
                          />
                        </FieldWrapper>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mt={3}>
                        <FieldWrapper label="Description">
                          <Field
                            component={TextFieldLimited}
                            fullWidth
                            name="description"
                            multiline
                            variant="outlined"
                            rows={4}
                            charLimit={650}
                            placeholder="Explain what this certification is about and what is required to receive it."
                            inputProps={{
                              'data-cy': 'description-field',
                            }}
                          />
                        </FieldWrapper>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </FormWrapper>
            </DialogContent>
            <ButtonFooter>
              <ThemeButton color="BLUE_ICE" onClick={onClose} data-cy="cancel-btn" size="large">
                Cancel
              </ThemeButton>

              <ThemeButton
                type="submit"
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                data-cy="submit-btn"
                color="YELLOW"
                size="large"
              >
                Send request
              </ThemeButton>
            </ButtonFooter>
          </Fragment>
        )}
      </Formik>
    </SlidesDialog>
  );
};

export default RequestCertificateDialog;
