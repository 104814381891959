import { EmptyStateCard } from 'designSystem';
import React, { FC, Fragment, useState } from 'react';
import { AvailableSizes, BackgroundTypes, ItemTypes } from 'types/enums';
import { Partner, PartnerRequest } from 'types/partner.types';
import unknownDocument from 'assets/img/icons/unknown-document.svg';
import { useConfig } from 'components/hooks';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { styled } from '@mui/material';
import { addRequestToCachePartnerOverview } from 'components/Partners/utils/partnerUtils';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import PartnerRequestList from 'components/Partners/PartnerRequests/PartnerRequestList';
import PartnerRequestDialog from 'components/Partners/PartnerRequests/PartnerRequestDialog';
import { ApolloCache } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

export const RequestIcon = styled('img')(() => ({
  width: 18,
}));

interface Props {
  requests: PartnerRequest[];
  partner: Partner;
}

const PartnerRequestOverview: FC<Props> = ({ requests, partner }) => {
  const navigate = useNavigate();
  const hasRequests = requests.length > 0;
  const [editRequest, setEditRequest] = useState<PartnerRequest>();

  const { deletePartnerRequest } = usePartnerRequestActions();
  const { appQueryParams } = useConfig();
  const [createNewRequest, setCreateNewRequest] = useQueryParam<boolean | null | undefined>(
    appQueryParams.createNewRequest,
    BooleanParam
  );

  const handleAllRequestsClick = () => {
    navigate(`/partners/${partner.id}/requests`);
  };

  return (
    <Fragment>
      {/* TODO: Use the dialogContext instead of using the dialog directly */}
      {(createNewRequest || editRequest) && (
        <PartnerRequestDialog
          open
          onClose={() => {
            setCreateNewRequest(undefined);
            setEditRequest(undefined);
          }}
          partner={partner}
          // Add here the request when it's in edit mode
          initialRequest={editRequest}
          onUpdateCache={(cache: ApolloCache<unknown>, request: PartnerRequest) =>
            addRequestToCachePartnerOverview(cache, request, partner.id)
          }
        />
      )}
      {!hasRequests ? (
        <EmptyStateCard
          variant={ItemTypes.DOCUMENT}
          onCreateItemClick={() => setCreateNewRequest(true)}
          showSpark={false}
          buttonText="New request"
          data-cy-button="new-request-button"
          title="Request partner documents to manage and prove your impact"
          size={AvailableSizes.LARGE}
          background={BackgroundTypes.WORLD}
          buttonIcon={<RequestIcon src={unknownDocument} />}
        />
      ) : (
        <PartnerRequestList
          requests={requests}
          onClickCreate={() => setCreateNewRequest(true)}
          onClickEdit={setEditRequest}
          onClickDelete={deletePartnerRequest}
          onAllRequestsClick={handleAllRequestsClick}
        />
      )}
    </Fragment>
  );
};

export default PartnerRequestOverview;
