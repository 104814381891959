import { FieldFunctionOptions } from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Company, CustomerPlan } from 'types/companyTypes';
import { getApplicationPlan } from 'utils/companyPlan.utils';

const companyPolicies = {
  Company: {
    fields: {
      isPartner: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        return readField<Company['customerType']>('customerType') === 'PARTNER';
      },
    },
  },
  CustomerPlan: {
    fields: {
      daysLeftInTrial: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        if (!readField('expirationDate')) {
          return null;
        }
        const expirationDate = moment(readField<CustomerPlan['expirationDate']>('expirationDate'));
        const diff = expirationDate.diff(moment(), 'days');
        return diff > 0 ? diff : 0;
      },
      planName: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        if (!readField('customerPlanId')) {
          return 'NO_PLAN';
        }

        return getApplicationPlan(readField<CustomerPlan['customerPlanId']>('customerPlanId'));
      },
    },
  },
  CompanyUser: {
    fields: {
      fullName: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        return `${readField('firstName')} ${readField('lastName')}`;
      },
      role: (role = '') => {
        if (role === 'SUPER_ADMIN') {
          return 'Admin';
        }

        return startCase(role.toLowerCase());
      },
      isAdmin: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        const role = readField<string>('role');
        return role && role.toLowerCase() === 'admin';
      },
      status: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        return readField('isRegistered') ? 'REGISTERED' : 'INVITED';
      },
    },
  },
};

export default companyPolicies;
