import { useMutation, useQuery } from '@apollo/client';
import { useConfig, useDialog } from 'components/hooks';
import { DELETE_STATEMENTS, UPDATE_STATEMENT } from 'graphql/mutations/compliance';
import { IUpdateStatementInput } from 'graphql/mutations/types/compliance-mutation.types';
import { GET_STATEMENT, GET_STATEMENTS } from 'graphql/queries/compliance';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EudrStatement } from 'types/compliance.types';
import { GraphQlConnection } from 'types/types';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphConnections';

const useEudrComplianceStatements = () => {
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { appQueryParams } = useConfig();
  const [addNewStatement] = useQueryParam(appQueryParams.new, BooleanParam);
  const { id: selectedStatementId } = useParams();

  const {
    data: statementData,
    loading: statementLoading,
    error: statementError,
  } = useQuery<{ statement: EudrStatement }>(GET_STATEMENT, {
    variables: { id: selectedStatementId },
    skip: !selectedStatementId,
  });

  const {
    data: statementsData,
    loading: statementsLoading,
    error: statementsError,
  } = useQuery<{
    statements: GraphQlConnection<EudrStatement>;
  }>(GET_STATEMENTS, {
    variables: { complianceId: '00000000-0000-0000-0000-000000000000' },
  });

  const [_updateStatement] = useMutation<
    { statement: EudrStatement },
    { id: string; input: IUpdateStatementInput }
  >(UPDATE_STATEMENT);

  const [_deleteStatements] = useMutation<{ ids: string[] }, { ids: string[] }>(DELETE_STATEMENTS);

  const statements = statementsData?.statements
    ? removeGraphConnections(statementsData.statements)
    : [];

  const navigateToStatementOverview = (id: string) => navigate(`/compliance/eudr/${id}`);
  const navigateToStatementGuide = (id: string) => navigate(`/compliance/eudr/${id}/guide`);

  const handleCreateStatement = () => {
    openDialog({ type: 'START_DUE_DILIGENCE' });
  };

  useEffect(() => {
    if (addNewStatement) {
      handleCreateStatement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewStatement]);

  const updateStatement = (id: string, input: IUpdateStatementInput) =>
    _updateStatement({ variables: { id, input } });

  const handleDeleteStatement = (id: string) => {
    const statement = statements.find(s => s.id === id);
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete Statement',
        itemTitle: statement ? `${statement.title} - ${statement.year}` : '',
        text: 'Are you sure you want to delete this statement? This action cannot be undone. The created farms and the datasets will not be removed.',
        cancelText: 'Cancel',
        submitText: 'Delete',
        onCancel: () => undefined,
        onSubmit: () => {
          _deleteStatements({ variables: { ids: [id] } });
        },
      },
    });
  };

  return {
    selectedStatementId,
    statement: statementData?.statement,
    statements,
    loading: statementLoading || statementsLoading,
    error: statementError || statementsError,
    updateStatement,
    handleDeleteStatement,
    navigateToStatementOverview,
    navigateToStatementGuide,
    handleCreateStatement,
  };
};

export default useEudrComplianceStatements;
