import { Box, styled } from '@mui/material';
import { FileEarmarkText, Trash } from '@styled-icons/bootstrap';
import { useDocumentUploadActions } from 'components/DocumentLibrary/hooks';
import { Dropzone } from 'components/FileUpload';
import { IDropzoneProps } from 'components/FileUpload/Dropzone';
import { ActionButton, FileNameText } from 'components/FileUpload/styles';
import { FlexBox } from 'components/Structure';
import { useUploadState } from 'components/hooks';
import { ThemeTypography } from 'designSystem';
import { FieldProps, useField } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { booleanish } from 'types/booleanish.types';
import UploadItem, { UploadItemContainer } from './UploadItem';

interface IUploadFieldProps {
  multiple?: boolean;
  supportedFileTypes?: IDropzoneProps['supportedFileTypes'];
  onExistingDocumentDelete?: (documentId: string) => void;
}

interface IUploadFile {
  id: string;
  title: string;
  inUploadContext: boolean;
}

const Background = styled(Box)<{ error?: booleanish }>(({ theme, error }) => ({
  background:
    error === 'true' ? theme.custom.themeColors.error[20] : theme.custom.themeColors.primary[5],
  borderRadius: 4,
  padding: theme.spacing(1),
}));

/**
 * Need to access the UploadContext so wrap the UploadField in the UploadProvider
 */
const UploadField: FC<IUploadFieldProps & FieldProps<IUploadFile[]>> = ({
  supportedFileTypes,
  multiple,
  field: { name, value },
  onExistingDocumentDelete,
}) => {
  const [, { touched }, { setValue, setError, setTouched }] = useField<IUploadFile[]>(name);
  const { fileIDs, fileMap } = useUploadState();
  const { onDrop, maxSize } = useDocumentUploadActions();

  useEffect(() => {
    const datasetRecordIds = fileIDs
      .filter(id => fileMap[id].contextObject.formikName === name && fileMap[id].recordId)
      .map(id => ({
        id: fileMap[id].recordId,
        title: fileMap[id].data.name,
        inUploadContext: true,
      }));
    setValue(
      [
        ...value.filter(({ id }) => datasetRecordIds.findIndex(record => id === record.id) === -1),
        ...datasetRecordIds,
      ],
      false
    );
    let index: number;
    if ((index = fileIDs.findIndex(id => fileMap[id].error)) !== -1) {
      setError(fileMap[fileIDs[index]].error);
    } else {
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, fileIDs, fileMap]);

  const contextFileIds = useMemo(
    () =>
      value
        .filter(({ inUploadContext }) => inUploadContext)
        .map(file => fileIDs.find(id => file.id === fileMap[id].recordId))
        .filter(id => id !== undefined) as string[],
    [value, fileIDs, fileMap]
  );

  const nonContextFiles = useMemo(
    () => value.filter(({ inUploadContext }) => !inUploadContext),
    [value]
  );

  useEffect(() => {
    if (contextFileIds.length) {
      setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextFileIds]);

  const hasFilesAttached = value.length > 0;
  const fileErrors = contextFileIds.filter(id => !!fileMap[id].error);

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    // Add formikName to the file object so we can track which field the file belongs to
    onDrop(acceptedFiles, fileRejections, { formikName: name, category: 'GEOGRAPHICAL_FEATURES' });
  };

  return (
    <Box>
      {!hasFilesAttached && touched && (
        <Background error="true" mb={3}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="RED">
            Upload at least one file
          </ThemeTypography>
        </Background>
      )}

      {/* Hide the dropzone if its single file upload and there is already an uploaded file */}
      {(multiple || (!multiple && !hasFilesAttached)) && (
        <Dropzone
          supportedFileTypes={supportedFileTypes}
          maxFileMb={maxSize}
          multiple={multiple}
          onDrop={handleDrop}
        />
      )}

      <FlexBox mb={2} gap={2}>
        {hasFilesAttached && (
          <Background>
            <ThemeTypography variant="BODY_MEDIUM_BOLD">
              {value.length} {value.length > 1 ? 'files' : 'file'}
            </ThemeTypography>
          </Background>
        )}
        {!!fileErrors.length && (
          <Background error="true">
            <ThemeTypography variant="BODY_MEDIUM_BOLD" color="RED">
              {fileErrors.length} upload errors
            </ThemeTypography>
          </Background>
        )}
      </FlexBox>

      <Box maxHeight={1000} overflow="auto">
        {contextFileIds?.map(id => (
          <UploadItem key={id} id={id} />
        ))}

        {nonContextFiles.map(({ id, title }) => (
          <UploadItemContainer key={id}>
            <Box display="flex" alignItems="center" width="90%">
              <FileEarmarkText size={28} />
              <Box display="flex" flexGrow={1} flexDirection="column" width="70%">
                <Box>
                  <FileNameText>{title}</FileNameText>
                </Box>
              </Box>
            </Box>
            <ActionButton
              onClick={() => {
                setTouched(true);
                onExistingDocumentDelete?.(id);
              }}
            >
              <Trash size={14} />
            </ActionButton>
          </UploadItemContainer>
        ))}
      </Box>
    </Box>
  );
};

export default UploadField;
