import { ExclamationCircle } from '@styled-icons/bootstrap/ExclamationCircle';
import { CustomTooltip } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import Icon, { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import kebabCase from 'lodash/kebabCase';
import React, { FC } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { Booleanish } from 'types/booleanish.types';
import { DRAWER_CLASSES } from '../DrawerMenu';
import { DrawerMenuItem } from '../DrawerUtils';
import {
  MainNavigationButtonWrapper,
  SectionLabel,
  SelectedBackground,
  SelectionLabelContainer,
  StyledBox,
  StyledHr,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledSvgIcon,
  SubmenuLabelContainer,
} from '../StyledDrawerItems';
import DrawerSubItem from './DrawerSubItem';

interface IDrawerItemProps {
  item: DrawerMenuItem;
  drawerExpanded: boolean;
  hasOverflow: boolean;
}

const MenuIcon: FC<
  | {
      /**
       * Use the iconName instead
       * @deprecated
       */
      Icon: FC;
    }
  | { iconName: IconNameType }
> = props => {
  if ('Icon' in props) {
    const ThisIcon = props.Icon;
    return (
      <StyledSvgIcon className={`${DRAWER_CLASSES.icon} menu-icon`} viewBox="0 0 16 16">
        <ThisIcon />
      </StyledSvgIcon>
    );
  }
  return <Icon name={props.iconName} color="white" size="large" />;
};

const DrawerItem: FC<IDrawerItemProps> = ({
  item: {
    label,
    to,
    submenu,
    submenuPaths,
    params,
    SubmenuTitle,
    SubmenuIcon,
    showWarning,
    exact = false,
    dividerAbove,
    visible,
    sectionLabelAbove,
    paths = [],
    ...menuIconProps
  },
  drawerExpanded,
  hasOverflow,
}) => {
  // Instead of calling this hook in each item, we could call them in the parent component and pass the result as props
  const location = useLocation();

  const matchDrawerItemRoute = matchRoutes(
    [to, ...paths].map(path => ({ path })),
    location
  );
  const matchesSubmenu = submenuPaths?.length
    ? matchRoutes(
        submenuPaths.map(path => ({ path })),
        location
      )
    : false;

  if (!visible) return <></>;

  return (
    <>
      {sectionLabelAbove &&
        (drawerExpanded ? (
          <SelectionLabelContainer>
            <SectionLabel variant="BODY_LARGE" color="BLUE_FROST">
              {sectionLabelAbove}
            </SectionLabel>
          </SelectionLabelContainer>
        ) : (
          <StyledHr is-section="true" />
        ))}
      {dividerAbove && <StyledHr drawer-expanded={Booleanish(drawerExpanded)} />}
      <MainNavigationButtonWrapper
        key={label}
        end={exact}
        to={to}
        className={({ isActive }) =>
          isActive || matchDrawerItemRoute || matchesSubmenu ? 'active' : ''
        }
        data-cy={`menu-link-${kebabCase(label)}`}
      >
        <CustomTooltip placement="right" title={drawerExpanded ? '' : label}>
          <StyledBox>
            <StyledListItem>
              <MenuIcon {...menuIconProps} />
              <StyledListItemText drawer-expanded={Booleanish(drawerExpanded)}>
                {label}
              </StyledListItemText>
            </StyledListItem>
            {showWarning && <ExclamationCircle size={18} />}
          </StyledBox>
        </CustomTooltip>
      </MainNavigationButtonWrapper>

      {submenuPaths && matchesSubmenu && (
        <>
          {SubmenuTitle && SubmenuIcon && (
            <SelectedBackground
              drawer-expanded={Booleanish(drawerExpanded)}
              has-overflow={Booleanish(hasOverflow)}
            >
              <SubmenuLabelContainer drawer-expanded={Booleanish(drawerExpanded)}>
                <SubmenuIcon />
                {drawerExpanded && (
                  <ThemeTypography variant="BODY_LARGE" color="BLUE_FROST">
                    <SubmenuTitle />
                  </ThemeTypography>
                )}
              </SubmenuLabelContainer>
            </SelectedBackground>
          )}
          <StyledList
            draw-line-before="true"
            drawer-expanded={Booleanish(drawerExpanded)}
            is-child="true"
          >
            {submenu?.map((subItem, index) => (
              <DrawerSubItem
                key={`${subItem.label}-${index}`}
                // To show the selected indicator of the submenu items correctly the inactive items before the current item has to be ignored
                visibleIndex={
                  index -
                  submenu.filter((submenu, filterIndex) => filterIndex < index && !submenu.visible)
                    .length
                }
                params={params}
                item={subItem}
                drawerExpanded={drawerExpanded}
              />
            ))}
          </StyledList>
        </>
      )}
    </>
  );
};

export default DrawerItem;
