import { ApolloCache } from '@apollo/client';
import { GET_PARTNER, GET_PARTNER_USER_HOME_DATA, GET_PRODUCTS_HOME } from 'graphql/queries';
import { GET_ALL_BASE_PARTNERS, GetPartnerUserHomeDataValues } from 'graphql/queries/partners';
import { Partner, PartnerRequest } from 'types/partner.types';
import { GraphQlConnection, Product } from 'types/types';

export const addNewPartnerToCache = (cache: ApolloCache<unknown>, newPartner: Partner) => {
  const result = cache.readQuery<{ partners: GraphQlConnection<Partner> }>({
    query: GET_ALL_BASE_PARTNERS,
  });

  const partners = result?.partners || { edges: [] };

  cache.writeQuery({
    query: GET_ALL_BASE_PARTNERS,
    data: {
      partners: {
        edges: [
          {
            node: newPartner,
            __typename: 'PartnerEdge',
          },
          ...partners.edges,
        ],
      },
    },
  });
};

export const removeRequestFromCachePartnerHome = (
  cache: ApolloCache<unknown>,
  removedRequest: PartnerRequest
) => {
  const cachedData = cache.readQuery<GetPartnerUserHomeDataValues>({
    query: GET_PARTNER_USER_HOME_DATA,
    variables: {
      status: ['REQUESTED'],
    },
  });
  const updatedRequests = cachedData?.partnerRequestsReceived?.edges.filter(
    request => removedRequest.id !== request.node.id
  );

  cache.writeQuery({
    query: GET_PARTNER_USER_HOME_DATA,
    data: {
      documents: cachedData?.documents,
      partnerRequestsReceived: { edges: updatedRequests },
    },
    variables: {
      status: ['REQUESTED'],
    },
  });
};

export const addRequestToCachePartnerOverview = (
  cache: ApolloCache<unknown>,
  request: PartnerRequest,
  partnerId: string
) => {
  const cachedData = cache.readQuery<
    {
      partner: Partner;
      partnerRequestsSent: GraphQlConnection<PartnerRequest>;
    },
    {
      id: string;
      status: PartnerRequest['requestStatus'][];
    }
  >({
    query: GET_PARTNER,
    variables: {
      id: partnerId,
      status: ['DENIED', 'PROVIDED', 'REQUESTED'],
    },
  });

  if (!cachedData) return;

  const updatedRequests = [...cachedData.partnerRequestsSent.edges, { node: request }];

  cache.writeQuery({
    query: GET_PARTNER,
    data: {
      partner: cachedData?.partner,
      partnerRequestsSent: updatedRequests,
    },
    variables: {
      id: partnerId,
      status: ['DENIED', 'PROVIDED', 'REQUESTED'],
    },
  });
};

export const removeRequestFromCacheBuyerHome = (
  cache: ApolloCache<unknown>,
  removedRequest: PartnerRequest
) => {
  const cachedData = cache.readQuery<{
    products: GraphQlConnection<Product>;
    overallProductStatus: { status: string; homeTitle: string };
    companyAnalyticsExist: boolean;
    partnerRequestsSent: GraphQlConnection<PartnerRequest>;
  }>({
    query: GET_PRODUCTS_HOME,
    variables: {
      status: ['DENIED', 'PROVIDED'],
    },
  });

  if (!cachedData) return;

  const updatedRequests = cachedData.partnerRequestsSent?.edges.filter(
    request => removedRequest.id !== request.node.id
  );

  cache.writeQuery({
    query: GET_PRODUCTS_HOME,
    data: {
      ...cachedData,
      partnerRequestsSent: { edges: updatedRequests },
    },
    variables: {
      status: ['DENIED', 'PROVIDED'],
    },
  });
};
