import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { Loader, SearchToolbar } from 'components/Forms';
import PartnersTable from 'components/Partners/PartnersTable/PartnersTable';
import usePartners from 'components/Partners/hooks/usePartners';
import { EmptySearch, ErrorState } from 'components/Structure';
import React, { FC, useState } from 'react';
import { Partner } from 'types/partner.types';
import { PartnerHeaderColumnEnum } from '../PartnersTable/partnerHeaderColumns';

const SearchbarContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: -35,
  left: 0,
  zIndex: 9,
}));

interface Props {
  selectedPartner?: Partner;
  setSelectedPartner: (partner: Partner) => void;
}

/**
 * @deprecated use PartnerSelection instead
 */
const PartnerSelector: FC<Props> = ({ selectedPartner, setSelectedPartner }) => {
  // Using custom search, and not the one from usePartner, because we don't want it in the query params
  const [page, setPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { partners, loading, error } = usePartners({ customSearch: searchTerm });

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    setPage(0);
  };

  if (loading) {
    return <Loader />;
  }
  if (error || !partners) {
    return <ErrorState />;
  }

  return (
    <Box position="relative" mt={7} mb={3} minHeight={400}>
      <SearchbarContainer>
        <SearchToolbar
          setSearchTerm={handleSearchTermChange}
          searchTerm={searchTerm}
          data-cy="document-search-input"
          placeholder="Search partners"
          className="search-input"
        />
      </SearchbarContainer>
      <PartnersTable
        page={page}
        hideHeader
        selectedPartner={selectedPartner}
        partners={partners}
        onSelect={setSelectedPartner}
        columns={[PartnerHeaderColumnEnum.TITLE]}
        onPageChange={setPage}
      />

      {!partners.length && <EmptySearch />}
    </Box>
  );
};

export default PartnerSelector;
