import { ImpactClaimLog, ImpactClaimStatus } from 'types/impactClaimTypes';
import LogoSmallImg from 'assets/img/seedtrace_logo_icon_black_color.svg';
import profileImage from 'assets/img/profile-image.svg';

const SEEDTRACE_AUTHOR = 'seedtrace';

export const getAuthor = (log: ImpactClaimLog): { name: string; icon: string } => {
  if (log.action === ImpactClaimStatus.APPROVED || log.action === ImpactClaimStatus.DENIED) {
    return { name: SEEDTRACE_AUTHOR, icon: LogoSmallImg };
  }

  return { name: `${log.user.firstName} ${log.user.lastName}`, icon: profileImage };
};
