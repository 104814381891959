import { gql } from '@apollo/client';
import {
  CONTENT_BLOCKS_FRAGMENT,
  PRODUCT_FRAGMENT,
  VERSION_FRAGMENT,
  VERSION_PROPS_FRAGMENT,
} from 'graphql/fragments';
import { removeDirectivesFromDocument } from '@apollo/client/utilities';
import { CHAIN_FRAGMENT } from 'graphql/fragments/chains';

export const GET_LATEST_VERSION_AND_PRODUCT = gql`
  query GetLatestVersion($id: UUID!, $lang: Language) {
    latestProductVersion(id: $id, lang: $lang) {
      ...versionProperties
      ...contentBlocks
      ...versionValues
      supplyChain {
        ...chainValues
      }
    }
    product(id: $id) {
      ...productValues
    }
  }
  ${CONTENT_BLOCKS_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${VERSION_PROPS_FRAGMENT}
  ${CHAIN_FRAGMENT}
  ${VERSION_FRAGMENT}
`;

// extra client query to avoid refetch queries error
export const GET_LATEST_VERSION_AND_PRODUCT_CLIENT = gql`
  query GetLatestVersionClient($id: UUID!, $lang: Language) {
    latestProductVersion(id: $id, lang: $lang) @client {
      ...versionProperties
      ...contentBlocks
      ...versionValues
      supplyChain {
        ...chainValues
      }
    }
    product(id: $id) @client {
      ...productValues
    }
  }
  ${CONTENT_BLOCKS_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${VERSION_FRAGMENT}
  ${VERSION_PROPS_FRAGMENT}
  ${CHAIN_FRAGMENT}
`;

export const GET_LATEST_VERSION_AND_PRODUCT_CLIENT_WITHOUT_EXPENSIVE = removeDirectivesFromDocument(
  [
    {
      name: 'expensive',
      remove: true,
    },
  ],
  GET_LATEST_VERSION_AND_PRODUCT_CLIENT
);
