import { styled } from '@mui/material/styles';
import { ITooltipParams } from 'ag-grid-community';
import React, { FC } from 'react';

const StyledTooltip = styled('div')(({ theme }) => ({
  borderRadius: '5px',
  padding: '10px',
  maxWidth: '180px',
  backgroundColor: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
}));

/**
 * Shows the validation message first and then the error message if the validation message is not present.
 */
const TooltipComponent: FC<ITooltipParams> = ({ value }) => {
  return (value.isValid || !value.validationMessage) &&
    (!value.hasError || !value.errorMessage) ? null : (
    <StyledTooltip>{value.validationMessage || value.errorMessage}</StyledTooltip>
  );
};

export default TooltipComponent;
