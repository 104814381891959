import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { useDialog } from 'components/hooks';
import usePartners from 'components/Partners/hooks/usePartners';
import Image from 'designSystem/DataDisplay/Image/Image';
import PaperWithAddItem from 'designSystem/Inputs/Paper/Paper';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Partner, PartnerStatus } from 'types/partner.types';

interface IPartnerAutocompleteProps {
  inviteNewPartner?: boolean;
  defaultValue?: Partner | null;
  placeholder?: string;
  /* Default is true */
  filterByRegisteredPartners?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  fontSize?: number;
  hasError?: boolean;
  errorMessage?: string;
  onPartnerSelect: (partner: Partner | null) => void;
}

const StyledAutocomplete = styled(Autocomplete<Partner>)<{ fontSize?: number }>(({ fontSize }) => ({
  '& .MuiAutocomplete-inputRoot': {
    paddingLeft: '16px !important',
    fontSize: fontSize ? fontSize : 12,
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: 4,
    '& .MuiInputBase-input': {
      fontSize: fontSize ? fontSize : 12,
    },
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
    {
      padding: 4,
      '& .MuiInputBase-input': {
        fontSize: fontSize ? fontSize : 12,
      },
    },
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  '&.MuiFormHelperText-root': {
    marginTop: -2,
  },
}));

const PartnerAutocomplete: FC<IPartnerAutocompleteProps> = ({
  inviteNewPartner,
  defaultValue = null,
  placeholder = 'Select partner',
  size = 'medium',
  filterByRegisteredPartners = true,
  disabled,
  fontSize,
  hasError,
  errorMessage = 'Please select a partner',
  onPartnerSelect,
}) => {
  const { openDialog } = useDialog();
  const [selectPartnerId, setSelectPartnerId] = useState<string | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(defaultValue);
  const { partners, loading, refetch } = usePartners({ includeSelf: true });
  const sizeImage = size === 'small' ? 14 : 17;

  const partnerOptions: Partner[] = useMemo(() => {
    if (filterByRegisteredPartners) {
      return partners.filter((partner: Partner) => partner.status === PartnerStatus.REGISTERED);
    }
    return partners;
  }, [partners, filterByRegisteredPartners]);

  const handleSelectPartner = (event: ChangeEvent<unknown>, partner: Partner | null) => {
    setSelectedPartner(partner);
    onPartnerSelect(partner);
  };

  const handleNewPartnerCreated = useCallback(
    async (partner: Partner) => {
      await refetch();
      setSelectPartnerId(partner.id);
    },
    [refetch, setSelectPartnerId]
  );

  const handleCreatePartner = () => {
    openDialog({
      type: 'ADD_EDIT_PARTNER',
      props: {
        enableInvite: inviteNewPartner,
        skipInvitationEmail: true,
        onPartnerCreated: handleNewPartnerCreated,
      },
    });
  };

  /**
   * Waiting until the partner list got updated and select the correct partner as soon as it exists
   * Removing the selectedPartner from the temporary state variable after the partner got created
   */
  useEffect(() => {
    if (selectPartnerId) {
      const partner = partners.find(({ id }) => id === selectPartnerId);
      if (!partner) {
        // eslint-disable-next-line no-console
        console.error(`Partner with id ${selectPartnerId} not found`);
        setSelectPartnerId(null);
        return;
      }
      setSelectedPartner(partner);
      onPartnerSelect(partner);
      setSelectPartnerId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPartnerId, partners]);

  /** Listen to the changes */
  useEffect(() => setSelectedPartner(defaultValue), [defaultValue]);

  return (
    <FormControl fullWidth size={size} error={hasError}>
      <StyledAutocomplete
        loading={loading}
        value={selectedPartner}
        options={partnerOptions}
        data-cy="partner-autocomplete"
        onChange={handleSelectPartner}
        fullWidth
        fontSize={fontSize}
        size={size}
        disabled={disabled}
        PaperComponent={({ children }) => (
          <PaperWithAddItem title="Create new partner" onClick={handleCreatePartner}>
            {children}
          </PaperWithAddItem>
        )}
        getOptionLabel={option => option.title}
        renderOption={(props, option: Partner) => (
          <li {...props} key={option.id} data-cy="autocomplete-option">
            <Box mr={2} display="flex" alignItems="center">
              <Image
                width={sizeImage}
                height={sizeImage}
                image={option.logo}
                backupImageUrl={defaultPartnerImage}
                borderRadius={0}
              />
            </Box>
            {option.title}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            variant="outlined"
            placeholder={placeholder}
            onKeyPress={keyEvent => {
              // Prevents the form from being submitted on enter.
              if (keyEvent.key === 'Enter') {
                keyEvent.preventDefault();
              }
            }}
            InputProps={{
              style: { height: size === 'medium' ? 40 : 30 },
              ...params.InputProps,
              startAdornment: selectedPartner ? (
                <Image
                  width={sizeImage}
                  height={sizeImage}
                  image={selectedPartner.logo}
                  backupImageUrl={defaultPartnerImage}
                  borderRadius={0}
                />
              ) : (
                <Image
                  width={sizeImage}
                  height={sizeImage}
                  backupImageUrl={defaultPartnerImage}
                  borderRadius={0}
                />
              ),
            }}
          />
        )}
      />
      {hasError && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}
    </FormControl>
  );
};

export default PartnerAutocomplete;
