import Loader from 'components/Forms/Loader';
import sha256 from 'crypto-js/sha256';
import React, { createContext, useEffect, useState } from 'react';
import Firebase from './Firebase';

interface FirebaseContextValues {
  authenticated: boolean;
  featureFlags: { [key: string]: string | boolean };
}

const FirebaseContext = createContext<FirebaseContextValues>({
  authenticated: false,
  featureFlags: {},
});

const FirebaseConsumer = FirebaseContext.Consumer;

const defaultConfig: { [key: string]: string | boolean } = {
  underMaintenance: false,
  documentVisibilitySetting: true,
  partnerInvitations: true,
  customLabelEnabled: true,
  enableStartDueDiligence: false,
  disableEudrGuideAfterStep3: true,
};

const determineFlagType = (value: string): string | boolean => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return value.toString();
};

interface Props {
  children: React.ReactNode;
  firebase: Firebase;
}

const FirebaseProvider: React.FC<Props> = ({ children, firebase }) => {
  // Check if the emulator is running
  const isEmulator: boolean = process.env.REACT_APP_FIREBASE_EMULATOR
    ? JSON.parse(process.env.REACT_APP_FIREBASE_EMULATOR)
    : false;

  const remoteConfig = firebase.getFirebase().remoteConfig();
  const firestore = firebase.getFirebase().firestore();
  const [featureFlags, setFeatureFlags] = useState(defaultConfig);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteConfig.defaultConfig = featureFlags;
    remoteConfig.settings.minimumFetchIntervalMillis = 0;

    // Force long polling for clients that are behind a proxy
    firestore.settings({ experimentalForceLongPolling: true });

    // Only get the remote config if the application is not running the emulator ( cypress )
    // This ensure the remote config is always the same when testing to prevent flaky tests working on different envs.
    if (isEmulator === false) {
      const fetchFlags = async () => {
        await remoteConfig.fetchAndActivate();
        const remoteFlags = await remoteConfig.getAll();
        const updatedFeatureFlags: { [key: string]: string | boolean } = {};

        Object.entries(remoteFlags).forEach(([key, config]) => {
          updatedFeatureFlags[key] = determineFlagType(config.asString());
        });

        setFeatureFlags(updatedFeatureFlags);
      };

      fetchFlags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase
      .getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        setAuthenticated(!!user);
        setLoading(false);

        // to see in the chat with which person we are chatting, we set attributes as soon as someone has logged in.
        if (window.Tawk_API && user) {
          window.Tawk_API.setAttributes({
            name: user.displayName,
            email: user.email,
            // @ts-ignore
            hash: sha256(user.email || '', process.env.REACT_APP_TAWK_API_KEY),
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return (
    <FirebaseContext.Provider
      value={{
        authenticated,
        featureFlags,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseConsumer };

export default FirebaseContext;
