import { X as Close } from '@styled-icons/bootstrap/X';
import {
  ControlBar,
  SlidesCloseButton,
  SlidesContainer,
  SlidesDialog,
} from 'components/SliderForm';
import { AlertDialog } from 'designSystem';
import React, { FC, Fragment } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { ImpactClaim } from 'types/impactClaimTypes';
import { useEditClaim } from '../hooks';

export interface IEditClaimDialogProps extends IDefaultDialogProps {
  claim: ImpactClaim;
}

const EditClaimDialog: FC<IEditClaimDialogProps> = ({ onClose, open, claim }) => {
  const {
    currentSlide,
    SLIDES,
    setCurrentSlide,
    openUpdateAlert,
    setOpenUpdateAlert,
    onSubmit,
    handleCloseDialog,
  } = useEditClaim({
    onClose,
    claim,
  });
  const {
    component: SlideComponent,
    props: currentSlideProps,
    button: SubmitButton,
  } = SLIDES[currentSlide];

  // TODO: Fix types for the slides and remove ts-ignore
  // @ts-ignore
  const renderBody = () => <SlideComponent {...currentSlideProps} />;

  return (
    <Fragment>
      <SlidesDialog fullWidth maxWidth="xl" open onClose={handleCloseDialog}>
        <SlidesCloseButton onClick={handleCloseDialog} data-cy="close-dialog-button">
          <Close size={20} />
        </SlidesCloseButton>
        <SlidesContainer>{renderBody()}</SlidesContainer>
        <ControlBar
          currentSlide={currentSlide}
          prevSlide={() => setCurrentSlide(currentSlide - 1)}
          setCurrentSlide={setCurrentSlide}
          slideCount={SLIDES.length}
          submitButton={SubmitButton}
        />
      </SlidesDialog>
      <AlertDialog
        onClose={() => setOpenUpdateAlert(false)}
        title="Update your claim?"
        open={openUpdateAlert}
        text={
          claim?.status === 'APPROVED'
            ? 'You made changes to an approved claim. Do you want to save your changes as a draft and continue editing later? ' +
              'This claim will become a draft and taken off your consumer facing pages, until it is approved again. '
            : ' You have edited this claim. Do you want to save as draft and continue editing later?'
        }
        onSubmit={() => onSubmit('UPDATED')}
        onCancel={onClose}
        cancelText="Discard changes"
        submitText="Save changes"
      />
    </Fragment>
  );
};

export default EditClaimDialog;
