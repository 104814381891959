import { Box, Grid, styled } from '@mui/material';
import { DropDown, MediaField } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { FastField as Field } from 'formik';
import React, { FC } from 'react';
import TextFieldLimited from 'components/Forms/TextFieldLimited';
import PartnerAutocompleteWrapper from 'components/ChainMapping/Forms/PartnerAutocomplete';
import LocationSelectorBlock from './LocationSelectorBlock';

interface ISiteClusterFormProps {
  hidePartnerSelector?: boolean;
}

const StyledCard = styled(Grid)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  borderRadius: 6,
  minHeight: 150,
  width: '100%',
  padding: theme.spacing(2),
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
}));

export const StyledDropDown = styled(DropDown)(({ theme }) => ({
  '& .MuiSelect-select': {
    height: '14px !important',
    minHeight: '14px !important',
    fontSize: 12,
    padding: '8px 12px !important',
  },
}));

const SiteClusterForm: FC<ISiteClusterFormProps> = ({ hidePartnerSelector }) => {
  return (
    <StyledCard container>
      <Grid item xs={3}>
        <FieldWrapper>
          <Field
            component={MediaField}
            name="image"
            data-cy="field-site-image"
            legacyImage={false}
            styles={{
              height: 130,
              maxWidth: '90%',
            }}
          />
        </FieldWrapper>
      </Grid>

      <Grid item xs={9}>
        <Grid container alignContent="center" spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <FieldWrapper width="100%">
                <Field
                  name="title"
                  size="small"
                  fullWidth
                  placeholder="E.g. Region or Community name"
                  component={TextFieldLimited}
                  variant="outlined"
                  charLimit={50}
                  inputProps={{
                    'data-cy': 'field-site-cluster-title',
                  }}
                />
              </FieldWrapper>
            </Box>
          </Grid>

          {!hidePartnerSelector && (
            <Grid item xs={6}>
              <PartnerAutocompleteWrapper
                name="partner"
                helperText="Select the owner of this site."
                labelVariant="medium"
                size="small"
              />
            </Grid>
          )}

          <Grid item xs={hidePartnerSelector ? 12 : 6}>
            <LocationSelectorBlock showOnlyCountry />
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default SiteClusterForm;
