import { useMutation, useQuery } from '@apollo/client';
import { Loader } from 'components/Forms';
import CompanyPlanDialog from 'components/Settings/CompanyPlanDialog';
import { useLogEvent, useMessages, useUserData } from 'components/hooks';
import { LOGOUT, REQUEST_PLAN_UPGRADE } from 'graphql/mutations';
import { GET_COMPANY_DETAILS, GET_PARTNER } from 'graphql/queries';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationPlan, PlanType } from 'types/companyTypes';
import { Partner, PartnerType, PartnerTypeEnum } from 'types/partner.types';

interface CompanyPlanContextValues {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  daysLeftInTrial: number;
  plan: ApplicationPlan;
  isPartner: boolean;
  companyType?: PartnerType;
  partnerId?: string;
}

const CompanyPlanContext = createContext<CompanyPlanContextValues>({
  open: false,
  setOpen: () => {
    console.error('unimplemented context');
  },
  daysLeftInTrial: 999,
  plan: 'FULL_PLAN',
  isPartner: false,
  partnerId: '',
});

const useCompanyPlanContext = () => useContext(CompanyPlanContext);

const CompanyPlanProvider: FC<PropsWithChildren> = ({ children }) => {
  useQuery(GET_COMPANY_DETAILS);
  const navigate = useNavigate();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { company } = useUserData();
  const plan: ApplicationPlan = company?.planDetails?.planName || 'NO_PLAN';
  const { logEvent } = useLogEvent();
  const isInactive = company?.planDetails?.planType === PlanType.INACTIVE;

  // Workaround to be able to identify the type of the company (in partner lib or partner ui)
  // TODO: Move that to the backend and return the type of the company (partner) in the company details query
  const { data: partnerData } = useQuery<{ partner: Partner }>(GET_PARTNER, {
    variables: {
      id: company?.partnerId,
    },
    skip: !company?.isPartner || !company?.partnerId,
  });

  const isUpgradeRequested: boolean =
    company?.planDetails?.upgradeRequested !== undefined
      ? company.planDetails.upgradeRequested
      : false;

  const isCompanyPlanExpired: boolean =
    company?.planDetails?.expired !== undefined ? company?.planDetails?.expired : true;

  const daysLeftInTrial: number =
    company?.planDetails?.daysLeftInTrial !== undefined ? company?.planDetails?.daysLeftInTrial : 0;

  const [requestSent, setRequestSent] = useState(isUpgradeRequested);
  const [open, setOpen] = useState<boolean>(false);

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => navigate('/login'),
  });

  const [requestUpgrade] = useMutation(REQUEST_PLAN_UPGRADE, {
    onError: () => {
      setErrorMessage('Something went wrong requesting your upgrade. Please contact our support.');
    },
    onCompleted: () => {
      logEvent('REQUEST_PLAN_UPGRADE', {
        days_left_in_trial: daysLeftInTrial,
      });
      setSuccessMessage('We received your upgrade request and will get back to you soon.');
      setRequestSent(true);
    },
  });

  const handleClose = () => {
    if (!isCompanyPlanExpired) {
      setOpen(false);
    }
  };

  const handleSubmit = async (values: { message: string }) => {
    await requestUpgrade({
      variables: {
        ...values,
      },
    });
  };

  useEffect(() => {
    if (company && (isCompanyPlanExpired || isInactive)) {
      setOpen(true);
    }
  }, [company, isCompanyPlanExpired, isInactive]);

  if (!company) return <Loader />;

  return (
    <CompanyPlanContext.Provider
      value={{
        open,
        companyType: partnerData?.partner.type || PartnerTypeEnum.SUPPLIER,
        setOpen,
        daysLeftInTrial,
        plan,
        isPartner: company?.isPartner || false,
        partnerId: company?.partnerId,
      }}
    >
      <CompanyPlanDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleLogout={logout}
        requestSent={requestSent}
        daysLeftInTrial={daysLeftInTrial}
        expired={isCompanyPlanExpired}
        isInactive={isInactive}
      />
      {children}
    </CompanyPlanContext.Provider>
  );
};

export { CompanyPlanProvider, useCompanyPlanContext };

export default CompanyPlanContext;
