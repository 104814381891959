import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from './company';

export const WORKER_FRAGMENT = gql`
  fragment workerValues on Worker {
    id
    name
    occupation
    payeeId
    locationCoordinates {
      lat
      lng
    }
    farm {
      id
    }
  }
`;

export const PAYMENT_FRAGMENT = gql`
  fragment paymentValues on Payment {
    paymentType
    quantity
    amount
    blockchainId
    timestamp
    payee {
      ... on Worker {
        ...workerValues
      }

      ... on Company {
        ...companyValues
      }
    }
    payer {
      ... on Worker {
        ...workerValues
      }

      ... on Company {
        ...companyValues
      }
    }
    rawMaterial {
      id
      title
    }
  }
  ${WORKER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;
