import { ApolloCache } from '@apollo/client';
import { Box, Grid, MenuItem, Select, styled } from '@mui/material';
import { LimitedTextField } from 'components/Forms';
import HTMLEditorField from 'designSystem/Inputs/HTMLEditorField/HTMLEditorField';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { DialogInner } from 'components/Partners/Partners.styles';
import { useDialog, useFeatureFlags, useMessages } from 'components/hooks';
import { PartnerRequestSchema } from 'constants/schemas';
import { DialogDefault, FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Partner, PartnerRequest, PartnerRequestForm, RequestType } from 'types/partner.types';
import { PartnerAutocomplete } from '../Form';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { REQUEST_ICON_NAME } from 'designSystem/utils/designSystemUtils';
import ComponentAutocomplete from 'components/ComponentsLibrary/Forms/ComponentAutocomplete';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { IComponentItem } from 'types/component.types';

export interface IPartnerRequestDialogProps extends IDefaultDialogProps {
  initialRequest?: PartnerRequest;
  partner?: Partner;
  /**
   * Preselects & disables the type selection.
   */
  requestType?: keyof typeof RequestType;
  onGetCreatedRequest?: (request: PartnerRequest) => void;
  onUpdateCache?: (cache: ApolloCache<unknown>, request: PartnerRequest) => void;
}

export const PartnerImage = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 4,
  objectFit: 'cover',
  marginRight: theme.spacing(2),
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
}));

const AutoCompleteWrapper = styled(Box)(({ theme }) => ({
  flex: 1,

  '& .MuiOutlinedInput-input': {
    padding: '4px 6px !important',
  },
}));

const ComponentContainer = styled(Grid)(({ theme }) => ({
  '&.MuiGrid-root': {
    paddingTop: theme.spacing(1.5),
  },
}));

const ComponentBox = styled(Box)(({ theme }) => ({
  background: theme.custom.colors.backgroundLight,
  borderRadius: 4,
  padding: 12,
}));

const PartnerRequestDialog: FC<IPartnerRequestDialogProps> = ({
  open,
  onClose,
  partner,
  initialRequest,
  requestType,
  onGetCreatedRequest,
  onUpdateCache,
}) => {
  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>();
  const partnerId = partner?.id;
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { createPartnerRequest, loading, updatePartnerRequest } = usePartnerRequestActions();
  const { closeDialog } = useDialog();
  const { isFeatureEnabled } = useFeatureFlags();
  // We need the component in order to suggest a title and description for the request.
  const [selectedComponent, setSelectedComponent] = useState<IComponentItem | undefined>();
  const enableChainRequests = isFeatureEnabled(FeatureFlag.ENABLE_CHAIN_REQUESTS);
  const isCreateRequest = !initialRequest;

  const handleSubmitRequest = (values: PartnerRequestForm) => {
    if (isCreateRequest) {
      createPartnerRequest({
        variables: {
          input: {
            requestTitle: values.requestTitle,
            requestMessage: values.requestMessage,
            partnerId: values.partnerId || (selectedPartner?.id as string),
            requestType: values.requestType,
            ...(values.componentId && { componentId: values.componentId }),
          },
        },
        onError: () =>
          setErrorMessage('Something went wrong sending the request. Please try again later.'),
        onCompleted: data => {
          setSuccessMessage('The request has been sent to your partner');
          if (onGetCreatedRequest) {
            onGetCreatedRequest(data.createPartnerRequest.partnerRequest);
          }
          onClose?.();
        },
        update: (cache, { data }) => {
          if (onUpdateCache && data) {
            onUpdateCache(cache, data?.createPartnerRequest.partnerRequest);
          }
        },
      });
    } else {
      updatePartnerRequest({
        variables: {
          id: initialRequest.id,
          input: {
            requestStatus: 'EDITED',
            documentId: undefined,
            requestReplyMessage: undefined,
            requestMessage: values.requestMessage,
          },
        },
        onError: () =>
          setErrorMessage('Something went wrong updating the request. Please try again later.'),
        onCompleted: () => {
          setSuccessMessage('The request has been updated and was sent to your partner');
          closeDialog();
        },
        update: (cache, { data }) => {
          if (onUpdateCache && data) {
            onUpdateCache(cache, data?.updatePartnerRequest.partnerRequest);
          }
        },
      });
    }
  };

  const formikBag = useFormik<PartnerRequestForm>({
    initialValues:
      initialRequest && partnerId
        ? {
            requestTitle: initialRequest.requestTitle,
            requestMessage: initialRequest.requestMessage,
            requestType: requestType || initialRequest.requestType,
            componentId: initialRequest?.componentId,
            partnerId,
          }
        : ({
            ...PartnerRequestSchema.default(),
            ...(requestType ? { requestType } : {}),
            partnerId,
          } as PartnerRequestForm),
    onSubmit: handleSubmitRequest,
    validationSchema: PartnerRequestSchema,
    validateOnMount: true,
    validateOnChange: true,
  });

  const { values, handleSubmit, isValid, setFieldValue, errors } = formikBag;

  const handleSelectComponent = (component: IComponentItem | null) => {
    if (!!component) {
      setSelectedComponent(component);
    }
    setFieldValue('componentId', component?.id);
  };

  useEffect(() => {
    // Selecting the component automatically suggests a description and title, if empty.
    if (values.requestType === RequestType.CHAIN && values.componentId) {
      if (!values.requestTitle && selectedComponent) {
        setFieldValue('requestTitle', `${selectedComponent.title} chain mapping`);
      }
      if (!values.requestMessage && selectedComponent) {
        setFieldValue(
          'requestMessage',
          `We are currently working on mapping our supply chain to enhance transparency and traceability. Could you please assist us by providing detailed information on the ‘${selectedComponent.title}’ chain, including the chain steps, activities, involved partners, and locations?`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.componentId, values.requestType]);

  const disableButton = !isValid || (!partnerId && !selectedPartner);

  return (
    <DialogDefault
      open={open}
      iconName={REQUEST_ICON_NAME[values.requestType]}
      title={initialRequest ? 'Edit request' : 'New request'}
      onClose={onClose}
      maxWidth="sm"
      data-cy="partner-request-dialog"
    >
      <form onSubmit={handleSubmit}>
        <Box p={3}>
          <DialogInner container minHeight={300} spacing={2}>
            <Grid item xs={6}>
              <FieldWrapper label="Request from">
                {!!partner ? (
                  <Box maxHeight={33} display="flex" alignItems="center">
                    {partner.logo && (
                      <PartnerImage alt={`Logo of ${partner.title}`} src={partner.logo?.url} />
                    )}
                    <ThemeTypography variant="BODY_LARGE_BOLD">{partner.title}</ThemeTypography>
                  </Box>
                ) : (
                  <AutoCompleteWrapper>
                    <PartnerAutocomplete
                      inviteNewPartner
                      onPartnerSelect={partner => setSelectedPartner(partner || undefined)}
                    />
                  </AutoCompleteWrapper>
                )}
              </FieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldWrapper label="Request type">
                <Select
                  variant="outlined"
                  value={values['requestType']}
                  onChange={e => setFieldValue('requestType', e.target.value as RequestType)}
                  inputProps={{
                    name: 'requestType',
                    'data-cy': 'request-form-type',
                  }}
                  fullWidth
                  disabled={!isCreateRequest || !!requestType}
                >
                  <MenuItem value="DOCUMENT">Document</MenuItem>
                  {enableChainRequests && <MenuItem value="CHAIN">Chain mapping</MenuItem>}
                  <MenuItem disabled value={'Other'}>
                    More coming soon
                  </MenuItem>
                </Select>
              </FieldWrapper>
            </Grid>
            {values.requestType === RequestType.CHAIN && (
              <Grid item xs={12}>
                <ComponentContainer item xs={12}>
                  <ComponentBox>
                    <FieldWrapper label="Select component">
                      <ComponentAutocomplete
                        defaultSelectedId={values['componentId']}
                        onSelect={component => handleSelectComponent(component)}
                        size="small"
                        partner={partner}
                        hideOtherPartnerComponents
                        disabled={!isCreateRequest}
                      />
                    </FieldWrapper>
                  </ComponentBox>
                </ComponentContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <LimitedTextField
                label="Request title *"
                value={values['requestTitle']}
                name="requestTitle"
                onChange={e => setFieldValue('requestTitle', e.target.value)}
                errorMessage={errors['requestTitle']}
                fullWidth
                data-cy="request-form-title"
                charLimit={50}
                disabled={!isCreateRequest}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Describe your request">
                <HTMLEditorField
                  minHeight={122}
                  value={values['requestMessage']}
                  data-cy="request-form-message"
                  name="requestMessage"
                  onChange={setFieldValue}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mt={0.5} mb={1} gap={1}>
                <Icon name="lock" size="small" color="gray-40" />
                <ThemeTypography variant="BODY_MEDIUM" color="GRAY_60">
                  Avoid mentioning company names or other sensitive information.
                </ThemeTypography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <ThemeButton color="BLUE_ICE" size="large" onClick={onClose}>
                  Cancel
                </ThemeButton>
                <ThemeButton
                  loading={loading}
                  size="large"
                  disabled={disableButton}
                  data-cy="request-form-submit"
                  type="submit"
                  tooltip={!disableButton ? undefined : 'Fill in all required fields'}
                >
                  Send Request
                </ThemeButton>
              </Box>
            </Grid>
          </DialogInner>
        </Box>
      </form>
    </DialogDefault>
  );
};

export default PartnerRequestDialog;
