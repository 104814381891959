import { Box } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { PageSubTitle } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import React, { FC } from 'react';

const RiskResultsInProgress: FC = () => {
  const { statement } = useEudrComplianceGuide();

  return (
    <EudrComplianceGuideSubSection titleLoading disableNavigation>
      <ThemeTypography variant="BODY_MEDIUM">
        This can take some time. You will receive a notification via email once the analysis is
        ready. In the meantime, you can continue your due diligence.
      </ThemeTypography>
      <Box mt={5} mb={1}>
        <PageSubTitle title="SUBMITTED DATA" />
      </Box>
      <Box display="flex" gap={2} flexWrap="wrap">
        {statement.eudrDatasets.map((dataSheet, index) => (
          <DatasetItem
            key={index}
            title={dataSheet.title}
            location={dataSheet.originCountry}
            owner={dataSheet.partner.title}
            commodity={dataSheet.rawMaterial.title}
            checked
            disabled
          />
        ))}
      </Box>
    </EudrComplianceGuideSubSection>
  );
};

export default RiskResultsInProgress;
