import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { Loader, SearchToolbar } from 'components/Forms';
import { EmptySearch, ErrorState } from 'components/Structure';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { IComponentItem } from 'types/component.types';
import ComponentItemTable from './ComponentItemsTable';
import { COMPONENT_ITEM_IDS } from './constants/headerColumns';
import useComponentItems from './hooks/useComponentItems';

const SearchbarContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  top: -35,
  left: 0,
  zIndex: 9,
}));

interface IComponentSelectorProps {
  selectedComponent?: IComponentItem;
  setSelectedComponent: (value: IComponentItem) => void;
}

const ComponentSelector: FC<IComponentSelectorProps> = ({
  selectedComponent,
  setSelectedComponent,
}) => {
  // Using custom search, and not the one from usePartner, because we don't want it in the query params
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { components, loading, error } = useComponentItems({ customSearch: searchTerm });

  const filteredComponents = useMemo(() => {
    if (!components) return [];
    return components.filter(component =>
      component.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [components, searchTerm]);

  if (error) return <ErrorState />;
  else if (!components && loading) return <Loader />;

  return (
    <Fragment>
      <Box position="relative" mt={7} mb={3}>
        <SearchbarContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            data-cy="component-search-input"
            placeholder="Search components"
            className="search-input"
          />
        </SearchbarContainer>
        <ComponentItemTable
          hideHeader
          components={filteredComponents}
          selected={selectedComponent}
          onSelect={setSelectedComponent}
          columns={[COMPONENT_ITEM_IDS.TITLE, COMPONENT_ITEM_IDS.PARTNER]}
        />
      </Box>
      {!filteredComponents.length && <EmptySearch />}
    </Fragment>
  );
};

export default ComponentSelector;
