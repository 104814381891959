import { Box, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import useEudrStatementDatasets from 'components/ComponentsLibrary/hooks/useEudrStatementDatasets';
import { FlexBox } from 'components/Structure';
import {
  AddBatchDetailsFormValues,
  addBatchDetailsSchema,
  batchDetailSchema,
} from 'constants/schemas/riskAnalysis.schema';
import { InfoTooltip, ThemeTypography } from 'designSystem';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Switch } from 'formik-mui';
import pick from 'lodash/pick';
import React, { FC, useMemo, useRef } from 'react';
import { EudrStatementStatus } from 'types/compliance.types';
import DatasetAccordionField from '../../Forms/DatasetAccordionField';

const SwitchContainer = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  width: 'fit-content',
  borderRadius: 8,
  padding: theme.spacing(0, 2),
}));

const AddBatchDetails: FC = () => {
  const formRef = useRef<FormikProps<AddBatchDetailsFormValues>>(null);
  const { formData, statement, setFormData, updateStatement } = useEudrComplianceGuide();
  const { submitEudrRiskAnalysis } = useEudrStatementDatasets();

  const initialValues: AddBatchDetailsFormValues = useMemo(
    () => ({
      ...addBatchDetailsSchema.default(),
      datasetBatchRelation:
        statement?.eudrDatasets
          .filter(({ id }) => formData.datasetIds.includes(id))
          .map(dataset => ({
            datasetId: dataset.id,
            ...pick(dataset, ['partner', 'title', 'originCountry', 'rawMaterial']),
            batches: [batchDetailSchema.default()],
          })) || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = async (values: AddBatchDetailsFormValues) => {
    setFormData(undefined);
    try {
      await submitEudrRiskAnalysis({
        statementId: statement.id,
        datasetBatches: values.datasetBatchRelation.map(({ datasetId, batches }) => ({
          eudrDatasetId: datasetId,
          batches,
        })),
      });
      updateStatement(statement.id, { status: EudrStatementStatus.PENDING_RISK_ANALYSIS });
    } catch (error) {
      console.error(error);
    } finally {
      formRef.current?.setSubmitting(false);
    }
  };

  return (
    <Formik<AddBatchDetailsFormValues>
      innerRef={formRef}
      validationSchema={addBatchDetailsSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, isSubmitting, submitForm }) => (
        <Form>
          <EudrComplianceGuideSubSection
            allowNextStepNavigation={isValid}
            nextStepLoading={isSubmitting}
            customNextStepButton={{ text: 'Start risk analysis', startIcon: 'analysis' }}
            onNextStepClick={submitForm}
          >
            <SwitchContainer>
              <ThemeTypography variant="BODY_MEDIUM">
                Auto-assign unique id for new rows:
              </ThemeTypography>
              <Field
                name="autoAssignIds"
                fullWidth
                component={Switch}
                type="checkbox"
                label="Auto-assign unique origin batch IDs"
              />
              <InfoTooltip
                variant="INFO"
                text="If you are using id numbers to identify different batches, it is better to use your existing id numbers. If not, you can enable here to assign an id to each new row. This will ensure a unique id for every batch."
              />
            </SwitchContainer>
            <Box mb={2} />

            <Box width="100%" display="flex" flexDirection="column">
              {values.datasetBatchRelation.map(({ datasetId }, datasetIndex) => (
                <Field
                  key={datasetId}
                  name={`datasetBatchRelation.[${datasetIndex}]`}
                  component={DatasetAccordionField}
                />
              ))}
            </Box>
          </EudrComplianceGuideSubSection>
        </Form>
      )}
    </Formik>
  );
};

export default AddBatchDetails;
