import React, { FC, useMemo, useRef, useState } from 'react';
import {
  StyledIconButton,
  IconContainer,
  StyledCell,
  TableRow,
  Date,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import { Box, Collapse, styled, Table, TableBody, TableCell, Tooltip } from '@mui/material';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { IClusterSite, IFarmSite, ISite, SiteType } from 'types/sites.types';
import { HeaderColumn } from 'types/types';
import { SitesTableColumnKeys } from './sitesHeaderColumns';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { PartnerPlaceholderImage } from 'assets/img';
import Moment from 'react-moment';
import { PencilSquare, Trash } from '@styled-icons/bootstrap';
import { isSafariBrowser } from 'utils';
import InnerTableRow from './InnerTableRow';
import { StyledTableRow } from 'components/TableBase';
import { ThemeButton } from 'designSystem';
import { FlexBox } from 'components/Structure';

interface ISiteClusterTableRowProps {
  siteCluster: IClusterSite;
  headerColumns: HeaderColumn[];
  onSiteClick?: (site: ISite) => void;
  onSiteEdit?: (site: ISite) => void;
  onSiteDelete?: (siteId: string) => void;
  onSiteClusterEdit?: (siteCluster: IClusterSite) => void;
  onManageClusterSites?: (siteClusterId: string) => void;
  onSiteClusterDelete?: (siteId: string) => void;
  onEditCultivationArea?: (site: ISite) => void;
}

// Creates a row with 0 height, but has the global table border-spacing applied so it acts as an additional spaceing item.
// Used to have enough spacing after the innerTableRows in the collapsed group within the shadow container.
const SpacingRow = styled('tr')(() => ({
  height: 0,
}));

const ShadowContainer = styled('div')<{ height: number; open: booleanish; display: booleanish }>(
  ({ theme, height, open, display }) => ({
    display: display === 'true' ? 'box' : 'none',
    position: 'absolute',
    background: '#fff',
    top: 0,
    left: 8,
    right: 8,
    bottom: open === 'true' ? -height : -8,
    zIndex: -1,
    borderRadius: 6,
    boxShadow: theme.custom.shadows[1],
  })
);

const InnerSiteTableRow = styled(StyledTableRow)<{ display: booleanish }>(({ display }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  display: display === 'true' ? 'table-row' : 'none',

  '&:hover': {
    background: 'transparent !important',
    boxShadow: 'none',
    cursor: 'pointer',
  },
}));

const UnclickableTableRow = styled(StyledTableRow)(({ theme }) => ({
  '&:hover': {
    background: 'white !important',
    cursor: 'default',
  },
}));

const SiteClusterTableRow: FC<ISiteClusterTableRowProps> = ({
  siteCluster,
  headerColumns,
  onSiteClick,
  onSiteClusterEdit,
  onSiteEdit,
  onManageClusterSites,
  onSiteClusterDelete,
  onEditCultivationArea,
  onSiteDelete,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const isSafari = isSafariBrowser();
  // use table cell to calculate the height of shadow container when opening impact claim group
  const firstTableCell = useRef<HTMLTableDataCellElement>(null);

  const sites = siteCluster.sites;
  const hasSites = sites?.edges && sites.edges.length > 0;
  const rawMaterial = useMemo(() => {
    if (!siteCluster.sites?.edges.length) return undefined;
    if (siteCluster.sites?.edges[0]?.node.siteType !== SiteType.FARM) return undefined;

    return (siteCluster.sites?.edges[0]?.node as IFarmSite).rawMaterial;
  }, [siteCluster]);

  return (
    <>
      <TableRow
        data-cy="site-cluster-table-row"
        onClick={event => {
          event.stopPropagation();
          onSiteClick?.(siteCluster);
        }}
      >
        {headerColumns.map(({ id: columnId, align }) => {
          switch (columnId) {
            case SitesTableColumnKeys.TITLE:
              return (
                <StyledCell
                  ref={firstTableCell}
                  key={`${siteCluster.id}-${columnId}-title`}
                  scope="row"
                  align={align}
                >
                  <Box display="flex" alignItems="center">
                    {!!siteCluster.image ? (
                      <Image width={44} height={44} image={siteCluster.image} />
                    ) : (
                      <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
                        <Icon name="site-cluster-placeholder" size="xx-large" color="blue-ice" />
                      </Box>
                    )}
                    <Box ml={1.5}>
                      <ThemeTypography variant="ITEM_TITLE">{siteCluster.title}</ThemeTypography>
                      <Box display="flex" alignItems="center">
                        <Icon name="tag-filled" size="small" color="gray-60" />
                        <Box ml={0.5} />
                        <ThemeTypography variant="LABEL_INPUT">Origin cluster</ThemeTypography>
                        <Box ml={1} />
                        {rawMaterial && (
                          <>
                            <Icon name="raw-material" size="small" color="gray-60" />
                            <Box ml={0.5} />
                            <ThemeTypography variant="LABEL_INPUT">
                              {rawMaterial.title}
                            </ThemeTypography>
                            <Box ml={1} />
                          </>
                        )}
                        <Icon name="location" size="small" color="gray-60" />
                        <Box ml={0.5} />
                        <ThemeTypography variant="LABEL_INPUT">
                          {siteCluster.locationName}
                        </ThemeTypography>
                      </Box>
                    </Box>
                  </Box>
                </StyledCell>
              );
            case SitesTableColumnKeys.PARTNER:
              return (
                <StyledCell key={`${siteCluster.id}-${columnId}-partner`} scope="row" align={align}>
                  <Box display="flex" alignItems="center">
                    <Image
                      width={44}
                      height={44}
                      image={siteCluster.partner?.logo}
                      backupImageUrl={PartnerPlaceholderImage}
                    />
                    <Box ml={1}>
                      <ThemeTypography variant="ITEM_SUBTITLE">
                        {siteCluster.partner?.title}
                      </ThemeTypography>
                    </Box>
                  </Box>
                </StyledCell>
              );
            case SitesTableColumnKeys.CREATED_AT:
              return (
                <StyledCell
                  key={`${siteCluster.id}-${columnId}-created-at`}
                  scope="row"
                  align={align}
                >
                  <Date>
                    <Moment format="MMM DD, YYYY">{siteCluster.createdAt}</Moment>
                  </Date>
                </StyledCell>
              );
            case SitesTableColumnKeys.ACTIONS:
              return (
                <TableCell
                  key={`${siteCluster.id}-${columnId}-actions`}
                  width={100}
                  scope="row"
                  align="right"
                >
                  <IconContainer>
                    {!!onManageClusterSites && (
                      <Tooltip title="Manage cluster sites" placement="top">
                        <StyledIconButton
                          aria-label="add"
                          onClick={event => {
                            event.stopPropagation();
                            onManageClusterSites(siteCluster.id);
                          }}
                        >
                          <Icon name="site-cluster-manage" />
                        </StyledIconButton>
                      </Tooltip>
                    )}
                    {!!onSiteClusterEdit && (
                      <Tooltip title="Edit" placement="top">
                        <StyledIconButton
                          aria-label="edit"
                          onClick={event => {
                            event.stopPropagation();
                            onSiteClusterEdit(siteCluster);
                          }}
                        >
                          <PencilSquare size={14} />
                        </StyledIconButton>
                      </Tooltip>
                    )}
                    <Tooltip
                      title={
                        siteCluster.inUse
                          ? 'Site cluster is used on an activity. Remove the site cluster on the activity first to be able to delete.'
                          : 'Delete'
                      }
                      placement="top"
                    >
                      <span>
                        <StyledIconButton
                          aria-label="delete"
                          disabled={siteCluster.inUse}
                          onClick={event => {
                            event.stopPropagation();
                            onSiteClusterDelete?.(siteCluster.id);
                          }}
                        >
                          <Trash size={14} />
                        </StyledIconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                      <StyledIconButton
                        aria-label="edit"
                        onClick={event => {
                          event.stopPropagation();
                          setOpen(!open);
                        }}
                      >
                        <Icon name={open ? 'chevron-up' : 'chevron-down'} />
                      </StyledIconButton>
                    </Tooltip>
                  </IconContainer>
                  <ShadowContainer
                    display={Booleanish(!isSafari)}
                    open={Booleanish(open)}
                    height={
                      (sites?.edges?.length ?? 0) *
                        ((firstTableCell?.current?.clientHeight ?? 0) + 8) +
                      8
                    }
                  />
                </TableCell>
              );
            default:
              return <></>;
          }
        })}
      </TableRow>
      {hasSites &&
        sites?.edges?.map(({ node }) => (
          <InnerTableRow
            key={node.id}
            site={node}
            open={open}
            headerColumns={headerColumns}
            onClick={onSiteClick}
            onEdit={onSiteEdit}
            onDelete={onSiteDelete}
            onEditCultivationArea={onEditCultivationArea}
          />
        ))}
      {!hasSites && (
        <InnerSiteTableRow data-cy="site-table-item" hover display={Booleanish(open)}>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box mr={-1.5} ml={1}>
                <Table size="small">
                  <TableBody>
                    <UnclickableTableRow>
                      <TableCell>
                        <FlexBox p={0.5}>
                          <ThemeButton
                            color="BLUE_ICE"
                            startIcon={<Icon name="site-cluster-manage" />}
                            onClick={() => onManageClusterSites?.(siteCluster.id)}
                          >
                            Manage cluster sites
                          </ThemeButton>
                        </FlexBox>
                      </TableCell>
                    </UnclickableTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </InnerSiteTableRow>
      )}
      <SpacingRow />
    </>
  );
};

export default SiteClusterTableRow;
