import React, { FC, useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { RiskLevel } from 'types/compliance.types';
import { ThemeTypography } from 'designSystem';
import theme from 'styles/theme';
import RiskPieLabel from 'designSystem/DataDisplay/RiskPie/RiskPieLabel';
import { PieChart } from '@mui/x-charts/PieChart';

interface IAssessmentChartProps {
  highRisks: number;
  mediumRisks: number;
  lowRisks: number;
  notAnswered: number;
}

const RISK_COLOR: Record<keyof typeof RiskLevel | 'PENDING', string> = {
  LOW: theme.custom.themeColors.success[100],
  MEDIUM: theme.custom.themeColors.accent[100],
  HIGH: theme.custom.themeColors.error[80],
  PENDING: theme.custom.themeColors.primary[40],
};

const Container = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  padding: theme.spacing(2),
}));

const RiskBadge = styled(Box)<{ riskLevel: keyof typeof RiskLevel | 'PENDING' }>(
  ({ theme, riskLevel }) => ({
    position: 'relative',
    backgroundColor: theme.custom.themeColors.primary[5],
    borderRadius: 20,
    padding: theme.spacing(0.5, 2, 0.5, 3.5),
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: '13px',
      height: '13px',
      borderRadius: '50%',
      backgroundColor: RISK_COLOR[riskLevel],
      position: 'absolute',
      top: '50%',
      left: theme.spacing(2),
      transform: 'translate(-50%, -50%)',
    },
  })
);

const ChartContainer = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  '& .MuiPieArc-root': {
    strokeWidth: 0,
  },
}));

const AssessmentChart: FC<IAssessmentChartProps> = ({
  highRisks,
  mediumRisks,
  lowRisks,
  notAnswered,
}) => {
  const { custom } = useTheme();

  const data = [
    { label: 'Low Risk', value: lowRisks, color: custom.themeColors.success[100] },
    { label: 'Medium Risk', value: mediumRisks, color: custom.themeColors.accent[100] },
    { label: 'High Risk', value: highRisks, color: custom.themeColors.error[80] },
    { label: 'In Progress', value: notAnswered, color: custom.themeColors.primary[40] },
  ];

  const percentageHighRisk = useMemo(
    () => ((highRisks / (lowRisks + notAnswered + mediumRisks + highRisks)) * 100).toFixed(0),
    [lowRisks, notAnswered, mediumRisks, highRisks]
  );

  return (
    <Container>
      <Box display="flex" flexWrap="wrap" mb={2} gap={1}>
        <RiskBadge riskLevel="HIGH">
          <ThemeTypography variant="BUTTON_SMALL">{highRisks} High risk answers</ThemeTypography>
        </RiskBadge>
        <RiskBadge riskLevel="MEDIUM">
          <ThemeTypography variant="BUTTON_SMALL">
            {mediumRisks} Medium risk answers
          </ThemeTypography>
        </RiskBadge>
        <RiskBadge riskLevel="LOW">
          <ThemeTypography variant="BUTTON_SMALL">{lowRisks} Low risk answers</ThemeTypography>
        </RiskBadge>
        <RiskBadge riskLevel="PENDING">
          <ThemeTypography variant="BUTTON_SMALL">{mediumRisks} answers pending</ThemeTypography>
        </RiskBadge>
      </Box>
      <ChartContainer>
        <PieChart
          series={[
            {
              data,
              innerRadius: 230 / 3.5,
            },
          ]}
          margin={{ right: 0, left: 0, top: 0, bottom: 0 }}
          height={230}
          width={230}
          slotProps={{
            legend: { hidden: true },
          }}
          tooltip={{ trigger: 'none' }}
        >
          <RiskPieLabel label={`${percentageHighRisk}%`} subLabel="High risk" />
        </PieChart>
      </ChartContainer>
    </Container>
  );
};

export default AssessmentChart;
