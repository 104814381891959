import { DialogContent, styled, useTheme } from '@mui/material';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { SeparatorLine } from 'components/Structure';
import { useDialog } from 'components/hooks';
import { DialogDefault } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import React, { FC } from 'react';
import { IChainActivity, IChainStep, ISubChain } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Partner } from 'types/partner.types';

const HelperTextWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
}));

interface IActivitySelectDecisionDialogProps extends IDefaultDialogProps {
  selectedStepId: string;
  steps: IChainStep[];
  activities: IChainActivity[];
  subChains: ISubChain[];
  /** If set it will open the next dialog in partner mode */
  partner?: Partner;
  onActivityAdd: (
    activityId: string,
    activity?: Pick<IChainActivity, 'chainStepId' | 'incomingLinks'>
  ) => void;
}

const ActivitySelectDecisionDialog: FC<IActivitySelectDecisionDialogProps> = ({
  open,
  selectedStepId,
  steps,
  activities,
  subChains,
  partner,
  onActivityAdd,
  onClose,
}) => {
  const { openDialog } = useDialog();
  const theme = useTheme();

  const handleSelectExistingActivity = () => {
    openDialog({
      type: 'SELECT_EXISTING_ACTIVITY',
      props: { onlyInternal: !!partner, onActivityAdd },
    });
    onClose?.();
  };

  const handleAddNewActivity = () => {
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: {
        mode: 'add-chain-activity',
        partner,
        hidePartnerSelector: !!partner,
        selectedStepId,
        steps,
        subChains,
        activities,
        onActivityAdd,
      },
    });
    onClose?.();
  };

  return (
    <DialogDefault
      open={open}
      title="Add Activity"
      onClose={onClose}
      maxWidth="xs"
      data-cy="decision-dialog"
    >
      <DialogContent>
        <ContentWrapper>
          <ThemeButton
            color="YELLOW"
            size="large"
            fullWidth
            data-cy="select-partner-button"
            onClick={handleSelectExistingActivity}
          >
            Select existing activity
          </ThemeButton>
          <SeparatorLine label="or" width="80%" backgroundColor={theme.custom.colors.offWhite} />
          <ThemeButton
            color="BLUE_ICE"
            size="large"
            fullWidth
            data-cy="add-new-activity-button"
            onClick={handleAddNewActivity}
          >
            Add new
          </ThemeButton>

          <HelperTextWrapper>
            <InlineHelperText
              variant="INFO"
              helperText="You can connect a new activity to an existing partner."
            />
          </HelperTextWrapper>
        </ContentWrapper>
      </DialogContent>
    </DialogDefault>
  );
};

export default ActivitySelectDecisionDialog;
