import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { InfoTooltip, Tabs } from 'designSystem';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import { GET_PARTNER_ONLY } from 'graphql/queries/partners';
import React, { FC, PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AvailableSizes } from 'types/enums';
import { Partner } from 'types/partner.types';
import { RouteParamsWithId } from 'types/router.types';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

enum PartnerComponentChainTabKey {
  COMPONENTS = 'components',
  CHAINS = 'component-chains',
}

interface IPartnerComponentsLayoutProps extends PropsWithChildren {
  selectedTab: `${PartnerComponentChainTabKey}`;
}

const COMPONENT_TABS: ITab<PartnerComponentChainTabKey>[] = [
  {
    key: PartnerComponentChainTabKey.COMPONENTS,
    label: 'Components',
    startIconName: 'components',
  },
  {
    key: PartnerComponentChainTabKey.CHAINS,
    label: 'Component chains',
    startIconName: 'component-chain',
  },
];

const PartnerComponentsLayout: FC<IPartnerComponentsLayoutProps> = ({ children, selectedTab }) => {
  usePageTitle('Partner components');
  const { id: partnerId } = useParams<RouteParamsWithId>();
  const navigate = useNavigate();
  const { isPartner } = useCompanyPlanContext();

  const { data: partnerData } = useQuery<{ partner: Partner }>(GET_PARTNER_ONLY, {
    variables: {
      id: partnerId,
    },
    skip: !partnerId,
    fetchPolicy: 'cache-first',
  });

  const handleChangeTab = (tab: PartnerComponentChainTabKey) => {
    if (tab === PartnerComponentChainTabKey.COMPONENTS) {
      navigate(`/partners/${partnerId}/components`);
    } else {
      navigate(`/partners/${partnerId}/component-chains`);
    }
  };

  return (
    <PageContainer>
      <Box>
        <PageTitle
          title="Components"
          titleExtension={partnerData?.partner?.title}
          goBackLabel="All partners"
          goBackUrl={`/partners`}
          TitleAdornment={
            <Box ml={1}>
              <InfoTooltip
                size={AvailableSizes.LARGE}
                text="Components are ingredients, packaging or something else that makes up your end products. All components that are part of your supply chains appear here. You can specify what components are coming from what partner and re-use your components in several supply chain activities. Component chains are re-usable sub-supply chains. They describe the material flow of your components, and can also be re-used across several of your different product’s supply chains."
              />
            </Box>
          }
        />
      </Box>

      {!isPartner && (
        <Box mt={3} mb={1}>
          <Tabs
            tabs={COMPONENT_TABS}
            selectedTab={selectedTab as PartnerComponentChainTabKey}
            onChange={handleChangeTab}
          />
        </Box>
      )}

      {children}
    </PageContainer>
  );
};

export default PartnerComponentsLayout;
