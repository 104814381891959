import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import { Box } from '@mui/material';
import { PageSubTitle } from 'components/Structure';
import { ItemCardSmall } from 'designSystem';
import truncate from 'lodash/truncate';
import { IPartnersOnSupplyChain } from 'types/partner.types';
import { getReadablePartnerStatus, getPartnerStatusColor } from 'utils/partnerUtils';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import Image from 'designSystem/DataDisplay/Image/Image';

interface IPartnerListProps {
  partners: IPartnersOnSupplyChain[];
}

const PartnerList: FC<IPartnerListProps> = ({ partners }) => {
  return (
    <Box flex={1} data-cy="partner-list">
      <PageSubTitle title="Connected partners" mb={2} />
      <Box>
        {partners.map(partner => (
          <ItemCardSmall key={partner.id}>
            <Box display="flex" alignItems="center" gap={2.5}>
              <Box pl={1}>
                <Image
                  alt={`Logo of ${partner.title}`}
                  image={partner.logo}
                  backupImageUrl={defaultPartnerImage}
                  width={42}
                  height={42}
                />
              </Box>
              <Box>
                <Tooltip title={partner.title || ''}>
                  <ThemeTypography variant="ITEM_TITLE">
                    {truncate(partner.title, {
                      length: 45,
                      separator: '',
                    })}
                  </ThemeTypography>
                </Tooltip>
                {partner.status && (
                  <Box display="flex" alignItems="center" gap={0.8}>
                    <ThemeTypography
                      variant="ITEM_SUBTITLE"
                      color={getPartnerStatusColor(partner.status)}
                    >
                      {getReadablePartnerStatus(partner.status)}
                    </ThemeTypography>
                  </Box>
                )}
              </Box>
            </Box>
          </ItemCardSmall>
        ))}
      </Box>
    </Box>
  );
};

export default PartnerList;
