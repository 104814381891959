import React, { FC, useEffect } from 'react';
import { IActivity, ActivityType } from 'types/partner.types';
import useActivities from 'hooks/useActivities';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { Box } from '@mui/material';

interface IActivitySelectionProps {
  activityType: ActivityType;
  setActivities: (activities: IActivity[]) => void;
  setLoading: (loading: boolean) => void;
}

const ActivitySelection: FC<IActivitySelectionProps> = ({
  setActivities,
  activityType,
  setLoading,
}) => {
  const { activities, loading } = useActivities({ typeOfActivity: activityType });

  useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setActivities(activities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  return (
    <Box mt={3}>
      <InlineHelperText
        variant="INFO"
        helperText={
          activityType === ActivityType.INTERNAL
            ? 'Select one of your own activities here, or switch tabs to select other activity.'
            : 'Select an activity that has not yet been assigned to a company.'
        }
      />
    </Box>
  );
};

export default ActivitySelection;
