import { gql } from '@apollo/client';
import { CHAIN_BASE_FRAGMENT, CHAIN_FRAGMENT } from 'graphql/fragments/chains';

export const GET_CHAIN = gql`
  query GetChain($id: UUID!, $lang: Language) {
    chain(id: $id, lang: $lang) {
      ...chainValues
    }
  }
  ${CHAIN_FRAGMENT}
`;

export const GET_BASE_CHAIN = gql`
  query GetBaseChain($id: UUID!) {
    chain(id: $id) {
      ...chainBaseValues
    }
  }
  ${CHAIN_BASE_FRAGMENT}
`;

/**
 * Returns a list of all base component chains (or a specific from a specific component)
 * @type COMPONENT_CHAIN_BASE_FRAGMENT
 */
export const GET_CHAINS = gql`
  query GetChains($chainFilter: ChainType, $partnerId: UUID) {
    chains(chainFilter: $chainFilter, partnerId: $partnerId) {
      edges {
        node {
          ...chainBaseValues
        }
      }
    }
  }
  ${CHAIN_BASE_FRAGMENT}
`;

/**
 * Returns a component chain for the onboarding process depending on the onboarding step.
 * client-side only (see resolvers)
 * @param id The id of the component chain
 * @param onboardingStep The onboarding step
 * @type COMPONENT_CHAIN_FRAGMENT
 */
export const GET_ONBOARDING_CHAIN = gql`
  query GetOnboardingChain($id: UUID!, $onboardingStep: Int!) {
    onboardingChain(id: $id, onboardingStep: $onboardingStep) @client {
      ...chainValues
    }
  }
  ${CHAIN_FRAGMENT}
`;
