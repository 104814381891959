import { useMutation } from '@apollo/client';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import {
  useAuthorization,
  useCompanyFeatureFlags,
  useLogEvent,
  useUserData,
} from 'components/hooks';
import { WelcomeSlidesDialog } from 'components/WelcomeSlides';
import {
  FULL_PLAN_SLIDES_CONTENT,
  PARTNER_UI_SLIDES_CONTENT,
} from 'components/WelcomeSlides/constants/carouselContent';
import { Address, Company, Design } from 'components/WelcomeSlides/Slides';
import { UPDATE_ONBOARDING } from 'graphql/mutations';
import clamp from 'lodash/clamp';
import React, { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';

export interface IWelcomeSlidesContext {
  currentSlide: number;
  isAdmin: boolean;
  designFeatureEnabled: boolean;
  slides: FC<{ active: boolean }>[];
  setCurrentSlide: (slide: number) => void;
  closeDialog: () => void;
  nextSlide: () => void;
  prevSlide: () => void;
}

const WelcomeSlidesContext = createContext<IWelcomeSlidesContext>({
  currentSlide: 0,
  isAdmin: false,
  designFeatureEnabled: false,
  slides: [],
  setCurrentSlide: () => undefined,
  closeDialog: () => undefined,
  nextSlide: () => undefined,
  prevSlide: () => undefined,
});

export const WelcomeSlidesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUserData();
  const { logEvent } = useLogEvent();
  const { isAdmin } = useAuthorization();
  const { isPartner } = useCompanyPlanContext();

  const { isCompanyFeatureEnabled } = useCompanyFeatureFlags();
  const designFeatureEnabled = isCompanyFeatureEnabled('whiteLabeling');

  const slidesContent = isPartner ? PARTNER_UI_SLIDES_CONTENT : FULL_PLAN_SLIDES_CONTENT;

  const [openWelcomeSlides, setOpenWelcomeSlides] = useState<boolean>(false);
  const [loggedInitialSlides, setLoggedInitialSlides] = useState<boolean>(false);
  const [backdropClickEnabled, setBackdropClickEnabled] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([...slidesContent]);

  useEffect(() => {
    const sawSlide: boolean =
      !!user && user.latestTermsAccepted && !user.onboarding.sawWelcomeSlides;
    setOpenWelcomeSlides(!!sawSlide);
  }, [user]);

  useEffect(() => {
    const extraSlides = [];
    if (isAdmin) {
      extraSlides.push(Company);
      extraSlides.push(Address);

      if (designFeatureEnabled && !isPartner) {
        extraSlides.push(Design);
      }
    }

    setSlides([...slides, ...extraSlides]);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (currentSlide >= 4) {
      setBackdropClickEnabled(true);
    }

    if (currentSlide === 3 && !loggedInitialSlides) {
      logEvent('VIEW_INITIAL_WELCOME_SLIDES');
      setLoggedInitialSlides(true);
    }
  }, [currentSlide]); // eslint-disable-line

  const nextSlide = () => {
    setCurrentSlide(clamp(currentSlide + 1, 0, slides.length - 1));
  };

  const prevSlide = () => {
    setCurrentSlide(clamp(currentSlide - 1, 0, slides.length - 1));
  };

  const [updateOnboarding] = useMutation(UPDATE_ONBOARDING);

  const closeDialog = async () => {
    setOpenWelcomeSlides(false);
    await updateOnboarding({
      variables: {
        input: { flag: 'sawWelcomeSlides' },
      },
    });
  };

  const state: IWelcomeSlidesContext = {
    currentSlide,
    isAdmin,
    designFeatureEnabled,
    slides,
    setCurrentSlide,
    closeDialog,
    nextSlide: currentSlide === slides.length - 1 ? closeDialog : nextSlide,
    prevSlide,
  };

  return (
    <WelcomeSlidesContext.Provider value={state}>
      <WelcomeSlidesDialog
        isOpen={openWelcomeSlides}
        backdropClickEnabled={backdropClickEnabled}
        closeDialog={closeDialog}
      />
      {children}
    </WelcomeSlidesContext.Provider>
  );
};

export default WelcomeSlidesContext;
