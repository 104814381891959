import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { Box, styled } from '@mui/material';
import {
  Feature,
  FeatureCollection,
  Polygon,
  area,
  bbox,
  center,
  featureCollection,
  geometry,
  polygon,
} from '@turf/turf';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { Loader } from 'components/Forms';
import { PageSubTitle } from 'components/Structure';
import { COLOR_PALETTE } from 'constants/colors';
import { ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import CustomMap, { IMapMarker } from 'designSystem/Map/CustomMap';
import { Map } from 'mapbox-gl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { booleanish } from 'types/booleanish.types';
import { EudrStatementStatus } from 'types/compliance.types';
import { CultivatedAreas } from 'types/types';
import { convertHexToRGBarray } from 'utils';
import { v4 as uuid } from 'uuid';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ theme, disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const CultivatedAreasSize = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.custom.themeColors.grayScale[20],
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  zIndex: 3,
  right: 46,
  top: 10,
}));

export interface IFeatureProperties {
  color: string;
  // Center of the multi polygon all cultivation areas
  multiPolygonCenter?: [number, number];
}

const ConfirmGeoData: FC = () => {
  const { statement, updateStatement } = useEudrComplianceGuide();

  // const [infoPopup, setInfoPopup] = useState<IMapPopup>();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();
  const [mapRef, setMapRef] = useState<Map | null>(null);
  const [mapZoom, setMapZoom] = useState<number>();

  const selectedDatasetCultivationAreas: (CultivatedAreas & { farm: { id: string } })[] =
    useMemo(() => {
      if (selectedDatasetId && statement?.eudrDatasets) {
        const selectedDataset = statement.eudrDatasets.find(({ id }) => id === selectedDatasetId);
        return selectedDataset?.cultivationAreas || [];
      }
      return [];
    }, [statement, selectedDatasetId]);

  /**
   * Used to calculate the owner of the cultivation areas and show colors based on the index
   */
  const cultivationAreasOwner = useMemo(
    () => selectedDatasetCultivationAreas.map(({ farm }) => farm.id),
    [selectedDatasetCultivationAreas]
  );

  /**
   * Returns hex color based on the owner (farm) of the cultivation area
   * Basally each farm will have a unique color
   */
  const getCultivationAreaColor: (farmId?: string) => string = useCallback(
    farmId => {
      if (!farmId || !cultivationAreasOwner.length) {
        return COLOR_PALETTE[0];
      }
      if (farmId && cultivationAreasOwner.includes(farmId)) {
        const colorIndex = cultivationAreasOwner.findIndex(id => id === farmId);
        return COLOR_PALETTE[colorIndex % COLOR_PALETTE.length];
      }
      return COLOR_PALETTE[0];
    },
    [cultivationAreasOwner]
  );

  const featuresCollection: FeatureCollection<Polygon, IFeatureProperties> = useMemo(() => {
    const features: Feature<Polygon, IFeatureProperties>[] = selectedDatasetCultivationAreas
      .map(cultivatedAreas => {
        if (!cultivatedAreas?.coordinates) {
          return [];
        }
        const multiPolygonGeometry = geometry('Polygon', cultivatedAreas.coordinates);
        return cultivatedAreas.coordinates.map(singlePolygon =>
          polygon<IFeatureProperties>(
            [singlePolygon],
            {
              color: getCultivationAreaColor(cultivatedAreas.farm.id),
              multiPolygonCenter: center(multiPolygonGeometry).geometry.coordinates as [
                number,
                number
              ],
            },
            { id: uuid() }
          )
        );
      })
      .flat();
    return featureCollection(features);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatasetCultivationAreas]);

  const layers = useMemo(
    () => {
      if (!featuresCollection.features.length) return undefined;

      return new GeoJsonLayer<IFeatureProperties>({
        id: 'polygons',
        data: featuresCollection,
        getLineWidth: 4,
        pickable: true,
        getFillColor: feature => convertHexToRGBarray(feature.properties?.color, 100),
        getLineColor: feature => convertHexToRGBarray(feature.properties?.color, 255),
        // onClick: info => handleFeatureClick(info.object.properties),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featuresCollection]
  );

  const centerPointMarkers: IMapMarker[] = useMemo(() => {
    return selectedDatasetCultivationAreas
      .filter(cultivatedArea => !!cultivatedArea.centerPoint && !cultivatedArea.coordinates)
      .map(cultivatedArea => {
        return {
          coordinate: [
            cultivatedArea?.centerPoint?.lng || 0,
            cultivatedArea?.centerPoint?.lat || 0,
          ],
          options: {
            customIcon: 'map-marker',
            color: getCultivationAreaColor(cultivatedArea.farm.id),
          },
        };
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatasetCultivationAreas]);

  /**
   * Markers are only shown when the zoom level is higher than 10 to identify the cultivation areas if the world is zoomed out
   */
  const markers: IMapMarker[] = useMemo(
    () =>
      mapZoom && mapZoom < 10
        ? featuresCollection.features.reduce((prev, feature) => {
            const coordinate = feature.properties.multiPolygonCenter;
            if (!coordinate) {
              return prev;
            }
            // If marker for that cultivation area was already added, don't add it again
            if (prev.findIndex(prevMarker => prevMarker.coordinate === coordinate) !== -1) {
              return prev;
            }
            return [
              ...prev,
              {
                coordinate,
                options: { customIcon: 'map-marker', color: feature.properties.color },
              },
            ];
          }, [] as IMapMarker[])
        : [],
    [featuresCollection, mapZoom]
  );

  // const handleFeatureClick = useCallback(
  //   (featureProperties: IFeatureProperties) => {
  //     setInfoPopup(prev => {
  //       if (prev && prev.id === featureProperties.id) {
  //         return undefined;
  //       }
  //       return {
  //         id: featureProperties.id,
  //         coordinate: featureProperties.polygonCenter,
  //         content: (
  //           <CultivationFarmAreaInfoPopup infoItem={featureProperties} onEditClick={console.log} />
  //         ),
  //       };
  //     });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [infoPopup, setInfoPopup]
  // );

  const centerPolygons = () => {
    if (!featuresCollection.features.length || !mapRef) return;
    const [topLeft, topRight, bottomRight, bottomLeft] = bbox(featuresCollection);
    mapRef.fitBounds([topLeft, topRight, bottomRight, bottomLeft], { padding: 30 });
  };

  const currentPolygonSize = useMemo(
    () => Math.round(area(featuresCollection)) / 1000000,
    [featuresCollection]
  );

  useEffect(centerPolygons, [featuresCollection, mapRef]);

  useEffect(() => {
    if (statement?.eudrDatasets.length) {
      setSelectedDatasetId(statement.eudrDatasets[0].id);
    }
  }, [statement]);

  if (!statement) return <Loader />;

  const handleNextStepClick = () => {
    updateStatement(statement.id, { status: EudrStatementStatus.SELECTING_RISK_ANALYSIS });
  };

  return (
    <>
      <EudrComplianceGuideSubSection
        allowNextStepNavigation
        customNextStepButton={{ text: 'Confirm' }}
        onNextStepClick={handleNextStepClick}
      >
        <Box display="flex" gap={2} flexWrap="wrap">
          {statement?.eudrDatasets.map(({ id, title, rawMaterial, originCountry, partner }) => (
            <DatasetContainer key={id} onClick={() => setSelectedDatasetId(id)}>
              <DatasetItem
                title={title}
                commodity={rawMaterial.title}
                location={originCountry}
                owner={partner.title}
                active={id === selectedDatasetId}
                status="valid" // TODO: Calculate this status based on geo data intersection
              />
            </DatasetContainer>
          ))}
        </Box>
      </EudrComplianceGuideSubSection>
      <Box mb={2} />

      <PageSubTitle title="Geo-data preview" mb={1} />
      <Box minHeight={500} height={500} mb={3} position="relative">
        <CultivatedAreasSize>
          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
            Total area
          </ThemeTypography>
          <ThemeTypography variant="BODY_SMALL">{currentPolygonSize} km²</ThemeTypography>
        </CultivatedAreasSize>
        <CustomMap
          markers={[...markers, ...centerPointMarkers]}
          mapStyle="satellite"
          config={{
            enableMapStyleToggle: true,
            enableCenterButton: true,
          }}
          layers={[layers]}
          onMapLoad={setMapRef}
          onZoom={event => setMapZoom(event.viewState.zoom)}
        />
      </Box>
    </>
  );
};

export default ConfirmGeoData;
