import axios from 'axios';
import { cachePersistor } from 'graphql/client';
import { GET_USER_LOGIN_DETAILS } from 'graphql/queries';
import { REGISTER_INVITED_USER, SEND_PW_RESET_MAIL, VERIFY_USER } from 'graphql/mutations';

const authResolvers = {
  Query: {
    getTerms: async (_, __, { client }) => {
      const { config } = client.defaultOptions;

      try {
        const {
          data: { refs },
        } = await axios.get(config.generatePrismicMasterRefUrl());
        const {
          data: { results },
        } = await axios.get(config.generatePrismicDocumentUrl(refs[0].ref));

        return { terms: results[0] };
      } catch (error) {
        throw new Error(error.message);
      }
    },
  },
  Mutation: {
    sendPasswordResetMail: async (_, { email }, { client }) => {
      try {
        await client.mutate({
          mutation: SEND_PW_RESET_MAIL,
          variables: {
            email,
          },
        });
      } catch (error) {
        throw new Error(error.message);
      }
    },
    updatePassword: async (_, { oldPassword, newPassword }, { client }) => {
      const auth = client.defaultOptions.firebase.getFirebase().auth();
      const user = auth.currentUser;

      try {
        const credential = client.defaultOptions.firebase
          .getFirebase()
          .auth.EmailAuthProvider.credential(user.email, oldPassword);
        await user.reauthenticateWithCredential(credential);
      } catch {
        throw new Error('Your current password is wrong.');
      }

      try {
        await user.updatePassword(newPassword);
      } catch (error) {
        throw new Error('Something went wrong updating your password. Please try again.');
      }
    },
    login: async (_, { password, email }, { client }) => {
      const firebase = client.defaultOptions.firebase.getFirebase();

      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);

        const token = await firebase.auth().currentUser.getIdToken();

        await client.query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });
      } catch (error) {
        throw new Error(error.code || error.message);
      }
    },
    logout: async (_, variables, { client }) => {
      const firebase = client.defaultOptions.firebase.getFirebase();
      firebase.auth().signOut();

      cachePersistor.purge();
      await client.clearStore();
    },
    tokenizedLogin: async (_, { token }, { client }) => {
      try {
        const auth = client.defaultOptions.firebase.getFirebase().auth();
        await auth.signInWithCustomToken(token);
      } catch (error) {
        throw new Error('Your invitation is invalid. Please contact your account administrator!');
      }

      try {
        const context = { headers: { authorization: `Bearer ${token}` } };
        await client.mutate({
          mutation: VERIFY_USER,
          context,
        });
        await client.query({
          query: GET_USER_LOGIN_DETAILS,
          context: { headers: { authorization: `Bearer ${token}` } },
        });
        return true;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    registerInvited: async (_, { password, token, input }, { client }) => {
      const auth = client.defaultOptions.firebase.getFirebase().auth();

      try {
        await auth.signInWithCustomToken(token);
      } catch {
        throw new Error('Your invitation is invalid. Please contact your account administrator!');
      }

      try {
        await auth.currentUser.updatePassword(password);
        await auth.currentUser.updateProfile({
          displayName: input.firstName,
        });
      } catch (e) {
        if (e.message === 'TOKEN_EXPIRED') {
          throw new Error(
            'Your invitation has expired. Please contact your account administrator!'
          );
        } else {
          throw new Error('Something went wrong registering your! Please contact our support!');
        }
      }

      try {
        const token = await auth.currentUser.getIdToken();
        const context = { headers: { authorization: `Bearer ${token}` } };
        await client.mutate({
          mutation: REGISTER_INVITED_USER,
          variables: {
            input,
          },
          context,
        });
      } catch (error) {
        throw new Error(error.message);
      }
    },
  },
};

export default authResolvers;
