import React, { FC } from 'react';
import { DialogDefault, ThemeTypography, ThemeButton } from 'designSystem';
import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PartnerRequest } from 'types/partner.types';
import { ReceivedRequestCard } from 'components/Home/Requests';
import { CompanyNameLogo } from 'components/Structure';
import HtmlField from 'components/Structure/HTMLField';
import { useDialog } from 'components/hooks';
import { useNavigate } from 'react-router-dom';
import { AvailableSizes, PartnerRequestStatus } from 'types/enums';
import { IDefaultDialogProps } from 'types/dialog.types';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { REQUEST_STATUS_CLIENT } from 'utils/requestStatus.utils';
import usePartnerRequestActions from './usePartnerRequestActions';

const CompanyName = styled(Box)(() => ({
  cursor: 'pointer',
}));

const DismissMessageContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: 6,
  padding: theme.spacing(1.2),
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

export interface IRequestDetailsDialogProps extends IDefaultDialogProps {
  request: PartnerRequest;
}

const RequestDetailsDialog: FC<IRequestDetailsDialogProps> = ({ open, onClose, request }) => {
  const { requestedToCompany, requestReplyMessage, partner } = request;
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { loading, updatePartnerRequest } = usePartnerRequestActions();

  const resendRequest = () => {
    openDialog({ type: 'PARTNER_REQUEST', props: { partner, initialRequest: request } });
    onClose?.();
  };

  const handleCloseRequest = async () => {
    await updatePartnerRequest({
      variables: {
        id: request.id,
        input: {
          requestStatus: 'DELETED',
        },
      },
    });
    onClose?.();
  };

  const navigateToPartner = () => {
    onClose?.();
    navigate(`/partner/${partner.id}`);
  };

  return (
    <DialogDefault
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title="Review response"
      icon={() => (
        <Icon
          name={REQUEST_STATUS_CLIENT[request.requestType][PartnerRequestStatus.EDITED].iconName}
        />
      )}
      data-cy="request-details-dialog"
    >
      <Box minWidth={550} maxWidth={550} minHeight={200} px={3} py={1}>
        <ReceivedRequestCard request={request} displayYourMessage displayActions={false} />
        <Box mt={4} mb={3}>
          <Divider />
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          <CompanyName onClick={navigateToPartner} display="flex" alignItems="center">
            <CompanyNameLogo companyName={requestedToCompany.name} size={AvailableSizes.MEDIUM} />
          </CompanyName>
          <Box ml={0.5}>
            <ThemeTypography variant="BODY_LARGE_BOLD">has dismissed your request</ThemeTypography>
          </Box>
        </Box>
        {requestReplyMessage && (
          <DismissMessageContainer>
            <ThemeTypography variant="BODY_LARGE_BOLD">
              {requestedToCompany.name}:&nbsp;
            </ThemeTypography>
            <HtmlField>{requestReplyMessage}</HtmlField>
          </DismissMessageContainer>
        )}
        <Box display="flex" justifyContent="space-between" mt={5} mb={2}>
          <ThemeButton onClick={onClose} color="BLUE_ICE">
            Cancel
          </ThemeButton>
          <Box display="flex" gap={1}>
            <ThemeButton
              color="BLUE_ICE"
              startIcon={<Icon name="delete" size="small" />}
              loading={loading}
              onClick={handleCloseRequest}
            >
              Close request
            </ThemeButton>
            <ThemeButton
              color="YELLOW"
              startIcon={<Icon name="edit" size="small" />}
              onClick={resendRequest}
              data-cy="resend-partner-request-btn"
              loading={loading}
            >
              Edit request
            </ThemeButton>
          </Box>
        </Box>
      </Box>
    </DialogDefault>
  );
};

export default RequestDetailsDialog;
