import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';
import React from 'react';
import { Company } from 'types/companyTypes';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { AvailableSizes } from 'types/enums';

export const Logo = styled('div')<{
  backgroundImage: string;
  small: boolean;
}>(({ backgroundImage, small, theme }) => {
  const size = small ? 28 : 40;
  return {
    width: size,
    height: size,
    flexShrink: 0,
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
  };
});

interface Props {
  companyName: Company['name'];
  companyLogoUrl?: string;
  size: AvailableSizes;
}

const CompanyNameLogo: React.FC<Props> = ({ companyName, companyLogoUrl, size }: Props) => {
  return (
    <Box display="inline-block">
      <Box display="flex" gap={1} alignItems="center">
        <Logo
          backgroundImage={companyLogoUrl || defaultPartnerImage}
          small={size === AvailableSizes.SMALL || size === AvailableSizes.MEDIUM}
        />
        <ThemeTypography
          variant={
            size === AvailableSizes.SMALL
              ? 'BUTTON_SMALL'
              : size === AvailableSizes.MEDIUM
              ? 'BODY_LARGE_BOLD'
              : 'TITLE_SMALL'
          }
          color={size === AvailableSizes.SMALL ? 'BLACK' : 'GRAY_40'}
        >
          {companyName}
        </ThemeTypography>
      </Box>
    </Box>
  );
};

export default CompanyNameLogo;
