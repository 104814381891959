import { useMutation } from '@apollo/client';
import { CREATE_PARTNER_REQUEST, UPDATE_PARTNER_REQUEST } from 'graphql/mutations/partners';
import { UpdatePartnerRequest, PartnerRequest, RequestType } from 'types/partner.types';
import { useDialog } from 'components/hooks';

const usePartnerRequestActions = () => {
  const { openDialog } = useDialog();
  const [createPartnerRequest, { loading: createLoading }] = useMutation<
    { createPartnerRequest: { partnerRequest: PartnerRequest } },
    {
      input: {
        requestTitle: string;
        requestMessage: string;
        partnerId: string;
        requestType: keyof typeof RequestType;
        componentId?: string;
      };
    }
  >(CREATE_PARTNER_REQUEST);

  const [updatePartnerRequest, { loading: updateLoading }] = useMutation<
    { updatePartnerRequest: UpdatePartnerRequest },
    {
      id: PartnerRequest['id'];
      input: {
        requestStatus?: PartnerRequest['requestStatus'];
        documentId?: PartnerRequest['documentId'];
        requestReplyMessage?: PartnerRequest['requestReplyMessage'];
        requestMessage?: PartnerRequest['requestMessage'];
      };
    }
  >(UPDATE_PARTNER_REQUEST);

  const deletePartnerRequest = (id: PartnerRequest['id']) => {
    updatePartnerRequest({ variables: { id, input: { requestStatus: 'DELETED' } } });
  };

  const handleDeletePartnerRequest = (id: PartnerRequest['id']) => {
    openDialog({
      type: 'ALERT',
      props: {
        displayCloseButton: true,
        title: 'Delete partner request',
        submitText: 'Delete',
        text: 'Are you sure you want to delete this partner request? You will not be able to restore it.',
        onCancel: () => undefined,
        onSubmit: () => deletePartnerRequest(id),
      },
    });
  };

  return {
    createPartnerRequest,
    updatePartnerRequest,
    deletePartnerRequest,
    handleDeletePartnerRequest,
    loading: createLoading || updateLoading,
  };
};

export default usePartnerRequestActions;
