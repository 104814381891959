import * as Yup from 'yup';

export interface DatasetSelectionFormValues {
  datasetIds: string[];
}

export interface DatasetBatch {
  externalBatchId: string;
  weight: number;
  harvestDate: string;
}

export interface DatasetBatchRelation {
  datasetId: string;
  title: string;
  originCountry: string;
  partner: { title: string };
  rawMaterial: { title: string };
  batches: DatasetBatch[];
}

export interface AddBatchDetailsFormValues {
  datasetBatchRelation: DatasetBatchRelation[];
  autoAssignIds: boolean;
}

export const datasetSelectionSchema = Yup.object().shape({
  datasetIds: Yup.array()
    .of(Yup.string().required())
    .min(1)
    .required('Please select a dataset')
    .default([]),
}) as Yup.ObjectSchema<DatasetSelectionFormValues>;

export const batchDetailSchema = Yup.object().shape({
  externalBatchId: Yup.string().required('Please enter a origin batch ID'),
  weight: Yup.number().required('Please enter a weight'),
  harvestDate: Yup.string().required('Please enter a harvest date'),
}) as Yup.ObjectSchema<DatasetBatch>;

export const addBatchDetailsSchema = Yup.object().shape({
  autoAssignIds: Yup.boolean().default(false),
  datasetBatchRelation: Yup.array().of(
    Yup.object().shape({
      datasetId: Yup.string(),
      title: Yup.string(),
      originCountry: Yup.string(),
      partner: Yup.object().shape({ title: Yup.string() }),
      rawMaterial: Yup.object().shape({ title: Yup.string() }),
      batches: Yup.array().of(batchDetailSchema).min(1).required('Please add at least one batch'),
    })
  ),
}) as Yup.ObjectSchema<AddBatchDetailsFormValues>;
