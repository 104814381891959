import { Dialog, IconButton, DialogContent, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@styled-icons/evaicons-solid/Close';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import VisibilityForm from './VisibilityForm';
import { DialogTitle } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { IVisibilitySettings } from 'types/supplyChain.type';

export interface IVisibilitySettingsDialogProps extends IDefaultDialogProps {
  visibilitySettings: IVisibilitySettings;
  supplyChainId: string;
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 370,
    maxWidth: '100%',
    overflow: 'visible',
    padding: theme.spacing(3, 4),
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
}));

const VisibilitySettingsDialog: FC<IVisibilitySettingsDialogProps> = ({
  onClose,
  visibilitySettings,
}) => {
  return (
    <StyledDialog open onClose={onClose}>
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      <DialogTitle icon={<Icon name="show" size="large" />}>Visibility Settings</DialogTitle>
      <Box mt={4} />
      <StyledDialogContent>
        <VisibilityForm visibilitySettings={visibilitySettings} onClose={onClose} />
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default VisibilitySettingsDialog;
