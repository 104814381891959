import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from 'components/Forms';
import { useMessages } from 'components/hooks';
import { AddressForm, CompanyEditForm, Container, SettingsHeader } from 'components/Settings';
import { ErrorState } from 'components/Structure';
import WarningMessage from 'components/Structure/WarningMessage';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { EDIT_COMPANY } from 'graphql/mutations';
import { GET_COMPANY_DETAILS } from 'graphql/queries';
import React, { Fragment } from 'react';

const AlertContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiAlert-root': {
    boxShadow: 'none',
    borderColor: theme.palette.warning.main,
  },

  '& .MuiAlert-icon ': {
    color: theme.palette.warning.main,
  },
}));

const CompanyDetails = () => {
  const { data: { company } = {}, error, loading } = useQuery(GET_COMPANY_DETAILS);

  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [editCompany] = useMutation(EDIT_COMPANY, {
    onCompleted: () => {
      setSuccessMessage('Success! Company details were updated.');
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your companies details.');
    },
  });

  const handleSubmit = input => {
    return editCompany({
      variables: {
        input,
      },
    });
  };

  if (error) return <ErrorState error={error} />;
  if (!company && loading) return <Loader />;

  return (
    <Fragment>
      {!company.dataValid && !company.isPartner && (
        <AlertContainer>
          <WarningMessage>
            In order to publish products you need to provide your company details.
          </WarningMessage>
        </AlertContainer>
      )}
      <Container>
        <SettingsHeader title="Company details" />
        <CompanyEditForm onSubmit={handleSubmit} company={company} />
      </Container>
      <Container>
        <SettingsHeader title="Address" />
        <AddressForm onSubmit={handleSubmit} address={company.address}>
          {({ submitForm, isSubmitting }) => (
            <Grid container item justifyContent="flex-end" alignItems="center">
              <ThemeButton
                size="large"
                color="YELLOW"
                loading={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                Save
              </ThemeButton>
            </Grid>
          )}
        </AddressForm>
      </Container>
    </Fragment>
  );
};

export default CompanyDetails;
