import { useMutation, useQuery } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { CREATE_RAW_MATERIAL } from 'graphql/mutations/components';
import { GET_RAW_MATERIALS } from 'graphql/queries/components';
import { IRawMaterial, IRawMaterialResponse } from 'types/component.types';
import { removeGraphConnections } from 'utils/graphConnections';

const useRawMaterials = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { data, loading, error } = useQuery<IRawMaterialResponse>(GET_RAW_MATERIALS);

  const [createRawMaterial] = useMutation<{ createRawMaterial: IRawMaterial }>(
    CREATE_RAW_MATERIAL,
    {
      onCompleted: ({ createRawMaterial: { id } }) => {
        setSuccessMessage(
          'You have added a new raw material! It will take some time util your raw material will be approved by us and can be used.'
        );
      },
      onError: e => {
        setErrorMessage('Something went wrong creating your raw material');
      },
    }
  );

  return {
    error,
    loading,
    rawMaterials: data?.rawMaterials ? removeGraphConnections(data.rawMaterials) : [],
    createRawMaterial,
  };
};

export default useRawMaterials;
