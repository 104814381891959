import { Box, styled } from '@mui/material';
import { useDialog } from 'components/hooks';
import { CardContainer, CardIllustration, ThemeButton, ThemeTypography } from 'designSystem';
import React from 'react';
import { AvailableSizes, BackgroundTypes, IllustrationTypes } from 'types/enums';
import { getReadablePartnerStatus, getPartnerStatusColor } from 'utils/partnerUtils';
import {
  OpacityContainer,
  PartnerImage,
  Title,
} from '../PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import unknownDocument from 'assets/img/icons/unknown-document.svg';
import { Partner } from 'types/partner.types';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';

export const RequestIcon = styled('img')(() => ({
  width: 18,
}));

interface Props {
  partner: Partner;
  setSkipInviteStepCard: (skip: boolean) => void;
}

const PartnerInviteCard: React.FC<Props> = ({ partner, setSkipInviteStepCard }) => {
  const { openDialog } = useDialog();

  return (
    <CardContainer
      title="Invite your partner to request their supply chain information directly"
      size={AvailableSizes.LARGE}
      background={BackgroundTypes.WORLD}
      data-cy="home-getting-started-block"
      renderIllustration={
        <CardIllustration image={IllustrationTypes.LOCATIONS} width={260} right={0} top={0} />
      }
    >
      <Box>
        <Box pb={2} mt="auto">
          <CardContainer padding={1.5} maxWidth="550px">
            <OpacityContainer display="flex" is-transparent="false">
              <PartnerImage
                alt={`Logo of ${partner.title}`}
                src={partner.logo?.url || defaultPartnerImage}
              />
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Title>{partner.title}</Title>

                <ThemeTypography
                  variant="ITEM_SUBTITLE"
                  color={getPartnerStatusColor(partner.status)}
                >
                  {getReadablePartnerStatus(partner.status)}
                </ThemeTypography>
              </Box>
            </OpacityContainer>
          </CardContainer>
        </Box>

        <Box display="flex">
          <ThemeButton
            color="YELLOW"
            size="large"
            startIcon={<RequestIcon src={unknownDocument} />}
            onClick={() => {
              openDialog({
                type: 'ADD_EDIT_PARTNER',
                props: { partner, enableInvite: true },
              });
            }}
          >
            Invite partner
          </ThemeButton>
          <Box ml={2}>
            <ThemeButton
              color="BLUE_ICE"
              size="large"
              onClick={() => {
                setSkipInviteStepCard(true);
              }}
            >
              Skip for now
            </ThemeButton>
          </Box>
        </Box>
      </Box>
    </CardContainer>
  );
};

export default PartnerInviteCard;
