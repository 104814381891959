import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT } from './media';
import { RAW_MATERIAL_FRAGMENT } from './components';

// Todo: Research how to use fragments with unions and fix this

const SITE_PARTNER_FRAGMENT = gql`
  fragment sitePartnerValues on Partner {
    id
    title
    type
  }
`;

export const BASE_SITE_FRAGMENT = (siteType: string) => gql`
  fragment base${siteType}Values on ${siteType} {
    id
    partnerId
    partner {
      ...sitePartnerValues
    }
    image {
      ...imageVariant
    }
    siteType
    title
    locationCoordinates {
      lat
      lng
    }
    locationName
    imageId
    externalId
    description
    coordinateFeatures {
      featureType
      locationName
      center {
        lat
        lng
      }
      }
    inUse
  }
    ${IMAGE_VARIANT_FRAGMENT}
    ${SITE_PARTNER_FRAGMENT}
  `;

export const CULTIVATED_AREAS_FRAGMENT = gql`
  fragment cultivatedAreaValues on CultivatedAreas {
    id
    coordinates
    farm {
      id
      partner {
        title
      }
    }
    centerPoint {
      lat
      lng
    }
  }
`;

export const FARM_SITE_FRAGMENT = gql`
  fragment farmValues on Farm {
    ...baseFarmValues
    size
    numberOfFarmers
    landOwnership
    rawMaterial {
      ...rawMaterialValues
    }
    cultivatedAreas {
      ...cultivatedAreaValues
    }
    additionalRawMaterialIds
  }
  ${BASE_SITE_FRAGMENT('Farm')}
  ${SITE_PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${RAW_MATERIAL_FRAGMENT}
  ${CULTIVATED_AREAS_FRAGMENT}
`;

export const GENERIC_SITE_FRAGMENT = gql`
  fragment genericSiteValues on GenericSite {
    ...baseGenericSiteValues
  }
  ${BASE_SITE_FRAGMENT('GenericSite')}
`;

export const MINE_SITE_FRAGMENT = gql`
  fragment mineValues on Mine {
    ...baseMineValues
  }
  ${BASE_SITE_FRAGMENT('Mine')}
`;

export const FOREST_SITE_FRAGMENT = gql`
  fragment forestValues on Forest {
    ...baseForestValues
  }
  ${BASE_SITE_FRAGMENT('Forest')}
`;

export const COLLECTION_SITE_FRAGMENT = gql`
  fragment collectionSiteValues on CollectionSite {
    ...baseCollectionSiteValues
  }
  ${SITE_PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_SITE_FRAGMENT('CollectionSite')}
`;

export const ORIGIN_SITE_FRAGMENT = gql`
  fragment originSiteValues on OriginSite {
    ...baseOriginSiteValues
  }
  ${SITE_PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_SITE_FRAGMENT('OriginSite')}
`;

export const PLANT_SITE_FRAGMENT = gql`
  fragment plantValues on Plant {
    ...basePlantValues
  }
  ${BASE_SITE_FRAGMENT('Plant')}
`;

export const PROCESSING_SITE_FRAGMENT = gql`
  fragment processingSiteValues on ProcessingSite {
    ...baseProcessingSiteValues
  }
  ${BASE_SITE_FRAGMENT('ProcessingSite')}
`;

export const FACTORY_SITE_FRAGMENT = gql`
  fragment factoryValues on Factory {
    ...baseFactoryValues
  }
  ${BASE_SITE_FRAGMENT('Factory')}
`;

export const MANUFACTURING_SITE_FRAGMENT = gql`
  fragment manufacturingSiteValues on ManufacturingSite {
    ...baseManufacturingSiteValues
  }
  ${BASE_SITE_FRAGMENT('ManufacturingSite')}
`;

export const WAREHOUSE_SITE_FRAGMENT = gql`
  fragment warehouseValues on Warehouse {
    ...baseWarehouseValues
  }
  ${BASE_SITE_FRAGMENT('Warehouse')}
`;

export const HANDLING_SITE_FRAGMENT = gql`
  fragment handlingSiteValues on HandlingSite {
    ...baseHandlingSiteValues
  }
  ${BASE_SITE_FRAGMENT('HandlingSite')}
`;

export const GROCERY_STORE_SITE_FRAGMENT = gql`
  fragment groceryStoreValues on GroceryStore {
    ...baseGroceryStoreValues
  }
  ${BASE_SITE_FRAGMENT('GroceryStore')}
`;

export const DISTRIBUTION_SITE_FRAGMENT = gql`
  fragment distributionSiteValues on DistributionSite {
    ...baseDistributionSiteValues
  }
  ${BASE_SITE_FRAGMENT('DistributionSite')}
`;

export const SITE_CLUSTER_FRAGMENT = gql`
  fragment siteClusterValues on SiteCluster {
    ...baseSiteClusterValues
    sites {
      count
      summary {
        workersAmount
        additionalRawMaterialTitles
      }
      edges {
        node {
          ... on GenericSite {
            ...genericSiteValues
          }
          ... on Farm {
            ...farmValues
          }
          ... on Mine {
            ...mineValues
          }
          ... on Forest {
            ...forestValues
          }
          ... on OriginSite {
            ...originSiteValues
          }
          ... on CollectionSite {
            ...collectionSiteValues
          }
          ... on Plant {
            ...plantValues
          }
          ... on ProcessingSite {
            ...processingSiteValues
          }
          ... on Factory {
            ...factoryValues
          }
          ... on ManufacturingSite {
            ...manufacturingSiteValues
          }
          ... on Warehouse {
            ...warehouseValues
          }
          ... on HandlingSite {
            ...handlingSiteValues
          }
          ... on GroceryStore {
            ...groceryStoreValues
          }
          ... on DistributionSite {
            ...distributionSiteValues
          }
        }
      }
    }
  }
  ${SITE_PARTNER_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${BASE_SITE_FRAGMENT('SiteCluster')}
`;

/** #### SITE_FRAGMENT ####
... on GenericSite {
  ...genericSiteValues
}
... on Farm {
  ...farmValues
}
... on Mine {
  ...mineValues
}
... on Forest {
  ...forestValues
}
... on CollectionSite {
  ...collectionSiteValues
}
... on OriginSite {
  ...originSiteValues
}
... on Plant {
  ...plantValues
}
... on ProcessingSite {
  ...processingSiteValues
}
... on Factory {
  ...factoryValues
}
... on ManufacturingSite {
  ...manufacturingSiteValues
}
... on Warehouse {
  ...warehouseValues
}
... on HandlingSite {
  ...handlingSiteValues
}
... on GroceryStore {
  ...groceryStoreValues
}
... on DistributionSite {
  ...distributionSiteValues
}
... on SiteCluster {
  ...siteClusterValues
}
__typename  
${GENERIC_SITE_FRAGMENT}
${FARM_SITE_FRAGMENT}
${MINE_SITE_FRAGMENT}
${FOREST_SITE_FRAGMENT}
${COLLECTION_SITE_FRAGMENT}
${ORIGIN_SITE_FRAGMENT}
${PLANT_SITE_FRAGMENT}
${PROCESSING_SITE_FRAGMENT}
${FACTORY_SITE_FRAGMENT}
${MANUFACTURING_SITE_FRAGMENT}
${WAREHOUSE_SITE_FRAGMENT}
${HANDLING_SITE_FRAGMENT}
${GROCERY_STORE_SITE_FRAGMENT}
${DISTRIBUTION_SITE_FRAGMENT}
${SITE_CLUSTER_FRAGMENT}
 */
