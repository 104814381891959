import { Box, IconButton, InputAdornment, Checkbox, styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { Search } from '@styled-icons/bootstrap';
import { Close } from '@styled-icons/evaicons-solid/Close';
import TraceableLocation from 'assets/img/cards/traceable-illustration.png';
import { SearchInput } from 'components/Forms/SearchToolbar';
import { ISite, SiteOwnershipType } from 'types/sites.types';
import SiteItem from './SiteItem';
import { ThemeTypography } from 'designSystem';
import InfiniteScrollWrapper from 'components/Structure/InfiniteScrollWrapper';
import { Loader } from 'components/Forms';

interface ISiteSelectionProps {
  sites: ISite[];
  selectedSites: ISite[];
  currentOwnershipType: SiteOwnershipType;
  loading?: boolean;
  hasNextPage: boolean;
  searchTerm: string | null;
  onSearchTermChange: (term: string) => void;
  onPageEndReached: () => void;
  onSelectionChange: (sites: ISite[]) => void;
}

const FilterBar = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const IllustrationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(1, 4),
}));

const Illustration = styled('img')(({ theme }) => ({
  width: 120,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
}));

const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  maxHeight: 380,
  width: '100%',
  paddingBottom: 80,
  paddingRight: 16,
  marginTop: -16,
});

const SiteSelection: FC<ISiteSelectionProps> = ({
  sites,
  selectedSites,
  currentOwnershipType,
  loading,
  hasNextPage,
  searchTerm,
  onSelectionChange,
  onPageEndReached,
  onSearchTermChange,
}) => {
  const isAllSelected = useMemo(() => {
    if (sites.length === 0) {
      return false;
    }
    return sites.every(site => selectedSites?.some(selectedSite => selectedSite.id === site.id));
  }, [sites, selectedSites]);

  const toggleAllSites = () => {
    if (isAllSelected) {
      onSelectionChange(
        selectedSites.filter(selectedSite => !sites.some(site => site.id === selectedSite.id))
      );
    } else {
      onSelectionChange([
        ...selectedSites,
        ...sites.filter(site => !selectedSites.some(selectedSite => selectedSite.id === site.id)),
      ]);
    }
  };

  const toggleSite = (site: ISite, isSelected: boolean | undefined) => {
    if (isSelected) {
      onSelectionChange(selectedSites?.filter(selectedSite => selectedSite.id !== site.id));
    } else {
      onSelectionChange([...selectedSites, site]);
    }
  };

  return (
    <Box width="100%">
      <FilterBar>
        {sites.length > 0 && <Checkbox checked={isAllSelected} onClick={toggleAllSites} />}
        <SearchInput
          setDebouncedState={onSearchTermChange}
          className="search-input"
          delay={200}
          placeholder="Search activities"
          initialValue={searchTerm}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm === '' ? (
                  <Search size={12} />
                ) : (
                  <CloseButton onClick={() => onSearchTermChange('')}>
                    <Close size={16} />
                  </CloseButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </FilterBar>
      {sites.length === 0 && searchTerm?.length === 0 && !loading && (
        <IllustrationWrapper>
          <Illustration src={TraceableLocation} alt="traceable illustration" />
          <Box mt={3}>
            <ThemeTypography variant="BODY_LARGER_BOLD" color="GRAY_60">
              {`You do not have any ${currentOwnershipType.toLowerCase()} sites yet`}
            </ThemeTypography>
          </Box>
        </IllustrationWrapper>
      )}

      {loading && (
        <Box position="relative" mt={5}>
          <Loader />
        </Box>
      )}

      {sites.length > 0 && !loading && (
        <ScrollContainer>
          {sites.length > 0 && !loading && (
            <InfiniteScrollWrapper hasMore={hasNextPage} next={onPageEndReached}>
              {sites.map((site, index) => {
                const isSelected = selectedSites?.some(selectedSite => selectedSite.id === site.id);
                return (
                  <Box
                    pt={index === 0 ? 2 : 1}
                    pb={index === sites.length - 1 ? 1 : 0}
                    key={`site-block-${site.id}`}
                    data-cy="site-row"
                  >
                    <SiteItem
                      selected={isSelected || false}
                      site={site}
                      onSelect={() => {
                        toggleSite(site, isSelected);
                      }}
                    />
                  </Box>
                );
              })}
            </InfiniteScrollWrapper>
          )}
        </ScrollContainer>
      )}
    </Box>
  );
};

export default SiteSelection;
