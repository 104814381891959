import React, { FC, ReactNode } from 'react';
import { FastField as Field, Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { TextInputField, MediaField } from 'components/Forms';
import { Typography } from '@mui/material';
import { companySchema } from 'constants/schemas';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import { PageContainer, TextContainer, ImageContainer, Headline, SkipButton } from './styles';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { Company } from 'types/companyTypes';
import { ImageVariant } from 'types/commonTypes';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

export interface CompanyFormValues {
  name: string;
  onlineShop?: string;
  website: string;
  logo?: ImageVariant;
}

interface ICompanyFormProps {
  company: Company;
  active: boolean;
  children: (props: { submitForm: () => void; isSubmitting: boolean }) => ReactNode;
  onSubmit: (values: CompanyFormValues) => void;
}

const StyledForm = styled(Form)(() => ({
  height: '100%',
}));

const LabelLogo = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FormContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const CompanyGrid = styled(Grid)(() => ({
  height: '80%',
  padding: 0,
}));

const CompanyForm: FC<ICompanyFormProps> = ({ company, onSubmit, children, active }) => {
  const { name, onlineShop, website, logo } = company;
  const { nextSlide } = useWelcomeSlides();
  const { isPartner } = useCompanyPlanContext();

  return (
    <Formik
      initialValues={{
        name,
        onlineShop,
        website,
        logo,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={companySchema}
      onSubmit={values =>
        onSubmit({
          ...values,
        })
      }
    >
      {({ isSubmitting, submitForm }) => (
        <StyledForm>
          <FormContainer>
            <PageContainer>
              <TextContainer>
                <CompanyGrid container spacing={2}>
                  <Grid item xs={12}>
                    <Headline variant="TITLE_LARGE">
                      Let’s get your <br /> account set up
                    </Headline>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputField label="Company name" name="name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputField
                      label="Company Website"
                      name="website"
                      placeholder="https://website.com"
                    />
                  </Grid>
                  {!isPartner && (
                    <Grid item xs={12}>
                      <TextInputField
                        label="Online Shop"
                        name="onlineShop"
                        placeholder="https://onlineshop.com"
                      />
                    </Grid>
                  )}
                </CompanyGrid>
              </TextContainer>
              <Fade in={active} timeout={500}>
                <ImageContainer>
                  <SkipButton onClick={nextSlide}>Add this later</SkipButton>
                  <LabelLogo variant="h6">Company logo</LabelLogo>
                  <Field
                    cropDisabled
                    component={MediaField}
                    fullWidth
                    singleUpload
                    name="logo"
                    legacyImage={false}
                    styles={{
                      borderRadius: 10,
                      width: 200,
                      height: 200,
                    }}
                  />
                </ImageContainer>
              </Fade>
            </PageContainer>
            {children({ submitForm, isSubmitting })}
          </FormContainer>
        </StyledForm>
      )}
    </Formik>
  );
};

export default CompanyForm;
