import { gql } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from './documents';

export const SDG_FRAGMENT = gql`
  fragment sdgValues on CmsSustainableDevGoal {
    title
    description
    number
    color
    iconUrl
  }
`;

export const IMPACT_CLAIM_LOG_FRAGMENT = gql`
  fragment impactClaimLog on ImpactClaimLog {
    comment
    timestamp
    action
    user {
      email
      firstName
      lastName
    }
  }
`;

export const IMPACT_CATALOG_ITEM_FRAGMENT = gql`
  fragment impactCatalogItemValues on CmsImpactCatalogItem {
    id
    category {
      type
    }
    categoryConfig @client {
      title
      titleSingular
      icon
      claimIcon
      color
    }
    iconUrl
    title
    definition
    sustainableDevGoals {
      ...sdgValues
    }
    acceptanceCriteria {
      description
    }
    thirdParties
    links
    docRequirements {
      description
    }
  }
  ${SDG_FRAGMENT}
`;

export const IMPACT_CLAIM_FRAGMENT = gql`
  fragment impactClaimValues on ImpactClaim {
    id
    createdTimestamp
    status
    attachmentCount
    attachments {
      edges {
        node {
          ...documentsValues
        }
      }
    }
    isPending @client
    isApproved @client
    isInvalid @client
    impactCatalogItem {
      ...impactCatalogItemValues
    }
    product {
      id
      title
    }
    partner {
      id
      title
    }
    component {
      id
      title
    }
    logs {
      ...impactClaimLog
    }
    impactLevel @client
    impactLevelTitle @client
  }
  ${IMPACT_CATALOG_ITEM_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${IMPACT_CLAIM_LOG_FRAGMENT}
`;
