import { gql } from '@apollo/client';
import { COMPONENT_FRAGMENT } from 'graphql/fragments';
import { RAW_MATERIAL_FRAGMENT } from 'graphql/fragments/components';

export const CREATE_COMPONENT = gql`
  mutation CreateComponent($input: CreateComponentInput!, $lang: Language) {
    createComponent(input: $input, lang: $lang) {
      component {
        ...componentValues
      }
    }
  }
  ${COMPONENT_FRAGMENT}
`;

export const UPDATE_COMPONENT = gql`
  mutation UpdateComponent($id: UUID!, $input: UpdateComponentInput!, $lang: Language) {
    updateComponent(input: $input, id: $id, lang: $lang) {
      component {
        ...componentValues
      }
    }
  }
  ${COMPONENT_FRAGMENT}
`;

export const DELETE_COMPONENT = gql`
  mutation DeleteComponent($id: UUID!) {
    deleteComponent(id: $id) {
      id
    }
  }
`;

export const CREATE_RAW_MATERIAL = gql`
  mutation CreateRawMaterial($title: String!) {
    createRawMaterial(title: $title) {
      rawMaterial {
        ...rawMaterialValues
      }
    }
  }
  ${RAW_MATERIAL_FRAGMENT}
`;
