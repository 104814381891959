export const HEADER_COLUMNS = [
  {
    id: 'title',
    headerName: 'Title',
    sortable: false,
  },
  {
    id: 'partners',
    headerName: 'Partners',
    sortable: false,
  },
  {
    id: 'lastEditedTimestamp',
    headerName: 'Last edited',
    sortable: true,
  },
  {
    id: 'actions',
    headerName: 'Actions',
    sortable: false,
  },
];
