import { imageSchema } from 'constants/schemas/shared';
import * as Yup from 'yup';
import { ProcessEUDRDatasetInput } from 'types/compliance.types';
import { Partner } from 'types/partner.types';

export type IGeoUploadFormValue = {
  // This only exists if the dataset was already created in the backend
  datasetId?: string;
  title: string;
  rawMaterialId: string;
  countryCode: string;
  partner: Partner;
  visibility: string;
  /**
   * This is used to keep track of the files that were already uploaded and attached to the datasets and are not of the upload context
   */
  files: { id: string; title: string; inUploadContext: boolean }[];
  allowMultipleFiles: boolean;
};

export type IGeoUploadFormValues = IGeoUploadFormValue[];
export type IGeoMappingFormValues = ProcessEUDRDatasetInput[];

export const geoUploadDatasetSchema = Yup.object().shape({
  datasetId: Yup.string().nullable(),
  title: Yup.string().required('Please enter a title'),
  rawMaterialId: Yup.string().required('Please select a raw material'),
  countryCode: Yup.string().required('Please select a country of origin'),
  partner: Yup.object()
    .shape({ id: Yup.string(), title: Yup.string(), logo: imageSchema })
    .default(undefined)
    .required('Please select a partner'),
  visibility: Yup.string().required('This field is required').default('PUBLIC'),
  files: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        title: Yup.string(),
        inUploadContext: Yup.string(),
      })
    )
    .min(1)
    .default([]),
  allowMultipleFiles: Yup.boolean().default(false),
}) as Yup.ObjectSchema<IGeoUploadFormValue>;

export const mappedColumnsSchema = Yup.object()
  .shape({
    targetColumnName: Yup.string(),
    sourceColumnName: Yup.string(),
    columnDataExample: Yup.array().of(Yup.string()),
  })
  .nullable();

export const geoMappingDatasetSchema = Yup.object().shape({
  datasetId: Yup.string(),
  mappedColumns: mappedColumnsSchema,
  removed: Yup.boolean().default(false),
}) as Yup.ObjectSchema<ProcessEUDRDatasetInput>;

export const geoMappingSchema = Yup.array()
  .of(geoMappingDatasetSchema)
  .default([]) as Yup.ObjectSchema<IGeoMappingFormValues>;

export const geoUploadSchema = Yup.array()
  .of(geoUploadDatasetSchema)
  .min(1)
  .default([geoUploadDatasetSchema.default()]) as Yup.ObjectSchema<IGeoUploadFormValues>;
