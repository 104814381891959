import { gql } from '@apollo/client';

export const COMPANY_DESIGN_FRAGMENT = gql`
  fragment companyDesignValues on CompanyDesign {
    typography {
      header
    }
    palette {
      primary
    }
  }
`;

export const COMPANY_PLAN_FRAGMENT = gql`
  fragment companyPlanDetails on CustomerPlan {
    customerPlanId
    expired
    expirationDate
    upgradeRequested
    planType
    daysLeftInTrial @client
    planName @client
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyValues on Company {
    id
    name
    website
    onlineShop
    address {
      street
      streetNumber
      zipCode
      city
      country
    }
    logo {
      url
      imageId
    }
    features {
      feedback
      userLimit
      productLimit
      whiteLabeling
      compliance
      payments
    }
    design {
      ...companyDesignValues
    }
    social {
      facebook
      twitter
      instagram
    }
    dataValid
    planDetails {
      ...companyPlanDetails
    }
    gaTrackingId
    impactClaims {
      count
    }
    isPartner @client
    customerType
    partnerId
  }
  ${COMPANY_DESIGN_FRAGMENT}
  ${COMPANY_PLAN_FRAGMENT}
`;
