import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { ApplicationPlan } from 'types/companyTypes';

const useHasAccess = (allowedPlans: ApplicationPlan[]) => {
  const { plan } = useCompanyPlanContext();

  return allowedPlans.includes(plan);
};

export default useHasAccess;
