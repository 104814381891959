import { useQuery } from '@apollo/client';
import { appQueryParams } from 'constants/appQueryParams';
import { GET_PRODUCTS_AND_BASE_CHAIN } from 'graphql/queries/products';
import { useMemo } from 'react';
import { GraphQlConnection, Product } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphConnections';

const useSupplyChains = () => {
  const {
    data: productsData,
    loading,
    error,
  } = useQuery<{
    products: GraphQlConnection<Product>;
  }>(GET_PRODUCTS_AND_BASE_CHAIN);

  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);

  const productsWithSupplyChains = useMemo(
    () => (productsData?.products ? removeGraphConnections(productsData.products) : []),
    [productsData]
  );

  const filteredProductsWithSupplyChains = useMemo(() => {
    const products = productsData?.products?.edges || [];

    if (!searchTerm?.length) return products.map(item => item.node);

    // filter products by title of supply chain
    const filtered = products.filter(
      ({ node: { articleNumber, title, latestSupplyChain } }) =>
        title?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        latestSupplyChain?.title?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        articleNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

    return filtered.map(item => item.node);
  }, [searchTerm, productsData]);

  return {
    searchTerm,
    productsWithSupplyChains,
    filteredProductsWithSupplyChains,
    loading,
    error,
    setSearchTerm,
  };
};

export default useSupplyChains;
