import { useQuery } from '@apollo/client';
import { useConfig, useDialog } from 'components/hooks';
import { GET_SHARED_DOCUMENTS } from 'graphql/queries';
import { useEffect, useMemo } from 'react';
import { RequestType } from 'types/partner.types';
import { Document, GraphQlConnection } from 'types/types';
import { useQueryParam } from 'use-query-params';
import { filterItems } from 'utils';

const useSharedDocuments = () => {
  const { openDialog } = useDialog();
  const { data, loading, error, fetchMore } = useQuery<{
    sharedDocuments: GraphQlConnection<Document>;
  }>(GET_SHARED_DOCUMENTS, {
    variables: {
      filters: { fileExtensions: ['pdf'] },
      first: 15,
    },
  });
  const { appQueryParams } = useConfig();

  const [searchTerm = '', setSearchTerm] = useQueryParam<string>(appQueryParams.query);

  const documents = useMemo(() => {
    const nodeDocuments = data?.sharedDocuments?.edges || [];
    return filterItems(nodeDocuments, searchTerm);
  }, [data, searchTerm]);

  const hasNextPage: boolean = !!data?.sharedDocuments?.pageInfo?.hasNextPage;

  const endCursor: string | undefined = data?.sharedDocuments?.pageInfo?.endCursor;

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  const handleNewDocumentRequestClick = () =>
    openDialog({ type: 'PARTNER_REQUEST', props: { requestType: RequestType.DOCUMENT } });

  useEffect(() => {
    if (!documents?.length && hasNextPage) {
      handlePageEndReached();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return {
    error,
    loading,
    documents,
    searchTerm,
    endCursor,
    hasNextPage,
    setSearchTerm,
    handlePageEndReached,
    handleNewDocumentRequestClick,
  };
};

export default useSharedDocuments;
