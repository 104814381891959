import { ImageVariant, WidgetType } from './commonTypes';
import { ReactourStep } from 'reactour';

export type ApplicationPlan = 'SLIM_PLAN' | 'FULL_PLAN' | 'PARTNER_PLAN' | 'NO_PLAN';
export type CustomerType = 'DEFAULT' | 'PARTNER' | 'SLIM';
export enum PlanType {
  INTERNAL = 'INTERNAL',
  PAYING = 'PAYING',
  TRIAL = 'TRIAL',
  INACTIVE = 'INACTIVE',
}

export interface Company {
  id: string;
  description: string;
  name: string;
  website: string;
  onlineShop?: string;
  address?: Address;
  logo?: ImageVariant;
  features?: CompanyFeature;
  planDetails?: CustomerPlan;
  design?: CompanyDesign;
  integrationSettings?: IntegrationSettings;
  dataValid: boolean;
  apiKey: string;
  gaTrackingId: string;
  social: SocialSettings;
  customerType: CustomerType;
  partnerId?: string;
  isPartner: boolean;
}

export interface IntegrationSettings {
  showClaims: boolean;
  widgetType: WidgetType;
}

export interface Address {
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
}

export interface CompanyFeature {
  feedback?: boolean;
  userLimit: number;
  productLimit: number;
  whiteLabeling: boolean;
  compliance: boolean;
  payments: boolean;
}

export interface CustomerPlan {
  expired: boolean;
  upgradeRequested: boolean;
  expirationDate?: string | null;
  daysLeftInTrial: number;
  customerPlanId: string;
  planName: ApplicationPlan;
  planType: string;
}

export interface CompanyDesign {
  typography: CompanyTypography;
  palette: CompanyPalette;
}

export interface CompanyTypography {
  header: string;
  body: string;
}

export interface CompanyPalette {
  primary: string;
  secondary: string;
}

export interface SocialSettings {
  twitter: string | null;
  facebook: string | null;
  instagram: string | null;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  role: string;
  invitedTimestamp: string | null;
  isRegistered: boolean;
  onboarding: {
    sawWelcomeSlides: boolean;
    sawEditorTour: boolean;
    sawComponentChainTour: boolean;
    sawSupplyChainTour: boolean;
    sawDataImportTour: boolean;
  };
  latestTermsAccepted: boolean;
  notifications: {
    weeklyRoundup: boolean;
  };
  isAdmin: boolean;
}

export interface OnboardingTour {
  tourSteps: ReactourStep[];
  amplitudeEvent: string;
  onCompleted: () => null | void;
  firstStepButtonText: string;
}
