import { Box, Dialog, IconButton, Divider } from '@mui/material';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { DialogTitle, ThemeButton } from 'designSystem';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { PartnerRequest } from 'types/partner.types';
import { ReceivedRequestCard } from 'components/Home/Requests';
import { ThemeTypography, CardContainer } from 'designSystem';
import CompanyNameLogo from 'components/Structure/CompanyNameLogo';
import { AvailableSizes } from 'types/enums';
import DocumentIntroTitle from 'components/DocumentLibrary/DocumentIntroTitle';
import { DocumentViewer } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { useDialog } from 'components/hooks';
import usePartnerRequestActions from 'components/Partners/PartnerRequests/usePartnerRequestActions';
import { useMessages } from 'components/hooks';
import { styled } from '@mui/material/styles';

interface IAcceptRequestDialogProps extends IDefaultDialogProps {
  request: PartnerRequest;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',

  '& .MuiDialog-paper': {
    width: '90%',
    maxWidth: '100%',
    maxHeight: 650,
  },
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
}));

const DialogContent = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
}));

const Column = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 4),
}));

const RequestDetailsColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  overflow: 'auto',
  boxShadow: theme.custom.shadows[1],
  zIndex: 2,
}));

const DocumentPreviewColumn = styled(Column)(({ theme }) => ({
  width: '50%',
  maxHeight: '100%',
  overflow: 'auto',
  padding: 0,
  backgroundColor: theme.custom.themeColors.grayScale[5],
  marginBottom: theme.spacing(10),
}));

const ActionBar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '50%',
  right: 0,
  backgroundColor: '#fff',
  height: theme.spacing(10),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  marginTop: theme.spacing(2),
  boxShadow: theme.custom.shadows[1],
}));

const AcceptRequestDialog: FC<IAcceptRequestDialogProps> = ({ onClose, request }) => {
  const { openDialog } = useDialog();
  const { updatePartnerRequest } = usePartnerRequestActions();
  const { setErrorMessage } = useMessages();

  const handleAccept = () => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Accept Document',
        submitText: 'Accept and close request',
        text: `Are you sure you want to accept this document? The request will be closed and considered completed.`,
        onSubmit: handleAcceptConfirm,
        onCancel: () => undefined,
      },
    });
  };

  const handleAcceptConfirm = () => {
    updatePartnerRequest({
      variables: {
        id: request.id,
        input: {
          requestStatus: 'PROVIDED_COMPLETED',
        },
      },
      onError: () =>
        setErrorMessage('Something went wrong finishing the request. Please try again later.'),
      onCompleted: onClose,
    });
  };

  const handleEditRequest = () => {
    openDialog({
      type: 'PARTNER_REQUEST',
      props: {
        onClose,
        initialRequest: request,
        partner: request.partner,
      },
    });
  };

  return (
    <StyledDialog open onClose={onClose} data-cy="accept-request-dialog">
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      <DialogContent>
        <RequestDetailsColumn>
          <Box mb={4}>
            <DialogTitle icon={<Icon size="large" name="document-request-manage" />}>
              Review Upload
            </DialogTitle>
          </Box>
          <ReceivedRequestCard displayYourMessage request={request} displayActions={false} />
          <Box mt={4} mb={3}>
            <Divider />
          </Box>
          <Box display="flex" alignItems="center" mb={1.5}>
            <CompanyNameLogo
              size={AvailableSizes.MEDIUM}
              companyName={request.requestedToCompany.name}
            />
            <ThemeTypography variant="BODY_LARGE_BOLD">
              &nbsp;has uploaded a new document
            </ThemeTypography>
          </Box>
          {request.document && (
            <Box mb={2}>
              <CardContainer padding={1.5} selected>
                <DocumentIntroTitle document={request.document} showOpenInNewTab />
              </CardContainer>
            </Box>
          )}
        </RequestDetailsColumn>
        <DocumentPreviewColumn>
          <Box>
            {request.document && (
              <DocumentViewer file={request.document?.url} transparentBackground />
            )}
          </Box>
        </DocumentPreviewColumn>
        <ActionBar>
          <ThemeButton color="BLUE_ICE" type="button" onClick={onClose}>
            Cancel
          </ThemeButton>
          <Box display="flex" justifyContent="end">
            <ThemeButton
              color="BLUE_ICE"
              type="button"
              onClick={handleEditRequest}
              startIcon={<Icon name="edit" />}
              data-cy="edit-request-btn"
            >
              Edit request
            </ThemeButton>
            <Box mr={1} />
            <ThemeButton
              data-cy="accept-uploaded-document-btn"
              color="YELLOW"
              type="submit"
              startIcon={<Icon name="document-request-fulfilled" />}
              onClick={handleAccept}
            >
              Accept upload
            </ThemeButton>
          </Box>
        </ActionBar>
      </DialogContent>
    </StyledDialog>
  );
};

export default AcceptRequestDialog;
