import { useLazyQuery } from '@apollo/client';
import { GET_CHAIN } from 'graphql/queries';
import { GET_PRODUCT_AND_BASE_CHAIN } from 'graphql/queries/products';
import React, { FC, useEffect } from 'react';
import { useParams, useMatch } from 'react-router-dom';
import { IChainResponse } from 'types/chain.types';
import { Product } from 'types/types';

const ProductTitle: FC = () => {
  const { id, productId, chainId } = useParams<{
    id: string;
    productId: string;
    chainId: string;
  }>();
  const isSupplyChainMapping = useMatch('/supply-chains/:productId/:chainId/editor');

  const [getProduct, { data: productResult }] = useLazyQuery<{
    product: Product;
  }>(GET_PRODUCT_AND_BASE_CHAIN);

  const [getSupplyChain, { data: supplyChainResult }] = useLazyQuery<IChainResponse>(GET_CHAIN);

  const fetchTitle = () =>
    !isSupplyChainMapping
      ? // The productId is the route is used only for the supply chain mapping, everything else the param is just called id
        // TODO: Refactor this in the router
        getProduct({ variables: { id: productId || id } })
      : getSupplyChain({ variables: { id: chainId } });

  useEffect(() => {
    if (!chainId && !productId && !id) {
      return;
    }
    fetchTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupplyChainMapping?.pathname]);

  if (isSupplyChainMapping) {
    return <span>{supplyChainResult?.chain.title || 'Loading..'}</span>;
  }

  return <span>{productResult?.product.title || 'Loading..'}</span>;
};

export default ProductTitle;
