import React, { Fragment, FC } from 'react';
import { Form, Formik, Field } from 'formik';
import { FormControlBar } from 'components/WelcomeSlides';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS } from 'graphql/queries';
import { useLogEvent, useMessages } from 'components/hooks';
import { UPDATE_COMPANY_CONFIGS } from 'graphql/mutations';
import { ErrorState } from 'components/Structure';
import { Loader } from 'components/Forms';
import { Fade, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { PageContainer, TextContainer, ImageContainer, Headline, SkipButton } from './styles';
import { ColorSelector } from 'components/Forms';
import { DesignPreview } from 'components/Settings';
import { designSettingsSchema } from 'constants/schemas';
import { mergeDefaults } from 'components/Forms/utils';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { FieldWrapper, ThemeTypography } from 'designSystem';
import { CompanyDesign } from 'types/companyTypes';
import FontsAutocomplete from 'components/Forms/FontsAutocomplete';

interface IDesignProps {
  active: boolean;
}

const Design: FC<IDesignProps> = ({ active }) => {
  const { logEvent } = useLogEvent();
  const {
    data: { company } = {},
    error,
    loading,
  } = useQuery(GET_COMPANY_DETAILS, {
    fetchPolicy: 'cache-only',
  });

  const { closeDialog } = useWelcomeSlides();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const [updateCompanyConfigs] = useMutation(UPDATE_COMPANY_CONFIGS, {
    onCompleted: () => {
      setSuccessMessage('Success! Company design was updated.');
      logEvent('WELCOME_SLIDES_UPDATE_DESIGN');
      closeDialog();
    },
    onError: () => {
      setErrorMessage('Something went wrong updating your company design.');
    },
  });

  const handleSubmit = (values: CompanyDesign) => {
    return updateCompanyConfigs({
      variables: {
        input: {
          design: values,
        },
      },
    });
  };

  if (error) return <ErrorState error={error} />;
  if (!company && loading) return <Loader />;

  const initialValues = mergeDefaults(designSettingsSchema.default(), company?.design);

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={designSettingsSchema}
      onSubmit={values => handleSubmit(values)}
    >
      {({ isSubmitting, submitForm, values }) => (
        <Fragment>
          <PageContainer>
            <TextContainer>
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Headline variant="TITLE_LARGE">Branding</Headline>
                    <ThemeTypography variant="BODY_LARGE">
                      Add your brand color and typefaces to style your product stories.
                    </ThemeTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper label="Header font">
                      <Field
                        component={FontsAutocomplete}
                        resultLimit={10}
                        fullWidth
                        name="typography.header"
                        variant="outlined"
                      />
                    </FieldWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper label="Accent color">
                      <Field name="palette.primary" component={ColorSelector} />
                    </FieldWrapper>
                  </Grid>
                </Grid>
              </Form>
            </TextContainer>
            <Fade in={active} timeout={500}>
              <ImageContainer>
                <SkipButton onClick={closeDialog}>Edit this later</SkipButton>
                <DesignPreview
                  fontFamily={values.typography.header}
                  color={values.palette.primary}
                  companyName={company.name}
                  logoUrl={company.logo?.url}
                />
              </ImageContainer>
            </Fade>
          </PageContainer>
          <FormControlBar onSubmit={submitForm} isSubmitting={isSubmitting} submitButton />
        </Fragment>
      )}
    </Formik>
  );
};

Design.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Design;
