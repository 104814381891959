import { Box } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import React from 'react';
import { Partner } from 'types/partner.types';
import { getReadablePartnerStatus, getPartnerStatusColor } from 'utils/partnerUtils';
import {
  OpacityContainer,
  PartnerImage,
  Title,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';

interface Props {
  partner: Partner;
}

const PartnerSummary: React.FC<Props> = ({ partner }) => {
  return (
    <OpacityContainer display="flex" is-transparent="false">
      <PartnerImage
        alt={`Logo of ${partner.title}`}
        src={partner.logo?.url || defaultPartnerImage}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Title>{partner.title}</Title>

        <ThemeTypography variant="ITEM_SUBTITLE" color={getPartnerStatusColor(partner.status)}>
          {getReadablePartnerStatus(partner.status)}
        </ThemeTypography>
      </Box>
    </OpacityContainer>
  );
};

export default PartnerSummary;
