import React from 'react';
import { Globe } from '@styled-icons/bootstrap/Globe';
import { Lock } from '@styled-icons/fa-solid/Lock';
import { DocumentVisibilityStatus } from 'types/enums';

export type VisibilityStatusKeyValue = {
  key: string;
  value: DocumentVisibilityStatus;
  icon: JSX.Element;
};

export const getVisibilityIcon = (
  visibility: keyof typeof DocumentVisibilityStatus,
  size: number = 14
): JSX.Element => {
  if (visibility === 'PUBLIC') {
    return <Globe size={size} />;
  }

  return <Lock size={size} />;
};

const documentVisibilityOptions: VisibilityStatusKeyValue[] = Object.entries(
  DocumentVisibilityStatus
).map(([key, value]) => ({
  key,
  value,
  icon: getVisibilityIcon(key as keyof typeof DocumentVisibilityStatus),
}));

export default documentVisibilityOptions;
