import ComponentChainPlaceholderImage from 'assets/img/components/component-chain-placeholder.svg';
import SupplyChainPlaceholderImage from 'assets/img/supply-chain-placeholder-image.svg';
import { ImageVariant } from 'types/commonTypes';

export const getChainPartnerTitle = (outputComponents?: { partner: { title: string } }[]) => {
  if (!outputComponents?.length) {
    return 'Unknown';
  }

  return outputComponents.reduce((prev, { partner }) => {
    if (!prev) {
      return partner?.title || '';
    }
    return partner?.title ? `${prev} & ${partner.title}` : prev;
  }, '');
};

/**
 * Get the image of a component chain
 * First tries to return the real image, then tries to return the first image of a output component, if none of those are available, returns the default placeholder image
 */
export const getChainImage = (chain?: { image?: ImageVariant; productId?: string }) =>
  chain?.image?.url ||
  (chain?.productId ? SupplyChainPlaceholderImage : ComponentChainPlaceholderImage);
