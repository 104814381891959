import { gql } from '@apollo/client';
import { QR_CODE_DESIGN_FRAGMENT } from './qrCodeDesign';
import { PARTNER_BASE_FRAGMENT } from './partner';
import { CHAIN_BASE_FRAGMENT } from './chains';
import { IMAGE_VARIANT_FRAGMENT } from './media';

export const PRODUCT_LANGUAGE_CONFIG = gql`
  fragment languageConfigValues on ProductLanguageConfig {
    default
    enabled
  }
`;

export const PRODUCT_BASE_FRAGMENT = gql`
  fragment productBaseValues on Product {
    id
    title
    imageUrl
    articleNumber
    lastEditedTimestamp
    slug
    publicUrl
    image {
      ...imageVariant
    }
    languageConfig {
      ...languageConfigValues
    }
    latestSupplyChain {
      id
    }
  }
  ${PRODUCT_LANGUAGE_CONFIG}
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const PRODUCT_FRAGMENT = gql`
  fragment productValues on Product {
    ...productBaseValues
    publishedLanguages
    createdTimestamp
    publicUrl
    publishedTimestamp
    firstPublishedTimestamp
    contentStatus
    status
    styledStatus @client
    styledProgress @client
    isArchived @client
    isDraft @client
    isPublished @client
    lastEditedLang @client
    defaultQrCode {
      id
      tag
      url
      design {
        ...qrCodeDesignValues
      }
    }
    notEnabledLanguages @client
    latestAction {
      action
      userName
    }
  }
  ${PRODUCT_BASE_FRAGMENT}
  ${QR_CODE_DESIGN_FRAGMENT}
`;

export const PRODUCT_WITH_BASE_CHAIN_FRAGMENT = gql`
  fragment productWithBaseChain on Product {
    ...productBaseValues
    connectedPartners {
      ...partnerBaseValues
    }
    latestSupplyChain {
      ...chainBaseValues
    }
  }
  ${PRODUCT_BASE_FRAGMENT}
  ${PARTNER_BASE_FRAGMENT}
  ${CHAIN_BASE_FRAGMENT}
`;
