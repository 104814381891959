import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, styled } from '@mui/material';
import RawMaterialSelection from 'components/ComponentsLibrary/Forms/RawMaterialSelection';
import { FieldWrapper, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { LandOwnershipType } from 'types/sites.types';
import { LandOwnershipTypeLabelKeys } from 'utils/site.utils';
import { FarmFormValues } from './AddEditSiteDialog';
import { StyledTextField, SubTitle } from './FarmForm';
import { StyledDropDown } from './SiteForm';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  background: 'transparent',

  '&.MuiAccordion-root': {
    margin: '0 !important',
  },
  '& .Mui-expanded': {
    margin: '0 !important',
  },
  '& .MuiAccordionSummary-root': {
    minHeight: '30px !important',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper ': {
    display: 'none',
  },
  ' .MuiAccordionDetails-root': {
    padding: 0,
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 6,
  padding: theme.spacing(2),
  marginTop: theme.spacing(1.5),
}));

const ExtendedFarmForm = () => {
  const { values, setFieldValue } = useFormikContext<FarmFormValues>();
  const ownershipTypeOptions = Object.entries(LandOwnershipType).map(([key, value]) => ({
    key,
    value: LandOwnershipTypeLabelKeys[value],
  }));

  const hasExtendedData =
    values?.farmData?.numberOfFarmers ||
    values?.farmData?.landOwnership ||
    values?.farmData?.additionalRawMaterialIds?.length ||
    values?.farmData?.size;

  const [formExpanded, setFormExpanded] = useState<boolean>(!!hasExtendedData);

  return (
    <StyledAccordion expanded={formExpanded} onChange={() => setFormExpanded(!formExpanded)}>
      <AccordionSummary has-border="false">
        <Box display="flex" alignItems="center" gap={1.5}>
          <Icon
            name={formExpanded ? 'chevron-up' : 'chevron-down'}
            size="x-small"
            color="gray-60"
          />
          <ThemeTypography variant="BODY_LARGE" color="GRAY_60">
            {formExpanded ? 'Hide extended data' : 'Show extended data'}
          </ThemeTypography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <ContentContainer>
          <SubTitle variant="BODY_MEDIUM">Extended farm data</SubTitle>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FieldWrapper width="100%" label="Size">
                <Field
                  name="farmData.size"
                  fullWidth
                  placeholder="Enter farm size (Hectares)"
                  component={StyledTextField}
                  type="number"
                  size="small"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <FieldWrapper width="100%" label="Number of farmers">
                <Field
                  name="farmData.numberOfFarmers"
                  fullWidth
                  placeholder="Enter number"
                  component={StyledTextField}
                  type="number"
                  size="small"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <RawMaterialSelection
                label="Additional cultivated material"
                multiple
                name="farmData.additionalRawMaterialIds"
                size="small"
                labelVariant="medium"
                helperText="Select the additional cultivated materials of the farm"
              />
            </Grid>
            <Grid item xs={6}>
              <FieldWrapper label="Land ownership">
                <Field
                  component={StyledDropDown}
                  name="farmData.landOwnership"
                  variant="outlined"
                  fullWidth
                  placeholder="Select type"
                  currentValue={values?.farmData?.landOwnership || ''}
                  options={ownershipTypeOptions}
                  keyField="key"
                  onChange={(item: LandOwnershipType) =>
                    setFieldValue('farmData.landOwnership', item)
                  }
                  renderItems={(item: {
                    key: keyof typeof LandOwnershipType;
                    value: LandOwnershipType;
                  }) => (
                    <Box>
                      <span>{item.value}</span>
                    </Box>
                  )}
                />
              </FieldWrapper>
            </Grid>
          </Grid>
        </ContentContainer>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default ExtendedFarmForm;
