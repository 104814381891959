import { PartnerRequestStatus } from 'types/enums';

export const REVIEW_REQUEST_BUTTON_STATUS_CLIENT: Record<
  PartnerRequestStatus,
  { tooltip: string; disabled: boolean }
> = {
  [PartnerRequestStatus.DELETED]: {
    tooltip: 'This chain used to be part of a deleted request',
    disabled: true,
  },
  [PartnerRequestStatus.DENIED]: {
    tooltip: 'This chain is part of a denied request',
    disabled: true,
  },
  [PartnerRequestStatus.DENIED_COMPLETED]: {
    tooltip: 'This chain used to be part of a denied request',
    disabled: true,
  },
  [PartnerRequestStatus.REQUESTED]: {
    tooltip:
      'Your partner is currently working on this chain. As soon as they are done, you will be able to review it.',
    disabled: true,
  },
  [PartnerRequestStatus.EDITED]: {
    tooltip:
      'Your partner is currently working on this chain. As soon as they are done, you will be able to review it.',
    disabled: true,
  },
  [PartnerRequestStatus.PROVIDED]: {
    tooltip: '',
    disabled: false,
  },
  [PartnerRequestStatus.PROVIDED_COMPLETED]: {
    tooltip: 'This chain is part of a completed request',
    disabled: true,
  },
};

export const REVIEW_REQUEST_BUTTON_STATUS_PARTNER: Record<
  PartnerRequestStatus,
  { tooltip: string; disabled: boolean }
> = {
  [PartnerRequestStatus.DELETED]: {
    tooltip: 'This chain used to be part of a deleted request',
    disabled: true,
  },
  [PartnerRequestStatus.DENIED]: {
    tooltip: 'This chain is part of a denied request',
    disabled: true,
  },
  [PartnerRequestStatus.DENIED_COMPLETED]: {
    tooltip: 'This chain used to be part of a denied request',
    disabled: true,
  },
  [PartnerRequestStatus.REQUESTED]: {
    tooltip: '',
    disabled: false,
  },
  [PartnerRequestStatus.EDITED]: {
    tooltip: '',
    disabled: false,
  },
  [PartnerRequestStatus.PROVIDED]: {
    tooltip: 'This chain is currently being reviewed by your partner',
    disabled: true,
  },
  [PartnerRequestStatus.PROVIDED_COMPLETED]: {
    tooltip: 'This chain is part of a completed request',
    disabled: true,
  },
};
