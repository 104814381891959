import { FieldFunctionOptions } from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import moment from 'moment';
import { DocumentStatus } from 'types/enums';

const documentsPolicies = {
  Document: {
    fields: {
      usageCount: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        return readField('impactClaimCount');
      },
      status: (_: SafeReadonly<null>, { readField }: FieldFunctionOptions) => {
        const expiryDate = readField('expiryDate') as string;
        const hasExpired = moment(expiryDate).isBefore(moment(new Date()));

        if (hasExpired) return DocumentStatus.EXPIRED;

        return DocumentStatus.VALID;
      },
    },
  },
};

export default documentsPolicies;
