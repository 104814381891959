import { useLazyQuery } from '@apollo/client';
import { GET_COMPONENT } from 'graphql/queries';
import { GET_CHAIN } from 'graphql/queries/chains';
import React, { FC, useEffect } from 'react';
import { useParams, useMatch } from 'react-router-dom';
import { IChainResponse } from 'types/chain.types';
import { IComponentItem } from 'types/component.types';
import { RouteParamsWithId } from 'types/router.types';

/**
 * This can either be a component or a component chain title
 */
const DrawerComponentItemTitle: FC = () => {
  const { id } = useParams<RouteParamsWithId>();
  const isComponentChainMapping = useMatch('/component-chains/:id/editor');

  const [getComponentItem, { data: componentItemData }] = useLazyQuery<{
    component: IComponentItem;
  }>(GET_COMPONENT);

  const [getComponentChain, { data: componentChainResult, loading }] =
    useLazyQuery<IChainResponse>(GET_CHAIN);

  const fetchTitle = () =>
    !isComponentChainMapping
      ? getComponentItem({ variables: { id } })
      : getComponentChain({ variables: { id } });

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); // Id is need since changing from component to component chain need to refetch the correct item with that id

  if (isComponentChainMapping) {
    if (loading || !componentChainResult) {
      return <span>Loading..</span>;
    }

    return <span>{componentChainResult.chain.title}</span>;
  }

  return <span>{componentItemData?.component?.title || 'Loading..'}</span>;
};

export default DrawerComponentItemTitle;
