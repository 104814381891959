import React from 'react';
import { Partner } from 'types/partner.types';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { useNavigate } from 'react-router-dom';
import { removeGraphConnections } from 'utils/graphConnections';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';

const ImpactClaimIcon = styled('div')(({ theme }) => ({
  width: 28,
  height: 28,
  padding: 3,
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  borderRadius: '50%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.common.white,

  '& img': {
    width: '100%',
  },

  '&:not(:first-of-type)': {
    marginLeft: -13,
  },

  '&:last-of-type': {
    marginRight: 10,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  overflow: 'hidden',
  border: `1px solid ${theme.custom.themeColors.primary[5]}`,
  borderRadius: 50,
  whiteSpace: 'nowrap',
  display: 'flex',
}));

const EditIcon = styled(Edit)(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[100],
  marginLeft: 30,
}));

interface Props {
  impactClaims: Partner['impactClaims'];
  loading?: boolean;
  /** When clicking on the button it will go to the impact claims page, make sure the list is filtered on the correct text, for example the product or partner name */
  searchQuery?: string;
}

const ImpactClaimsCollection: React.FC<Props> = ({ impactClaims, searchQuery }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/impact-claims', {
      state: {
        search: `?query=${searchQuery}`,
      },
    });
  };

  const impactClaimIcons = removeGraphConnections(impactClaims);

  return (
    <StyledButton variant="outlined" onClick={onClick}>
      {impactClaimIcons.slice(0, 2).map(({ impactCatalogItem }) => (
        <ImpactClaimIcon key={impactCatalogItem.id}>
          <img alt={impactCatalogItem.title} src={impactCatalogItem.iconUrl} />
        </ImpactClaimIcon>
      ))}

      <ThemeTypography variant="BUTTON_LARGE">Impact claims</ThemeTypography>

      <EditIcon size={16} />
    </StyledButton>
  );
};

export default ImpactClaimsCollection;
