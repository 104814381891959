import { TablePagination } from '@mui/material';
import {
  PaginationContainer,
  SortableTableHead,
  StyledTable,
  StyledTableBody,
} from 'components/TableBase';
import { appQueryParams } from 'constants/appQueryParams';
import { COMPONENTS_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import React, { FC, Fragment, useState } from 'react';
import { IComponentItem } from 'types/component.types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { parseStringToOrder } from 'utils/order';
import ComponentsTableRow from './ComponentItemTableRow';
import { COLUMNS_COMPONENT_ITEMS, COMPONENT_ITEM_IDS } from './constants/headerColumns';

interface IComponentsTableProps {
  components: IComponentItem[];
  hideHeader?: boolean;
  selected?: IComponentItem;
  columns: COMPONENT_ITEM_IDS[];
  hidePartner?: boolean;
  onSelect: (partner: IComponentItem) => void;
  'data-cy'?: string;
}

const ComponentItemTable: FC<IComponentsTableProps> = ({
  components,
  columns,
  hideHeader,
  selected,
  hidePartner,
  onSelect,
  'data-cy': dataCy = 'component-items-table',
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [query, setQuery] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'desc'),
    [appQueryParams.orderBy]: withDefault(StringParam, 'modifiedTimestamp'),
  });
  const order = parseStringToOrder(query[appQueryParams.order]);
  const orderBy = query[appQueryParams.orderBy];

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setQuery({
      [appQueryParams.order]: isAsc ? 'desc' : 'asc',
      [appQueryParams.orderBy]: property,
    });
  };

  const headerColumns = COLUMNS_COMPONENT_ITEMS.filter(({ id }) => columns.includes(id));

  return (
    <Fragment>
      <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
        {!hideHeader && (
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            headerColumns={headerColumns}
            onSort={handleRequestSort}
          />
        )}
        <StyledTableBody data-cy={dataCy}>
          {components
            .slice(
              currentPage * COMPONENTS_TABLE_ROWS_PER_PAGE,
              currentPage * COMPONENTS_TABLE_ROWS_PER_PAGE + COMPONENTS_TABLE_ROWS_PER_PAGE
            )
            .map(component => (
              <ComponentsTableRow
                selected={selected}
                key={component.id}
                componentItem={component}
                columns={columns}
                hidePartner={hidePartner}
                onRowClick={onSelect}
              />
            ))}
        </StyledTableBody>
      </StyledTable>
      {components.length > COMPONENTS_TABLE_ROWS_PER_PAGE && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={components.length}
            rowsPerPage={COMPONENTS_TABLE_ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={(_, newPage) => setCurrentPage(newPage)}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(components.length / COMPONENTS_TABLE_ROWS_PER_PAGE)}`
            }
          />
        </PaginationContainer>
      )}
    </Fragment>
  );
};

export default ComponentItemTable;
