import { IActivityFormValue } from 'constants/schemas/activities.schema';
import { IChainActivityFormValue } from 'constants/schemas/chains.schema';
import omit from 'lodash/omit';

export const extractActivityMutationValues = (
  values?:
    | IChainActivityFormValue
    | {
        activity?: IActivityFormValue;
      }
) => ({
  partnerId: values?.activity?.partner?.id || null,
  componentId: values?.activity?.component?.id || null,
  siteId: values?.activity?.site?.id || null,
  ...omit(values?.activity, [
    'id',
    'partnerId',
    'partner',
    'component',
    'langs',
    'cultivatedAreas',
    'createdTimestamp',
    'site',
  ]),
});
