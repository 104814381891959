import { Box } from '@mui/material';
import { InfoCircle } from '@styled-icons/bootstrap';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import StepSelectorBlock from 'components/ChainMapping/Forms/StepSelectorBlock';
import useComponentChainActions from 'components/ComponentsLibrary/hooks/useComponentChainActions';
import useComponentItemActions from 'components/ComponentsLibrary/hooks/useComponentItemActions';
import { usePartnerMutations } from 'components/Partners/hooks';
import { useDialog } from 'components/hooks';
import chainActivitySchema, {
  IChainActivityFormValue,
  nestedActivitySchema,
} from 'constants/schemas/chains.schema';
import { DialogDefault, ThemeTypography } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { Form, Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React, { FC, useRef } from 'react';
import { IChainActivity, IChainStep, ISubChain } from 'types/chain.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { AvailableLanguages, AvailableLanguagesType } from 'types/enums';
import { IActivity, Partner } from 'types/partner.types';
import ActivityCommunicationBlocks from '../Forms/ActivityCommunicationBlocks';
import CompanySelectBlock from '../Forms/CompanySelectBlock';
import LinkConnectionsBlock from '../Forms/LinkConnectionsBlock';
import OutputComponentBlock from '../Forms/OutputComponentBlock';
import SiteSelectorBlock from '../Forms/SiteSelectorBlock';
import {
  BlockContainer,
  BottomContainer,
  ButtonContainer,
  DialogInner,
  FormContainerLeft,
  FormContainerRight,
  StyledLink,
} from '../Forms/styles';
import { extractActivityMutationValues } from '../utils/activity-form.utils';
import { activitySchema, IActivityFormValue } from 'constants/schemas/activities.schema';
import useSiteMutations from 'components/Sites/hooks/useSiteMutations';

export type AddEditActivity =
  | {
      mode: 'add-activity';
      /**
       * The partner / company selector will be hidden and the partner can be preselected with the partner prop
       */
      partner?: Partner;
    }
  | {
      mode: 'edit-activity';
      /**
       * Location to be edited
       */
      activity: IActivity;
      /**
       * In this language the activity will be edited
       * Default is English
       */
      lang?: AvailableLanguagesType;
      /**
       * Shown on the communication side where you are not able to edit chain related information
       */
      linkToSupplyChainMapping?: string;
      /**
       * For mode default the actual properties can be edited and changed
       * In 'translate' only the communication values like translations and media can be edited
       */
      editMode?: 'default' | 'translate';
    };

export type AddEditChainActivity =
  | {
      mode: 'add-chain-activity';
      /**
       * The step where the activity will be added to
       */
      selectedStepId: string;
      activities: IChainActivity[];
      subChains: ISubChain[];
      steps: IChainStep[];
      partner?: Partner;
      onActivityAdd: (
        activityId: string,
        activity: Pick<IChainActivity, 'chainStepId' | 'incomingLinks'>
      ) => void;
    }
  | {
      mode: 'edit-chain-activity';
      activities: IChainActivity[];
      subChains: ISubChain[];
      steps: IChainStep[];
      /**
       * Activity to be edited
       */
      chainStepActivity: Omit<IChainActivity, 'pointPosition'>;
      onActivityEdit: (
        activity: Pick<IChainActivity, 'id'> & Pick<IChainActivity, 'chainStepId' | 'incomingLinks'>
      ) => void;
      onActivityDelete: (activityId: string) => void;
    };

type IAddEditActivityDialogProps = {
  disableDelete?: boolean;
  hidePartnerSelector?: boolean;
} & IDefaultDialogProps &
  (AddEditChainActivity | AddEditActivity);

enum AddEditActivityMode {
  ADD_ACTIVITY = 'add-activity',
  EDIT_ACTIVITY = 'edit-activity',
  ADD_CHAIN_ACTIVITY = 'add-chain-activity',
  EDIT_CHAIN_ACTIVITY = 'edit-chain-activity',
}

type FormValues =
  | IChainActivityFormValue
  | {
      activity?: IActivityFormValue;
    };

/**
 * The dialog can be opened in different modes controlling which information is shown and can be edited or created
 * AddEditActivityMode.ADD_ACTIVITY | AddEditActivityMode.EDIT_ACTIVITY | AddEditActivityMode.ADD_CHAIN_ACTIVITY | AddEditActivityMode.EDIT_CHAIN_ACTIVITY;
 */
const AddEditActivityDialog: FC<IAddEditActivityDialogProps> = ({
  open,
  disableDelete,
  hidePartnerSelector,
  onClose,
  ...props
}) => {
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { openDialog } = useDialog();
  const { createActivity: createPartnerActivity, handleRemoveActivity } = usePartnerMutations();
  const { updateActivityPartner: updatePartnerActivity } = useComponentChainActions();
  const { updateComponent } = useComponentItemActions();
  const { updateSite } = useSiteMutations();

  const mode = props.mode;
  const isChainActivity =
    mode === AddEditActivityMode.ADD_CHAIN_ACTIVITY ||
    mode === AddEditActivityMode.EDIT_CHAIN_ACTIVITY;
  const isEdit =
    mode === AddEditActivityMode.EDIT_CHAIN_ACTIVITY || mode === AddEditActivityMode.EDIT_ACTIVITY;
  const editMode = 'editMode' in props ? props.editMode : 'default';

  const initialData: FormValues = isChainActivity
    ? {
        ...chainActivitySchema.default(),
        ...(isEdit
          ? {
              ...omit(props.chainStepActivity, 'pointPosition'),
            }
          : {
              chainStepId: props.selectedStepId,
              activity: {
                ...activitySchema.default(),
                partner: props.partner,
              },
            }),
      }
    : {
        activity: {
          ...activitySchema.default(),
          ...(isEdit ? { ...props.activity } : { partner: props.partner }),
        },
      };

  const handleSubmit = async (values: FormValues) => {
    let activityResponse;
    if (isEdit && 'activity' in values && values.activity && initialData.activity) {
      // If we are editing the communication of an activity and the component title has changed, we need to update the correct component language
      if (editMode === 'translate') {
        // Trigger update of the output component if the title has changed
        if (
          values.activity.component &&
          values.activity.component.title !== (initialData.activity as IActivity).component?.title
        ) {
          updateComponent({
            variables: {
              id: values.activity.component.id,
              input: {
                ...omit(values.activity.component, ['id', 'rawMaterials']),
                rawMaterialIds: values.activity.component.rawMaterials.map(({ id }) => id),
              },
              lang: ('lang' in props && props.lang) || AvailableLanguages.ENGLISH,
            },
          });
        }

        if (
          values.activity.site &&
          (values.activity.site.title !== (initialData.activity as IActivity).site?.title ||
            values.activity.site.description !==
              (initialData.activity as IActivity).site?.description)
        ) {
          updateSite({
            variables: {
              id: values.activity.site.id,
              input: {
                siteType: values.activity.site.siteType,
                title: values.activity.site.title,
                locationCoordinates: values.activity.site.locationCoordinates,
                description: values.activity.site.description,
              },
              lang: ('lang' in props && props.lang) || AvailableLanguages.ENGLISH,
            },
          });
        }
      }

      const newActivityValues = extractActivityMutationValues(values);
      const hasActivityChanged = !isEqual(
        newActivityValues,
        extractActivityMutationValues(initialData)
      );
      if (hasActivityChanged && values.activity.id) {
        await updatePartnerActivity({
          variables: {
            id: values.activity.id,
            input: {
              ...newActivityValues,
            },
            lang: !isChainActivity && props.lang ? props.lang : AvailableLanguages.ENGLISH,
          },
        });
      }
    } else if ('activity' in values && values.activity) {
      // with the id in the response we can create the activity
      activityResponse = await createPartnerActivity({
        variables: {
          input: {
            partnerId: values.activity.partner?.id || null,
            siteId: values.activity.site?.id,
            componentId: values.activity.component?.id,
            description: values.activity.description,
            title: values.activity.title,
            mediaList: values.activity.mediaList,
          },
        },
      });
    }

    if (isChainActivity && 'chainStepId' in values && 'incomingLinks' in values) {
      const initialActivityData = pick(initialData, ['chainStepId', 'incomingLinks']);
      const currentActivityData = pick(values, ['chainStepId', 'incomingLinks']);
      if (isEdit) {
        if (!isEqual(initialActivityData, currentActivityData)) {
          props.onActivityEdit({ id: props.chainStepActivity.id, ...currentActivityData });
        }
      } else {
        if (!activityResponse?.data?.createActivity?.activity?.id) {
          throw new Error('Partner location id is not defined');
        }
        props.onActivityAdd(activityResponse.data.createActivity.activity?.id, currentActivityData);
      }
    }
    onClose?.();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialData)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  const handleDeleteClick = () => {
    // Delete is only possible in edit mode
    if (!disableDelete && isEdit) {
      if (isChainActivity) {
        props.onActivityDelete(props.chainStepActivity.id);
      } else {
        handleRemoveActivity({ activityId: props.activity.id });
      }
      onClose?.();
    }
  };

  return (
    <DialogDefault
      title={!isEdit ? 'New activity' : 'Edit activity'}
      onClose={handleClose}
      data-cy="add-edit-activity-dialog"
      open={open}
      fullWidth
      icon={!isEdit ? Plus : Edit}
      maxWidth="xl"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialData}
        validateOnChange
        initialStatus={{
          lang: isEdit && !isChainActivity && props.lang ? props.lang : AvailableLanguages.ENGLISH,
        }}
        validationSchema={isChainActivity ? chainActivitySchema : nestedActivitySchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, values, initialValues }) => (
          <Form>
            <DialogInner container min-height="70vh">
              <FormContainerLeft item xs={6}>
                <BlockContainer container overflowY="auto">
                  <StepSelectorBlock steps={isChainActivity ? props.steps : undefined} />

                  {/* The site selector block is not shown in the product */}
                  <SiteSelectorBlock mode={editMode} />

                  {isChainActivity && props.steps && props.activities && (
                    <LinkConnectionsBlock
                      steps={props.steps}
                      activities={props.activities}
                      subChains={props.subChains}
                    />
                  )}

                  {!hidePartnerSelector && <CompanySelectBlock />}

                  <OutputComponentBlock mode={editMode} />

                  {isEdit && !isChainActivity && props.linkToSupplyChainMapping && (
                    <BottomContainer>
                      <InfoCircle size={14} />
                      <Box mr={1} />
                      <ThemeTypography variant="BODY_LARGE">
                        To edit supply chain details of this activity, go to the{' '}
                      </ThemeTypography>
                      <Box mr={0.5} />
                      <StyledLink to={props.linkToSupplyChainMapping} onClick={onClose}>
                        Supply chain mapping tool.
                      </StyledLink>
                    </BottomContainer>
                  )}
                </BlockContainer>
              </FormContainerLeft>

              <FormContainerRight item xs={6}>
                <BlockContainer container z-index={0} height="calc(100% - 100px)" overflowY="auto">
                  <ActivityCommunicationBlocks />
                </BlockContainer>

                <ButtonContainer justifyContent="space-between">
                  <Box>
                    {isEdit && !disableDelete && (
                      <ThemeButton
                        color="BLUE_ICE"
                        size="large"
                        type="button"
                        onClick={handleDeleteClick}
                        data-cy="delete-activity-form-btn"
                        startIcon={<Trash size={14} />}
                      >
                        Delete
                      </ThemeButton>
                    )}
                  </Box>

                  <Box display="flex" justifyContent="end" gap={1.8}>
                    <ThemeButton color="BLUE_ICE" size="large" type="button" onClick={handleClose}>
                      Cancel
                    </ThemeButton>

                    <ThemeButton
                      data-cy="submit-activity-form-btn"
                      loading={isSubmitting}
                      color="YELLOW"
                      size="large"
                      type="submit"
                      disabled={!isValid || isEqual(values, initialValues)}
                    >
                      {isEdit ? 'Apply changes' : 'Save'}
                    </ThemeButton>
                  </Box>
                </ButtonContainer>
              </FormContainerRight>
            </DialogInner>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default AddEditActivityDialog;
