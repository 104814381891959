import { Grid } from '@mui/material';
import { companySchema } from 'constants/schemas';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { Form, Formik } from 'formik';
import React from 'react';
import { Company } from 'types/companyTypes';
import CompanyFields from './CompanyFields';

interface Props {
  company: Company;
  onSubmit: (company: Partial<Company>) => void;
}

const CompanyEditForm: React.FC<Props> = ({ company, onSubmit }) => {
  // Destructure to make sure not all fields are submitted through the form like the id etc.
  const { description, name, onlineShop, website, logo, social } = company;

  return (
    <Formik
      initialValues={{ description, name, onlineShop, website, logo, social }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={companySchema}
      onSubmit={values => {
        return onSubmit({
          ...values,
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container>
            <CompanyFields />
            <Grid container item justifyContent="flex-end" alignItems="center">
              <ThemeButton size="large" color="YELLOW" loading={isSubmitting} type="submit">
                Save
              </ThemeButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyEditForm;
