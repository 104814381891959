import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageContainer, PageTitle } from 'components/Structure';
import { useAuthorization, usePageTitle, useUserData } from 'components/hooks';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsSidebar from './SettingsSidebar';

const StyledTitle = styled(props => <PageTitle {...props} />)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Layout = () => {
  usePageTitle('Settings');
  const { isAdmin } = useAuthorization();
  const {
    company: { dataValid },
  } = useUserData();
  const { plan, isPartner } = useCompanyPlanContext();

  const menu = [
    {
      label: 'Profile',
      to: '/settings',
      active: true,
    },
    {
      label: 'Security',
      to: '/settings/security',
      active: true,
    },
    {
      label: 'Notifications',
      to: '/settings/notifications',
      active: !isPartner,
    },
    {
      label: 'Company',
      to: '/settings/company',
      displayWarning: !dataValid,
      active: !isPartner && isAdmin,
    },
    {
      label: 'Users',
      to: '/settings/users',
      active: isAdmin,
    },
    {
      label: 'Integrations',
      to: '/settings/integrations',
      active: !isPartner && isAdmin,
    },
    {
      label: 'Branding',
      to: '/settings/branding',
      active: !isPartner && isAdmin,
    },
    {
      label: 'Google Analytics',
      to: '/settings/google-analytics',
      active: !isPartner && isAdmin && plan === 'FULL_PLAN',
    },
  ];

  return (
    <PageContainer>
      <StyledTitle title="Settings" />
      <Grid container direction="row" spacing={2}>
        <Grid item xs={2}>
          <SettingsSidebar menu={menu} />
        </Grid>
        <Grid item xs={10}>
          <Outlet />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
