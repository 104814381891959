import React, { FC } from 'react';
import {
  PageContainer,
  PageTitle,
  ErrorState,
  EmptySearchResults,
  ScrollableTableContainer,
  ActionContainer,
} from 'components/Structure';
import useActivities from 'hooks/useActivities';
import { Loader, SearchToolbar } from 'components/Forms';
import { EmptyStateCard, CardIllustration, ThemeButton } from 'designSystem';
import { Box } from '@mui/material';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';
import { Plus } from '@styled-icons/bootstrap';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import ActivitiesTable from 'components/Partners/ActivitiesTable/ActivitiesTable';
import { ACTIVITIES_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import { ActivitiesTableColumnKeys } from 'components/Partners/ActivitiesTable/activitiesHeaderColumns';

const PartnerActivities: FC = () => {
  const {
    activities,
    filteredActivities,
    loading,
    error,
    searchTerm,
    partner,
    handleSearchTermChange: setSearchTerm,
    handleCreateActivity,
    handleEditActivity,
  } = useActivities();
  if (loading) return <Loader />;
  if (error) return <ErrorState />;

  if (!activities.length) {
    return (
      <PageContainer>
        <PageTitle
          title="Partner activities"
          goBackLabel="All Partners"
          goBackUrl="/partners"
          titleExtension={partner?.title}
        />
        <Box mt={4}>
          <EmptyStateCard
            data-cy="activities-getting-started-card"
            data-cy-button="activities-getting-started-card-button"
            title="Add the partner activities you know of to give your collaborator a head start"
            variant={ItemTypes.LOCATION}
            buttonText="Add new activity"
            background={BackgroundTypes.WORLD}
            size={AvailableSizes.LARGE}
            renderIllustration={
              <CardIllustration
                image={IllustrationTypes.LOCATIONS}
                width={300}
                right={25}
                top={0}
              />
            }
            onCreateItemClick={handleCreateActivity}
          />
        </Box>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageTitle
        title="Partner activities"
        goBackLabel="All Partners"
        goBackUrl="/partners"
        titleExtension={partner?.title}
      />
      <Box mt={2} position="relative">
        <ActionContainer>
          <SearchToolbar
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm || ''}
            className="search-input"
            data-cy="activity-search-input"
            placeholder="Search activity"
            actions={
              <Box ml={1}>
                <ThemeButton
                  size="medium"
                  color="YELLOW"
                  onClick={handleCreateActivity}
                  startIcon={<Plus size={20} />}
                  data-cy="create-activity-btn"
                >
                  Create activity
                </ThemeButton>
              </Box>
            }
          />
        </ActionContainer>

        {filteredActivities.length > 0 && (
          <ScrollableTableContainer pb={2}>
            <ActivitiesTable
              columns={[
                ActivitiesTableColumnKeys.TITLE,
                ActivitiesTableColumnKeys.CREATED_AT,
                ActivitiesTableColumnKeys.ACTIONS,
              ]}
              rowsPerPage={ACTIVITIES_TABLE_ROWS_PER_PAGE}
              activities={filteredActivities}
              onSelect={handleEditActivity}
              onEdit={handleEditActivity}
            />
          </ScrollableTableContainer>
        )}
      </Box>

      {!filteredActivities.length && (
        <EmptySearchResults
          message="No search results found"
          action={{
            label: 'Create new activity',
            onClick: handleCreateActivity,
          }}
          skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
        />
      )}
    </PageContainer>
  );
};

export default PartnerActivities;
