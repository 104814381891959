import { gql } from '@apollo/client';
import { FIRST_MILE_IMPORT_FRAGMENT } from 'graphql/fragments/imports';

export const GET_FIRST_MILE_IMPORTS = gql`
  query GetFirstMileImports($partnerId: UUID) {
    firstMileImports(partnerId: $partnerId) {
      edges {
        node {
          ...firstMileImportValues
        }
      }
    }
  }
  ${FIRST_MILE_IMPORT_FRAGMENT}
`;

export const GET_FIRST_MILE_IMPORT = gql`
  query GetFirstMileImport($id: UUID!) {
    firstMileImport(id: $id) {
      ...firstMileImportValues
      rows {
        edges {
          node {
            siteTitle
            farmExternalId
            rawMaterialIds
            locationCoordinates {
              lat
              lng
            }
            farmSize
            farmDescription
          }
        }
      }
    }
  }
  ${FIRST_MILE_IMPORT_FRAGMENT}
`;
