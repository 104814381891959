import { TablePagination } from '@mui/material';
import ActivityTableBody from 'components/Partners/ActivitiesTable/ActivitiesTableBody';
import {
  ActivitiesTableColumnKeys,
  activitiesHeaderColumns,
} from 'components/Partners/ActivitiesTable/activitiesHeaderColumns';
import { PaginationContainer, SortableTableHead, StyledTable } from 'components/TableBase';
import { appQueryParams } from 'constants/appQueryParams';
import React, { Fragment, useMemo, useState } from 'react';
import { IActivity } from 'types/partner.types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { getComparator, stableSort } from 'utils';

interface Props {
  columns: ActivitiesTableColumnKeys[];
  activities: IActivity[];
  hideHeader?: boolean;
  isTransparent?: boolean;
  rowsPerPage?: number;
  onSelect: (activity: IActivity) => void;
  onEdit: (activity: IActivity) => void;
}

const ActivitiesTable: React.FC<Props> = ({
  columns,
  activities,
  hideHeader = false,
  isTransparent = false,
  rowsPerPage = 5,
  onSelect,
  onEdit,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const [query, setQuery] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'desc'),
    [appQueryParams.orderBy]: withDefault(StringParam, 'lastEditedTimestamp'),
  });
  const order = query[appQueryParams.order] as 'asc' | 'desc' | undefined;
  const orderBy = query[appQueryParams.orderBy];

  const shownColumns = useMemo(
    () => activitiesHeaderColumns.filter(({ id }) => columns.includes(id)),
    [columns]
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setCurrentPage(0);
    setQuery({
      [appQueryParams.order]: isAsc ? 'desc' : 'asc',
    });
    setQuery({
      [appQueryParams.orderBy]: property,
    });
  };

  const sortedActivities = useMemo(
    () => stableSort(activities, getComparator(order, orderBy)),
    [order, orderBy, activities]
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Fragment>
      <StyledTable
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
        data-cy="partners-table"
      >
        {!hideHeader && (
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            headerColumns={shownColumns}
          />
        )}
        <ActivityTableBody
          isTransparent={isTransparent}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          activities={sortedActivities}
          onSelect={onSelect}
          onEdit={onEdit}
          headerColumns={shownColumns}
        />
      </StyledTable>

      {activities.length > rowsPerPage && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={activities.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(activities.length / rowsPerPage)}`
            }
          />
        </PaginationContainer>
      )}
    </Fragment>
  );
};

export default ActivitiesTable;
