import { gql } from '@apollo/client';
import { IMPACT_CLAIM_FRAGMENT, IMPACT_CATALOG_ITEM_FRAGMENT } from 'graphql/fragments';
import { configInstance } from 'config';

export const GET_IMPACT_CLAIMS = gql`
  query GetImpactClaims($requestStatus: [PartnerRequestStatus]) {
    impactClaims {
      edges {
        node {
          ...impactClaimValues
          partnerRequests(status: $requestStatus) {
            edges {
              node {
                id
                requestedByUserId
                partnerId
                documentId
                requestTitle
                requestMessage
                requestReplyMessage
                requestStatus
                requestedTimestamp
              }
            }
          }
        }
      }
    }
  }
  ${IMPACT_CLAIM_FRAGMENT}
`;

// Fix: use PARTNER_REQUEST from partner.ts for IMPACT_CLAIM_FRAGMENT
export const GET_SINGLE_CLAIM = gql`
  query GetClaim($id: UUID!, $lang: Language!, $requestStatus: [PartnerRequestStatus]) {
    impactClaim(id: $id, lang: $lang) {
      ...impactClaimValues
      partnerRequests(status: $requestStatus) {
        edges {
          node {
            id
            requestedByUserId
            partnerId
            documentId
            requestTitle
            requestMessage
            requestReplyMessage
            requestStatus
            requestedTimestamp
          }
        }
      }
    }
  }
  ${IMPACT_CLAIM_FRAGMENT}
`;

export const GET_IMPACT_CATALOG = gql`
  query GetImpactCatalog {
    ${Object.keys(configInstance.claimCategories).map(category => {
      const { catalogQueryAlias } = configInstance.claimCategories[category];

      return `
        ${catalogQueryAlias}: impactCatalogItems(category: ${category}) {
          edges {
            node {
              ...impactCatalogItemValues
            }
          }
        }
      `;
    })}
  }
  ${IMPACT_CATALOG_ITEM_FRAGMENT}
`;
