import { useMutation } from '@apollo/client';
import { Box, DialogActions, DialogContent, MenuItem, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap';
import { useMessages } from 'components/hooks';
import eudrStatementCreationSchema, {
  IEudrStatementCreationFormValues,
} from 'constants/schemas/compliance.schema';
import { DialogDefault, FieldWrapper, ThemeButton, ThemeTypography } from 'designSystem';
import TextField from 'designSystem/Formik/TextField/TextField';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { CREATE_STATEMENT } from 'graphql/mutations/compliance';
import { ICreateStatementInput } from 'graphql/mutations/types/compliance-mutation.types';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EudrStatement } from 'types/compliance.types';
import { IDefaultDialogProps } from 'types/dialog.types';
import { getYearsUntilToday } from 'utils/year.utils';

const YEARS = getYearsUntilToday();

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  marginTop: theme.spacing(6),
  padding: theme.spacing(4, 0, 0, 0),
  justifyContent: 'space-between',
}));

const StartDueDiligenceDialog: FC<IDefaultDialogProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { setErrorMessage } = useMessages();
  const [createStatement] = useMutation<
    {
      createStatement: { statement: EudrStatement };
    },
    { input: ICreateStatementInput }
  >(CREATE_STATEMENT, {
    onCompleted: ({
      createStatement: {
        statement: { id },
      },
    }) => {
      navigate(`/compliance/eudr/${id}/guide`);
      onClose?.();
    },
    onError: () => setErrorMessage('Something went wrong creating your due diligence statement'),
  });

  const handleSubmit = (values: IEudrStatementCreationFormValues) => {
    createStatement({
      variables: { input: { ...values, complianceId: '00000000-0000-0000-0000-000000000000' } },
    });
  };

  return (
    <DialogDefault
      title="New Due diligence"
      onClose={onClose}
      data-cy="add-eudr-statement-dialog"
      open={open}
      fullWidth
      icon={Plus}
      maxWidth="sm"
    >
      <DialogContent>
        <Formik<IEudrStatementCreationFormValues>
          initialValues={eudrStatementCreationSchema.default()}
          validationSchema={eudrStatementCreationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <FieldWrapper
                label="Name your Due diligence"
                required
                tooltip={{
                  variant: 'INFO',
                  helperText:
                    'This is the process of risk-assessing one or multiple origin batches that are imported to the EU, resulting in one Due diligence statement. It could be linked to a certain month or season of imports, or a certain end-product manufacturing batch.',
                }}
              >
                <Field
                  component={TextField}
                  fullWidth
                  type="text"
                  name="title"
                  variant="outlined"
                  size="small"
                  placeholder="E.g. “May imports”, or “2024 due diligence”"
                  data-cy="statement-title-input"
                  inputProps={{
                    style: { fontSize: 14, height: 19 }, // Fix size difference between select and text input
                    'data-cy': 'statement-title-input',
                  }}
                />
              </FieldWrapper>
              <Box mt={2} />

              <FieldWrapper
                label="For what year are you conducting your EUDR due diligence?"
                required
              >
                <Field
                  component={FormikTextField}
                  placeholder="Select year"
                  fullWidth
                  type="number"
                  name="year"
                  variant="outlined"
                  select
                  size="small"
                >
                  {YEARS.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Field>
              </FieldWrapper>

              <Box mt={2}>
                <ThemeTypography variant="BODY_MEDIUM">
                  You can carry out multiple due diligence processes in a year.
                </ThemeTypography>
              </Box>

              <StyledDialogActions>
                <ThemeButton
                  color="BLUE_ICE"
                  size="large"
                  className="button-wide"
                  onClick={onClose}
                >
                  Cancel
                </ThemeButton>
                <ThemeButton
                  loading={isSubmitting}
                  color="YELLOW"
                  size="large"
                  className="button-wide"
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  Start due diligence
                </ThemeButton>
              </StyledDialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </DialogDefault>
  );
};

export default StartDueDiligenceDialog;
