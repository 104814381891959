import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLogEvent, useMessages, useUploadState } from 'components/hooks';
import { ConfirmationSlide, ImpactLevelSlide, ProofSlide } from 'components/SliderForm';
import { CREATE_IMPACT_CLAIM } from 'graphql/mutations';
import { GET_IMPACT_CATALOG, GET_IMPACT_CLAIMS, GET_PRODUCT, GET_PARTNER } from 'graphql/queries';
import React, { useEffect, useState } from 'react';
import { GraphQlConnection, Product } from 'types/types';
import { SelectSlide } from '../ClaimDialog/Slides';
import { impactLevelOptions } from '../constants/impactLevelOptions';
import {
  CreateImpactClaimPayload,
  ImpactCatalogItem,
  ImpactClaim,
  ImpactClaimCreateInput,
  ImpactClaimStatus,
} from 'types/impactClaimTypes';
import { Partner, PartnerRequest } from 'types/partner.types';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { Document } from 'types/types';
import { IComponentItem } from 'types/component.types';

const StyledButton = styled(ThemeButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));

interface Props {
  onClose?: () => void;
  // The partner that was selected before the create claim flow.
  // This means that the select impact level step can be skipped.
  preSelectedPartner?: Partner;
}

const useCreateClaim = ({ onClose, preSelectedPartner }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { cleanFiles, handleCleanCreatedRecords } = useUploadState();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const {
    data: impactCatalogData,
    loading,
    error,
  } = useQuery<ImpactCatalogItem>(GET_IMPACT_CATALOG);
  const { logEvent } = useLogEvent();

  const [selectedItem, setSelectedItem] = useState<ImpactCatalogItem | null>(null);

  const [selectedImpactLevel, setSelectedImpactLevel] = useState(
    preSelectedPartner ? impactLevelOptions[2].value : impactLevelOptions[0].value
  );

  const [selectedProductId, setSelectedProductId] = useState<string>();
  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>(preSelectedPartner);
  const [selectedComponent, setSelectedComponent] = useState<IComponentItem | undefined>();

  const [documents, setDocuments] = useState([]);
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);
  const [deletedDocuments, setDeletedDocuments] = useState<string[]>([]);
  const [documentRequests, setDocumentRequests] = useState<PartnerRequest[]>([]);
  const handleDelete = (recordId: string) => {
    setDeletedDocuments([...deletedDocuments, recordId]);
  };
  const handleDeleteRequest = (requestId: string) => {
    const updatedDocumentRequests = documentRequests.filter(
      documentRequest => documentRequest.id !== requestId
    );
    setDocumentRequests(updatedDocumentRequests);
  };

  const [getProduct, { data: productData }] = useLazyQuery<{ product: Product }, { id: string }>(
    GET_PRODUCT
  );

  const product = productData?.product;

  const selectedProduct = selectedProductId ? product : null;

  const [createImpactClaim, { loading: isSubmitting }] = useMutation<
    CreateImpactClaimPayload,
    { input: ImpactClaimCreateInput }
  >(CREATE_IMPACT_CLAIM, {
    onCompleted: ({ createImpactClaim: { impactClaim } = {} }) => {
      if (impactClaim?.status === ImpactClaimStatus.CREATED) {
        logEvent('SAVE_CLAIM_AS_DRAFT');
        setSuccessMessage('Your draft has been successfully saved');
      } else {
        logEvent('REQUEST_CLAIM');
        setSuccessMessage('Your claim was submitted and is waiting for approval');
      }
      onClose?.();
      setOpenUpdateAlert(false);
    },
    onError: () => setErrorMessage('There was an error submitting your claim'),
    update: (cache, { data }) => {
      const impactClaim = data?.createImpactClaim.impactClaim;
      const result = cache.readQuery<{ impactClaims: GraphQlConnection<ImpactClaim> }>({
        query: GET_IMPACT_CLAIMS,
      });
      const impactClaims = result?.impactClaims || {
        edges: [],
      };

      cache.writeQuery({
        query: GET_IMPACT_CLAIMS,
        data: {
          impactClaims: {
            edges: [
              {
                node: impactClaim,
                __typename: 'ImpactClaimEdge',
              },
              ...impactClaims.edges,
            ],
          },
        },
      });

      // If there's a selected partner we should also update the partner cache to include the newly created claim
      if (selectedPartner) {
        const getPartnerCache = cache.readQuery<{ partner: Partner }>({
          query: GET_PARTNER,
          variables: {
            id: selectedPartner.id,
          },
        });

        const partnerCache = getPartnerCache?.partner;

        if (!partnerCache || !data) return;

        cache.writeQuery({
          query: GET_PARTNER,
          data: {
            partner: {
              ...partnerCache,
              impactClaims: {
                edges: [
                  ...partnerCache.impactClaims.edges,
                  {
                    node: impactClaim,
                  },
                ],
              },
            },
          },
        });
      }
    },
  });

  const onSubmit = async (status: ImpactClaimStatus) => {
    // Will not happen
    if (!selectedItem) return;

    await createImpactClaim({
      variables: {
        input: {
          impactCatalogItemId: selectedItem?.id,
          productId: selectedProductId,
          partnerId: selectedPartner?.id,
          componentId: selectedComponent?.id,
          attachments: documents.map(({ id }) => id),
          status: status,
          requestIds: documentRequests.map(({ id }) => id),
        },
      },
    });
  };

  const handleCloseDialog = () => {
    if (!!documents.length) {
      setOpenUpdateAlert(true);
    } else {
      logEvent('CLOSE_CREATE_CLAIM_DIALOG', {
        current_step: currentSlide,
      });
      onClose?.();
    }
  };

  const NextButton = ({ onClick }: { onClick?: () => void }) => (
    <StyledButton
      size="large"
      data-cy="claim-dialog-next-btn"
      onClick={onClick ? onClick : () => setCurrentSlide(currentSlide + 1)}
      disabled={!selectedItem}
    >
      Next
    </StyledButton>
  );

  const impactLevelItemTitle =
    selectedProduct?.title || selectedPartner?.title || selectedComponent?.title || '';
  const disableClaimLevelButton =
    (selectedImpactLevel === 'PRODUCT' && !selectedProductId) ||
    (selectedImpactLevel === 'PARTNER' && !selectedPartner) ||
    (selectedImpactLevel === 'COMPONENT' && !selectedComponent);

  const SELECT_CLAIM_SLIDE = {
    component: SelectSlide,
    button: (
      <NextButton
        onClick={() => {
          logEvent('SELECT_IMPACT_CLAIM', {
            impact_claim: selectedItem?.title,
          });
          setCurrentSlide(currentSlide + 1);
        }}
      />
    ),
    props: {
      handleSelectClaim: setSelectedItem,
      selectedImpactClaim: selectedItem,
      catalogItems: impactCatalogData,
      title: 'Select impact claim',
    },
  };

  const SELECT_IMPACT_LEVEL_SLIDE = {
    component: ImpactLevelSlide,
    button: (
      <Tooltip
        title={
          disableClaimLevelButton ? 'You have to select a product/partner in order to continue' : ''
        }
      >
        <Box position="absolute" right={32}>
          <ThemeButton
            data-cy="claim-dialog-next-btn"
            disabled={disableClaimLevelButton}
            color="YELLOW"
            size="large"
            onClick={() => {
              logEvent('SELECT_IMPACT_LEVEL', {
                impact_level: selectedImpactLevel,
                selected_product_slug: selectedProduct?.slug,
              });
              setCurrentSlide(currentSlide + 1);
            }}
          >
            Next
          </ThemeButton>
        </Box>
      </Tooltip>
    ),
    props: {
      title: 'Select impact level',
      selectedImpactClaim: selectedItem,
      setSelectedImpactLevel,
      selectedImpactLevel,
      setSelectedProductId,
      selectedPartner,
      setSelectedPartner,
      selectedProductId,
      setSelectedComponent,
      selectedComponent,
      impactLevelItemTitle,
    },
  };

  const PROOF_SLIDE = {
    component: ProofSlide,
    button: (
      <NextButton
        onClick={() => {
          logEvent('SELECT_DOCUMENTS', {
            documents: documents,
          });
          setCurrentSlide(currentSlide + 1);
        }}
      />
    ),
    props: {
      selectedImpactClaim: selectedItem,
      title: 'Upload documentation',
      documents,
      handleDocumentsChange: setDocuments,
      deletedDocuments,
      handleDelete,
      selectedImpactLevel,
      impactLevelItem: impactLevelItemTitle,
      setDocumentRequests,
      documentRequests,
      handleDeleteRequest,
    },
  };

  const CONFIRMATION_SLIDE = {
    component: ConfirmationSlide,
    button: (
      <Tooltip
        title={
          documents.length || documentRequests.length
            ? ''
            : 'You have to upload or request proof in order to submit your claim'
        }
      >
        <Box position="absolute" right={32} display="flex">
          <Tooltip title={documents.length ? 'Save claim as draft and continue editing later' : ''}>
            <Box mr={1}>
              <ThemeButton
                color="BLUE_ICE"
                size="large"
                // disabled={!documents.length && !documentRequests.length}
                onClick={() => onSubmit(ImpactClaimStatus.CREATED)}
                data-cy="create-claim-submit-btn"
              >
                Save draft
              </ThemeButton>
            </Box>
          </Tooltip>
          <Tooltip
            title={
              documents.length
                ? 'Create claim and send it for approval'
                : documentRequests.length
                ? 'You can send this claim for approval as soon as your requested documents have been uploaded by your partner, or if you remove the document requests from this claim.'
                : ''
            }
          >
            <Box>
              <ThemeButton
                color="YELLOW"
                size="large"
                disabled={!documents.length}
                loading={isSubmitting}
                onClick={() => onSubmit(ImpactClaimStatus.REQUESTED)}
                data-cy="create-and-request-claim-submit-btn"
              >
                Create and request
              </ThemeButton>
            </Box>
          </Tooltip>
        </Box>
      </Tooltip>
    ),
    props: {
      selectedImpactClaim: selectedItem,
      title: 'Submit impact claim',
      documents,
      selectedImpactLevel,
      impactLevelItem: impactLevelItemTitle,
      documentRequests,
    },
  };

  useEffect(() => {
    if (!!selectedProductId) {
      getProduct({
        variables: {
          id: selectedProductId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId]);

  useEffect(() => {
    return () => {
      cleanFiles();
      handleCleanCreatedRecords();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // removing documents from the deletedDocuments array if they get reselected
    const updatedDeletedDocuments = deletedDocuments.filter(
      id => !documents.some((document: Document) => document.id === id)
    );
    if (updatedDeletedDocuments.length !== deletedDocuments.length) {
      setDeletedDocuments(updatedDeletedDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  // Contains all the slides for the full impact claim creation process
  const FULL_SLIDES = [
    SELECT_CLAIM_SLIDE,
    SELECT_IMPACT_LEVEL_SLIDE,
    PROOF_SLIDE,
    CONFIRMATION_SLIDE,
  ];

  // Contains all the slides except the impact level slide, as the impact level  is already predefined on partner level.
  const PRE_SELECTED_PARTNER_SLIDES = [SELECT_CLAIM_SLIDE, PROOF_SLIDE, CONFIRMATION_SLIDE];

  return {
    SLIDES: preSelectedPartner ? PRE_SELECTED_PARTNER_SLIDES : FULL_SLIDES,
    currentSlide,
    setCurrentSlide,
    error,
    loading,
    handleCloseDialog,
    onSubmit,
    setOpenUpdateAlert,
    openUpdateAlert,
  };
};

export default useCreateClaim;
