import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  styled,
  Tooltip,
} from '@mui/material';
import ComplianceEudrImage from 'assets/img/compliance/eudr.png';
import { EUDR_COMPLIANCE_GUIDE } from 'components/ComplianceGuide/components/data/compliance.data';
import { EUDR_GUIDE_PROGRESS } from 'components/ComplianceGuide/utils/eudrCompliance.utils';
import { Loader } from 'components/Forms';
import {
  ErrorState,
  FlexBox,
  PageContainer,
  PageTitle,
  SlideIndicator,
} from 'components/Structure';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import { useDialog, useFeatureFlags, usePageTitle } from 'components/hooks';
import { FeatureFlag } from 'components/hooks/useFeatureFlags';
import { CardIllustration, EmptyStateCard, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useEudrComplianceStatements from 'hooks/useEudrComplianceStatements';
import React, { FC, useEffect } from 'react';
import { EudrStatementStatus } from 'types/compliance.types';
import { AvailableSizes, BackgroundTypes, IllustrationTypes } from 'types/enums';

const StatementContainer = styled(Box)(({ theme }) => ({
  border: `${theme.custom.colors.lightBorderColor} 1px solid`,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  borderRadius: 6,
}));

const Tag = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.colors.lightBorderColor,
  height: theme.spacing(2.625),
  padding: theme.spacing(1),
  borderRadius: 4,
  marginRight: theme.spacing(1),
  overflow: 'hidden',
}));

const DueDiligenceStatementItem = styled(FlexBox)(({ theme }) => ({
  cursor: 'pointer',
  height: 20,
  padding: theme.spacing(2, 1),
  borderRadius: 6,
  maxWidth: '100%',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: theme.custom.colors.backgroundMedium,
    '& .MuiIconButton-root': {
      display: 'block',
    },
  },
  '& .MuiIconButton-root': {
    display: 'none',
  },
}));

const BorderLinearProgress = styled(LinearProgress)<{ percentage: number }>(
  ({ percentage, theme }) => ({
    borderRadius: 2,
    height: 12,
    width: 100,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),

    '& .MuiLinearProgress-bar': {
      backgroundColor: percentage === 100 ? theme.palette.success.main : theme.palette.primary.main,
    },
  })
);

const AffectivenessIndicator = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.accent[20],
  borderRadius: 8,
  padding: theme.spacing(0, 2),
}));

const EudrComplianceOverview: FC = () => {
  const { openDialog } = useDialog();
  usePageTitle('Compliance overview');
  const {
    statement,
    selectedStatementId,
    statements,
    loading,
    error,
    handleDeleteStatement,
    handleCreateStatement,
    navigateToStatementGuide,
    navigateToStatementOverview,
  } = useEudrComplianceStatements();
  const { isFeatureEnabled } = useFeatureFlags();
  const enableStartDueDiligence = isFeatureEnabled(FeatureFlag.ENABLE_START_DUE_DILIGENCE);

  /**
   * If there are statements and no selected statement, navigate to the first statement
   */
  useEffect(() => {
    if (statements?.length && !selectedStatementId) {
      navigateToStatementOverview(statements[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatementId, statements]);

  if (loading || !statements) {
    return <Loader />;
  }
  if (error) {
    return <ErrorState />;
  }

  const handleNavigateToSelectedStatement = () => {
    if (selectedStatementId) {
      navigateToStatementGuide(selectedStatementId);
    }
  };
  const handleSubmitStatement = () => {
    if (statement?.id) {
      openDialog({
        type: 'SUBMIT_EUDR_STATEMENT',
        props: { statementId: statement.id, eudrDatasets: statement.eudrDatasets },
      });
    }
  };

  const handleViewStatementDetails = () => {
    if (statement?.id) {
      openDialog({
        type: 'EUDR_STATEMENT_DETAILS',
        props: { eudrDatasets: statement.eudrDatasets, eoriNumber: 'xxx' },
      });
    }
  };

  return (
    <PageContainer>
      <PageTitle
        title="Compliance overview"
        titleExtension="EU Deforestation regulation (EUDR)"
        goBackLabel="Regulation overview"
        goBackUrl="/compliance"
      />
      <Box mb={3} />

      <Grid container spacing={2}>
        {/* Left container for main card and empty card  */}
        <Grid item xs={statements.length ? 9 : 12}>
          <Grid container spacing={2}>
            <Grid item xs={12} direction="column">
              <Box maxHeight={180}>
                <OverviewHeader imageUrl={ComplianceEudrImage}>
                  <Box display="flex" justifyContent="space-between" flex={1} height="100%">
                    <Box>
                      {/* Title  */}
                      <FlexBox mb={1}>
                        <ThemeTypography variant="TITLE_MEDIUM" data-cy="component-title">
                          EU Deforestation Regulation (EUDR)
                        </ThemeTypography>
                      </FlexBox>

                      {statements.length > 0 && statement && selectedStatementId && (
                        <Box display="flex" mb={2}>
                          <Box mr={1} width={200}>
                            <Select
                              size="small"
                              value={selectedStatementId}
                              fullWidth
                              onChange={event => navigateToStatementOverview(event.target.value)}
                            >
                              {statements.map(({ id, title, year }) => (
                                <MenuItem key={id} value={id}>{`${title} - ${year}`}</MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box display="flex" alignItems="center" mr={1}>
                            <BorderLinearProgress
                              percentage={EUDR_GUIDE_PROGRESS[statement.status].percentage}
                              variant="determinate"
                              value={EUDR_GUIDE_PROGRESS[statement.status].percentage}
                            />
                            <ThemeTypography variant="ITEM_SUBTITLE" color="GRAY_80">
                              {EUDR_GUIDE_PROGRESS[statement.status].step}/6 COMPLETED
                            </ThemeTypography>
                          </Box>
                          <AffectivenessIndicator>
                            <ThemeTypography variant="BODY_MEDIUM_BOLD">
                              Fully affected
                            </ThemeTypography>
                          </AffectivenessIndicator>
                        </Box>
                      )}

                      {/* Action buttons  */}
                      <FlexBox>
                        {statements.length > 0 && (
                          <>
                            <Box mr={2}>
                              {statement?.status === EudrStatementStatus.COMPLETED && (
                                <ThemeButton
                                  startIcon={<Icon name="upload" />}
                                  size="large"
                                  onClick={handleSubmitStatement}
                                >
                                  Submit statement
                                </ThemeButton>
                              )}

                              {statement?.status === EudrStatementStatus.SUBMITTED_EU && (
                                <ThemeButton
                                  size="large"
                                  color="BLUE_ICE"
                                  onClick={handleViewStatementDetails}
                                >
                                  View statement details
                                </ThemeButton>
                              )}

                              {statement?.status !== EudrStatementStatus.COMPLETED &&
                                statement?.status !== EudrStatementStatus.SUBMITTED_EU && (
                                  <ThemeButton
                                    startIcon={<Icon name="guide" />}
                                    size="large"
                                    onClick={handleNavigateToSelectedStatement}
                                  >
                                    Continue guide
                                  </ThemeButton>
                                )}
                            </Box>
                          </>
                        )}

                        <ThemeButton
                          startIcon={<Icon name="link" />}
                          size="large"
                          color="BLUE_ICE"
                          onClick={() => window.open(EUDR_COMPLIANCE_GUIDE.regulationUrl, '_blank')}
                        >
                          View regulation
                        </ThemeButton>

                        {statements.length > 0 &&
                          statement?.status === EudrStatementStatus.SUBMITTED_EU && (
                            <Box ml={2}>
                              <ThemeButton
                                disabled
                                startIcon={<Icon name="download" />}
                                size="large"
                                color="BLUE_ICE"
                                onClick={handleSubmitStatement}
                              >
                                Download report
                              </ThemeButton>
                            </Box>
                          )}
                      </FlexBox>
                    </Box>
                  </Box>
                </OverviewHeader>
              </Box>
            </Grid>

            <Grid item xs={12}>
              {statements.length > 0 ? (
                statement?.status !== EudrStatementStatus.SUBMITTED_EU ? (
                  <EmptyStateCard
                    title="Continue your due diligence process to become compliant with this regulation"
                    onCreateItemClick={handleNavigateToSelectedStatement}
                    buttonIcon={<Icon name="guide" />}
                    renderForeground={
                      <Box display="flex">
                        <SlideIndicator
                          slideCount={6}
                          activeIndex={
                            statement ? EUDR_GUIDE_PROGRESS[statement.status].step - 1 : 0
                          }
                          stepColor="GRAY"
                          fillPrevious
                        />
                      </Box>
                    }
                    renderIllustration={
                      <CardIllustration
                        image={IllustrationTypes.COMPLIANCE}
                        itemType="LOCATIONS"
                        width={130}
                        right={50}
                        top={50}
                      />
                    }
                    background={BackgroundTypes.MAP_LAYERS_RIGHT}
                    buttonText="Continue guide"
                    showSpark={false}
                    size={AvailableSizes.LARGE}
                    data-cy="statements-getting-started-card"
                    data-cy-button="statements-getting-started-card-button"
                  />
                ) : (
                  <></>
                )
              ) : (
                <EmptyStateCard
                  title="Start with your first due diligence process to become compliant with this regulation"
                  onCreateItemClick={handleCreateStatement}
                  buttonIcon={<Icon name="due-diligence-statement-add" />}
                  buttonText="Start due diligence"
                  size={AvailableSizes.LARGE}
                  showSpark={false}
                  renderForeground={
                    <Box display="flex">
                      <SlideIndicator
                        onClick={() => undefined}
                        slideCount={6}
                        activeIndex={0}
                        stepColor="GRAY"
                        fillPrevious
                      />
                    </Box>
                  }
                  renderIllustration={
                    <CardIllustration
                      image={IllustrationTypes.COMPLIANCE}
                      itemType="LOCATIONS"
                      width={130}
                      right={50}
                      top={50}
                    />
                  }
                  background={BackgroundTypes.MAP_LAYERS_RIGHT}
                  data-cy="statements-getting-started-card"
                  data-cy-button="statements-getting-started-card-button"
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {!!statements.length && (
          <Grid item xs={3}>
            <StatementContainer>
              <FlexBox mb={1}>
                <ThemeTypography variant="ITEM_TITLE">Due diligence</ThemeTypography>
                <Box mr={1} />
                <ThemeButton
                  disabled={!enableStartDueDiligence}
                  size="small"
                  color="BLUE_ICE"
                  startIcon={<Icon name="due-diligence-statement-add" />}
                  onClick={handleCreateStatement}
                >
                  Start new
                </ThemeButton>
              </FlexBox>
              {statements.map(({ id, title, status, year }) => {
                return (
                  <DueDiligenceStatementItem key={id} onClick={() => navigateToStatementGuide(id)}>
                    <FlexBox>
                      <Icon size="large" name="due-diligence-statement" mr={1} />
                      <Tag>
                        <ThemeTypography autoOverflow variant="BODY_SMALL_BOLD" color="GRAY_60">
                          {title} - {year}
                        </ThemeTypography>
                      </Tag>
                      <ThemeTypography
                        autoOverflow
                        variant="ITEM_SUBTITLE"
                        textTransform="uppercase"
                        color={EUDR_GUIDE_PROGRESS[status].color}
                      >
                        {EUDR_GUIDE_PROGRESS[status].title}
                      </ThemeTypography>
                    </FlexBox>
                    <Tooltip title="Delete statement">
                      <IconButton
                        size="small"
                        onClick={event => {
                          event.stopPropagation();
                          handleDeleteStatement(id);
                        }}
                      >
                        <Icon size="small" name="delete" />
                      </IconButton>
                    </Tooltip>
                  </DueDiligenceStatementItem>
                );
              })}
            </StatementContainer>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};

export default EudrComplianceOverview;
