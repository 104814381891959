import { Box, InputAdornment, styled } from '@mui/material';
import { Search } from '@styled-icons/bootstrap';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { Loader } from 'components/Forms';
import { SearchInput } from 'components/Forms/SearchToolbar';
import { CloseButton } from 'components/Partners/Partners.styles';
import PartnersTable from 'components/Partners/PartnersTable/PartnersTable';
import { PartnerHeaderColumnEnum } from 'components/Partners/PartnersTable/partnerHeaderColumns';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import usePartners from 'components/Partners/hooks/usePartners';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { EmptySearchResults, ScrollableTableContainer } from 'components/Structure';
import useActivities from 'hooks/useActivities';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { IActivity, Partner } from 'types/partner.types';

interface IExternalActivitySelectionProps {
  setActivities: (activities: IActivity[]) => void;
}

const HelperTextWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: theme.spacing(4),
}));

const ExternalActivitySelection: FC<IExternalActivitySelectionProps> = ({ setActivities }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { partners, loading } = usePartners({
    customSearch: searchTerm,
    includeSelf: true,
    useSearchQuery: false,
  });
  const [selectedPartner, setSelectedPartner] = useState<Partner | undefined>();

  const { activities, loading: activitiesLoading } = useActivities({
    queryForPartnerId: selectedPartner?.id,
    skipQuery: !selectedPartner,
  });

  // filter out partners without activities
  const filteredPartners = useMemo(
    () => partners.filter(partner => partner.activities.count),
    [partners]
  );

  useEffect(() => {
    setActivities(activities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  useEffect(() => {
    if (partners.length > 0) {
      // Automatically select first partner
      setSelectedPartner(partners[partners.length - 1]);
    } else {
      setSelectedPartner(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);

  const handleSelectedPartner = (partner: Partner | undefined, lang?: string, page?: number) => {
    setSelectedPartner(partner);
    setActivities([]);
    if (page) {
      setCurrentPage(page);
    }
  };

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(0);
    setActivities([]);
    setSelectedPartner(undefined);
  };

  return (
    <Box mt={2}>
      {loading || activitiesLoading ? (
        <Loader />
      ) : (
        <Box px={4} pb={4} zIndex={1} position="relative" minHeight={400}>
          <SearchInput
            setDebouncedState={handleSearchTermChange}
            className="search-input"
            data-cy="partner-partner-search-input"
            delay={200}
            placeholder="Search partner"
            initialValue={searchTerm}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm === '' ? (
                    <Search size={12} />
                  ) : (
                    <CloseButton onClick={() => handleSearchTermChange('')}>
                      <Close size={16} />
                    </CloseButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* Search results  */}
          {partners.length > 0 && (
            <ScrollableTableContainer pb={2} pt={1}>
              <PartnersTable
                page={currentPage}
                selectedPartner={selectedPartner}
                partners={filteredPartners}
                onSelect={handleSelectedPartner}
                hideHeader={true}
                columns={[PartnerHeaderColumnEnum.TITLE]}
                rowsPerPage={4}
              />
            </ScrollableTableContainer>
          )}

          {/* Empty search result card */}
          {!loading && !partners.length && (
            <EmptySearchResults
              message="No search results found"
              skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
            />
          )}
        </Box>
      )}

      <HelperTextWrapper>
        <InlineHelperText
          variant="INFO"
          helperText="Only partners that have available activities shows here."
        />
      </HelperTextWrapper>
    </Box>
  );
};

export default ExternalActivitySelection;
