import React, { Fragment } from 'react';
import MenuItem from '../MenuItem';

const StaticMenu = ({ items, showValidation }) => {
  return (
    <Fragment>
      {items.map(item => {
        if (!item) {
          return null;
        }

        return (
          <MenuItem
            key={item.id}
            step={item.contentType}
            title={item.title}
            valid={item.validation?.valid}
            disabled={!item.enabled}
            showWarning={showValidation}
            isContentBlock
          />
        );
      })}
    </Fragment>
  );
};

export default StaticMenu;
