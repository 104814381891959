import { Box, Dialog, IconButton, styled } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { DialogTitle } from 'designSystem';
import React, { FC } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Document } from 'types/types';
import EditContainer from './EditContainer';

export interface IEditDocumentDialogProps extends IDefaultDialogProps {
  document: Document;
  onDelete: () => void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',

  '& .MuiDialog-paper': {
    width: 600,
    maxWidth: '100%',
    overflow: 'visible',
    padding: theme.spacing(3, 4, 10),
  },
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
}));

const EditDocumentDialog: FC<IEditDocumentDialogProps> = ({ onClose, document, onDelete }) => {
  const handleDelete = () => {
    onDelete();
    onClose?.();
  };
  return (
    <StyledDialog open onClose={onClose}>
      <CloseButton onClick={onClose}>
        <Close size={23} />
      </CloseButton>
      <Box mb={2}>
        <DialogTitle icon={<Edit size={16} />}>Edit Document</DialogTitle>
      </Box>
      <EditContainer
        documentId={document?.id}
        onDelete={handleDelete}
        onClose={onClose}
        renderedOnDialog
      />
    </StyledDialog>
  );
};

export default EditDocumentDialog;
