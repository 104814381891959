import { ApplicationPlan } from 'types/companyTypes';

export const SLIM_PLAN_IDENTIFIER = '00000000-0000-0000-0000-000000000002';

export const getApplicationPlan = (customerPlanId?: string): ApplicationPlan => {
  switch (customerPlanId) {
    case SLIM_PLAN_IDENTIFIER:
      return 'SLIM_PLAN';
    default:
      return 'FULL_PLAN';
  }
};
