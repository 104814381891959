import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT, COMPANY_DESIGN_FRAGMENT } from 'graphql/fragments';

export const EDIT_COMPANY = gql`
  mutation EditCompany($input: CompanyInput!) {
    editCompany(input: $input) {
      ...companyValues
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const UPDATE_COMPANY_CONFIGS = gql`
  mutation UpdateCompanyConfigs($input: UpdateCompanyConfigsInput!) {
    updateCompanyConfigs(input: $input) {
      company {
        id
        design {
          ...companyDesignValues
        }
        gaTrackingId
        integrationSettings {
          widgetType
          showClaims
        }
      }
    }
  }
  ${COMPANY_DESIGN_FRAGMENT}
`;

export const GENERATE_API_KEY = gql`
  mutation GenerateApiKey {
    generateApiKey {
      id
      apiKey
    }
  }
`;

export const REQUEST_PLAN_UPGRADE = gql`
  mutation RequestPlanUpgrade($message: String!) {
    requestPlanUpgrade(message: $message) {
      message
    }
  }
`;
