import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CalendarEvent } from '@styled-icons/bootstrap/CalendarEvent';
import { TagFill } from '@styled-icons/bootstrap/TagFill';
import React, { FC } from 'react';
import Moment from 'react-moment';
import { DocumentCategories, DocumentCategoryMapping } from 'types/enums';

interface ISubTitleUploadItemProps {
  category?: keyof DocumentCategoryMapping;
  expiryDate?: string;
}

const CalendarIcon = styled(CalendarEvent)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const CategoryIcon = styled(TagFill)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const SubTitleUploadItem: FC<ISubTitleUploadItemProps> = ({ category, expiryDate }) => {
  const formattedCategory =
    category && category in DocumentCategories
      ? DocumentCategories[category]
      : DocumentCategories.CERTIFICATE;

  return (
    <Box display="flex" alignItems="center" component="span">
      {category && (
        <Box display="flex" alignItems="center" mr={1} component="span">
          <CategoryIcon size={8} />
          {formattedCategory}
        </Box>
      )}
      <CalendarIcon size={8} />
      {expiryDate ? <Moment format="LL">{expiryDate}</Moment> : 'Expiry date unknown'}
    </Box>
  );
};

export default SubTitleUploadItem;
