import { ThemeTypographyColor } from 'designSystem/utils/designSystemUtils';
import theme from 'styles/theme';
import { EudrAffectednessType, EudrStatementStatus } from 'types/compliance.types';

export const EUDRAffectednessTypeLabel: Record<EudrAffectednessType, string> = {
  [EudrAffectednessType.NOT_AFFECTED]: 'Not affected',
  [EudrAffectednessType.PARTIALLY_AFFECTED]: 'Partially affected',
  [EudrAffectednessType.INDIRECTLY_AFFECTED]: 'Indirectly affected',
  [EudrAffectednessType.FULLY_AFFECTED]: 'Fully affected',
};

export const EUDRAffectednessTypeColor: Record<EudrAffectednessType, string> = {
  [EudrAffectednessType.NOT_AFFECTED]: theme.custom.themeColors.success[20],
  [EudrAffectednessType.PARTIALLY_AFFECTED]: theme.custom.themeColors.accent[20],
  [EudrAffectednessType.INDIRECTLY_AFFECTED]: theme.custom.themeColors.accent[20],
  [EudrAffectednessType.FULLY_AFFECTED]: theme.custom.themeColors.accent[20],
};

export const EUDR_GUIDE_PROGRESS: Record<
  EudrStatementStatus,
  { step: number; percentage: number; title: string; color: ThemeTypographyColor }
> = {
  [EudrStatementStatus.CREATED]: {
    title: 'Created',
    color: 'YELLOW',
    step: 0,
    percentage: 0,
  },
  [EudrStatementStatus.EVALUATING_AFFECTEDNESS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 0,
    percentage: 0,
  },
  [EudrStatementStatus.COLLECTING_GEO_DATA]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 1,
    percentage: (100 / 6) * 1,
  },
  [EudrStatementStatus.SELECTING_RISK_ANALYSIS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 2,
    percentage: (100 / 6) * 2,
  },
  [EudrStatementStatus.PENDING_RISK_ANALYSIS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 2,
    percentage: (100 / 6) * 2,
  },
  [EudrStatementStatus.COMPLETED_RISK_ANALYSIS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 3,
    percentage: (100 / 6) * 3,
  },
  [EudrStatementStatus.SELECTING_ASSESSMENTS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 3,
    percentage: (100 / 6) * 3,
  },
  [EudrStatementStatus.PENDING_ASSESSMENTS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 3,
    percentage: (100 / 6) * 3,
  },
  [EudrStatementStatus.COMPLETED_ASSESSMENTS]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 4,
    percentage: (100 / 6) * 4,
  },
  [EudrStatementStatus.RISK_MITIGATION]: {
    title: 'In progress',
    color: 'YELLOW',
    step: 5,
    percentage: (100 / 6) * 5,
  },
  [EudrStatementStatus.COMPLETED]: {
    title: 'Completed',
    color: 'GREEN',
    step: 6,
    percentage: 100,
  },
  [EudrStatementStatus.SUBMITTED_EU]: {
    title: 'Submitted',
    color: 'GREEN',
    step: 6,
    percentage: 100,
  },
  [EudrStatementStatus.ARCHIVED]: {
    title: 'Archived',
    color: 'GREEN',
    step: 6,
    percentage: 100,
  },
};

export const SECTION_CONFIGURATION: Record<
  EudrStatementStatus,
  {
    selectedSection?: string;
    selectedSubSection?: string;
    disabledSubSections?: string[];
    disabledSections?: string[];
    sectionsCompleted?: string[];
  }
> = {
  [EudrStatementStatus.CREATED]: {
    selectedSection: undefined,
    selectedSubSection: undefined,
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'partner-assessment',
      'risk-mitigation',
      'due-diligence',
    ],
  },
  [EudrStatementStatus.EVALUATING_AFFECTEDNESS]: {
    selectedSection: 'affectedness',
    selectedSubSection: 'company-location',
    disabledSections: [
      'geo-data',
      'risk-analysis',
      'partner-assessment',
      'risk-mitigation',
      'due-diligence',
    ],
  },
  [EudrStatementStatus.COLLECTING_GEO_DATA]: {
    selectedSection: 'geo-data',
    selectedSubSection: 'affected-origins',
    disabledSections: [
      'affectedness',
      'risk-analysis',
      'partner-assessment',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness'],
  },
  [EudrStatementStatus.SELECTING_RISK_ANALYSIS]: {
    selectedSection: 'risk-analysis',
    selectedSubSection: 'select-datasets',
    disabledSections: [
      'affectedness',
      'geo-data',
      'partner-assessment',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data'],
  },
  [EudrStatementStatus.PENDING_RISK_ANALYSIS]: {
    selectedSection: 'risk-analysis',
    selectedSubSection: 'risk-analysis-in-progress',
    disabledSections: [
      'affectedness',
      'geo-data',
      'partner-assessment',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data'],
  },
  [EudrStatementStatus.COMPLETED_RISK_ANALYSIS]: {
    selectedSection: 'risk-analysis',
    selectedSubSection: 'risk-analysis-results',
    disabledSubSections: ['select-datasets', 'add-batch-details', 'risk-analysis-in-progress'],
    disabledSections: [
      'partner-assessment', // Will disable this for the prod for now
      'affectedness',
      'geo-data',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data', 'risk-analysis'],
  },
  [EudrStatementStatus.SELECTING_ASSESSMENTS]: {
    selectedSection: 'partner-assessment',
    selectedSubSection: 'request-partner-assessment',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data', 'risk-analysis'],
  },
  [EudrStatementStatus.PENDING_ASSESSMENTS]: {
    selectedSection: 'partner-assessment',
    selectedSubSection: 'preview-partner-assessment',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data', 'risk-analysis'],
  },
  [EudrStatementStatus.COMPLETED_ASSESSMENTS]: {
    selectedSection: 'partner-assessment',
    selectedSubSection: 'partner-assessment-results',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'risk-mitigation',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data', 'risk-analysis', 'partner-assessment'],
  },
  [EudrStatementStatus.RISK_MITIGATION]: {
    selectedSection: 'risk-mitigation',
    selectedSubSection: 'risk-mitigation-polygons',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'partner-assessment',
      'due-diligence',
    ],
    sectionsCompleted: ['affectedness', 'geo-data', 'risk-analysis', 'partner-assessment'],
  },
  [EudrStatementStatus.COMPLETED]: {
    selectedSection: 'due-diligence',
    selectedSubSection: 'overview',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'partner-assessment',
      'risk-mitigation',
    ],
    sectionsCompleted: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'partner-assessment',
      'risk-mitigation',
    ],
  },
  [EudrStatementStatus.SUBMITTED_EU]: {
    selectedSection: 'due-diligence',
    selectedSubSection: 'overview',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'risk-mitigation',
      'due-diligence',
    ],
    // sectionsCompleted: [
    //   'affectedness',
    //   'geo-data',
    //   'risk-analysis',
    //   'partner-assessment',
    //   'risk-mitigation',
    //   'due-diligence',
    // ],
  },
  [EudrStatementStatus.ARCHIVED]: {
    selectedSection: 'due-diligence',
    selectedSubSection: 'overview',
    disabledSections: [
      'affectedness',
      'geo-data',
      'risk-analysis',
      'partner-assessment',
      'risk-mitigation',
      'due-diligence',
    ],
  },
};
