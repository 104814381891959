import { useConfig } from 'components/hooks';
import React, { FC, PropsWithChildren, createContext, useContext, useState } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import DenyRequestDialog from '../RequestReplyDialogs/DenyRequestDialog';
import UploadDocumentToRequestDialog from '../RequestReplyDialogs/UploadDocumentToRequestDialog';
import { useQuery } from '@apollo/client';
import { GET_PARTNER_REQUEST, GetPartnerRequestData } from 'graphql/queries/partners';
import { Loader } from 'components/Forms';
import ManageRequestDialog from 'components/Requests/ManageRequestDialog';

interface RequestReplyDialogContextValues {
  uploadDialogOpened: boolean;
  openUploadDialog: (requestId: string) => void;
  openDismissDialog: (requestId: string) => void;
}

const RequestReplyDialogContext = createContext<RequestReplyDialogContextValues>({
  uploadDialogOpened: false,
  openUploadDialog: () => {
    console.error('unimplemented context');
  },
  openDismissDialog: () => {
    console.error('unimplemented context');
  },
});

const useRequestReplyDialogContext = () => useContext(RequestReplyDialogContext);

const RequestReplyDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const config = useConfig();
  const [uploadOpened, setUploadOpened] = useQueryParam<boolean | undefined | null>(
    config.appQueryParams.requestReplyOpen,
    BooleanParam
  );
  const [chainRequestReplyOpen, setChainRequestReplyOpen] = useQueryParam<
    boolean | undefined | null
  >(config.appQueryParams.chainRequestReplyOpen, BooleanParam);
  const [requestId, setRequestId] = useQueryParam<string | undefined | null>(
    config.appQueryParams.requestId,
    StringParam
  );
  const [denyDialogOpened, setDenyDialogOpened] = useState<boolean>(false);

  const { data, loading } = useQuery<GetPartnerRequestData>(GET_PARTNER_REQUEST, {
    variables: {
      id: requestId,
    },
    skip: !requestId || !chainRequestReplyOpen,
  });

  const openUploadDialog = (requestId: string) => {
    setRequestId(requestId);
    setUploadOpened(true);
  };
  const openDismissDialog = (requestId: string) => {
    setRequestId(requestId);
    setDenyDialogOpened(true);
  };
  const openChainRequestReplyDialog = (requestId: string) => {
    setRequestId(requestId);
    setChainRequestReplyOpen(true);
  };
  const handleClose = () => {
    setUploadOpened(undefined);
    setRequestId(undefined);
    setDenyDialogOpened(false);
    setChainRequestReplyOpen(undefined);
  };

  return (
    <RequestReplyDialogContext.Provider
      value={{
        uploadDialogOpened: !!uploadOpened,
        openUploadDialog,
        openDismissDialog,
      }}
    >
      {loading && !data?.partnerRequest && <Loader />}
      {uploadOpened && requestId && (
        <UploadDocumentToRequestDialog
          open={!!uploadOpened}
          requestId={requestId}
          onClose={handleClose}
        />
      )}
      {denyDialogOpened && requestId && (
        <DenyRequestDialog open={!!denyDialogOpened} requestId={requestId} onClose={handleClose} />
      )}
      {chainRequestReplyOpen && requestId && data?.partnerRequest && (
        <ManageRequestDialog
          request={data.partnerRequest}
          open={!!openChainRequestReplyDialog}
          onClose={handleClose}
        />
      )}

      {children}
    </RequestReplyDialogContext.Provider>
  );
};

export { RequestReplyDialogProvider, useRequestReplyDialogContext };

export default RequestReplyDialogContext;
