import { useQuery } from '@apollo/client';
import { useUploadDialogActions } from 'components/DocumentLibrary/hooks';
import { GET_DOCUMENTS } from 'graphql/queries';
import { useEffect, useMemo, useState } from 'react';
import { Document, GraphQlConnection } from 'types/types';
import { useQueryParam } from 'use-query-params';
import { filterItems } from 'utils';
import useConfig from './useConfig';
import useDialog from './useDialog';

const useInternalDocuments = () => {
  const { data, loading, error, fetchMore } = useQuery<{ documents: GraphQlConnection<Document> }>(
    GET_DOCUMENTS,
    {
      variables: {
        filters: { fileExtensions: ['pdf'] },
        first: 15,
      },
    }
  );

  const { openDialog } = useDialog();
  const { appQueryParams } = useConfig();
  const { handleDeleteDocuments } = useUploadDialogActions();

  const [selectedForDeletion, setSelectedForDeletion] = useState<string[]>([]);
  const [usageSelectedDocuments, setUsageSelectedDocuments] = useState(0);
  const [searchTerm = '', setSearchTerm] = useQueryParam<string>(appQueryParams.query);

  const documents: Document[] = useMemo(() => {
    const nodeDocuments = data?.documents?.edges || [];
    return filterItems(nodeDocuments, searchTerm);
  }, [data, searchTerm]);

  const hasNextPage: boolean = !!data?.documents?.pageInfo?.hasNextPage;

  const endCursor: string | undefined = data?.documents?.pageInfo?.endCursor;

  const handlePageEndReached = () => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  useEffect(() => {
    if (!documents?.length && hasNextPage) {
      handlePageEndReached();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, hasNextPage]);

  useEffect(() => {
    if (selectedForDeletion.length) {
      const usedDocuments = documents.filter(({ id }) => selectedForDeletion.includes(id));
      setUsageSelectedDocuments(
        usedDocuments.reduce((current, { usageCount }) => current + (usageCount || 0), 0)
      );
    }
  }, [documents, selectedForDeletion]);

  const handlePrepareDeletion = () => {
    openDialog({
      type: 'ALERT',
      props: {
        displayCloseButton: true,
        title: `Delete ${selectedForDeletion.length === 1 ? 'document' : 'documents'}`,
        submitText: 'Delete',
        itemTitle: selectedForDeletion.length === 1 ? '' : `${selectedForDeletion.length} selected`,
        text:
          selectedForDeletion.length === 1
            ? 'Are you sure you want to delete this document? You will not be able to restore it.'
            : 'Are you sure you want to delete these documents? You will not be able to restore them.',
        onSubmit: handleDelete,
      },
    });
  };

  const handleClickDelete: (document: Document) => void = ({ id, title }) => {
    openDialog({
      type: 'ALERT',
      props: {
        displayCloseButton: true,
        title: 'Delete document',
        submitText: 'Delete',
        itemTitle: title,
        text: 'Are you sure you want to delete this document? You will not be able to restore it.',
        onCancel: () => undefined,
        onSubmit: () => handleDeleteSingleDocument(id),
      },
    });
  };

  const handleDeleteSingleDocument = async (documentId: string) => {
    await handleDeleteDocuments({
      variables: {
        ids: [documentId],
      },
    });
  };

  const handleDelete = async () => {
    await handleDeleteDocuments({
      variables: {
        ids: selectedForDeletion,
      },
    });
    if (hasNextPage && endCursor) {
      await fetchMore({
        variables: {
          first: selectedForDeletion.length,
          after: endCursor,
        },
      });
    }
    setSelectedForDeletion([]);
  };

  return {
    error,
    loading,
    documents,
    selectedForDeletion,
    usageSelectedDocuments,
    searchTerm,
    endCursor,
    hasNextPage,
    setSelectedForDeletion,
    setSearchTerm,
    handleClickDelete,
    handleDelete,
    handlePrepareDeletion,
    handlePageEndReached,
  };
};

export default useInternalDocuments;
