import { Box, Grid, MenuItem, Select, styled } from '@mui/material';
import { BarChart, BarSeriesType, ChartsXAxis } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { Loader } from 'components/Forms';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { ThemeTypography } from 'designSystem';
import InfoDetailNumber from 'designSystem/DataDisplay/InfoDetail/InfoDetailNumber';
import React, { FC, createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useMeasure from 'react-use/lib/useMeasure';
import {
  EudrStatementStatus,
  QuestionnaireResponse,
  QuestionnaireResponseSummary,
  RiskLevel,
} from 'types/compliance.types';
import { Partner, PartnerRequest } from 'types/partner.types';
import { GraphQlConnection } from 'types/types';
import { removeGraphConnections } from 'utils/graphConnections';
import AssessmentChart from '../../AssessmentChart';
import PartnerRowItem from '../../PartnerRowItem';
import Axis from '../BarChartComponents/Axis';
import CustomBarElement from '../BarChartComponents/CustomBarElement';
import Tooltip from '../BarChartComponents/Tooltip';

const RISK_COLOR: Record<'LOW' | 'MEDIUM' | 'HIGH', string> = {
  LOW: '#2DB875',
  MEDIUM: 'orange',
  HIGH: '#FF7F7F',
};

const Y_AXIS_LABEL_WIDTH = 200;
const Y_AXIS_LABEL_HEIGHT = 30;
const SCROLL_OFFSET = 250;

const Container = styled(Box)(({ theme }) => ({
  borderRadius: 6,
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  background: theme.custom.themeColors.white,
  padding: theme.spacing(2),
  maxHeight: 'calc(100vh - 64px)',
  overflow: 'auto',
}));

const StyledSelect = styled(Select<string>)(() => ({
  '& .MuiSelect-select': {
    paddingRight: '30px !important',
  },
}));

const PartnerAssessmentResults: FC = () => {
  const questionsContainer = createRef<HTMLDivElement>();
  const chartContainer = useRef<HTMLDivElement>();
  const { formData, selectedStatementId, updateStatement } = useEudrComplianceGuide();
  const [chartContainerRefCallback, size] = useMeasure<HTMLDivElement>();

  const [selectedPartnerId, setSelectedPartnerId] = useState<string>('all');

  //  useQuery<{
  // questionnaireResponses: GraphQlConnection<QuestionnaireResponse> & {summary: QuestionnaireResponseSummary};
  // }>(GET_QUESTIONNAIRE_RESPONSES, {
  //   variables: {
  //     statementId: selectedStatementId,
  //     filters: { partnerId: selectedPartnerId === 'all' ? null : selectedPartnerId },
  //     orderBy: { orderByField: 'riskLevel' },
  //     first: 30,
  //   },
  //   skip: true,
  // });

  // const aggregatedQuestionnaires = statement.aggregatedQuestionnaires;

  const aggregatedQuestionnaires = {
    aggregatedResponseSummary: { answered: 6, notAnswered: 4, total: 10 },
    aggregatedRiskSummary: {
      lowRiskPercentage: 60,
      mediumRiskPercentage: 20,
      highRiskPercentage: 20,
    },
    requests: [
      ...formData.partnerSelfAssessmentIds?.slice(0, 2).map((partner: Partner) => ({
        id: `${partner.id}-12141`,
        partnerId: partner.id,
        partner,
        requestedTimestamp: '2024-03-01',
        latestLog: { id: 'log1', message: 'Latest Log', timestamp: '2024-03-01' },
        questionnaires: { overallRisk: 'LOW' },
        requestStatus: 'PROVIDED_COMPLETED',
      })),
      ...formData.partnerSelfAssessmentIds
        ?.slice(2, formData.partnerSelfAssessmentIds.length - 1)
        .map((partner: Partner) => ({
          id: `${partner.id}-12141`,
          partnerId: partner.id,
          partner,
          requestedTimestamp: '2024-03-01',
          latestLog: { id: 'log1', message: 'Latest Log', timestamp: '2024-03-01' },
          requestStatus: 'REQUESTED',
        })),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as any as PartnerRequest[],
  };

  const aggregatedResponseSummary = aggregatedQuestionnaires?.aggregatedResponseSummary;
  const aggregatedRiskSummary = aggregatedQuestionnaires?.aggregatedRiskSummary;
  const sentRequests = aggregatedQuestionnaires?.requests.filter(
    ({ requestStatus }) => requestStatus === 'REQUESTED'
  );
  const providedCompletedRequests = aggregatedQuestionnaires?.requests.filter(
    ({ requestStatus }) => requestStatus === 'PROVIDED_COMPLETED'
  );

  const { loading, fetchMore } = {
    loading: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchMore: (args: unknown) => undefined,
  };
  const data: {
    questionnaireResponses: GraphQlConnection<QuestionnaireResponse> & {
      summary: QuestionnaireResponseSummary;
    };
  } = useMemo(
    () => ({
      questionnaireResponses: {
        summary: {
          averageResponseTime: 23,
          riskMode: RiskLevel.LOW,
        },
        edges: [
          {
            node: {
              text: 'Are you and your business partners (producers and service providers) aware of the regional and national laws and regulations applicable to your company?',
              options: [
                {
                  id: '1',
                  answerPercentage: 20,
                  weight: 0.2,
                  text: 'Yes, we are fully aware',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 40,
                  weight: 0.5,
                  text: 'We are partially aware. Some laws and regulations are known but not all',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 20,
                  weight: 0.5,
                  text: 'No, we are not aware of these laws and regulations',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 20,
                  weight: 1,
                  text: 'Unknown',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
          {
            node: {
              text: 'Can it be guaranteed that the relevant products to be supplied are not mixed with products of unknown origin?',
              options: [
                {
                  id: '1',
                  answerPercentage: 30,
                  weight: 0.2,
                  text: 'Yes',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 30,
                  weight: 0.5,
                  text: 'No',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 20,
                  weight: 0.5,
                  text: 'Maybe',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 20,
                  weight: 1,
                  text: 'Unknown',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
          {
            node: {
              text: '. If you supply wood: Was the wood you supply harvested in forests where forest degradation occurred after 31 December 2020?',
              options: [
                {
                  id: '1',
                  answerPercentage: 30,
                  weight: 0.2,
                  text: 'Yes',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 30,
                  weight: 0.5,
                  text: 'No',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 20,
                  weight: 0.5,
                  text: 'Not applicable',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 20,
                  weight: 1,
                  text: 'Unnknown',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
          {
            node: {
              text: 'Are mass balance chains of custody used for tracing geolocations?',
              options: [
                {
                  id: '1',
                  answerPercentage: 30,
                  weight: 0.2,
                  text: 'Yes',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 38,
                  weight: 0.5,
                  text: 'No',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 20,
                  weight: 0.5,
                  text: 'Unknown',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 12,
                  weight: 1,
                  text: 'Other',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
          {
            node: {
              text: 'Do you have a system in place to check and ensure the compliance of your business partners with all applicable laws and regulations?',
              options: [
                {
                  id: '1',
                  answerPercentage: 30,
                  weight: 0.2,
                  text: 'Yes, a fully implemented and operational system',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 30,
                  weight: 0.5,
                  text: 'Partially',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 20,
                  weight: 0.5,
                  text: 'No, we do not have a system in place',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 20,
                  weight: 1,
                  text: 'Unkown',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
          {
            node: {
              text: '25. Are practices of forced eviction to acquire land for raw material production, processing, and handling inhibited?',
              options: [
                {
                  id: '1',
                  answerPercentage: 35,
                  weight: 0.2,
                  text: 'Yes',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 35,
                  weight: 0.5,
                  text: 'Partially',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 20,
                  weight: 0.5,
                  text: 'No',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 10,
                  weight: 1,
                  text: 'Unknown',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
          {
            node: {
              text: 'Do you monitor and have documentation in place proving that the use of pesticides for raw material production is limited to officially registered products in the country of production?',
              options: [
                {
                  id: '1',
                  answerPercentage: 35,
                  weight: 0.2,
                  text: 'Yes, we have comprehensive monitoring and documentation (please specify below)',
                  riskLevel: RiskLevel.LOW,
                },
                {
                  id: '2',
                  answerPercentage: 35,
                  weight: 0.5,
                  text: 'Partially, the monitoring and documentation is limited',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '3',
                  answerPercentage: 25,
                  weight: 0.5,
                  text: 'No, we do not have any monitoring or documentation in place',
                  riskLevel: RiskLevel.MEDIUM,
                },
                {
                  id: '4',
                  answerPercentage: 5,
                  weight: 1,
                  text: 'Unkown',
                  riskLevel: RiskLevel.HIGH,
                },
              ],
            },
          },
        ],
        count: 60,
        pageInfo: { hasNextPage: true, endCursor: '', hasPreviousPage: false, startCursor: '' },
      },
    }),
    []
  );

  const questions = useMemo(
    () =>
      data?.questionnaireResponses ? removeGraphConnections(data?.questionnaireResponses) : [],
    [data]
  );
  const questionnaireSummary = data?.questionnaireResponses?.summary;
  const hasNextPage: boolean = !!data?.questionnaireResponses?.pageInfo?.hasNextPage;
  const endCursor: string | undefined = data?.questionnaireResponses?.pageInfo?.endCursor;

  const sortedOptions = [
    ...questions.map(({ text }) => ({
      text,
    })),
    ...(hasNextPage
      ? [
          { text: 'Loading ...' },
          { text: 'Loading ... ' },
          { text: 'Loading ...  ' },
          { text: 'Loading ...   ' },
          { text: 'Loading ...    ' },
        ]
      : []),
  ];

  const series: MakeOptional<BarSeriesType, 'type'>[] = [
    {
      data: questions.map(({ options }) =>
        options
          .filter(({ weight }) => weight <= 0.33)
          .reduce((prev, { answerPercentage }) => prev + answerPercentage, 0)
      ),
      label: 'Low risk',
      stack: 'total',
      color: RISK_COLOR['LOW'],
    },
    {
      data: questions.map(({ options }) =>
        options
          .filter(({ weight }) => weight > 0.33 && weight <= 0.66)
          .reduce((prev, { answerPercentage }) => prev + answerPercentage, 0)
      ),
      label: 'Medium risk',
      stack: 'total',
      color: RISK_COLOR['MEDIUM'],
    },
    {
      data: questions.map(({ options }) =>
        options
          .filter(({ weight }) => weight > 0.66)
          .reduce((prev, { answerPercentage }) => prev + answerPercentage, 0)
      ),
      label: 'High risk',
      stack: 'total',
      color: RISK_COLOR['HIGH'],
    },
    ...(hasNextPage
      ? [
          {
            data: [
              ...Array.from(Array(questions.length).keys()).map(() => null),
              100,
              100,
              100,
              100,
              100,
            ],
            stack: 'total',
            id: 'loading',
          },
        ]
      : []),
  ];

  const handleNextStepClick = () => {
    if (selectedStatementId) {
      updateStatement(selectedStatementId, { status: EudrStatementStatus.RISK_MITIGATION });
    }
  };

  const handlePartnerSelect = (partnerId: string) => {
    questionsContainer.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    setSelectedPartnerId(partnerId);
  };

  const onScroll = useCallback(async () => {
    if (!chartContainer.current) {
      return;
    }
    if (
      chartContainer.current.scrollTop + size.height + SCROLL_OFFSET >
        chartContainer.current.scrollHeight &&
      hasNextPage &&
      !loading &&
      endCursor
    ) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer, hasNextPage, size, loading, endCursor]);

  useEffect(() => {
    const ref = chartContainer.current;
    if (ref) {
      chartContainerRefCallback(ref);
      ref.addEventListener('scroll', onScroll, true);
    }
    return () => ref?.removeEventListener('scroll', onScroll, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer]);

  return (
    <>
      <EudrComplianceGuideSubSection
        disabledPreviousNavigation
        allowNextStepNavigation
        onNextStepClick={handleNextStepClick}
      />
      <Box mb={2} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3} direction="column">
              <Box mb={2}>
                <PageSubTitle title="Responses" mb={1} />
                <Container display="flex" gap={4} flexWrap="wrap">
                  <InfoDetailNumber
                    number={aggregatedResponseSummary?.answered}
                    description="Answered"
                  />
                  <InfoDetailNumber
                    number={aggregatedResponseSummary?.notAnswered}
                    description="Not answered"
                  />
                  <InfoDetailNumber number={aggregatedResponseSummary?.total} description="Total" />
                </Container>
              </Box>

              {aggregatedRiskSummary && (
                <AssessmentChart
                  lowRisks={aggregatedRiskSummary.lowRiskPercentage}
                  mediumRisks={aggregatedRiskSummary.mediumRiskPercentage}
                  highRisks={aggregatedRiskSummary.highRiskPercentage}
                  notAnswered={aggregatedResponseSummary?.notAnswered || 0}
                />
              )}
            </Grid>
            <Grid item xs={9} direction="column">
              {!!providedCompletedRequests?.length && (
                <>
                  <PageSubTitle title="Completed self-assessments" mb={1} />
                  <FlexBox flexDirection="column" gap={1} mb={2}>
                    {providedCompletedRequests.map(({ partner, latestLog }) => (
                      <PartnerRowItem
                        key={partner.id}
                        partner={partner}
                        completedDate={latestLog.timestamp}
                        onSelect={handlePartnerSelect}
                      />
                    ))}
                  </FlexBox>
                </>
              )}

              {!!sentRequests?.length && (
                <>
                  <PageSubTitle title="Pending self-assessments" mb={1} />
                  <FlexBox flexDirection="column" gap={1} mb={2}>
                    {sentRequests.map(({ partner, requestedTimestamp }) => (
                      <PartnerRowItem
                        key={partner.id}
                        partner={partner}
                        requestedDate={requestedTimestamp}
                      />
                    ))}
                  </FlexBox>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} ref={questionsContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FlexBox gap={2}>
                <PageSubTitle title="Responses" />
                <Box width={200}>
                  <StyledSelect
                    size="small"
                    value={selectedPartnerId}
                    fullWidth
                    disabled={!providedCompletedRequests?.length}
                    onChange={event => setSelectedPartnerId(event.target.value)}
                  >
                    <MenuItem value="all">All partners</MenuItem>
                    {providedCompletedRequests?.map(({ partner }) => (
                      <MenuItem key={partner.id} value={partner.id}>
                        {partner.title}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Box>
                <ThemeTypography variant="BODY_SMALL">
                  {data?.questionnaireResponses.count || 0} Questions answered by{' '}
                  {providedCompletedRequests?.length || 0} partners in total
                </ThemeTypography>
              </FlexBox>
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Loader />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Container mb={2}>
                      <InfoDetailNumber
                        number={questionnaireSummary?.averageResponseTime}
                        unit="hours"
                        description="Average response time"
                      />
                    </Container>

                    <Container>
                      <InfoDetailNumber
                        number={questionnaireSummary?.riskMode}
                        description="Most frequent risk"
                      />
                    </Container>
                  </Grid>

                  <Grid item xs={10}>
                    <Container ref={chartContainer} minHeight={400}>
                      <BarChart
                        dataset={sortedOptions}
                        margin={{ left: Y_AXIS_LABEL_WIDTH, top: 75 }}
                        slots={{
                          axisTickLabel: Axis,
                          axisContent: Tooltip,
                          bar: CustomBarElement,
                        }}
                        // Workaround to pass the questions to the axisContent
                        slotProps={{ axisContent: { axisValue: questions } }}
                        yAxis={[
                          {
                            scaleType: 'band',
                            dataKey: 'text',
                            disableTicks: true,
                            hideTooltip: true,
                            position: 'left',
                          },
                        ]}
                        xAxis={[
                          {
                            min: 0,
                            max: 100,
                            label: 'Risk',
                            hideTooltip: true,
                            position: 'bottom',
                            tickPlacement: 'middle',
                          },
                        ]}
                        series={series}
                        layout="horizontal"
                        grid={{ vertical: true }}
                        width={size.width}
                        height={Y_AXIS_LABEL_HEIGHT * sortedOptions.length}
                      >
                        <ChartsXAxis position="top" label="" />
                      </BarChart>
                    </Container>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PartnerAssessmentResults;
