import { gql } from '@apollo/client';
import {
  COLLECTION_SITE_FRAGMENT,
  DISTRIBUTION_SITE_FRAGMENT,
  FACTORY_SITE_FRAGMENT,
  FARM_SITE_FRAGMENT,
  FOREST_SITE_FRAGMENT,
  GENERIC_SITE_FRAGMENT,
  GROCERY_STORE_SITE_FRAGMENT,
  HANDLING_SITE_FRAGMENT,
  MANUFACTURING_SITE_FRAGMENT,
  MINE_SITE_FRAGMENT,
  ORIGIN_SITE_FRAGMENT,
  PLANT_SITE_FRAGMENT,
  PROCESSING_SITE_FRAGMENT,
  SITE_CLUSTER_FRAGMENT,
  WAREHOUSE_SITE_FRAGMENT,
} from 'graphql/fragments/sites';

export const GET_SITES = gql`
  query GetSites(
    $first: Int
    $after: String
    $filters: SitesFilters
    $siteViewOption: SiteViewOption
  ) {
    sites(first: $first, after: $after, filters: $filters, siteViewOption: $siteViewOption) {
      edges {
        node {
          ... on GenericSite {
            ...genericSiteValues
          }
          ... on Farm {
            ...farmValues
          }
          ... on Mine {
            ...mineValues
          }
          ... on Forest {
            ...forestValues
          }
          ... on CollectionSite {
            ...collectionSiteValues
          }
          ... on OriginSite {
            ...originSiteValues
          }
          ... on Plant {
            ...plantValues
          }
          ... on ProcessingSite {
            ...processingSiteValues
          }
          ... on Factory {
            ...factoryValues
          }
          ... on ManufacturingSite {
            ...manufacturingSiteValues
          }
          ... on Warehouse {
            ...warehouseValues
          }
          ... on HandlingSite {
            ...handlingSiteValues
          }
          ... on GroceryStore {
            ...groceryStoreValues
          }
          ... on DistributionSite {
            ...distributionSiteValues
          }
          ... on SiteCluster {
            ...siteClusterValues
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;

export const GET_SITE = gql`
  query GetSite($id: UUID!, $lang: Language) {
    site(id: $id, lang: $lang) {
      ... on GenericSite {
        ...genericSiteValues
      }
      ... on Farm {
        ...farmValues
      }
      ... on Mine {
        ...mineValues
      }
      ... on Forest {
        ...forestValues
      }
      ... on CollectionSite {
        ...collectionSiteValues
      }
      ... on OriginSite {
        ...originSiteValues
      }
      ... on Plant {
        ...plantValues
      }
      ... on ProcessingSite {
        ...processingSiteValues
      }
      ... on Factory {
        ...factoryValues
      }
      ... on ManufacturingSite {
        ...manufacturingSiteValues
      }
      ... on Warehouse {
        ...warehouseValues
      }
      ... on HandlingSite {
        ...handlingSiteValues
      }
      ... on GroceryStore {
        ...groceryStoreValues
      }
      ... on DistributionSite {
        ...distributionSiteValues
      }
      ... on SiteCluster {
        ...siteClusterValues
      }
    }
  }
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;
