import React, { FC, useEffect } from 'react';
import Tabs, { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import { SiteOwnershipType } from 'types/sites.types';
import { Box } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { Partner } from 'types/partner.types';
import ExternalSitesPartnerSelection from './ExternalSitesPartnerSelection';

interface ISelectSiteTypeProps {
  currentOwnershipType: SiteOwnershipType;
  selectedPartner: Partner | undefined;
  onCurrentOwnershipTypeChange: (ownershipType: SiteOwnershipType) => void;
  onSelectedPartnerChange: (partner: Partner | undefined) => void;
}

const SITE_TABS: ITab<SiteOwnershipType>[] = [
  {
    key: SiteOwnershipType.INTERNAL,
    label: 'Internal',
  },
  {
    key: SiteOwnershipType.EXTERNAL,
    label: 'External',
  },
  {
    key: SiteOwnershipType.UNASSIGNED,
    label: 'Unassigned',
  },
];

const SelectSiteType: FC<ISelectSiteTypeProps> = ({
  currentOwnershipType,
  selectedPartner,
  onCurrentOwnershipTypeChange,
  onSelectedPartnerChange,
}) => {
  useEffect(() => {
    if (currentOwnershipType !== SiteOwnershipType.EXTERNAL) {
      onSelectedPartnerChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOwnershipType]);

  const handleChangeTab = (tab: SiteOwnershipType) => {
    onCurrentOwnershipTypeChange(tab);
  };

  return (
    <Box width="100%">
      <Tabs tabs={SITE_TABS} selectedTab={currentOwnershipType} onChange={handleChangeTab} />
      {currentOwnershipType === SiteOwnershipType.INTERNAL && (
        <Box display="flex" alignItems="center" mt={3}>
          <Icon name="info" color="gray-60" />
          <Box mr={1.5} />
          <ThemeTypography variant="BODY_MEDIUM" color="GRAY_60">
            Select one of your own sites to the right, or switch tabs to select sites not owned by
            you.
          </ThemeTypography>
        </Box>
      )}
      {currentOwnershipType === SiteOwnershipType.EXTERNAL && (
        <ExternalSitesPartnerSelection
          partner={selectedPartner}
          onPartnerChange={onSelectedPartnerChange}
        />
      )}
      {currentOwnershipType === SiteOwnershipType.UNASSIGNED && (
        <Box display="flex" alignItems="center" mt={3}>
          <Icon name="info" color="grey-60" />
          <Box mr={1.5} />
          <ThemeTypography variant="BODY_MEDIUM" color="GRAY_60">
            Select to an activity that have not yet been assigned to a company.
          </ThemeTypography>
        </Box>
      )}
    </Box>
  );
};

export default SelectSiteType;
