import { Box, Tooltip, Typography, styled } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { ReactComponent as DocumentImage } from 'assets/img/icons/document.svg';
import { PageSubTitle } from 'components/Structure';
import { ItemCardSmall, ThemeButton, ThemeTypography } from 'designSystem';
import truncate from 'lodash/truncate';
import React, { FC } from 'react';
import { DocumentStatus } from 'types/enums';
import { Document } from 'types/types';

const Status = styled(Typography)<{ status: DocumentStatus }>(({ status, theme }) => ({
  fontSize: 13,
  marginRight: 8,
  color: status === 'VALID' ? theme.palette.success.main : theme.palette.error.main,
}));

const DocumentIcon = styled(DocumentImage)(() => ({
  width: 26,
}));

const DocumentButton = styled(ThemeButton)(() => ({
  background: 'transparent',
}));

interface IPartnerDocumentListProps {
  documents: Document[];
  onCreateClick: () => void;
  onAllDocumentsClick: () => void;
}

const PartnerDocumentList: FC<IPartnerDocumentListProps> = ({
  documents,
  onCreateClick,
  onAllDocumentsClick,
}) => {
  return (
    <Box flex={1} data-cy="document-list">
      <PageSubTitle title="Documents" mb={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <DocumentButton
            onClick={onAllDocumentsClick}
            size="small"
            color="BLUE_ICE"
            data-cy="see-all-documents-btn"
          >
            See all documents
          </DocumentButton>
          <ThemeButton
            onClick={onCreateClick}
            startIcon={<Plus size={20} />}
            size="small"
            color="WHITE"
            data-cy="new-request-btn"
          >
            New request
          </ThemeButton>
        </Box>
      </PageSubTitle>
      <Box data-cy="home-document-container">
        {documents.map(document => (
          <ItemCardSmall key={document.id} onClickOpen={() => window.open(document.url, '_blank')}>
            <Box display="flex" alignItems="center" gap={2.5}>
              <Box pl={1}>
                <DocumentIcon />
              </Box>
              <Box>
                <Tooltip title={document.title || ''}>
                  <ThemeTypography variant="ITEM_TITLE">
                    {truncate(document.title, {
                      length: 45,
                      separator: '',
                    })}
                  </ThemeTypography>
                </Tooltip>
                <Box display="flex" alignItems="center" gap={0.8}>
                  <Status variant="body2" status={document.status}>
                    {DocumentStatus[document.status]}
                  </Status>
                </Box>
              </Box>
            </Box>
          </ItemCardSmall>
        ))}
      </Box>
    </Box>
  );
};

export default PartnerDocumentList;
