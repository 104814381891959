import { ImageVariant } from './commonTypes';
import { IRawMaterial } from './component.types';
import { Partner } from './partner.types';
import { Coordinates, CultivatedAreas } from './types';

export enum SiteOwnershipType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  UNASSIGNED = 'UNASSIGNED',
}

export enum SiteType {
  FARM = 'FARM',
  MINE = 'MINE',
  FOREST = 'FOREST',
  COLLECTION_SITE = 'COLLECTION_SITE',
  ORIGIN_SITE = 'ORIGIN_SITE',
  PLANT = 'PLANT',
  PROCESSING_SITE = 'PROCESSING_SITE',
  FACTORY = 'FACTORY',
  MANUFACTURING_SITE = 'MANUFACTURING_SITE',
  WAREHOUSE = 'WAREHOUSE',
  HANDLING_SITE = 'HANDLING_SITE',
  GROCERY_STORE = 'GROCERY_STORE',
  DISTRIBUTION_SITE = 'DISTRIBUTION_SITE',
  GENERIC_SITE = 'GENERIC_SITE',
  CLUSTER = 'CLUSTER',
}

export enum LandOwnershipType {
  PRIVATE_OWNERSHIP = 'PRIVATE_OWNERSHIP',
  PUBLIC_OWNERSHIP = 'PUBLIC_OWNERSHIP',
  COMMUNAL_OWNERSHIP = 'COMMUNAL_OWNERSHIP',
  STATE_OWNERSHIP = 'STATE_OWNERSHIP',
  TENANCY = 'TENANCY',
  CONCESSION = 'CONCESSION',
  EASEMENT = 'EASEMENT',
  COLLECTIVE_FARMS = 'COLLECTIVE_FARMS',
  TRUSTS = 'TRUSTS',
  COMMON_PROPERTY_REGIMES = 'COMMON_PROPERTY_REGIMES',
  ADVERSE_POSSESSION = 'ADVERSE_POSSESSION',
  LAND_COOPERATIVES = 'LAND_COOPERATIVES',
  USUFRUCT_RIGHTS = 'USUFRUCT_RIGHTS',
  OTHER = 'OTHER',
}

export enum SiteViewOption {
  LIST = 'LIST',
  CLUSTER = 'CLUSTER',
}

export enum CoordinateFeatureType {
  UNKOWN = 'UNKOWN',
  COUNTRY = 'COUNTRY',
  REGION = 'REGION',
  POSTCODE = 'POSTCODE',
  DISTRICT = 'DISTRICT',
  PLACE = 'PLACE',
  LOCALITY = 'LOCALITY',
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  STREET = 'STREET',
  ADDRESS = 'ADDRESS',
}

export interface ICoordinateFeature {
  featureType: CoordinateFeatureType;
  locationName: string;
  center: Coordinates;
}

export interface IBaseSite {
  id: string;
  title: string;
  partner?: Partner;
  locationName: string;
  locationCoordinates: Coordinates;
  image?: ImageVariant | null;
  createdAt?: string;
  externalId?: string;
  description?: string;
  coordinateFeatures: ICoordinateFeature[];
  inUse: boolean;
}

export type IGenericSite = IBaseSite & { siteType: SiteType.GENERIC_SITE };

export type IMineSite = IBaseSite & { siteType: SiteType.MINE };

export type IForestSite = IBaseSite & { siteType: SiteType.FOREST };

export type ICollectionSite = IBaseSite & { siteType: SiteType.COLLECTION_SITE };

export type IOriginSite = IBaseSite & { siteType: SiteType.ORIGIN_SITE };

export type IPlantSite = IBaseSite & { siteType: SiteType.PLANT };

export type IProcessingSite = IBaseSite & { siteType: SiteType.PROCESSING_SITE };

export type IFactorySite = IBaseSite & { siteType: SiteType.FACTORY };

export type IManufacturingSite = IBaseSite & { siteType: SiteType.MANUFACTURING_SITE };

export type IWarehouseSite = IBaseSite & { siteType: SiteType.WAREHOUSE };

export type IHandlingSite = IBaseSite & { siteType: SiteType.HANDLING_SITE };

export type IGroceryStoreSite = IBaseSite & { siteType: SiteType.GROCERY_STORE };

export type IDistributionSite = IBaseSite & { siteType: SiteType.DISTRIBUTION_SITE };

export interface IFarmSite extends IBaseSite {
  siteType: SiteType.FARM;
  rawMaterial: IRawMaterial;
  size?: number;
  numberOfFarmers?: number;
  additionalRawMaterialIds?: string[];
  landOwnership?: LandOwnershipType;
  cultivatedAreas?: CultivatedAreas[]; // This is an array of multi polygons bc we want to enable users later to have different categories of cultivated areas and assign parts of them to activities
}

export type ISiteTypeWithoutCluster =
  | IFarmSite
  | IGenericSite
  | IMineSite
  | IForestSite
  | ICollectionSite
  | IOriginSite
  | IPlantSite
  | IProcessingSite
  | IFactorySite
  | IManufacturingSite
  | IWarehouseSite
  | IHandlingSite
  | IGroceryStoreSite
  | IDistributionSite;

export interface IClusterSite<SiteType = ISiteTypeWithoutCluster> extends IBaseSite {
  siteType: SiteType.CLUSTER;
  sites: {
    edges: {
      node: SiteType;
    }[];
    count: number;
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    summary: { workersAmount: number; additionalRawMaterialTitles: string[] };
  };
}

export type ISite = ISiteTypeWithoutCluster | IClusterSite;
