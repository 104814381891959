import { HeaderColumn } from 'types/types';

export enum ActivitiesTableColumnKeys {
  TITLE = 'title',
  PARTNER = 'partner',
  CREATED_AT = 'createdAt',
  ACTIONS = 'actions',
}

export const activitiesHeaderColumns: HeaderColumn<ActivitiesTableColumnKeys>[] = [
  { id: ActivitiesTableColumnKeys.TITLE, headerName: 'Title', sortable: false },
  {
    id: ActivitiesTableColumnKeys.PARTNER,
    headerName: 'Owner',
    sortable: false,
  },
  {
    id: ActivitiesTableColumnKeys.CREATED_AT,
    headerName: 'Created at',
    sortable: false,
  },
  { id: ActivitiesTableColumnKeys.ACTIONS, headerName: 'Actions', sortable: false, align: 'left' },
];
