import { gql } from '@apollo/client';

export const DOCUMENT_FRAGMENT = gql`
  fragment documentsValues on Document {
    id
    path
    url
    title
    createdTimestamp
    visibility
    expiryDate
    issuedDate
    category
    usageCount @client
    status @client
    impactClaimCount
    company {
      name
      logo {
        url
        imageId
      }
    }
    certificateCatalogItem {
      id
      title
      description
      imageUrl
      status
      requestedByUserEmail
      requestedTimestamp
    }
  }
`;
