import { Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoImg from 'assets/img/seedtrace_logo_color.svg';
import LogoLightImg from 'assets/img/seedtrace_logo_light_version.svg';
import LogoSmallImg from 'assets/img/seedtrace_logo_small.svg';
import LogoLightSmallImg from 'assets/img/seedtrace_logo_small_light_version.svg';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IDrawerHeadProps {
  drawerExpanded: boolean;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(5, 0),
  justifyContent: 'center',
  cursor: 'pointer',
  height: 20,
}));

const ImageContainer = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  textAlign: 'center',
}));

const Logo = styled('img')(() => ({
  alignSelf: 'flex-start',
  width: 140,
}));

const LogoSmall = styled('img')(() => ({
  alignSelf: 'flex-start',
  width: 20,
}));

const LogoLightSmall = styled('img')(() => ({
  alignSelf: 'flex-start',
  width: 46,
}));

const LogoLight = styled('img')(() => ({
  alignSelf: 'flex-start',
  width: 150,
  marginLeft: 13,
}));

const DrawerHead: FC<IDrawerHeadProps> = ({ drawerExpanded }) => {
  const navigate = useNavigate();
  const { plan } = useCompanyPlanContext();
  const isSlimVersion = plan === 'SLIM_PLAN';

  const handleClick = () => navigate('/');

  return (
    <Container onClick={handleClick}>
      <Fade in={drawerExpanded}>
        <ImageContainer>
          {isSlimVersion ? (
            <LogoLight src={LogoLightImg} alt="logo" />
          ) : (
            <Logo src={LogoImg} alt="logo" />
          )}
        </ImageContainer>
      </Fade>
      <Fade in={!drawerExpanded}>
        {isSlimVersion ? (
          <LogoLightSmall src={LogoLightSmallImg} alt="logo" />
        ) : (
          <LogoSmall src={LogoSmallImg} alt="logo" />
        )}
      </Fade>
    </Container>
  );
};

export default DrawerHead;
