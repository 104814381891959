import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from 'graphql/fragments/company';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments/documents';
import { VIDEO_FRAGMENT } from 'graphql/fragments/media';
import { IMPACT_CLAIM_FRAGMENT } from './impactClaims';
import {
  COLLECTION_SITE_FRAGMENT,
  DISTRIBUTION_SITE_FRAGMENT,
  FACTORY_SITE_FRAGMENT,
  FARM_SITE_FRAGMENT,
  FOREST_SITE_FRAGMENT,
  GENERIC_SITE_FRAGMENT,
  GROCERY_STORE_SITE_FRAGMENT,
  HANDLING_SITE_FRAGMENT,
  MANUFACTURING_SITE_FRAGMENT,
  MINE_SITE_FRAGMENT,
  ORIGIN_SITE_FRAGMENT,
  PLANT_SITE_FRAGMENT,
  PROCESSING_SITE_FRAGMENT,
  SITE_CLUSTER_FRAGMENT,
  WAREHOUSE_SITE_FRAGMENT,
} from './sites';

const IMAGE_VARIANT_FRAGMENT = gql`
  fragment imageVariant on ImageVariant {
    id
    imageId
    path
    url
    width
    height
    size
    crop {
      x
      y
      width
      height
    }
  }
`;

export const BASE_ACTIVITY_FRAGMENT = gql`
  fragment baseActivityValues on Activity {
    id
    title
    createdTimestamp
  }
`;

export const PARTNER_DOCUMENT_REQUEST_LOG = gql`
  fragment partnerRequestLogValues on PartnerRequestLog {
    id
    comment
    userEmail
    user {
      firstName
      lastName
      email
    }
    companyId
    company {
      name
      logo {
        ...imageVariant
      }
    }
    timestamp
    action
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const PARTNER_LOGS_FRAGMENT = gql`
  fragment partnerLogValues on PartnerLog {
    id
    comment
    userEmail
    user {
      firstName
      lastName
      email
    }
    companyId
    company {
      name
      logo {
        ...imageVariant
      }
    }
    timestamp
    action
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const ACTIVITY_FRAGMENT = gql`
  fragment activityValues on Activity {
    ...baseActivityValues
    langs
    description
    mediaList {
      ... on Video {
        ...video
      }
      ... on ImageVariant {
        ...imageVariant
      }
    }
    component {
      id
      title
      partnerId
      rawMaterials {
        id
        title
      }
    }
    partner {
      id
      title
      createdTimestamp
      status
      type
    }
    site {
      ... on GenericSite {
        ...genericSiteValues
      }
      ... on Farm {
        ...farmValues
      }
      ... on Mine {
        ...mineValues
      }
      ... on Forest {
        ...forestValues
      }
      ... on CollectionSite {
        ...collectionSiteValues
      }
      ... on OriginSite {
        ...originSiteValues
      }
      ... on Plant {
        ...plantValues
      }
      ... on ProcessingSite {
        ...processingSiteValues
      }
      ... on Factory {
        ...factoryValues
      }
      ... on ManufacturingSite {
        ...manufacturingSiteValues
      }
      ... on Warehouse {
        ...warehouseValues
      }
      ... on HandlingSite {
        ...handlingSiteValues
      }
      ... on GroceryStore {
        ...groceryStoreValues
      }
      ... on DistributionSite {
        ...distributionSiteValues
      }
      ... on SiteCluster {
        ...siteClusterValues
      }
      __typename
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${BASE_ACTIVITY_FRAGMENT}
  ${GENERIC_SITE_FRAGMENT}
  ${FARM_SITE_FRAGMENT}
  ${MINE_SITE_FRAGMENT}
  ${FOREST_SITE_FRAGMENT}
  ${COLLECTION_SITE_FRAGMENT}
  ${ORIGIN_SITE_FRAGMENT}
  ${PLANT_SITE_FRAGMENT}
  ${PROCESSING_SITE_FRAGMENT}
  ${FACTORY_SITE_FRAGMENT}
  ${MANUFACTURING_SITE_FRAGMENT}
  ${WAREHOUSE_SITE_FRAGMENT}
  ${HANDLING_SITE_FRAGMENT}
  ${GROCERY_STORE_SITE_FRAGMENT}
  ${DISTRIBUTION_SITE_FRAGMENT}
  ${SITE_CLUSTER_FRAGMENT}
`;

export const PARTNER_BASE_FRAGMENT = gql`
  fragment partnerBaseValues on Partner {
    id
    title
    createdTimestamp
    status
    type
    externalId
    logo {
      ...imageVariant
    }
    company {
      id
      name
      logo {
        ...imageVariant
      }
      partnerId
    }
    activities {
      count
    }
    sites {
      count
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
`;

export const PARTNER_FRAGMENT = gql`
  fragment partnerValues on Partner {
    ...partnerBaseValues
    logs {
      ...partnerLogValues
    }
    latestLog {
      ...partnerLogValues
    }
    impactClaims {
      edges {
        node {
          ...impactClaimValues
        }
      }
    }
    logo {
      ...imageVariant
    }
    activities {
      count
      edges {
        node {
          ...activityValues
        }
      }
    }
    sites {
      count
      edges {
        node {
          ... on Farm {
            ...farmValues
          }
        }
      }
    }
  }
  ${FARM_SITE_FRAGMENT}
  ${PARTNER_BASE_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  ${IMPACT_CLAIM_FRAGMENT}
  ${PARTNER_LOGS_FRAGMENT}
`;

export const BASE_PARTNER_REQUEST_FRAGMENT = gql`
  fragment basePartnerRequestValues on PartnerRequest {
    id
    requestedByUserId
    partnerId
    documentId
    componentId
    chainId
    requestTitle
    requestMessage
    requestReplyMessage
    requestStatus
    requestedTimestamp
    requestedToCompany {
      ...companyValues
    }
    requestedFromCompany {
      ...companyValues
    }
    partner {
      ...partnerValues
    }
    document {
      ...documentsValues
    }
    logs {
      ...partnerRequestLogValues
    }
    latestLog {
      ...partnerRequestLogValues
    }
    previouslyUploadedDocuments {
      ...documentsValues
    }
    requestType
  }
  ${PARTNER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${PARTNER_DOCUMENT_REQUEST_LOG}
`;

export const PARTNER_REQUEST_FRAGMENT = gql`
  fragment partnerRequestValues on PartnerRequest {
    ...basePartnerRequestValues
    chain {
      id
      title
      image {
        ...imageVariant
      }
      chainTasksDetails {
        completed
        total
      }
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${BASE_PARTNER_REQUEST_FRAGMENT}
`;
