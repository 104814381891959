// TODO: Refactor the enums to types and lower case the values to be consistent with the rest of the codebase and material standards

export enum AvailableLanguages {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  DUTCH = 'nl',
  SPANISH = 'es',
}

// Typemap of the values
export type AvailableLanguagesType = `${AvailableLanguages}`;

export enum BackgroundTypes {
  WORLD = 'WORLD',
  SEXAGON = 'SEXAGON',
  RECTANGLE = 'RECTANGLE',
  DOCUMENTS = 'DOCUMENTS',
  DETAILED_DOCUMENTS = 'DETAILED_DOCUMENTS',
  MAP_LAYERS = 'MAP_LAYERS',
  MAP_LAYERS_RIGHT = 'MAP_LAYERS_RIGHT',
}

export enum AvailableSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum IllustrationTypes {
  LOCATIONS = 'LOCATIONS',
  TRUST = 'TRUST',
  PROOF = 'PROOF',
  ROCKET = 'ROCKET',
  IDEA = 'IDEA',
  MEDIA = 'MEDIA',
  SEEDTRACE = 'SEEDTRACE',
  CERTIFICATE = 'CERTIFICATE',
  WEBSHOP = 'WEBSHOP',
  EMPTY_SEARCH = 'EMPTY_SEARCH',
  MAP_LAYERS = 'MAP_LAYERS',
  CHAIN_MAPPING = 'CHAIN_MAPPING',
  COMPLIANCE = 'COMPLIANCE',
}

export enum ItemTypes {
  MAP_LAYERS = 'MAP_LAYERS',
  DOCUMENT = 'DOCUMENT',
  PRODUCT = 'PRODUCT',
  IMPACT_CLAIM = 'IMPACT_CLAIM',
  PARTNER = 'PARTNER',
  LOCATION = 'LOCATION',
  COMPONENT = 'COMPONENT',
  COMPONENT_CHAIN = 'COMPONENT_CHAIN',
  CHAIN_MAPPING = 'CHAIN_MAPPING',
}

export enum DocumentCategories {
  'MANAGEMENT_LETTER' = 'Management letter',
  'CERTIFICATE' = 'Certificate',
  'SHAREHOLDER_AGREEMENT' = 'Shareholder agreement',
  'PICTURES' = 'Pictures',
  'LABORATORY_TEST' = 'Laboratory test',
  'REPORT' = 'Report',
  'STATEMENT' = 'Statement',
  'GEOGRAPHICAL_FEATURES' = 'Geographical features',
  'OTHER' = 'Other document',
}

export interface DocumentCategoryMapping {
  MANAGEMENT_LETTER: 'Management letter';
  CERTIFICATE: 'Certificate';
  SHAREHOLDER_AGREEMENT: 'Shareholder agreement';
  PICTURES: 'Pictures';
  LABORATORY_TEST: 'Laboratory test';
  REPORT: 'Report';
  STATEMENT: 'Statement';
  GEOGRAPHICAL_FEATURES: 'Geographical features';
  OTHER: 'Other document';
}

export enum DocumentStatus {
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
}

export enum DocumentVisibilityStatus {
  PRIVATE = 'Private',
  // Disabled for now until we develop the partner autocomplete
  // SELECTED = 'Partners',
  PUBLIC = 'Public',
}

export enum PartnerRequestStatus {
  DELETED = 'Deleted',
  DENIED = 'Denied',
  DENIED_COMPLETED = 'Denied complete',
  PROVIDED = 'Provided',
  PROVIDED_COMPLETED = 'Provided completed',
  REQUESTED = 'Request sent',
  EDITED = 'Edited',
}

export enum RequestStatusLogs {
  DENIED = 'Denied',
  PROVIDED = 'Request fulfilled',
  DELETED = 'Request deleted',
  REQUESTED = 'Request sent',
  EDITED = 'Edited request',
  COMPLETED = 'Accepted',
}

export enum SupportedImageCustomFormats {
  PNG = 'PNG',
  JPEG = 'JPEG',
  SVG = 'SVG',
  EPS = 'EPS',
}

export enum SupportedImageFromHTMLFormats {
  PNG = 'PNG',
  JPEG = 'JPEG',
  SVG = 'SVG',
  EPS = 'EPS',
}

// TODO: Replace this
export enum DocumentCollaborationType {
  INTERNAL = 'INTERNAL',
  SHARED = 'SHARED',
}

export enum DocumentLibraryTabs {
  INTERNAL = 'internal',
  SHARED = 'shared',
  REQUESTED = 'requested',
}

export enum DocumentOverviewType {
  INTERNAL = 'internal',
  SHARED = 'shared',
  PARTNER = 'partner',
}
