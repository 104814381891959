import { Box, Checkbox, TablePagination, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import { SearchToolbar } from 'components/Forms';
import {
  PartnerImage,
  Title,
} from 'components/Partners/PartnersTable/PartnersTableBody/PartnersTableBody.styles';
import PartnerSkeleton from 'components/Partners/Skeleton/PartnerSkeleton';
import { EmptySearchResults, FlexBox } from 'components/Structure';
import { PaginationContainer } from 'components/TableBase';
import { useDialog } from 'components/hooks';
import { CardContainer, ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC, useMemo, useState } from 'react';
import { Partner, PartnerStatus, PartnerTypeEnum } from 'types/partner.types';
import { getPartnerStatusColor, getReadablePartnerStatus } from 'utils/partnerUtils';

const ROWS_PER_PAGE = 5;

interface IPartnersSelectionProps {
  partners: Partner[];
  selectedPartnerIds: string[];
  /**
   * If true, only registered partners can be selected
   */
  allowOnlyRegisteredPartner?: boolean;
  onSelect: (partnerIds: string[]) => void;
}

const ItemContainer = styled(FlexBox)(({ theme }) => ({
  position: 'relative',
  alignItems: 'normal',
  flexDirection: 'column',
  width: '100%',
  minHeight: 300,
}));

const PartnersSelection: FC<IPartnersSelectionProps> = ({
  partners,
  selectedPartnerIds,
  allowOnlyRegisteredPartner,
  onSelect,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { openDialog } = useDialog();

  const filteredPartners: Partner[] = useMemo(() => {
    if (!partners) return partners;

    const suppliers = partners.filter(partner => partner.type === PartnerTypeEnum.SUPPLIER);

    if (!searchTerm?.length) return suppliers;

    const filtered = suppliers.filter(({ title }) => {
      return title?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filtered;
  }, [partners, searchTerm]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setCurrentPage(page);
  };

  const handleSelect = (partnerId: string) => {
    const isItemSelected = selectedPartnerIds.includes(partnerId);
    if (!isItemSelected) {
      onSelect([...selectedPartnerIds, partnerId]);
    } else {
      const updatedSelection = selectedPartnerIds.filter(partner => partner !== partnerId);
      onSelect(updatedSelection);
    }
  };

  const handleSelectAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event.target instanceof HTMLInputElement && event.target.checked) {
      // only select the registered partners
      const partners = allowOnlyRegisteredPartner
        ? filteredPartners.filter(partner => partner.status === PartnerStatus.REGISTERED)
        : filteredPartners;
      const newSelected = partners.map(partner => partner.id);
      onSelect?.(newSelected);

      return;
    }
    onSelect?.([]);
  };

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(0);
  };

  return (
    <Box width="100%">
      <FlexBox display="flex" alignItems="center" mb={0.5}>
        <Checkbox onClick={handleSelectAll} />
        <SearchToolbar
          setSearchTerm={handleSearchTermChange}
          searchTerm={searchTerm}
          data-cy="partner-search-input"
          placeholder="Search"
          className="search-input"
        />
        <Box ml={1} />
        <ThemeButton
          startIcon={<Icon name="plus" size="small" />}
          color="BLUE_ICE"
          onClick={() => {
            openDialog({ type: 'ADD_EDIT_PARTNER', props: { enableInvite: true } });
          }}
        >
          Add new partner
        </ThemeButton>
      </FlexBox>

      <ItemContainer gap={1} mb={0.5}>
        {filteredPartners
          .slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
          .map(partner => {
            const isItemSelected = selectedPartnerIds.includes(partner.id);
            const disabled =
              allowOnlyRegisteredPartner && partner.status !== PartnerStatus.REGISTERED;

            return (
              <Box display="flex" alignItems="center" key={partner.id}>
                <Tooltip
                  title={
                    disabled
                      ? 'The partner has to accept your invitation before you can send a self-assessment request'
                      : ''
                  }
                >
                  <Box>
                    <Checkbox
                      onClick={() => handleSelect(partner.id)}
                      checked={isItemSelected}
                      disabled={disabled}
                    />
                  </Box>
                </Tooltip>
                <CardContainer
                  padding={1.5}
                  width="100%"
                  blueHover={!disabled}
                  onClick={() => {
                    if (disabled) return;
                    handleSelect(partner.id);
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <PartnerImage
                        alt={`Logo of ${partner.title}`}
                        src={partner.logo?.url || defaultPartnerImage}
                      />
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Title>{partner.title}</Title>
                        <Box display="flex" alignItems="center">
                          <Box display="flex" alignItems="center" mr={1}>
                            <Icon name="tag-filled" color="gray-80" size="small" mr={1} />
                            <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
                              Supplier
                            </ThemeTypography>
                          </Box>
                          <ThemeTypography
                            variant="ITEM_SUBTITLE"
                            color={getPartnerStatusColor(partner.status)}
                          >
                            {getReadablePartnerStatus(partner.status)}
                          </ThemeTypography>
                        </Box>
                      </Box>
                    </Box>
                    {partner.status === PartnerStatus.NOT_INVITED && (
                      <ThemeButton
                        startIcon={<Icon name="document-request-pending" />}
                        color="BLUE_ICE"
                        onClick={() => {
                          openDialog({
                            type: 'ADD_EDIT_PARTNER',
                            props: { partner, enableInvite: true },
                          });
                        }}
                      >
                        Invite partner
                      </ThemeButton>
                    )}
                    {partner.status === PartnerStatus.INVITED && (
                      <ThemeButton
                        startIcon={<Icon name="re-invite" />}
                        data-cy="resend-invite-btn"
                        color="BLUE_ICE"
                        onClick={() => {
                          openDialog({
                            type: 'PARTNER_RESEND_INVITE',
                            props: {
                              partnerId: partner.id,
                            },
                          });
                        }}
                      >
                        Re-send invite
                      </ThemeButton>
                    )}
                  </Box>
                </CardContainer>
              </Box>
            );
          })}

        {!filteredPartners.length && (
          <EmptySearchResults
            message="No search results found"
            skeletonComponent={<PartnerSkeleton title={searchTerm || ''} />}
          />
        )}
      </ItemContainer>
      {filteredPartners.length > ROWS_PER_PAGE && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={filteredPartners.length}
            rowsPerPage={ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(filteredPartners.length / ROWS_PER_PAGE)}`
            }
          />
        </PaginationContainer>
      )}
    </Box>
  );
};

export default PartnersSelection;
