import React from 'react';
import { Box, Typography } from '@mui/material';
import { Container, FileNameText, ActionButton, SubTitle } from 'components/FileUpload/styles';
import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { PartnerRequest } from 'types/partner.types';
import { CalendarEvent } from '@styled-icons/bootstrap/CalendarEvent';
import Moment from 'react-moment';
import { PartnerRequestStatus } from 'types/enums';
import { ReactComponent as UnknownDocument } from 'assets/img/icons/unknown-document.svg';

const Status = styled(Typography)<{ status: PartnerRequest['requestStatus'] }>(
  ({ status, theme }) => ({
    fontSize: 12,
    marginRight: 8,
    textTransform: 'uppercase',

    color:
      status === 'REQUESTED' || status === 'PROVIDED'
        ? theme.palette.success.main
        : status === 'DENIED'
        ? theme.palette.error.main
        : theme.custom.colors.textLight,
  })
);

const CalendarIcon = styled(CalendarEvent)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

interface Props {
  partnerRequest: PartnerRequest;
  onDelete?: () => void;
}

const ProofListItem: React.FC<Props> = ({ partnerRequest, onDelete }: Props) => {
  const { requestTitle, requestedTimestamp, requestStatus } = partnerRequest;
  return (
    <Container data-cy="request-proof-item">
      <Box display="flex" alignItems="center" width="90%">
        <UnknownDocument height={28} width={28} />
        <Box display="flex" flexGrow={1} flexDirection="column" width="70%">
          <Box>
            <FileNameText>{requestTitle}</FileNameText>
            <SubTitle>
              <Box display="flex" alignItems="center" component="span">
                <Status variant="body2" status={requestStatus}>
                  {PartnerRequestStatus[requestStatus]}
                </Status>
                <CalendarIcon size={8} />
                <Moment format="LL">{requestedTimestamp}</Moment>
              </Box>
            </SubTitle>
          </Box>
        </Box>
      </Box>
      {onDelete && (
        <ActionButton onClick={onDelete}>
          <Trash size={14} />
        </ActionButton>
      )}
    </Container>
  );
};

export default ProofListItem;
