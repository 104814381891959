import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import App from 'App';
import { ConfigProvider } from 'config';
import 'constants/schemas/locale';
import { AmplitudeProvider } from 'contexts/AmplitudeContext';
import CustomProviders from 'contexts/CustomProviders';
import Firebase from 'contexts/Firebase';
import { FirebaseProvider } from 'contexts/FirebaseContext';
import { HeadProvider } from 'contexts/HeadContext';
import MaintenanceProvider from 'contexts/MaintenaceContext';
import { MessageProvider } from 'contexts/MessageContext';
import { SentryProvider } from 'contexts/SentryContext';
import ApolloProvider from 'graphql/ApolloProvider';
import 'lazysizes';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import theme from 'styles/theme';

// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_DEPLOYMENT_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Tracing
  tracesSampleRate: process.env.REACT_APP_DEPLOYMENT_ENV === 'production' ? 0.1 : 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.REACT_APP_GQL_ENDPOINT || ''],
});

const firebase = new Firebase({
  useMock: process.env.REACT_APP_FIREBASE_EMULATOR,
});
const graphqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT;

/** These providers live at the top of the application and are available for child component  */
const OUTER_ROUTER_PROVIDERS = [
  { provider: ThemeProvider, props: { theme } },
  // @ts-ignore // TODO: Refactor upload provider and use tsx
  ConfigProvider as FC<PropsWithChildren>,
  { provider: FirebaseProvider, props: { firebase } },
  { provider: ApolloProvider, props: { firebase, graphqlEndpoint } },
  MessageProvider,
  HeadProvider,
  SentryProvider,
  AmplitudeProvider,
  MaintenanceProvider,
];

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <CustomProviders providers={OUTER_ROUTER_PROVIDERS}>
    <CssBaseline />
    <App />
  </CustomProviders>
);
