import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { FlexBox, PageSubTitle } from 'components/Structure';
import { AlertDialog, ItemCardSmall, ThemeButton, ThemeTypography } from 'designSystem';
import truncate from 'lodash/truncate';
import React, { FC, Fragment, useState } from 'react';
import { PartnerRequestStatus } from 'types/enums';
import { PartnerRequest } from 'types/partner.types';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { REQUEST_STATUS_CLIENT } from 'utils/requestStatus.utils';

const Status = styled(Typography)<{ status: PartnerRequest['requestStatus'] }>(
  ({ status, theme }) => ({
    fontSize: 13,
    marginRight: 8,
    textTransform: 'uppercase',

    color:
      status === 'REQUESTED' || status === 'PROVIDED'
        ? theme.palette.success.main
        : status === 'DENIED'
        ? theme.palette.error.main
        : theme.custom.colors.textLight,
  })
);

const RequestButton = styled(ThemeButton)(() => ({
  background: 'transparent',
}));

interface IPartnerRequestList {
  requests: PartnerRequest[];
  onClickCreate: () => void;
  onClickDelete: (id: PartnerRequest['id']) => void;
  onClickEdit: (request: PartnerRequest) => void;
  onAllRequestsClick: () => void;
}

const PartnerRequestList: FC<IPartnerRequestList> = ({
  requests,
  onClickCreate,
  onClickDelete,
  onAllRequestsClick,
  // onClickEdit,
}) => {
  const [showDeleteAlertForId, setShowDeleteAlertForId] = useState<
    PartnerRequest['id'] | undefined
  >(undefined);

  return (
    <Fragment>
      <Box flex={1} data-cy="requests-list">
        <PageSubTitle title="Requests" mb={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <RequestButton onClick={onAllRequestsClick} size="small" color="BLUE_ICE">
              See all requests
            </RequestButton>
            <ThemeButton
              onClick={onClickCreate}
              startIcon={<Plus size={20} />}
              size="small"
              color="WHITE"
              data-cy="button-new-request"
            >
              New request
            </ThemeButton>
          </Box>
        </PageSubTitle>
        <Box data-cy="home-document-container">
          {requests.map(request => (
            <ItemCardSmall
              key={request.id}
              // onCardClick={() => onClickEdit(request)}
              // onClickEdit={() => onClickEdit(request)}
              onClickDelete={() => setShowDeleteAlertForId(request.id)}
            >
              <Box display="flex" alignItems="center" gap={2.5}>
                <FlexBox pl={1}>
                  <Icon
                    name={
                      REQUEST_STATUS_CLIENT[request.requestType][
                        PartnerRequestStatus[request.requestStatus]
                      ].iconName
                    }
                    size="xx-large"
                  />
                </FlexBox>

                <Box>
                  <Tooltip title={request.requestTitle || ''}>
                    <ThemeTypography variant="ITEM_TITLE">
                      {truncate(request.requestTitle, {
                        length: 45,
                        separator: '',
                      })}
                    </ThemeTypography>
                  </Tooltip>
                  <Box display="flex" alignItems="center" gap={0.8}>
                    <Status variant="body2" status={request.requestStatus}>
                      {PartnerRequestStatus[request.requestStatus]}
                    </Status>
                  </Box>
                </Box>
              </Box>
            </ItemCardSmall>
          ))}
        </Box>
      </Box>
      <AlertDialog
        onClose={() => setShowDeleteAlertForId(undefined)}
        title="Delete request"
        open={!!showDeleteAlertForId}
        text="Are you sure you want to delete this request?"
        onSubmit={() => {
          if (showDeleteAlertForId) {
            onClickDelete(showDeleteAlertForId);
            setShowDeleteAlertForId(undefined);
          }
        }}
        onCancel={() => setShowDeleteAlertForId(undefined)}
        submitText="Yes, delete"
      />
    </Fragment>
  );
};

export default PartnerRequestList;
