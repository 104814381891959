import { styled } from '@mui/material/styles';
import { Drawer, DrawerContent } from 'components/Drawer';
import { useDrawerState } from 'components/Drawer/hooks';
import some from 'lodash/some';
import React, { FC, memo, useMemo } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import HasAccess from './HasAccess';
import FeatureAnnouncements from 'components/FeatureAnnouncements/FeatureAnnouncements';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

const Container = styled('div')({
  display: 'flex',
});

const Main = styled('main')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  flexGrow: 1,
  padding: theme.spacing(3),
  minHeight: '100vh',
}));

// some routes do not allow expanding of drawer for layout issues
const COLLAPSED_DRAWER_ROUTES = ['/products/:id/editor', '/compliance/eudr/:id/guide'];

const Layout: FC = () => {
  const { isPartner } = useCompanyPlanContext();
  const { drawerOpen, toggleDrawer } = useDrawerState();
  const { pathname } = useLocation();

  const drawerCollapsed = useMemo(
    () => some(COLLAPSED_DRAWER_ROUTES, path => !!matchPath(path, pathname)),
    [pathname]
  );

  // Feature announcements are only shown in production, not in the partner UI and if the drawer is not in a collapsed route
  const showFeatureAnnouncements =
    process.env.NODE_ENV !== 'development' && !isPartner && !drawerCollapsed;

  return (
    <>
      {showFeatureAnnouncements && (
        <HasAccess allowedPlans={['FULL_PLAN']}>
          <FeatureAnnouncements />
        </HasAccess>
      )}
      <Container>
        <Drawer open={!drawerCollapsed && drawerOpen}>
          <DrawerContent
            disableExpand={drawerCollapsed}
            drawerExpanded={!drawerCollapsed && drawerOpen}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
        <Main>
          <Outlet />
        </Main>
      </Container>
    </>
  );
};

export default memo(Layout);
