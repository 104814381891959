import { useQuery } from '@apollo/client';
import { Box, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader, SearchToolbar } from 'components/Forms';
import { EmptyState, ErrorState, ActionContainer } from 'components/Structure';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import DialogDefault from 'designSystem/Overlays/Dialog/DialogDefault/DialogDefault';
import Tabs, { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import { GET_DOCUMENTS, GET_SHARED_DOCUMENTS } from 'graphql/queries';
import moment from 'moment';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { IDefaultDialogProps } from 'types/dialog.types';
import { DocumentCollaborationType } from 'types/enums';
import { Document, GraphQlConnection } from 'types/types';
import { filterItems } from 'utils';
import { DocumentTable } from './Table';
import { DocumentsTableColumnKeys } from './Table/constants/documentHeaderColumns';

export interface IDocumentSelectorProps extends IDefaultDialogProps {
  selectedDocuments: Document[];
  multiselect?: boolean;
  onSelect: (selectedIds: string[]) => void;
}

const StyledDialog = styled(DialogDefault)(({ theme }) => ({
  '& .MuiDialog-paperWidthXl': {
    width: 1300,
    minHeight: 500,
    zIndex: 9,
    paddingRight: theme.spacing(2),
  },
}));

const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: theme.spacing(0, 3, 4),
}));

const SearchbarContainer = styled(ActionContainer)(() => ({
  backgroundColor: 'transparent',
}));

const DOCUMENT_TABS = [
  {
    key: DocumentCollaborationType.INTERNAL,
    label: 'Internal documents',
  },
  {
    key: DocumentCollaborationType.SHARED,
    label: 'Shared with me',
    onlyForBuyer: true,
  },
];

const DocumentSelector: FC<IDocumentSelectorProps> = ({
  open,
  onSelect,
  onClose,
  selectedDocuments,
  multiselect = true,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([
    ...selectedDocuments.map(document => document.id),
  ]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [documentCollaborationType, setDocumentCollaborationType] = useState(
    DocumentCollaborationType.INTERNAL
  );

  const { isPartner } = useCompanyPlanContext();

  const tabs: ITab<DocumentCollaborationType>[] = useMemo(
    () => DOCUMENT_TABS.filter(({ onlyForBuyer }) => !onlyForBuyer || (onlyForBuyer && !isPartner)),
    [isPartner]
  );

  const documentQuery =
    documentCollaborationType === DocumentCollaborationType.SHARED
      ? GET_SHARED_DOCUMENTS
      : GET_DOCUMENTS;

  const { data, loading, error } = useQuery<
    { documents: GraphQlConnection<Document> } & { sharedDocuments: GraphQlConnection<Document> }
  >(documentQuery, {
    variables: {
      filters: { fileExtensions: ['pdf'] },
    },
  });

  const currentData: GraphQlConnection<Document> | undefined =
    documentCollaborationType === DocumentCollaborationType.SHARED
      ? data?.sharedDocuments
      : data?.documents;

  const handleChangeTab = (documentCollaborationType: DocumentCollaborationType) => {
    setDocumentCollaborationType(documentCollaborationType);
  };

  const validDocuments = useMemo(() => {
    if (!currentData?.edges) return currentData;

    return currentData?.edges.filter(
      edge => !moment(edge.node?.expiryDate).isBefore(moment(new Date()))
    );
  }, [currentData]);

  const filteredDocuments: Document[] = useMemo(() => {
    return filterItems(validDocuments, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, searchTerm]);

  const columns = useMemo(() => {
    const baseColumns = [
      DocumentsTableColumnKeys.TITLE,
      DocumentsTableColumnKeys.USAGE,
      DocumentsTableColumnKeys.ISSUE_DATE,
      DocumentsTableColumnKeys.EXPIRY_DATE,
    ];

    if (documentCollaborationType === DocumentCollaborationType.SHARED) {
      return [...baseColumns, DocumentsTableColumnKeys.OWNER];
    }

    return baseColumns;
  }, [documentCollaborationType]);

  const onSubmit = () => {
    onSelect(selectedIds);
    onClose?.();
  };

  let dialogContent;
  if (error) dialogContent = <ErrorState />;
  else if (!currentData?.edges && loading) dialogContent = <Loader />;
  else {
    dialogContent = (
      <Fragment>
        <Tabs tabs={tabs} selectedTab={documentCollaborationType} onChange={handleChangeTab} />
        <Box position="relative">
          <SearchbarContainer>
            <SearchToolbar
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              data-cy="document-search-input"
              placeholder="Search document"
              className="search-input"
            />
          </SearchbarContainer>
        </Box>
        {!filteredDocuments.length && (
          <EmptyState message="You have not created any documents or all available documents are already selected." />
        )}
        <DocumentTable
          columns={columns}
          documents={filteredDocuments}
          selectedDocumentIds={selectedIds}
          onSelectionChange={setSelectedIds}
          showPagination
          multiselect={multiselect}
        />
      </Fragment>
    );
  }

  return (
    <StyledDialog
      open={open}
      maxWidth="xl"
      data-cy="document-selector"
      title="Add documents"
      onClose={onClose}
    >
      <DialogContent>{dialogContent}</DialogContent>
      <StyledActions>
        <ThemeButton onClick={onClose} color="WHITE" size="large">
          Cancel
        </ThemeButton>

        <Box ml={2}>
          <ThemeButton
            color="YELLOW"
            size="large"
            data-cy="select-document-button"
            onClick={onSubmit}
          >
            Add selected documents
          </ThemeButton>
        </Box>
      </StyledActions>
    </StyledDialog>
  );
};

export default DocumentSelector;
