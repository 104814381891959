import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import DataImportView from 'components/DataImport/DataImportView';
import { Loader } from 'components/Forms';
import { FlexBox, PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useMeasure from 'react-use/lib/useMeasure';
import { ThemeButton } from 'designSystem';
import useDataImportOnboarding from 'components/DataImport/useDataImportOnboarding';
import { Tour } from '@styled-icons/material/Tour';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: 50,
  padding: theme.spacing(0.5),
}));

const DataImportOverview: FC = () => {
  usePageTitle('Farms import');

  const navigate = useNavigate();
  const company = useCompanyPlanContext();
  const { id: partnerId, importId } = useParams<{ id?: string; importId?: string }>();
  const [containerRef, { height: containerHeight }] = useMeasure<HTMLDivElement>();
  const { displayOnboardingTour, handleStartOnboardingTour } = useDataImportOnboarding({
    // only display onboarding tour in edit mode
    hideTour: !!importId,
  });

  const importForPartner = !partnerId || company.isPartner ? company.partnerId : partnerId;

  const handleDataImportCreated = () => {
    navigate(partnerId ? `/partners/${partnerId}/imports` : '/imports');
  };

  if (!importForPartner) {
    return <Loader />;
  }

  return (
    <>
      <PageContainer ref={containerRef}>
        <Box display="flex" alignItems="end">
          <Box width="100%">
            <PageTitle
              title="Origin data import"
              goBackLabel="All data sets"
              goBackUrl={company.isPartner ? `/imports` : `/partners/${partnerId}/imports`}
              TitleAdornment={
                <FlexBox ml={1} mt={0.5}>
                  <Icon name="farm" color="gray-60" size="large" />
                  <Box mr={0.5} />
                  <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_60">
                    Farm data
                  </ThemeTypography>
                </FlexBox>
              }
            />
          </Box>
          {!importId && (
            <Box pr={1}>
              <ThemeButton
                color="WHITE"
                data-cy="restart-tour-button"
                size="small"
                startIcon={<Tour size={16} />}
                onClick={handleStartOnboardingTour}
              >
                Restart guided tour
              </ThemeButton>
            </Box>
          )}
        </Box>

        <Container>
          {!!containerHeight ? (
            <DataImportView
              containerHeight={containerHeight}
              datasetId={importId}
              displayOnboardingTour={displayOnboardingTour}
              partnerId={importForPartner}
              onDataImportCreated={handleDataImportCreated}
            />
          ) : (
            <Loader />
          )}
        </Container>
      </PageContainer>
    </>
  );
};

export default DataImportOverview;
