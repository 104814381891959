import { useMutation } from '@apollo/client';
import { Box, Grid, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import MappingItem from 'components/ComplianceGuide/components/MappingItem';
import { FlexBox } from 'components/Structure';
import {
  IGeoMappingFormValues,
  geoMappingSchema,
  mappedColumnsSchema,
} from 'constants/schemas/geoUpload.schema';
import { InfoTooltip, ThemeTypography } from 'designSystem';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import { Field, Form, Formik, FormikProps } from 'formik';
import { PROCESS_EUDR_DATASETS } from 'graphql/mutations/compliance';
import { IProcessEUDRDatasetInput } from 'graphql/mutations/types/compliance-mutation.types';
import omit from 'lodash/omit';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import {
  DatasetMapping,
  EUDRDatasetColumnKeys,
  EUDRDatasetStatus,
  ProcessEUDRDatasetsPayload,
} from 'types/compliance.types';
import { AvailableSizes } from 'types/enums';

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const MappingContainer = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 4,
  border: `1px solid #EEEEEE`,
}));

const TableHeaderNames = styled(ThemeTypography)(({ theme }) => ({
  fontWeight: `300 !important`,
  textTransform: 'uppercase',
}));

const MapGeoDataColumns: FC = () => {
  const formRef = useRef<FormikProps<IGeoMappingFormValues>>(null);
  const { formData, statement, setSelectedSubSection } = useEudrComplianceGuide();

  const [processEUDRDatasets] = useMutation<
    { processEUDRDatasets: ProcessEUDRDatasetsPayload },
    { inputs: IProcessEUDRDatasetInput[] }
  >(PROCESS_EUDR_DATASETS);

  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState<number>(0);

  useEffect(() => {
    if (!statement) {
      return;
    }
    // find the first data sheet that is has the status 'MAPPED_FALIED'
    const failedDataSetIndex = statement.eudrDatasets.findIndex(
      dataset => dataset.status === EUDRDatasetStatus.MAPPED_FAILED
    );
    if (failedDataSetIndex !== -1) {
      setSelectedDatasetIndex(failedDataSetIndex);
    }
  }, [statement]);

  const initialValues: IGeoMappingFormValues = useMemo(() => {
    if (!statement || !formData.datasetMappings) {
      return geoMappingSchema.default();
    }

    const schema = statement.eudrDatasets.map(dataset => {
      const mapping = (formData.datasetMappings as DatasetMapping[])?.find(
        mapping => mapping.datasetId === dataset.id
      );
      if (!mapping) {
        return {
          datasetId: dataset.id,
          mappedColumns: [],
        };
      }
      return {
        datasetId: mapping.datasetId,
        mappedColumns: mapping.columnsData.map((column, index) => {
          return {
            ...mappedColumnsSchema.default(),
            targetColumnName: EUDRDatasetColumnKeys[index % EUDRDatasetColumnKeys.length],
            sourceColumnName: column.columnName,
            columnDataExample: column.columnDataExample,
          };
        }),
      };
    });

    return schema;
  }, [statement, formData]);

  const handleClickDataSheet = (index: number) => {
    setSelectedDatasetIndex(index);
  };

  const handleSubmit = async (values: IGeoMappingFormValues) => {
    try {
      const response = await processEUDRDatasets({
        variables: {
          inputs: values.map(({ datasetId, mappedColumns }) => ({
            datasetId,
            // Remove the excluded columns and the columnDataExample
            mappedColumns: mappedColumns
              .filter(({ removed }) => !removed)
              .map(mapped => omit(mapped, ['columnDataExample', 'removed'])),
          })),
        },
      });
      const containsAnyTableRepresentation = response.data?.processEUDRDatasets?.eudrDatasets.some(
        ({ tableRepresentation }) => !!tableRepresentation?.length
      );
      if (containsAnyTableRepresentation) {
        setSelectedSubSection('validate');
      } else {
        setSelectedSubSection('confirmation');
      }
    } catch (error) {
      console.error(error);
    } finally {
      formRef.current?.setSubmitting(false);
    }
  };

  const customValidation = (values: IGeoMappingFormValues) => {
    const errors = values.reduce((prev, dataset, index) => {
      const mappedColumns = dataset.mappedColumns.filter(column => !column.removed);
      // Check if all required columns exist

      if (
        mappedColumns.length < 2 ||
        !mappedColumns.some(
          column =>
            column.targetColumnName === 'FARM_ID' ||
            column.targetColumnName === 'FARM_NAME' ||
            column.targetColumnName === 'FARM_OWNER'
        ) ||
        (!mappedColumns.some(column => column.targetColumnName.startsWith('COORDINATES')) &&
          (!mappedColumns.some(column => column.targetColumnName === 'LATITUDE') ||
            !mappedColumns.some(column => column.targetColumnName === 'LONGITUDE')))
      ) {
        return {
          ...prev,
          [index]: {
            mappedColumns:
              'Please add at least the columns Farm ID or Farm name or Farm owner name and your coordinates',
          },
        };
      }

      // Check if no duplicates exist
      if (
        mappedColumns.some(
          ({ targetColumnName }) =>
            mappedColumns.filter(column => column.targetColumnName === targetColumnName).length > 1
        )
      ) {
        return {
          ...prev,
          [index]: {
            mappedColumns: 'Please make sure you have selected unique columns without duplicates',
          },
        };
      }

      return prev;
    }, {});

    return errors;
  };

  return (
    <Formik<IGeoMappingFormValues>
      validationSchema={geoMappingSchema}
      initialValues={initialValues}
      validate={customValidation}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, isValid, submitForm, errors }) => (
        <Form>
          <EudrComplianceGuideSubSection
            allowNextStepNavigation={isValid}
            nextStepLoading={isSubmitting}
            onNextStepClick={submitForm}
          >
            <Box display="flex" gap={2} flexWrap="wrap">
              {statement?.eudrDatasets.map((dataset, index) => (
                <DatasetContainer
                  key={dataset.id}
                  onClick={() =>
                    dataset.status === EUDRDatasetStatus.MAPPED
                      ? undefined
                      : handleClickDataSheet(index)
                  }
                  disabled={Booleanish(dataset.status === EUDRDatasetStatus.MAPPED)}
                >
                  <DatasetItem
                    title={dataset.title}
                    commodity={dataset.rawMaterial.title}
                    location={dataset.originCountry}
                    owner={dataset.partner.title}
                    active={index === selectedDatasetIndex}
                    status={
                      dataset.status === EUDRDatasetStatus.MAPPED
                        ? 'valid'
                        : errors[index]
                        ? 'error'
                        : undefined
                    }
                  />
                </DatasetContainer>
              ))}
            </Box>
          </EudrComplianceGuideSubSection>

          <MappingContainer mt={2} px={2} py={2.5}>
            {errors[selectedDatasetIndex]?.mappedColumns && (
              <Box mb={3}>
                <ThemeTypography variant="BODY_LARGE" color="RED">
                  <>{errors[selectedDatasetIndex]?.mappedColumns}</>
                </ThemeTypography>
              </Box>
            )}

            <Box mb={1}>
              <Grid container>
                <Grid item xs={3}>
                  <FlexBox>
                    <TableHeaderNames variant="BODY_LARGE">Uploaded Columns</TableHeaderNames>
                    <InfoTooltip
                      size={AvailableSizes.SMALL}
                      text="The data columns that you uploaded"
                    />
                  </FlexBox>
                </Grid>
                <Grid item xs={3}>
                  <FlexBox>
                    <TableHeaderNames variant="BODY_LARGE">Sample Data</TableHeaderNames>
                    <InfoTooltip
                      size={AvailableSizes.SMALL}
                      text="Samples of what is contained in each column of your uploaded dataset"
                    />
                  </FlexBox>
                </Grid>
                <Grid item xs={6}>
                  <FlexBox>
                    <TableHeaderNames variant="BODY_LARGE">Template Columns</TableHeaderNames>
                    <InfoTooltip
                      size={AvailableSizes.SMALL}
                      text="The equivalent column in the Seedtrace system with EUDR-required formats"
                    />
                  </FlexBox>
                </Grid>
              </Grid>
            </Box>

            {values[selectedDatasetIndex]?.mappedColumns.map((_, index) => (
              <Field
                key={index}
                component={MappingItem}
                name={`${selectedDatasetIndex}.mappedColumns[${index}]`}
              />
            ))}
          </MappingContainer>
        </Form>
      )}
    </Formik>
  );
};

export default MapGeoDataColumns;
