import React, { useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS, GET_LATEST_VERSION_AND_PRODUCT } from 'graphql/queries';
import { DropDown } from 'components/Forms';
import { Product, GraphQlConnection, ProductVersion } from 'types/types';
import { ContentType } from 'types/contentBlocks';
import { styled, useTheme } from '@mui/material/styles';
import { Collapse, Box } from '@mui/material';
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown';
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp';
import { ThemeButton, ThemeTypography } from 'designSystem';
import { Loader } from 'components/Forms';
import { useEditorState } from 'components/Product/MultiStep/hooks';
import { useProduct } from 'components/Product/hooks';
import { useDialog } from 'components/hooks';
import { useMessages } from 'components/hooks';
import { Download } from '@styled-icons/bootstrap/Download';

const Container = styled('div')(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  borderRadius: 6,
  padding: '8px 16px',
  cursor: 'pointer',

  '& .MuiFormControl-root': {
    width: '100%',
  },

  '& .MuiSelect-select': {
    background: '#fff !important',
    width: '100%',
    borderRadius: 4,
    border: `1px solid #EEEEEE`,
    padding: '4px 8px !important',
  },
}));

const HeadlineContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const ChevronContainer = styled('div')(({ theme }) => ({
  color: theme.custom.themeColors.grayScale[40],
}));

const ImportButton = styled(ThemeButton)(({ theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
}));

interface DropDownType {
  id: string;
  title: string;
}

interface Props {
  contentType: ContentType;
  setContent: (data: unknown) => void;
}

const ImportContentBlock: React.FC<Props> = ({ contentType, setContent }: Props) => {
  const [getAllProducts, { data, loading }] = useLazyQuery<{
    products: GraphQlConnection<Product>;
  }>(GET_PRODUCTS);
  const [selectedProduct, setSelectedProduct] = useState<DropDownType | null>(null);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { custom } = useTheme();
  const { openDialog, closeDialog } = useDialog();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { lang } = useEditorState();
  const { currentProductId } = useProduct();

  const [getVersion, { loading: loadingVersion }] = useLazyQuery<{
    latestProductVersion: ProductVersion;
  }>(GET_LATEST_VERSION_AND_PRODUCT, {
    // no-cache, otherwise it overrides the product journey of the current product
    fetchPolicy: 'no-cache',
  });

  const handleOpenCollapse = async () => {
    if (!openCollapse) {
      await getAllProducts();
    }
    setOpenCollapse(!openCollapse);
  };

  const dropdownOptions = useMemo(
    () =>
      data?.products?.edges
        .filter(
          // @ts-ignore
          ({ node }) => node.id !== currentProductId && node.languageConfig.enabled.includes(lang)
        )
        .map(({ node }) => ({
          id: node.id,
          title: node.title,
        })),
    [data, currentProductId, lang]
  );

  const importContentBlock = async () => {
    setHasError(false);
    if (selectedProduct) {
      const { data: { latestProductVersion = {} as ProductVersion } = {} } = await getVersion({
        variables: {
          id: selectedProduct.id,
          lang: lang,
        },
      });
      const contentBlockContent = latestProductVersion.contentBlocks.find(
        contentBlock => contentType === contentBlock.contentType
      );

      if (!!contentBlockContent) {
        const { id, ...values } = contentBlockContent.data;
        setContent(values);
        setSuccessMessage('Content was successfully imported from other product');
      } else {
        setHasError(true);
        setErrorMessage('There was an error importing content from other product');
      }
    }
  };

  const handleImportContentBlock = () => {
    openDialog({
      type: 'ALERT',
      props: {
        onSubmit: importContentBlock,
        onCancel: closeDialog,
        title: 'Import Content',
        text: 'Information may already have been entered for this content block. Do you want to overwrite the existing content with the imported content? This cannot be undone.',
        submitText: 'Overwrite',
      },
    });
  };

  if (loading) return <Loader />;

  return (
    <Container>
      <HeadlineContainer onClick={handleOpenCollapse} data-cy="import-content-button">
        <Download size={14} color={custom.themeColors.grayScale[100]} />
        <Box ml={1} mr={1}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD">Import Content</ThemeTypography>
        </Box>
        <ChevronContainer>
          {openCollapse ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
        </ChevronContainer>
      </HeadlineContainer>
      <Collapse in={openCollapse}>
        {openCollapse && dropdownOptions && (
          <Box mt={'12px'} width={175}>
            <ThemeTypography variant="BODY_SMALL">Product to import from</ThemeTypography>
            <Box mt={'8px'}>
              <DropDown
                options={dropdownOptions}
                onChange={(item: DropDownType) => setSelectedProduct(item)}
                renderItems={(item: DropDownType) => (
                  <ThemeTypography variant={'BODY_MEDIUM'}>{item.title}</ThemeTypography>
                )}
                currentValue={selectedProduct || ''}
                placeholder="Select product"
                data-cy="import-content-dropdown"
              />
            </Box>
            <ImportButton
              disabled={!selectedProduct}
              onClick={handleImportContentBlock}
              color="WHITE"
              loading={loadingVersion}
              data-cy="submit-import-content-btn"
            >
              Import
            </ImportButton>
            {hasError && (
              <ThemeTypography variant={'BODY_MEDIUM'} color={'RED'}>
                No content to import from this product
              </ThemeTypography>
            )}
          </Box>
        )}
      </Collapse>
    </Container>
  );
};

export default ImportContentBlock;
