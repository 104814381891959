import { Box, styled } from '@mui/material';
import { EudrComplianceGuideContextProvider } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { FlexBox, PageContainer, PageTitle } from 'components/Structure';
import { useDialog, usePageTitle } from 'components/hooks';
import { ThemeButton, ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useEudrComplianceStatements from 'hooks/useEudrComplianceStatements';
import React, { FC } from 'react';
import { EudrStatementStatus } from 'types/compliance.types';

const GlobalControls = styled(FlexBox)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: 0,
}));

const SaveContainer = styled(FlexBox)(({ theme }) => ({
  background: theme.custom.themeColors.white,
  borderRadius: 8,
  maxHeight: 30,
  padding: theme.spacing(1, 2),
}));

const EudrComplianceGuide: FC = () => {
  // TODO: This is quite stupid here, we need the statement here which is also queried inside the context but can not be accessed here
  // Move the query here and pass it into the context
  const { statement } = useEudrComplianceStatements();
  const { openDialog } = useDialog();

  usePageTitle('Compliance guide');

  const handleSubmitStatementClick = () => {
    if (!statement) return;
    openDialog({
      type: 'SUBMIT_EUDR_STATEMENT',
      props: { statementId: statement.id, eudrDatasets: statement.eudrDatasets },
    });
  };

  return (
    <PageContainer>
      <PageTitle
        title="Compliance guide"
        titleExtension="EUDR"
        goBackLabel="Regulation overview"
        goBackUrl="/compliance"
      />
      <GlobalControls gap={2}>
        <SaveContainer>
          <Icon color="gray-40" name="check" mr={1} />
          <ThemeTypography color="GRAY_80" variant="BODY_MEDIUM">
            Latest saved today 14:32
          </ThemeTypography>
        </SaveContainer>
        <ThemeButton
          disabled={statement?.status !== EudrStatementStatus.COMPLETED}
          onClick={handleSubmitStatementClick}
        >
          Submit statement
        </ThemeButton>
      </GlobalControls>

      <Box mb={3} />
      {/* The provider maintains the content and its children */}
      <EudrComplianceGuideContextProvider />
    </PageContainer>
  );
};

export default EudrComplianceGuide;
