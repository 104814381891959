import { gql } from '@apollo/client';
import { IMAGE_VARIANT_FRAGMENT } from './media';

export const PRODUCT_CHAIN_FRAGMENT = gql`
  fragment productChainValues on ProductChain {
    id
    title
    image {
      ...imageVariant
    }
    tasks
  }
`;

export const RAW_MATERIAL_FRAGMENT = gql`
  fragment rawMaterialValues on RawMaterial {
    id
    title
  }
`;

export const COMPONENT_FRAGMENT = gql`
  fragment componentValues on Component {
    id
    title
    articleNumber
    image {
      ...imageVariant
    }
    modifiedTimestamp
    partner {
      id
      title
      externalId
      logo {
        ...imageVariant
      }
    }
    inUse
    rawMaterials {
      ...rawMaterialValues
    }
  }
  ${IMAGE_VARIANT_FRAGMENT}
  ${RAW_MATERIAL_FRAGMENT}
`;

export const COMPONENT_FIELDS_FRAGMENT = gql`
  fragment componentFields on Component {
    id
    companyId
    imageId
    partnerId
    title
    inUse
    modifiedTimestamp
    createdTimestamp
    articleNumber
  }
`;
