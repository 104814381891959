import React, { FC } from 'react';

/**
 * @deprecated the slim plan is no longer supported
 */
const SlimPlanRoutes: FC = () => {
  return (
    <>Slim plan is not supported anymore</>
    // <Route exact path={authenticatedRoutes}>
    //   <Layout showFeatureAnnouncements={showFeatureAnnouncements}>
    //     <Route path="/" exact>
    //       <Redirect to="/products" />
    //     </Route>
    //     <Route path="/products" exact component={Products} />
    //     <Route path="/products/:id" exact component={ProductOverview} />
    //     <EditorRoute path="/products/:id/editor" exact component={ProductEditor} />

    //     <Route
    //       path="/supply-chains"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.SUPPLY_CHAIN_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <SupplyChains />
    //         </Can>
    //       )}
    //     />
    //     <Route
    //       path="/supply-chains/:productId/:chainId"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.SUPPLY_CHAIN_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <SupplyChainOverview />
    //         </Can>
    //       )}
    //     />
    //     <Route
    //       path="/supply-chains/:productId/:chainId/editor"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.SUPPLY_CHAIN_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <SupplyChainMapping />
    //         </Can>
    //       )}
    //     />

    //     <Route
    //       path="/components"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.COMPONENTS_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <ComponentsLayout selectedTab="overview">
    //             <ComponentItems />
    //           </ComponentsLayout>
    //         </Can>
    //       )}
    //     />
    //     <Route
    //       path="/components/:id"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.COMPONENTS_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <ComponentItemOverview />
    //         </Can>
    //       )}
    //     />
    //     <Route
    //       path="/component-chains"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.COMPONENTS_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <ComponentsLayout selectedTab="chains">
    //             <ComponentChains />
    //           </ComponentsLayout>
    //         </Can>
    //       )}
    //     />
    //     <Route
    //       path="/component-chains/:id/editor"
    //       exact
    //       component={() => (
    //         <Can featureFlag={FeatureFlag.COMPONENTS_PAGE_ENABLED} no={() => <Redirect to="/" />}>
    //           <ComponentChainMapping />
    //         </Can>
    //       )}
    //     />

    //     <Route
    //       path="/products/:id/analytics"
    //       exact
    //       render={({ match }) => (
    //         <AnalyticsLayout>
    //           <ProductMainAnalytics match={match} />
    //         </AnalyticsLayout>
    //       )}
    //     />

    //     <Route
    //       path="/products/:id/analytics/embedded"
    //       exact
    //       render={({ match }) => (
    //         <AnalyticsLayout>
    //           <ProductEmbeddedAnalytics match={match} />
    //         </AnalyticsLayout>
    //       )}
    //     />

    //     <Route
    //       exact
    //       path={[
    //         '/settings',
    //         '/settings/security',
    //         '/settings/notifications',
    //         '/settings/company',
    //         '/settings/users',
    //         '/settings/branding',
    //         '/settings/integrations',
    //         '/settings/google-analytics',
    //         '/settings/keys',
    //       ]}
    //     >
    //       <SettingsLayout>
    //         <Route exact path="/settings" component={UserDetails} />
    //         <Route exact path="/settings/security" component={UserSecurity} />
    //         <Route exact path="/settings/notifications" component={NotificationSettings} />
    //         <Route
    //           exact
    //           path="/settings/branding"
    //           component={() => (
    //             <Can rule="designSettings:access" no={() => <Redirect to="/settings" />}>
    //               <DesignSettings />
    //             </Can>
    //           )}
    //         />
    //         <Route
    //           exact
    //           path="/settings/company"
    //           component={() => (
    //             <Can rule="companySettings:access" no={() => <Redirect to="/settings" />}>
    //               <CompanySettings />
    //             </Can>
    //           )}
    //         />
    //         <Route
    //           exact
    //           path="/settings/users"
    //           component={() => (
    //             <Can rule="userSettings:access" no={() => <Redirect to="/settings" />}>
    //               <UserList />
    //             </Can>
    //           )}
    //         />
    //         <Route
    //           exact
    //           path="/settings/integrations"
    //           component={() => (
    //             <Can rule="keys:access" no={() => <Redirect to="/settings" />}>
    //               <IntegrationSettings />
    //             </Can>
    //           )}
    //         />

    //         {/* Old keys endpoint is still used from inside shopify and woocommerce setup, so we need to redirect it to the new page */}
    //         <Route
    //           exact
    //           path="/settings/keys"
    //           component={() => <Redirect to="/settings/integrations" />}
    //         />
    //       </SettingsLayout>
    //     </Route>
    //   </Layout>
    // </Route>
  );
};

export default SlimPlanRoutes;
