import React, { useEffect, createContext, useContext, FC, PropsWithChildren } from 'react';
import amplitude from 'amplitude-js';
import * as Sentry from '@sentry/browser';
import { useApolloClient } from '@apollo/client';
import { useUserData } from 'components/hooks';
import FirebaseContext from './FirebaseContext';

const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

interface AmplitudeContextValues {
  logEvent: amplitude.AmplitudeClient['logEvent'];
}

const defaultLogEvent: amplitude.AmplitudeClient['logEvent'] = (
  event: string,
  // data is literally allowed to be anything.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  callback?: amplitude.Callback,
  errorCallback?: amplitude.Callback,
  outOfSession?: boolean
) => {
  console.warn('unimplemented amplitude context', event);

  Sentry.captureMessage('unimplemented amplitude context', {
    extra: { event, data },
  });

  return undefined;
};

const AmplitudeContext = createContext<AmplitudeContextValues>({
  logEvent: defaultLogEvent,
});

const AmplitudeConsumer = AmplitudeContext.Consumer;

const AmplitudeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { authenticated } = useContext(FirebaseContext);
  const client = useApolloClient();

  const { user, company, refetch } = useUserData();

  const initAmplitude = (apiKey: string) => {
    amplitude.getInstance().init(apiKey);
  };

  const setAmplitudeUserId = (userId: string) => {
    amplitude.getInstance().setUserId(userId);
  };

  const setAmplitudeUserProperties = (properties: unknown) => {
    amplitude.getInstance().setUserProperties(properties);
  };

  // Function is used to send the actual events. The eventType is the name/type of the event and the eventProperties can be anything.
  const sendAmplitudeData: amplitude.AmplitudeClient['logEvent'] = (eventType, eventProperties) => {
    return amplitude.getInstance().logEvent(eventType, eventProperties);
  };

  // Initialise
  useEffect(() => {
    // On mount amplitude should be initialised.
    if (!apiKey) return;

    initAmplitude(apiKey);
  }, []);

  // Handle user logging out and refetching the user/company if they login.
  useEffect(() => {
    if (!authenticated) {
      setAmplitudeUserId('-');
      setAmplitudeUserProperties({
        name: '-',
        email: '-',
        role: '-',
        company: '-',
        companyId: '-',
        plan: 'NO_PLAN',
      });
    } else {
      // If the authenticated status changes, make sure the user is fetched again from the cache.
      // This makes sure the correct user is added to amplitude.
      // ( if this is not refetched, logging in and out of different accounts causes amplitude to update with the old user.)
      refetch();
    }
  }, [authenticated, client, refetch]);

  // Handle user logging in, setting new properties.
  useEffect(() => {
    // If the user or company changes the amplitude properties should be reset accordingly.
    if (user && company) {
      setAmplitudeUserId(user.id);

      setAmplitudeUserProperties({
        name: user.fullName,
        email: user.email,
        role: user.role,
        company: company.name,
        companyId: company.id,
        plan: company.planDetails?.planName,
      });
    }
  }, [user, company]);

  return (
    <AmplitudeContext.Provider
      value={{
        logEvent: sendAmplitudeData,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};

export { AmplitudeProvider, AmplitudeConsumer };

export default AmplitudeContext;
