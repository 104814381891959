import { useQuery } from '@apollo/client';
import { useMessages } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { GET_CHAINS } from 'graphql/queries/chains';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBaseChain, IChainsResponse } from 'types/chain.types';
import { StringParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphConnections';
import useComponentChainActions from './useComponentChainActions';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

interface IComponentChainHookProps {
  // Can be used to skip the useQueryParam search and use the internal search term instead
  skipUseQueryParamSearch?: boolean;
}

const useComponentChains = (
  { skipUseQueryParamSearch }: IComponentChainHookProps = { skipUseQueryParamSearch: false }
) => {
  const navigate = useNavigate();
  const { setErrorMessage } = useMessages();
  const { data, loading, error } = useQuery<IChainsResponse>(GET_CHAINS, {
    variables: { chainFilter: 'COMPONENT' },
  });
  const { createComponentChain } = useComponentChainActions();
  const { isPartner, partnerId } = useCompanyPlanContext();

  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);
  const [internalSearchTerm, setInternalSearchTerm] = useState<string | null>(searchTerm);

  // Keep states in sync
  useEffect(() => setInternalSearchTerm(searchTerm), [searchTerm]);

  const componentChains = useMemo(
    () => (data?.chains ? removeGraphConnections(data.chains) : []),
    [data]
  );

  const filteredComponentChains: IBaseChain[] = useMemo(() => {
    const componentChainEdges = data?.chains.edges || [];
    if (!componentChainEdges) return componentChainEdges;

    if (!internalSearchTerm?.length)
      return componentChainEdges.map(componentChainEdge => componentChainEdge.node);

    // Check if one of the output components contains the search term
    const filtered = componentChainEdges.filter(({ node: { title } }) => {
      return title.toLowerCase().includes(internalSearchTerm.toLowerCase());
    });

    return filtered.map(item => item.node);
  }, [internalSearchTerm, data?.chains]);

  const handleCreateComponentChain = async () => {
    try {
      const result = await createComponentChain(
        isPartner
          ? {
              variables: {
                input: {
                  partnerId,
                },
              },
            }
          : undefined
      );
      if (result?.data?.createChain?.chain?.id) {
        navigate(`/component-chains/${result.data.createChain.chain.id}/editor`);
      } else {
        setErrorMessage(`Failed to create new component chain. No component chain id returned.`);
      }
    } catch (error: unknown) {
      setErrorMessage(`Failed to create new component chain. ${error}`);
    }
  };

  const handleSearchTermChange = skipUseQueryParamSearch ? setInternalSearchTerm : setSearchTerm;

  return {
    searchTerm: internalSearchTerm,
    componentChains,
    filteredComponentChains,
    data,
    loading,
    error,
    handleCreateComponentChain,
    setSearchTerm: handleSearchTermChange,
  };
};

export default useComponentChains;
