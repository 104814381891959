import { Box, TableHead, TablePagination } from '@mui/material';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import {
  PaginationContainer,
  SortableTableHead,
  StyledTable,
  StyledTableBody,
} from 'components/TableBase';
import React, { FC, useState } from 'react';
import { PartnerRequest } from 'types/partner.types';
import {
  REQUEST_HEADER_COLUMNS,
  REQUEST_HEADER_PARTNER_COLUMNS,
} from '../DocumentLibrary/Table/constants/documentRequestColumns';
import RequestGroupTableRow from './RequestGroupTableRow';

export enum RequestsTableAction {
  'MANAGE',
  'DELETE',
  'DISMISS',
  'DISMISS_EDIT',
  'ACCEPT',
  'ADD_CLAIM',
  'EDIT',
  'SEE_DETAILS',
  'REVIEW',
}

export interface RequestsTableProps {
  'data-cy'?: string;
  requests: PartnerRequest[];
  selectedIds?: string[];
  hideHeader?: boolean;
  rowsPerPage: number;
  onRequestClick?: (id: string) => void;
  onActionClick: (id: string, action: RequestsTableAction) => void;
}

const PaginatedRequestsTable: FC<RequestsTableProps> = ({
  'data-cy': dataCy = 'requested-documents-table-body',
  requests,
  hideHeader,
  rowsPerPage,
  onActionClick,
  onRequestClick,
}) => {
  const { isPartner } = useCompanyPlanContext();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const isPaginated = requests.length > rowsPerPage;

  return (
    <Box position="relative">
      <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
        {hideHeader ? (
          <TableHead />
        ) : (
          <SortableTableHead
            headerColumns={isPartner ? REQUEST_HEADER_PARTNER_COLUMNS : REQUEST_HEADER_COLUMNS}
            multiselect={false}
          />
        )}

        <StyledTableBody data-cy={dataCy}>
          {requests
            .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
            .map(request => (
              <RequestGroupTableRow
                key={request.id}
                isPartner={isPartner}
                request={request}
                onRequestClick={onRequestClick}
                onActionClick={onActionClick}
              />
            ))}
        </StyledTableBody>
      </StyledTable>
      {isPaginated && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={requests.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={(e, page) => setCurrentPage(page)}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(requests.length / rowsPerPage)}`
            }
          />
        </PaginationContainer>
      )}
    </Box>
  );
};

export default PaginatedRequestsTable;
