import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { PageContainer, PageTitle } from 'components/Structure';
import { PRODUCT_TABS, ProductTabKey } from 'containers/ProductOverview';
import { Tabs } from 'designSystem';
import { GET_PRODUCT } from 'graphql/queries';
import React, { FC, PropsWithChildren } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const AnalyticsLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery(GET_PRODUCT, {
    variables: { id },
    fetchPolicy: 'cache-only',
  });

  const onChange = (tab: ProductTabKey) => {
    if (tab === ProductTabKey.OVERVIEW) {
      navigate(`/products/${id}`);
    } else {
      navigate(`/products/${id}/${tab}`);
    }
  };

  return (
    <PageContainer>
      <PageTitle
        title="Product analytics"
        titleExtension={data.product?.title}
        goBackLabel="Product pages"
        goBackUrl="/products"
      />
      <Box mt={3} mb={2}>
        <Tabs tabs={PRODUCT_TABS} selectedTab={ProductTabKey.ANALYTICS} onChange={onChange} />
      </Box>
      <Box mt={2} height="100%">
        <Outlet />
      </Box>
    </PageContainer>
  );
};

export default AnalyticsLayout;
