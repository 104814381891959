import { TablePagination } from '@mui/material';
import {
  PaginationContainer,
  SortableTableHead,
  StyledTable,
  StyledTableBody,
} from 'components/TableBase';
import { appQueryParams } from 'constants/appQueryParams';
import { SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import React, { FC, Fragment, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { parseStringToOrder } from 'utils/order';
import TableRow from './TableRow';
import { IBaseProduct } from 'types/product.type';
import { HEADER_COLUMNS } from './constants/headerColumns';
import { ITableRowAction } from './TableRow';

interface IComponentsTableProps {
  products: IBaseProduct[];
  actions: ITableRowAction[];
  onRowClick?: (product: IBaseProduct) => void;
  'data-cy': string;
}

const SupplyChainTable: FC<IComponentsTableProps> = ({
  products,
  'data-cy': dataCy = '',
  actions,
  onRowClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [query, setQuery] = useQueryParams({
    [appQueryParams.order]: withDefault(StringParam, 'desc'),
    [appQueryParams.orderBy]: withDefault(StringParam, 'modifiedTimestamp'),
  });
  const order = parseStringToOrder(query[appQueryParams.order]);
  const orderBy = query[appQueryParams.orderBy];

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setQuery({
      [appQueryParams.order]: isAsc ? 'desc' : 'asc',
      [appQueryParams.orderBy]: property,
    });
  };

  return (
    <Fragment>
      <StyledTable aria-labelledby="tableTitle" aria-label="enhanced table">
        <SortableTableHead
          order={order}
          orderBy={orderBy}
          headerColumns={HEADER_COLUMNS}
          onSort={handleRequestSort}
        />
        <StyledTableBody data-cy={dataCy}>
          {products
            .slice(
              currentPage * SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE,
              currentPage * SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE + SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE
            )
            .map(product => (
              <TableRow
                key={product.id}
                product={product}
                actions={actions}
                onRowClick={onRowClick}
              />
            ))}
        </StyledTableBody>
      </StyledTable>
      {products.length > SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={products.length}
            rowsPerPage={SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={(_, newPage) => setCurrentPage(newPage)}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(products.length / SUPPLY_CHAIN_TABLE_ROWS_PER_PAGE)}`
            }
          />
        </PaginationContainer>
      )}
    </Fragment>
  );
};

export default SupplyChainTable;
