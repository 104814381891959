import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/storage';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

class Firebase {
  constructor({ useMock }) {
    firebase.initializeApp(firebaseConfig);

    if (useMock) {
      firebase.auth().useEmulator('http://localhost:9099/', {
        disableWarnings: true,
      });
      firebase.storage().useEmulator('localhost', 9199);
    }

    this.firebase = firebase;
  }

  getFirebase = () => this.firebase;
}

export default Firebase;
