import React, { FC, useMemo, useState } from 'react';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import { Partner, PartnerStatus } from 'types/partner.types';
import { partnerMitigationSchema, IPartnerMitigation } from 'constants/schemas/mitigation.schema';
import PartnerCard from 'components/ComplianceGuide/components/PartnerCard';
import { Field, Form, Formik } from 'formik';
import { EudrStatementStatus, RiskLevel } from 'types/compliance.types';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { PageSubTitle } from 'components/Structure';
import MitigationCheckbox from '../../MitigationCheckbox';
import FieldWrapper from 'designSystem/Inputs/FieldWrapper/FieldWrapper';
import { TextField } from 'formik-mui';
import UploadField from '../../Forms/UploadField';

export interface IRiskPartner {
  partner: Partner;
  riskLevel?: keyof typeof RiskLevel;
  location: string;
  riskQuestions?: {
    categoryId: string;
    category: string;
    questionId: string;
    question: string;
    answer: string;
    riskLevel: keyof typeof RiskLevel;
    recommendation: {
      title: string;
      actions: string[];
    };
  }[];
}

const PARTNERS: Partner[] = [
  {
    id: '1',
    title: 'Quallcoco Inc.',
    createdTimestamp: '2021-08-10T00:00:00Z',
    activities: { edges: [] },
    impactClaims: { edges: [] },
    status: PartnerStatus.INVITED,
    logs: [],
    latestLog: null,
    sites: { count: 0, edges: [] },
    type: 'SUPPLIER',
  },
  {
    id: '2',
    title: 'Cocomass',
    createdTimestamp: '2021-08-10T00:00:00Z',
    activities: { edges: [] },
    impactClaims: { edges: [] },
    status: PartnerStatus.INVITED,
    logs: [],
    latestLog: null,
    sites: { count: 0, edges: [] },
    type: 'SUPPLIER',
  },
];

const RISK_PARTNERS: IRiskPartner[] = [
  {
    partner: PARTNERS[0],
    riskLevel: 'MEDIUM',
    location: 'Madacasgar',
    riskQuestions: [
      {
        categoryId: 'D',
        category: 'Land-use rights',
        questionId: '2',
        question:
          'Do you have evidence and controls in place to ensure that there have been no incidents of forced evictions related to land acquisition for raw material production, processing, or handling?',
        answer: 'Unknown',
        riskLevel: 'MEDIUM',
        recommendation: {
          title: '',
          actions: [
            'Work with the supplier to adapt the code of conduct to ensure that land acquisitions and uses are conducted in accordance with national legislation.',
            'Request the supplier to collect and provide all relevant documentation and evidence that evictions have not taken place. This can be done, for example, through independent reports.',
            'Advise the supplier to take immediate action to develop and implement controls and monitoring mechanisms to ensure that no forced evictions occur in connection with land acquisition.',
            'Advise the supplier to work with NGOs and local communities to ensure that all land acquisitions are conducted fairly and without forced evictions.',
            'Implement a continuous monitoring system, e.g. by involving third parties',
          ],
        },
      },
    ],
  },
  {
    partner: PARTNERS[1],
    riskLevel: 'HIGH',
    location: 'Nicaragua',
    riskQuestions: [
      {
        categoryId: 'B',
        category: 'General laws & regulations',
        questionId: '2',
        question:
          'Can you and your business partners guarantee that the relevant legislation of the country of production has been complied with?',
        answer: 'Partially',
        riskLevel: 'MEDIUM',
        recommendation: {
          title: '',
          actions: [
            'Engage with this supplier to assess the compliance with the relevant legislation of the country of production and incorporate additional information.',
            'Request additional data or documentation, such as certificates, official licenses or other legal documents.',
            'Carry out regular audits or have them carried out by independent third parties.',
            'Organise trainings for the supplier and its partners to learn about the relevant laws and regulations of the country of production and ensure that these are understood and complied with.',
          ],
        },
      },
      {
        categoryId: 'D',
        category: 'Land-use rights',
        questionId: '3',
        question:
          'Do you have evidence and controls in place to ensure that there have been no incidents of forced evictions related to land acquisition for raw material production, processing, or handling?',
        answer: 'No, we do not have any evidence or controls in place',
        riskLevel: 'HIGH',
        recommendation: {
          title: '',
          actions: [
            'Work with the supplier to adapt the code of conduct to ensure that land acquisitions and uses are conducted in accordance with national legislation.',
            'Request the supplier to collect and provide all relevant documentation and evidence that evictions have not taken place. This can be done, for example, through independent reports.',
            'Advise the supplier to take immediate action to develop and implement controls and monitoring mechanisms to ensure that no forced evictions occur in connection with land acquisition.',
            'Advise the supplier to work with NGOs and local communities to ensure that all land acquisitions are conducted fairly and without forced evictions.',
            'Implement a continuous monitoring system, e.g. by involving third parties',
          ],
        },
      },
    ],
  },
];

const WarningBox = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  background: theme.custom.themeColors.primary[5],
  borderRadius: 4,
  padding: 8,
  marginBottom: theme.spacing(2),
}));

const PartnerContainer = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

const Container = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.custom.themeColors.grayScale[20]}`,
  background: theme.custom.themeColors.white,
  borderRadius: 6,
  padding: theme.spacing(3),
}));

const Pill = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  background: theme.custom.themeColors.grayScale[20],
  borderRadius: '50%',
  minWidth: theme.spacing(3.5),
  height: theme.spacing(3.5),
  fontSize: 12,
  lineHeight: 11,
  boxShadow: theme.custom.shadows[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  marginRight: theme.spacing(2),
}));

const QuestionContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[5],
  padding: theme.spacing(2),
  borderRadius: 6,
}));

const AnswerContainer = styled(Box)<{ riskLevel: keyof typeof RiskLevel }>(
  ({ theme, riskLevel }) => ({
    background:
      riskLevel === 'HIGH'
        ? theme.custom.themeColors.error[20]
        : theme.custom.themeColors.accent[20],
    padding: theme.spacing(2),
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
  })
);

const RiskContainer = styled(Box)<{ riskLevel: keyof typeof RiskLevel }>(
  ({ theme, riskLevel }) => ({
    border: `1px solid ${
      riskLevel === 'HIGH'
        ? theme.custom.themeColors.error[100]
        : theme.custom.themeColors.accent[100]
    }`,
    padding: theme.spacing(0.5),
    borderRadius: 4,
  })
);

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
}));

const DisclaimerContainer = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.primary[5],
  padding: theme.spacing(2),
  borderRadius: 6,
  border: `1px solid ${theme.custom.themeColors.primary[40]}`,
}));

const MitigationPartners: FC = () => {
  const { selectedStatementId, updateStatement } = useEudrComplianceGuide();
  const [selectedPartnerId, setSelectedPartnerId] = useState<string>();

  const initialValues = useMemo(() => {
    return {
      generalRiskNegligible: false,
      comment: '',
      documents: [],
      partners: RISK_PARTNERS.map(riskPartner => ({
        partnerId: riskPartner.partner.id,
        riskQuestions:
          riskPartner.riskQuestions?.map(riskQuestion => ({
            id: riskQuestion.questionId,
            answer: riskQuestion.answer,
            question: riskQuestion.question,
            riskNegligible: false,
            comment: '',
            documents: [],
          })) || [],
      })),
    };
  }, []);

  const activeRiskQuestions = useMemo(
    () =>
      RISK_PARTNERS.find(riskPartner => riskPartner.partner.id === selectedPartnerId)
        ?.riskQuestions,
    [selectedPartnerId]
  );

  const handlePartnerChange = (partnerId: string) => {
    // unselect the partner if it is already selected
    setSelectedPartnerId(selectedPartnerId === partnerId ? undefined : partnerId);
  };

  const handleSubmit = (values: IPartnerMitigation) => {
    if (selectedStatementId) {
      updateStatement(selectedStatementId, { status: EudrStatementStatus.COMPLETED });
    }
  };

  return (
    <Formik<IPartnerMitigation>
      validationSchema={partnerMitigationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, submitForm, setFieldValue }) => {
        const currentPartnerFormIndex = values.partners.findIndex(
          value => value.partnerId === selectedPartnerId
        );
        return (
          <Form>
            <EudrComplianceGuideSubSection
              nextStepLoading={isSubmitting}
              allowNextStepNavigation
              onNextStepClick={submitForm}
            >
              <Box>
                <WarningBox>
                  <ThemeTypography variant="BODY_MEDIUM_BOLD" color="BLUE_60">
                    Mitigation procedures required
                  </ThemeTypography>
                </WarningBox>
                <ThemeTypography variant="BODY_MEDIUM">
                  Some partner assessments resulted in a risk. Click on a partner to se specific
                  risk mitigation recommendations per answer that triggered a risk. When risk
                  mitigation procedure has been implemented, you can add comments and upload
                  supporting documentation in order to include it in your overall due diligence
                  report.
                </ThemeTypography>
                <DisclaimerContainer mt={2}>
                  <ThemeTypography variant="BODY_MEDIUM">
                    <b>Disclaimer:</b> As a digital software provider, Seedtrace is legally not
                    allowed and authorised to provide legal advice in individual cases. This means
                    that the recommendations for action should be considered merely as guidance, and
                    the implementation of the mitigation recommendations does not provide a
                    guarantee of a full assessment of your legal obligations under the EUDR. To
                    consult additional legal advice in particular based on the individual countries
                    legislation is recommended.
                  </ThemeTypography>
                </DisclaimerContainer>
                <Box display="flex" gap={2} flexWrap="wrap" mt={2}>
                  {RISK_PARTNERS.map((riskPartner, index) => (
                    <PartnerContainer
                      key={index}
                      onClick={() => handlePartnerChange(riskPartner.partner.id)}
                    >
                      <PartnerCard
                        partner={riskPartner}
                        active={selectedPartnerId === riskPartner.partner.id}
                      />
                    </PartnerContainer>
                  ))}
                </Box>
              </Box>
            </EudrComplianceGuideSubSection>

            {!selectedPartnerId && (
              <>
                <Box mt={3} mb={0.5}>
                  <PageSubTitle title="General risk measureS" />
                </Box>
                <Container mb={3}>
                  <ThemeTypography variant="BODY_LARGER_BOLD">
                    Consider the following recommendations
                  </ThemeTypography>
                  <Box mt={2}>
                    <ThemeTypography variant="BODY_MEDIUM_BOLD">
                      To gather more information from your suppliers, you could:
                    </ThemeTypography>
                    <Box ml={2}>
                      <ThemeTypography variant="BODY_MEDIUM">
                        <List>
                          <li>
                            Engage with relevant stakeholders to incorporate appropriate information
                            and perspectives into decisions about how to proceed further about
                            specific risks
                          </li>
                          <li>
                            Include local stakeholders as a critical source of information and
                            perspective for assessing risk
                          </li>
                          <li>
                            Information from local stakeholders can be conveyed through interviews,
                            surveys, grievance mechanisms, and community reports, among others
                          </li>
                          <li>Conduct independent surveys or audits</li>
                          <li>
                            Assist your suppliers, in particular small farmers, to comply with this
                            Regulation through capacity building and investment
                          </li>
                        </List>
                      </ThemeTypography>
                    </Box>
                    <Box mt={2} />
                    <ThemeTypography variant="BODY_MEDIUM_BOLD">
                      Request additional information, data, or documentation, such as:
                    </ThemeTypography>
                    <Box ml={2}>
                      <ThemeTypography variant="BODY_MEDIUM">
                        <List>
                          <li>Available third party reports relevant to the site</li>
                          <li>Documents of internal policies and procedures</li>
                          <li>Land titles</li>
                          <li>Workers&apos; wages and safety records</li>
                          <li>Company grievance mechanisms</li>
                          <li>Results or documentation of assessments or planning processes</li>
                          <li>Repositories and other records of social conflicts</li>
                        </List>
                      </ThemeTypography>
                    </Box>
                  </Box>
                  <Box mt={2} />
                  <MitigationCheckbox
                    selected={values.generalRiskNegligible}
                    label="I have performed risk mitigations and now consider this risk negligible."
                    onClick={() =>
                      setFieldValue(`generalRiskNegligible`, !values.generalRiskNegligible)
                    }
                  />
                  {values.generalRiskNegligible && (
                    <Box mt={2}>
                      <ThemeTypography variant="BODY_MEDIUM">
                        Add comments or upload any supporting documents to verify your risk
                        mitigation procedures below.
                      </ThemeTypography>
                      <Box mt={2.5} />
                      <FieldWrapper label="Comment">
                        <Field
                          component={TextField}
                          lang="en"
                          name="comment"
                          fullWidth
                          multiline
                          rows={4}
                          charLimit={650}
                          placeholder="Write your message..."
                        />
                      </FieldWrapper>
                      <Box mt={2} />
                      <FieldWrapper label="Document upload">
                        <Field
                          component={UploadField}
                          name="documents"
                          multiple={true}
                          supportedFileTypes={['pdf', 'csv', 'xls', 'xlsx']}
                        />
                      </FieldWrapper>
                    </Box>
                  )}
                </Container>
              </>
            )}
            {activeRiskQuestions?.map((riskQuestion, index) => (
              <>
                <Box mt={3} mb={0.5}>
                  <PageSubTitle title="Specific risk measures" />
                </Box>
                <Container key={index} mb={3}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Pill>{riskQuestion.categoryId}</Pill>
                    <ThemeTypography variant="TITLE_SMALL" color="GRAY_60">
                      {riskQuestion.category}
                    </ThemeTypography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <QuestionContainer>
                        <ThemeTypography variant="BODY_LARGER_BOLD">
                          {riskQuestion.question}
                        </ThemeTypography>
                      </QuestionContainer>
                      <Box mt={2}>
                        <AnswerContainer riskLevel={riskQuestion.riskLevel} gap={1}>
                          <Icon name="reply" color="almost-black" />
                          <ThemeTypography variant="BODY_MEDIUM" color="ALMOST_BLACK">
                            {riskQuestion.answer}
                          </ThemeTypography>
                          <RiskContainer riskLevel={riskQuestion.riskLevel}>
                            <ThemeTypography
                              variant="BODY_SMALLER_BOLD"
                              color={riskQuestion.riskLevel === 'HIGH' ? 'RED' : 'YELLOW'}
                            >
                              {RiskLevel[riskQuestion.riskLevel]}
                            </ThemeTypography>
                          </RiskContainer>
                        </AnswerContainer>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <ThemeTypography variant="BODY_LARGER_BOLD">
                        Consider the following recommendations
                      </ThemeTypography>
                      <Box mt={2}>
                        {riskQuestion.recommendation.title && (
                          <ThemeTypography variant="BODY_MEDIUM_BOLD">
                            {riskQuestion.recommendation.title}
                          </ThemeTypography>
                        )}
                        <Box ml={2}>
                          <ThemeTypography variant="BODY_MEDIUM">
                            <List>
                              {riskQuestion.recommendation.actions.map((action, index) => (
                                <li key={index}>{action}</li>
                              ))}
                            </List>
                          </ThemeTypography>
                        </Box>
                      </Box>
                      <Box mt={2} />
                      <MitigationCheckbox
                        selected={
                          values.partners[currentPartnerFormIndex]?.riskQuestions[index]
                            .riskNegligible
                        }
                        label="I have performed risk mitigations and now consider this risk negligible."
                        onClick={() =>
                          setFieldValue(
                            `partners.${currentPartnerFormIndex}.riskQuestions.${index}.riskNegligible`,
                            !values.partners[currentPartnerFormIndex]?.riskQuestions[index]
                              .riskNegligible
                          )
                        }
                      />
                      {values.partners[currentPartnerFormIndex]?.riskQuestions[index]
                        .riskNegligible && (
                        <Box mt={2}>
                          <ThemeTypography variant="BODY_MEDIUM">
                            Add comments or upload any supporting documents to verify your risk
                            mitigation procedures below.
                          </ThemeTypography>
                          <Box mt={2.5} />
                          <FieldWrapper label="Comment">
                            <Field
                              component={TextField}
                              lang="en"
                              name={`partners.${currentPartnerFormIndex}.riskQuestions.${index}.comment`}
                              fullWidth
                              multiline
                              rows={4}
                              charLimit={650}
                              placeholder="Write your message..."
                            />
                          </FieldWrapper>
                          <Box mt={2} />
                          <FieldWrapper label="Document upload">
                            <Field
                              component={UploadField}
                              name={`partners.${currentPartnerFormIndex}.riskQuestions.${index}.documents`}
                              multiple={true}
                              supportedFileTypes={['pdf', 'csv', 'xls', 'xlsx']}
                            />
                          </FieldWrapper>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </>
            ))}
          </Form>
        );
      }}
    </Formik>
  );
};

export default MitigationPartners;
