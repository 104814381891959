import { Box, InputAdornment, styled, TablePagination, Typography } from '@mui/material';
import React, { memo, useEffect, useState, FC } from 'react';
import ActivityRow from '../ActivityRow/ActivityRow';
import { CloseButton } from '../Partners.styles';
import { Search } from '@styled-icons/bootstrap';
import { Close } from '@styled-icons/evaicons-solid/Close';
import TraceableLocation from 'assets/img/cards/traceable-illustration.png';
import { SearchInput } from 'components/Forms/SearchToolbar';
import { ActivityType, IActivity } from 'types/partner.types';
import { EmptySearchResults, LocationSkeleton } from 'components/Structure';

interface IActivitySelectionProps {
  activityOptions: IActivity[];
  currentActivityType: ActivityType;
  selectedActivity: IActivity | undefined;
  setSelectedActivity: (location: IActivity | undefined) => void;
}

const FilterBar = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const IllustrationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(5),
  marginTop: theme.spacing(5),
}));

const Illustration = styled('img')(({ theme }) => ({
  width: 200,
}));

const IllustrationText = styled(Typography)(({ theme }) => ({
  color: theme.custom.colors.textLight,
}));

const ROWS_PER_PAGE = 4;

const ActivitySelection: FC<IActivitySelectionProps> = ({
  activityOptions,
  currentActivityType,
  selectedActivity,
  setSelectedActivity,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const filteredActivities = activityOptions.filter(activity => {
    const hasTitleMatch = activity.title
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase());

    const hasActivityMatch = activity.site.locationName
      ? activity.site.locationName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      : false;

    return hasTitleMatch || hasActivityMatch;
  });

  const toggleSelectedActivity = (activityToToggle: IActivity, currentActivity?: IActivity) => {
    if (currentActivity?.id === activityToToggle.id) {
      setSelectedActivity(undefined);
    } else {
      setSelectedActivity(activityToToggle);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Reset page and search when currentActivityType change
    setCurrentPage(0);
    setSearchTerm('');
  }, [currentActivityType]);

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(0);
  };

  return (
    <Box width="100%">
      {activityOptions.length === 0 && (
        <IllustrationWrapper>
          <Illustration src={TraceableLocation} alt="traceable illustration" />
          <Box mt={5}>
            <IllustrationText variant="h6">
              {currentActivityType === ActivityType.EXTERNAL
                ? 'Select a partner to see activities'
                : `You do not have any ${currentActivityType.toLowerCase()} activities yet`}
            </IllustrationText>
          </Box>
        </IllustrationWrapper>
      )}

      {activityOptions.length > 0 && (
        <Box>
          <FilterBar>
            <SearchInput
              setDebouncedState={handleSearchTermChange}
              className="search-input"
              delay={200}
              placeholder="Search activities"
              initialValue={searchTerm}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm === '' ? (
                      <Search size={12} />
                    ) : (
                      <CloseButton onClick={() => handleSearchTermChange('')}>
                        <Close size={16} />
                      </CloseButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </FilterBar>

          {filteredActivities
            .slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
            .map(activity => {
              return (
                <Box mt={1} key={`activity-block-${activity.id}`} data-cy="activity-row">
                  <ActivityRow
                    isSelected={selectedActivity?.id === activity.id}
                    activity={activity}
                    hasShadow
                    onSelect={() => {
                      toggleSelectedActivity(activity, selectedActivity);
                    }}
                  />
                </Box>
              );
            })}

          {filteredActivities && filteredActivities.length > ROWS_PER_PAGE && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={activityOptions.length}
              rowsPerPage={ROWS_PER_PAGE}
              page={currentPage}
              onPageChange={handleChangePage}
              labelDisplayedRows={({ page }) =>
                `Page ${page + 1} of ${Math.ceil(filteredActivities.length / ROWS_PER_PAGE)}`
              }
            />
          )}

          {filteredActivities.length === 0 && (
            <EmptySearchResults
              message="No search results found"
              skeletonComponent={<LocationSkeleton />}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(ActivitySelection);
