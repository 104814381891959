import { Can } from 'components/Auth';
import ComponentsLayout from 'components/ComponentsLibrary/ComponentsLayout';
import PartnerComponentsLayout from 'components/ComponentsLibrary/PartnerComponentsLayout';
import DocumentsLayout from 'components/DocumentLibrary/DocumentsLayout';
import NotFoundPage from 'components/NotFoundPage/NotFoundPage';
import { EditorRoute } from 'components/Product/MultiStep';
import { AnalyticsLayout } from 'components/Product/Overview/Analytics';
import { Layout as SettingsLayout } from 'components/Settings';
import { Layout } from 'components/Structure';
import ActivityLayout from 'components/Structure/ActivitiesLayout';
import SitesLayout from 'components/Structure/SitesLayout';
import {
  ClaimOverview,
  CompanySettings,
  DesignSettings,
  DocumentOverview,
  FeedbackAnalytics,
  GoogleAnalyticsTrackingId,
  Home,
  ImpactClaims,
  IntegrationSettings,
  InternalDocuments,
  MediaLibrary,
  NotificationSettings,
  PartnerActivities,
  PartnerDocuments,
  PartnerRequestOverview,
  PartnerRequests,
  Partners,
  ProductBehaviorAnalytics,
  ProductEditor,
  ProductEmbeddedAnalytics,
  ProductMainAnalytics,
  ProductOverview,
  Products,
  SharedDocuments,
  SupplyChainMapping,
  SupplyChainOverview,
  SupplyChains,
  UserDetails,
  UserList,
  UserSecurity,
} from 'containers';
import ClusterSites from 'containers/ClusterSites';
import Compliance from 'containers/Compliance';
import ComponentChainMapping from 'containers/ComponentChainMapping';
import ComponentChains from 'containers/ComponentChains';
import ComponentItemOverview from 'containers/ComponentItemOverview';
import ComponentItems from 'containers/ComponentItems';
import DataImportOverview from 'containers/DataImportOverview';
import DataImports from 'containers/DataImports';
import EudrComplianceGuide from 'containers/EudrComplianceGuide';
import EudrComplianceOverview from 'containers/EudrComplianceOverview';
import ExternalActivities from 'containers/ExternalActivities';
import InternalActivities from 'containers/InternalActivities';
import PartnerComponentChains from 'containers/PartnerComponentChains';
import PartnerComponentItems from 'containers/PartnerComponentItems';
import PartnerOverview from 'containers/PartnerOverview';
import Payments from 'containers/Payments';
import SiteOverview from 'containers/SiteOverview';
import Sites from 'containers/Sites';
import UnassignedActivities from 'containers/UnassignedActivities';
import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SiteOwnershipType } from 'types/sites.types';

const ClientUIRouter: FC = () => (
  <Routes>
    <Route element={<Layout />}>
      {/* Home */}
      <Route path="/" element={<Home />} />

      {/* Supply chains */}
      <Route path="/supply-chains" element={<SupplyChains />} />
      <Route path="/supply-chains/:productId/:chainId" element={<SupplyChainOverview />} />
      <Route path="/supply-chains/:productId/:chainId/editor" element={<SupplyChainMapping />} />

      {/* Components */}
      <Route
        path="/components"
        element={
          <ComponentsLayout selectedTab="components">
            <ComponentItems />
          </ComponentsLayout>
        }
      />
      <Route path="components/:id" element={<ComponentItemOverview />} />

      {/* Component chains */}
      <Route
        path="/component-chains"
        element={
          <ComponentsLayout selectedTab="component-chains">
            <ComponentChains />
          </ComponentsLayout>
        }
      />
      <Route path="/component-chains/:id/editor" element={<ComponentChainMapping />} />

      {/* Partners */}
      <Route path="/partners" element={<Partners />} />
      <Route path="/partners/:id" element={<PartnerOverview />} />
      {/* <Route path="/partners/:id/sub-partners" element={<SubPartners />} /> */}
      <Route path="/partners/:id/documents" element={<PartnerDocuments />} />
      <Route path="/partners/:id/activities" element={<PartnerActivities />} />
      <Route path="/partners/:id/requests" element={<PartnerRequests />} />
      <Route path="/partners/:id/requests/:requestId" element={<PartnerRequestOverview />} />
      <Route
        path="/partners/:id/documents/:documentId"
        element={<DocumentOverview documentType="PARTNER" />}
      />
      <Route path="/partners/:id/imports" element={<DataImports />} />
      <Route path="/partners/:id/imports/farms" element={<DataImportOverview />} />
      <Route path="/partners/:id/imports/farms/:importId" element={<DataImportOverview />} />
      <Route
        path="/partners/:id/components"
        element={
          <PartnerComponentsLayout selectedTab="components">
            <PartnerComponentItems />
          </PartnerComponentsLayout>
        }
      />
      <Route
        path="partners/:id/components/:componentId"
        element={<ComponentItemOverview renderInsidePartnerLibrary />}
      />
      <Route
        path="partners/:id/component-chains"
        element={
          <PartnerComponentsLayout selectedTab="component-chains">
            <PartnerComponentChains />
          </PartnerComponentsLayout>
        }
      />

      {/* Activities */}
      <Route element={<ActivityLayout />}>
        <Route path="/activities/internal" element={<InternalActivities />} />
        <Route path="/activities/external" element={<ExternalActivities />} />
        <Route path="/activities/unassigned" element={<UnassignedActivities />} />
      </Route>

      {/* Sites */}
      <Route element={<SitesLayout />}>
        <Route path="/sites/internal" element={<Sites typeOfSite={SiteOwnershipType.INTERNAL} />} />
        <Route path="/sites/external" element={<Sites typeOfSite={SiteOwnershipType.EXTERNAL} />} />
        <Route
          path="/sites/unassigned"
          element={<Sites typeOfSite={SiteOwnershipType.UNASSIGNED} />}
        />
      </Route>
      <Route path="/sites/internal/:id" element={<SiteOverview />} />
      <Route path="/sites/external/:id" element={<SiteOverview />} />
      <Route path="/sites/unassigned/:id" element={<SiteOverview />} />
      <Route path="/sites/internal/:id/sites" element={<ClusterSites />} />
      <Route path="/sites/external/:id/sites" element={<ClusterSites />} />
      <Route path="/sites/unassigned/:id/sites" element={<ClusterSites />} />

      {/* Documents */}
      <Route element={<DocumentsLayout />}>
        <Route path="/documents/internal" element={<InternalDocuments />} />
        <Route path="/documents/shared" element={<SharedDocuments />} />
      </Route>
      <Route
        path="/documents/internal/:documentId"
        element={<DocumentOverview documentType="INTERNAL" />}
      />
      <Route
        path="/documents/shared/:documentId"
        element={<DocumentOverview documentType="SHARED" />}
      />

      {/* Compliance */}
      <Route path="/compliance" element={<Compliance />} />
      <Route path="/compliance/eudr" element={<EudrComplianceOverview />} />
      <Route path="/compliance/eudr/:id" element={<EudrComplianceOverview />} />
      <Route path="/compliance/eudr/:id/guide" element={<EudrComplianceGuide />} />

      {/* Payments */}
      <Route path="/payments" element={<Payments />} />

      {/* Products */}
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductOverview />} />
      <Route
        path="/products/:id/editor"
        element={
          <EditorRoute>
            <ProductEditor />
          </EditorRoute>
        }
      />
      <Route element={<AnalyticsLayout />}>
        <Route path="/products/:id/analytics" element={<ProductMainAnalytics />} />
        <Route path="/products/:id/analytics/behavior" element={<ProductBehaviorAnalytics />} />
        <Route path="/products/:id/analytics/embedded" element={<ProductEmbeddedAnalytics />} />
      </Route>
      <Route path="/products/:id/feedback" element={<FeedbackAnalytics />} />

      {/* Impact claims */}
      <Route path="/impact-claims" element={<ImpactClaims />} />
      <Route path="/impact-claims/:id" element={<ClaimOverview />} />

      {/* Media */}
      <Route path="/media-library" element={<MediaLibrary />} />

      {/* Settings */}
      <Route element={<SettingsLayout />}>
        <Route path="/settings" element={<UserDetails />} />
        <Route path="/settings/security" element={<UserSecurity />} />
        <Route path="/settings/notifications" element={<NotificationSettings />} />
        <Route
          path="/settings/branding"
          element={
            <Can rule="designSettings:access" no={() => <Navigate to="/settings" />}>
              <DesignSettings />
            </Can>
          }
        />
        <Route
          path="/settings/company"
          element={
            <Can rule="companySettings:access" no={() => <Navigate to="/settings" />}>
              <CompanySettings />
            </Can>
          }
        />
        <Route
          path="/settings/users"
          element={
            <Can rule="userSettings:access" no={() => <Navigate to="/settings" />}>
              <UserList />
            </Can>
          }
        />
        <Route
          path="/settings/integrations"
          element={
            <Can rule="keys:access" no={() => <Navigate to="/settings" />}>
              <IntegrationSettings />
            </Can>
          }
        />
        <Route
          path="/settings/google-analytics"
          element={
            <Can rule="googleAnalytics:access" no={() => <Navigate to="/settings" />}>
              <GoogleAnalyticsTrackingId />
            </Can>
          }
        />
        {/* Old keys endpoint is still used from inside shopify and woocommerce setup, so we need to redirect it to the new page */}
        <Route path="/settings/keys" element={<Navigate to="/settings/integrations" />} />
      </Route>
    </Route>

    {/* 404 */}
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default ClientUIRouter;
