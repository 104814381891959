import { Grid } from '@mui/material';
import { FieldWrapper } from 'designSystem';
import { TextFieldLimited } from 'components/Forms';
import { useCompanyFeatureFlags } from 'components/hooks';
import { useContentBlock } from 'components/Product/MultiStep/hooks';
import { FeatureOverlay } from 'components/Structure';
import { applicationHelperTexts } from 'config/applicationHints';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import React from 'react';
import FormContainer from './FormContainer';
import Header from './Header';
import { Container } from './styles';

const Feedback = ({ menu }) => {
  const { isCompanyFeatureEnabled } = useCompanyFeatureFlags();
  const [{ data, enabled, id }, schema] = useContentBlock(menu.id);

  const feedbackFeatureEnabled = isCompanyFeatureEnabled('feedback');

  return (
    <Container>
      {!feedbackFeatureEnabled && <FeatureOverlay />}
      <FormContainer
        schema={schema}
        initialValues={{
          enabled,
          ...data,
        }}
      >
        {({ setValues }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                title={menu.title}
                helperText={menu.description}
                showEnabled
                contentBlockId={id}
                contentType={menu.id}
                setContent={setValues}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Headline">
                <Field
                  component={TextFieldLimited}
                  placeholder="Leave us a rating!"
                  fullWidth
                  name="headline"
                  variant="outlined"
                  charLimit={50}
                  inputProps={{
                    'data-cy': 'feedback-form-title-field',
                  }}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Subtitle">
                <Field
                  component={TextFieldLimited}
                  placeholder="Thanks for buying our product! We'd be extremely happy if you could leave us a review of how satisfied you are!"
                  fullWidth
                  multiline
                  name="subtitle"
                  variant="outlined"
                  rows={3}
                  charLimit={650}
                  inputProps={{
                    'data-cy': 'feedback-form-subtitle-field',
                  }}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper
                label="Rating enabled"
                tooltip={{
                  variant: 'INFO',
                  helperText: applicationHelperTexts.feedback.rating,
                }}
              >
                <Field
                  component={Switch}
                  type="checkbox"
                  name="ratingEnabled"
                  inputProps={{
                    'data-cy': 'feedback-form-rating-checkbox',
                  }}
                />
              </FieldWrapper>
            </Grid>
          </Grid>
        )}
      </FormContainer>
    </Container>
  );
};

export default Feedback;
