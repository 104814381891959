import { gql } from '@apollo/client';
import {
  PARTNER_FRAGMENT,
  ACTIVITY_FRAGMENT,
  PARTNER_REQUEST_FRAGMENT,
} from 'graphql/fragments/partner';

export const CREATE_PARTNER = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      partner {
        ...partnerValues
      }
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const UPDATE_PARTNER = gql`
  mutation UpdatePartner($id: UUID!, $input: UpdatePartnerInput!) {
    updatePartner(id: $id, input: $input) {
      partner {
        ...partnerValues
      }
    }
  }
  ${PARTNER_FRAGMENT}
`;

export const DELETE_PARTNER = gql`
  mutation DeletePartners($ids: [UUID!]!) {
    deletePartners(ids: $ids) {
      ids
    }
  }
`;

export const CREATE_PARTNER_LOCATION = gql`
  mutation CreateActivity($input: CreateActivityInput!, $lang: Language) {
    createActivity(input: $input, lang: $lang) {
      activity {
        ...activityValues
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const UPDATE_PARTNER_LOCATION = gql`
  mutation UpdateActivity($id: UUID!, $input: UpdateActivityInput!, $lang: Language) {
    updateActivity(id: $id, input: $input, lang: $lang) {
      activity {
        ...activityValues
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const TRANSLATE_PARTNER_LOCATION = gql`
  mutation TranslateActivity($id: UUID!, $input: TranslationParams!) {
    translateActivity(id: $id, input: $input) {
      activity {
        ...activityValues
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: CreateActivityInput!, $lang: Language) {
    createActivity(input: $input, lang: $lang) {
      activity {
        ...activityValues
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity($id: UUID!, $input: UpdateActivityInput!, $lang: Language) {
    updateActivity(id: $id, input: $input, lang: $lang) {
      activity {
        ...activityValues
      }
    }
  }

  ${ACTIVITY_FRAGMENT}
`;

export const DELETE_PARTNER_LOCATION = gql`
  mutation DeleteActivities($ids: [UUID]!) {
    deleteActivities(ids: $ids) {
      ids
    }
  }
`;

export const INVITE_PARTNER = gql`
  mutation InvitePartner($email: String!, $partnerId: UUID!, $sendEmail: Boolean) {
    invitePartner(email: $email, partnerId: $partnerId, sendEmail: $sendEmail) {
      firstName
      lastName
      email
    }
  }
`;

/** @return { partnerRequest: PartnerRequest } */
export const CREATE_PARTNER_REQUEST = gql`
  mutation CreatePartnerRequest($input: CreatePartnerRequestInput!) {
    createPartnerRequest(input: $input) {
      partnerRequest {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

/** @return { partnerRequest: PartnerRequest } */
export const UPDATE_PARTNER_REQUEST = gql`
  mutation UpdatePartnerRequest($id: UUID!, $input: UpdatePartnerRequestInput!) {
    updatePartnerRequest(id: $id, input: $input) {
      partnerRequest {
        ...partnerRequestValues
      }
    }
  }
  ${PARTNER_REQUEST_FRAGMENT}
`;

export const REINVITE_PARTNER = gql`
  mutation ReinvitePartner($email: String!, $partnerId: UUID!) {
    reinvitePartner(email: $email, partnerId: $partnerId) {
      firstName
      lastName
      email
    }
  }
`;
