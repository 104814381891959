import {
  About,
  Feedback,
  General,
  ImpactFacts,
  MoreInfo,
  MoreProducts,
  ProductJourney,
  VerifiedPayments,
} from 'components/Product/MultiStep/FormParts';
import { contentBlockSchema, generalSchema } from 'constants/schemas';
import { SupplyChainSchema } from 'constants/schemas/supplyChain';
import { DocumentNode } from 'graphql';
import {
  UPDATE_ABOUT,
  UPDATE_FEEDBACK_BLOCK,
  UPDATE_IMPACT_FACTS,
  UPDATE_MORE_INFO_BLOCK,
  UPDATE_PRODUCTS_BLOCK,
  UPDATE_VERIFIED_PAYMENT_BLOCK,
  UPDATE_VERSION_DEFAULTS,
  UPDATE_PRODUCT_JOURNEY,
} from 'graphql/mutations';
import React from 'react';
import { ApplicationPlan } from 'types/companyTypes';
import { ObjectSchema } from 'yup';

export enum ContentBlockIdentifier {
  GENERAL = 'General',
  ABOUT_BLOCK = 'ABOUT_BLOCK',
  PRODUCT_JOURNEY_BLOCK = 'PRODUCT_JOURNEY_BLOCK',
  IMPACT_FACTS_BLOCK = 'IMPACT_FACTS_BLOCK',
  PRODUCTS_BLOCK = 'PRODUCTS_BLOCK',
  MORE_INFO_BLOCK = 'MORE_INFO_BLOCK',
  FEEDBACK_BLOCK = 'FEEDBACK_BLOCK',
  VERIFIED_PAYMENT_BLOCK = 'VERIFIED_PAYMENT_BLOCK',
}

export interface ContentBlock {
  id: ContentBlockIdentifier;
  title: string;
  description: string;
  renderContentBlock: (contentBlock: ContentBlock, previewIsActive: boolean) => JSX.Element | null;
  mutation: DocumentNode;
  schema: ObjectSchema;
  formWide: boolean;
}

export const getContentBlocks = (plan: ApplicationPlan): ContentBlock[] => {
  const menu =
    plan === 'FULL_PLAN' ? FULL_VERSION_CONTENT_BLOCK_MENU : SLIM_VERSION_CONTENT_BLOCK_MENU;

  return [GENERAL_BLOCK, ...menu];
};

export const renderContentBlock = (contentBlock: ContentBlock, previewIsActive: boolean) => {
  switch (contentBlock.id) {
    case ContentBlockIdentifier.ABOUT_BLOCK:
      return <About menu={contentBlock} previewIsActive={previewIsActive} />;
    case ContentBlockIdentifier.FEEDBACK_BLOCK:
      return <Feedback menu={contentBlock} />;
    case ContentBlockIdentifier.GENERAL:
      return <General />;
    case ContentBlockIdentifier.IMPACT_FACTS_BLOCK:
      return <ImpactFacts menu={contentBlock} previewIsActive={previewIsActive} />;
    case ContentBlockIdentifier.MORE_INFO_BLOCK:
      return <MoreInfo menu={contentBlock} previewIsActive={previewIsActive} />;
    case ContentBlockIdentifier.PRODUCT_JOURNEY_BLOCK:
      return <ProductJourney menu={contentBlock} />;
    case ContentBlockIdentifier.PRODUCTS_BLOCK:
      return <MoreProducts menu={contentBlock} />;
    case ContentBlockIdentifier.VERIFIED_PAYMENT_BLOCK:
      return <VerifiedPayments menu={contentBlock} />;
    default:
      return null;
  }
};

const GENERAL_BLOCK: ContentBlock = {
  id: ContentBlockIdentifier.GENERAL,
  title: 'General',
  description: 'General info about your company and product.',
  renderContentBlock,
  mutation: UPDATE_VERSION_DEFAULTS,
  schema: generalSchema,
  formWide: true,
};

const SLIM_VERSION_CONTENT_BLOCK_MENU: ContentBlock[] = [
  {
    id: ContentBlockIdentifier.PRODUCT_JOURNEY_BLOCK,
    title: 'Product Journey',
    description: `
      The Product Journey is the heart of your product story. It is an animated
      map with each step of your supply chain as well as information about said step.
    `,
    renderContentBlock,
    mutation: UPDATE_PRODUCT_JOURNEY,
    schema: SupplyChainSchema,
    formWide: true,
  },
];

const FULL_VERSION_CONTENT_BLOCK_MENU: ContentBlock[] = [
  {
    id: ContentBlockIdentifier.ABOUT_BLOCK,
    title: 'About',
    description: `
      The about section is meant to give the user a quick overview about your
      company and presented product. It displays your logo along with other company
      information as well as free text and image.
    `,
    renderContentBlock,
    mutation: UPDATE_ABOUT,
    schema: contentBlockSchema.ABOUT_BLOCK.schema,
    formWide: true,
  },
  {
    id: ContentBlockIdentifier.PRODUCT_JOURNEY_BLOCK,
    title: 'Product Journey',
    description: `
      The Product Journey is the heart of your product story. It is an animated
      map with each step of your supply chain as well as information about that step.
    `,
    renderContentBlock,
    mutation: UPDATE_PRODUCT_JOURNEY,
    schema: SupplyChainSchema,
    formWide: true,
  },
  {
    id: ContentBlockIdentifier.IMPACT_FACTS_BLOCK,
    title: 'Impact',
    description: `
      The Impact element is a collection of all the good you're doing with your
      product. Provide information about the benefits your product has along it's
      supply chain and align it with the Sustainable Development Goals.
    `,
    renderContentBlock,
    mutation: UPDATE_IMPACT_FACTS,
    schema: contentBlockSchema.IMPACT_FACTS_BLOCK.schema,
    formWide: true,
  },
  {
    id: ContentBlockIdentifier.PRODUCTS_BLOCK,
    title: 'More Products',
    description: `
      Here you can display more of your products and provide links to your online
      shop.
    `,
    renderContentBlock,
    mutation: UPDATE_PRODUCTS_BLOCK,
    schema: contentBlockSchema.PRODUCTS_BLOCK.schema,
    formWide: true,
  },

  {
    id: ContentBlockIdentifier.MORE_INFO_BLOCK,
    title: 'Additional Info',
    description: `
      This is a variable block that can contain more information of your
      own choosing. You could add more details about your product, ingredients
      or something entirely else.
    `,
    renderContentBlock,
    mutation: UPDATE_MORE_INFO_BLOCK,
    schema: contentBlockSchema.MORE_INFO_BLOCK.schema,
    formWide: true,
  },
  {
    id: ContentBlockIdentifier.FEEDBACK_BLOCK,
    title: 'Feedback',
    renderContentBlock,
    description: `
      The feedback function let's the user rate your product on a scale of 1 - 5 and also leave you a message. This way you can collect valuable data to improve your products.
    `,
    mutation: UPDATE_FEEDBACK_BLOCK,
    schema: contentBlockSchema.FEEDBACK_BLOCK.schema,
    formWide: true,
  },
  {
    id: ContentBlockIdentifier.VERIFIED_PAYMENT_BLOCK,
    title: 'Verified Payments',
    description: `
      This block lets you enter meta data about your fair payments project.
    `,
    renderContentBlock,
    mutation: UPDATE_VERIFIED_PAYMENT_BLOCK,
    schema: contentBlockSchema.VERIFIED_PAYMENT_BLOCK.schema,
    formWide: true,
  },
];
