import React from 'react';
import Moment from 'react-moment';
import { Box, Tooltip, useTheme, styled } from '@mui/material';
import { ThemeTypography } from 'designSystem';
import Icon from 'designSystem/Primitives/Icon/Icon';

export interface ILogItem {
  title: string;
  date: string;
  logo?: string;
  author?: string;
  completed?: boolean;
}

export interface ILogItem {
  title: string;
  date: string;
  logo?: string;
  author?: string;
}

const ColoredDot = styled('div')<{ color: string }>(({ theme, color }) => ({
  width: '11px',
  height: '11px',
  borderRadius: '50%',
  background: color,
  marginTop: theme.spacing(0.5),
  marginRight: theme.spacing(1),
  zIndex: 1,
}));

const NameBox = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  background: theme.custom.themeColors.primary[5],
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const AuthorIcon = styled('img')(({ theme }) => ({
  width: 16,
  marginRight: theme.spacing(1),
}));

const LogItem: React.FC<ILogItem> = ({ title, date, logo, author, completed }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
      <Box display="flex">
        <Tooltip title={title}>
          <ColoredDot
            color={
              completed
                ? theme.custom.themeColors.success[100]
                : theme.custom.themeColors.grayScale[10]
            }
          />
        </Tooltip>
        <Box>
          <ThemeTypography variant="BODY_MEDIUM">{title}</ThemeTypography>
          {author && (
            <Box mt={0.5}>
              <NameBox>
                {logo ? (
                  <AuthorIcon src={logo} />
                ) : (
                  <Box mr={1}>
                    <Icon name="profile-image" size="medium" />
                  </Box>
                )}
                <ThemeTypography variant="BODY_MEDIUM">{author}</ThemeTypography>
              </NameBox>
            </Box>
          )}
        </Box>
      </Box>
      <ThemeTypography variant="BODY_SMALL" color="GRAY_60">
        <Moment format="MMMM DD, yyyy">{date}</Moment>
      </ThemeTypography>
    </Box>
  );
};

export default LogItem;
