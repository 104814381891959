import { useMutation } from '@apollo/client';
import { Box, DialogContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { TextFieldLimited } from 'components/Forms';
import { SlidesDialog } from 'components/SliderForm';
import { useMessages } from 'components/hooks';
import { DialogTitle, FieldWrapper, ThemeTypography } from 'designSystem';
import ThemeButton from 'designSystem/Buttons/ThemeButton/ThemeButton';
import { FastField as Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { CREATE_IMPACT_CATALOG_ITEM } from 'graphql/mutations';
import React, { FC, Fragment } from 'react';
import { DisclaimerTarget } from 'types/impactClaimTypes';
import * as Yup from 'yup';
import { ClaimDisclaimer } from '../ClaimShared';

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(10),
}));

const ButtonFooter = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(3),
}));

const ImpactClaimCatalogItemSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
});

interface IRequestClaimDialog {
  onClose: () => void;
}

interface IClaimFormValues {
  title: string;
  description: string;
}

const RequestClaimDialog: FC<IRequestClaimDialog> = ({ onClose }: IRequestClaimDialog) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [createImpactClaimCatalogItem, { loading: isSubmitting }] = useMutation<
    { createImpactCatalogItemRequest: { item: IClaimFormValues } },
    { input: IClaimFormValues }
  >(CREATE_IMPACT_CATALOG_ITEM, {
    onCompleted: () => {
      setSuccessMessage('Claim successfully requested. We will get back to you.');
      onClose();
    },
    onError: () => {
      setErrorMessage('There was an error submitting your impact claim request');
    },
  });

  const onSubmit = async ({ title, description }: IClaimFormValues) => {
    await createImpactClaimCatalogItem({
      variables: {
        input: {
          title,
          description,
        },
      },
    });
  };

  return (
    <SlidesDialog fullWidth maxWidth="md" open onClose={onClose}>
      <Box p={2}>
        <DialogTitle icon={<Plus size={16} />}>New impact claim request</DialogTitle>
      </Box>

      <Formik
        initialValues={{ title: '', description: '' }}
        validationSchema={ImpactClaimCatalogItemSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Fragment>
            <DialogContent data-cy="request-claim-form">
              <FormWrapper>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Box mb={1}>
                        <ThemeTypography variant="TITLE_SMALL">
                          Request a new impact claim
                        </ThemeTypography>
                      </Box>

                      <ThemeTypography variant="BODY_LARGE">
                        Please enter any details of the impact claim you want to add to the
                        seedtrace impact framework. Our experts will review your submission as soon
                        as possible. If relevant, we will assist you in establishing criterias and
                        deciding on required proof documentation.
                      </ThemeTypography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box mr={1}>
                      <FieldWrapper label="Title">
                        <Field
                          component={TextField}
                          name="title"
                          fullWidth
                          placeholder="Carbon neutral"
                          variant="outlined"
                          data-cy="title-field"
                        />
                      </FieldWrapper>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <FieldWrapper label="Description">
                        <Field
                          component={TextFieldLimited}
                          fullWidth
                          name="description"
                          multiline
                          variant="outlined"
                          rows={4}
                          charLimit={650}
                          placeholder="Explain briefly what this impact claim is about."
                          inputProps={{
                            'data-cy': 'description-field',
                          }}
                        />
                      </FieldWrapper>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <ClaimDisclaimer renderedOn={DisclaimerTarget.REQUEST_CLAIM_DIALOG} />
                    </Box>
                  </Grid>
                </Grid>
              </FormWrapper>
            </DialogContent>
            <ButtonFooter>
              <ThemeButton color="BLUE_ICE" size="large" onClick={onClose} data-cy="cancel-btn">
                Cancel
              </ThemeButton>
              <ThemeButton
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                data-cy="submit-btn"
                color="YELLOW"
                size="large"
              >
                Send request
              </ThemeButton>
            </ButtonFooter>
          </Fragment>
        )}
      </Formik>
    </SlidesDialog>
  );
};

export default RequestClaimDialog;
