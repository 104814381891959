import { Box } from '@mui/material';
import { NextStepClaim, NextStepLanguage, NextStepWebshop } from 'components/Home/NextSteps';
import random from 'lodash/random';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useUserData } from 'components/hooks';
import useHasAccess from 'components/hooks/useHasAccess';

const MIN_CARD_HEIGHT = 300;

const HomeNextSteps = ({ publishedProduct, hideWebshopCard }) => {
  const [cards, setCards] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const { company } = useUserData();
  const hasAccessToClaimCard = useHasAccess('FULL_PLAN');

  useEffect(() => {
    const extraCards = [];
    if (publishedProduct) {
      extraCards.push(NextStepLanguage);
    }
    if (!hideWebshopCard) {
      extraCards.push(NextStepWebshop);
    }
    if (company.impactClaims.count === 0 && hasAccessToClaimCard) {
      extraCards.push(NextStepClaim);
    }

    if (!extraCards.length) return;

    setCards(extraCards);

    // Random the starting point of the tips

    setActiveCard(extraCards.length > 1 ? random(0, extraCards.length - 1) : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedProduct, company]);

  const handleShowNextCard = () => {
    setActiveCard(getNextActiveCardNumber(activeCard));
  };

  const getNextActiveCardNumber = currentNumber => {
    return currentNumber + 1 < cards.length ? currentNumber + 1 : 0;
  };

  const Card = useMemo(() => (cards.length ? cards[activeCard] : null), [cards, activeCard]);

  if (!cards.length || !Card) return null;

  return (
    <Fragment>
      <Box display="flex" flex={1} justifyContent="space-between" data-cy="home-next-steps">
        <Card
          handleShowNextCard={cards.length > 1 ? handleShowNextCard : undefined}
          language={publishedProduct?.languageConfig?.default}
          minHeight={MIN_CARD_HEIGHT}
          publishedProduct={publishedProduct}
        />
      </Box>
    </Fragment>
  );
};

HomeNextSteps.propTypes = {
  publishedProduct: PropTypes.object,
  hideWebshopCard: PropTypes.bool,
};

export default HomeNextSteps;
