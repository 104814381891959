import { IconNameType } from 'designSystem/Primitives/Icon/Icon';
import { PartnerRequestStatus } from 'types/enums';
import { RequestType } from 'types/partner.types';

type RequestStatusType = {
  iconName: IconNameType;
  statusTitle?: string;
  actionText?: string;
};

/**
 * Depending on the requestType and PartnerRequestStatus the correct object is returned
 */

export const REQUEST_STATUS_CLIENT: Record<
  RequestType,
  Record<PartnerRequestStatus, RequestStatusType>
> = {
  [RequestType.DOCUMENT]: {
    [PartnerRequestStatus.DELETED]: {
      iconName: 'document-request-dismissed',
      statusTitle: 'Deleted',
    },
    [PartnerRequestStatus.DENIED]: {
      iconName: 'document-request-dismissed',
      statusTitle: 'Dismissed by partner',
    },
    [PartnerRequestStatus.DENIED_COMPLETED]: {
      iconName: 'document-request-dismissed',
      statusTitle: 'Dismissed by partner',
    },
    [PartnerRequestStatus.PROVIDED]: {
      iconName: 'document-request-uploaded',
      statusTitle: 'Document uploaded',
    },
    [PartnerRequestStatus.PROVIDED_COMPLETED]: {
      iconName: 'document-request-fulfilled',
      statusTitle: 'Completed',
    },
    [PartnerRequestStatus.REQUESTED]: {
      iconName: 'document-request-pending',
      statusTitle: 'Pending',
    },
    [PartnerRequestStatus.EDITED]: { iconName: 'document-request-manage', statusTitle: 'Pending' },
  },
  [RequestType.CHAIN]: {
    [PartnerRequestStatus.DELETED]: { iconName: 'chain-request-dismissed', statusTitle: 'Deleted' },
    [PartnerRequestStatus.DENIED]: {
      iconName: 'chain-request-dismissed',
      statusTitle: 'Dismissed by partner',
    },
    [PartnerRequestStatus.DENIED_COMPLETED]: {
      iconName: 'chain-request-dismissed',
      statusTitle: 'Dismissed by partner',
    },
    [PartnerRequestStatus.PROVIDED]: {
      iconName: 'chain-request-uploaded',
      statusTitle: 'Chain mapped',
    },
    [PartnerRequestStatus.PROVIDED_COMPLETED]: {
      iconName: 'chain-request-fulfilled',
      statusTitle: 'Completed',
    },
    [PartnerRequestStatus.REQUESTED]: { iconName: 'chain-request-pending', statusTitle: 'Pending' },
    [PartnerRequestStatus.EDITED]: { iconName: 'chain-request-manage', statusTitle: 'Pending' },
  },
  [RequestType.SELF_ASSESSMENT]: {
    [PartnerRequestStatus.DELETED]: {
      iconName: 'assessment-request-dismissed',
      statusTitle: 'Deleted',
    },
    [PartnerRequestStatus.DENIED]: {
      iconName: 'assessment-request-dismissed',
      statusTitle: 'Dismissed by partner',
    },
    [PartnerRequestStatus.DENIED_COMPLETED]: {
      iconName: 'assessment-request-dismissed',
      statusTitle: 'Dismissed by partner',
    },
    [PartnerRequestStatus.PROVIDED]: {
      iconName: 'assessment-request-uploaded',
      statusTitle: 'Self-assessment uploaded',
    },
    [PartnerRequestStatus.PROVIDED_COMPLETED]: {
      iconName: 'assessment-request-fulfilled',
      statusTitle: 'Completed',
    },
    [PartnerRequestStatus.REQUESTED]: {
      iconName: 'assessment-request-pending',
      statusTitle: 'Pending',
    },
    [PartnerRequestStatus.EDITED]: {
      iconName: 'assessment-request-manage',
      statusTitle: 'Pending',
    },
  },
};

export const REQUEST_STATUS_PARTNER: Record<
  RequestType,
  Record<PartnerRequestStatus, RequestStatusType>
> = {
  [RequestType.DOCUMENT]: {
    [PartnerRequestStatus.DELETED]: {
      iconName: 'document-request-dismissed',
      statusTitle: 'Deleted',
    },
    [PartnerRequestStatus.DENIED]: {
      iconName: 'document-request-dismissed',
      statusTitle: 'Dismissed by you',
    },
    [PartnerRequestStatus.DENIED_COMPLETED]: {
      iconName: 'document-request-dismissed',
      statusTitle: 'Dismissed by you',
    },
    [PartnerRequestStatus.PROVIDED]: {
      iconName: 'document-request-uploaded',
      statusTitle: 'Document uploaded',
    },
    [PartnerRequestStatus.PROVIDED_COMPLETED]: {
      iconName: 'document-request-fulfilled',
      statusTitle: 'Accepted',
    },
    [PartnerRequestStatus.REQUESTED]: {
      iconName: 'document-request-pending',
      statusTitle: 'Pending',
      actionText: 'Upload document',
    },
    [PartnerRequestStatus.EDITED]: {
      iconName: 'document-request-manage',
      statusTitle: 'Pending',
      actionText: 'Upload document',
    },
  },
  [RequestType.CHAIN]: {
    [PartnerRequestStatus.DELETED]: { iconName: 'chain-request-dismissed', statusTitle: 'Deleted' },
    [PartnerRequestStatus.DENIED]: {
      iconName: 'chain-request-dismissed',
      statusTitle: 'Dismissed by you',
    },
    [PartnerRequestStatus.DENIED_COMPLETED]: {
      iconName: 'chain-request-dismissed',
      statusTitle: 'Dismissed by you',
    },
    [PartnerRequestStatus.PROVIDED]: {
      iconName: 'chain-request-uploaded',
      statusTitle: 'Chain mapped',
    },
    [PartnerRequestStatus.PROVIDED_COMPLETED]: {
      iconName: 'chain-request-fulfilled',
      statusTitle: 'Accepted',
    },
    [PartnerRequestStatus.REQUESTED]: {
      iconName: 'chain-request-pending',
      statusTitle: 'Pending',
      actionText: 'Map & confirm chain',
    },
    [PartnerRequestStatus.EDITED]: {
      iconName: 'chain-request-manage',
      statusTitle: 'Pending',
      actionText: 'Map & confirm chain',
    },
  },
  [RequestType.SELF_ASSESSMENT]: {
    [PartnerRequestStatus.DELETED]: {
      iconName: 'assessment-request-dismissed',
      statusTitle: 'Deleted',
    },
    [PartnerRequestStatus.DENIED]: {
      iconName: 'assessment-request-dismissed',
      statusTitle: 'Dismissed by you',
    },
    [PartnerRequestStatus.DENIED_COMPLETED]: {
      iconName: 'assessment-request-dismissed',
      statusTitle: 'Dismissed by you',
    },
    [PartnerRequestStatus.PROVIDED]: {
      iconName: 'assessment-request-uploaded',
      statusTitle: 'Self-assessment uploaded',
    },
    [PartnerRequestStatus.PROVIDED_COMPLETED]: {
      iconName: 'assessment-request-fulfilled',
      statusTitle: 'Accepted',
    },
    [PartnerRequestStatus.REQUESTED]: {
      iconName: 'assessment-request-pending',
      statusTitle: 'Pending',
    },
    [PartnerRequestStatus.EDITED]: {
      iconName: 'assessment-request-manage',
      statusTitle: 'Pending',
    },
  },
};
