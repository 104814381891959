import { useQuery } from '@apollo/client';
import { GET_SINGLE_CLAIM } from 'graphql/queries';
import { useParams } from 'react-router-dom';
import { ImpactClaim } from 'types/impactClaimTypes';
import { RouteParamsWithId } from 'types/router.types';

const useImpactClaim = () => {
  const { id: paramId } = useParams<RouteParamsWithId>();

  const { data, loading, error } = useQuery<{
    impactClaim: ImpactClaim;
  }>(GET_SINGLE_CLAIM, {
    variables: {
      id: paramId,
      lang: 'en',
      requestStatus: ['REQUESTED'],
    },
  });

  return {
    impactClaim: data?.impactClaim,
    loading,
    error,
  };
};

export default useImpactClaim;
