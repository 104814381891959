import { useQuery } from '@apollo/client';
import { useDialog, useLogEvent } from 'components/hooks';
import { appQueryParams } from 'constants/appQueryParams';
import { GET_PARTNER_ACTIVITIES, GET_PARTNER } from 'graphql/queries/partners';
import { useMemo } from 'react';
import { GraphQlConnection } from 'types/types';
import { StringParam, useQueryParam } from 'use-query-params';
import { removeGraphConnections } from 'utils/graphConnections';
import { useParams } from 'react-router-dom';
import { IActivity, Partner, ActivityType } from 'types/partner.types';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';

interface IUseActivitiesProps {
  typeOfActivity?: ActivityType;
  queryForPartnerId?: string;
  skipQuery?: boolean;
}

const useActivities = ({
  typeOfActivity,
  queryForPartnerId,
  skipQuery,
}: IUseActivitiesProps = {}) => {
  const { openDialog } = useDialog();
  const { logEvent } = useLogEvent();
  const { id: partnerLibraryId } = useParams<'id'>();
  const { isPartner, partnerId: companiesPartnerId } = useCompanyPlanContext();
  const isForSpecificPartner = !typeOfActivity && !!partnerLibraryId;
  const { data, loading, error } = useQuery<{
    activities: GraphQlConnection<IActivity>;
  }>(GET_PARTNER_ACTIVITIES, {
    // when using this inside the partner activity page, we need to pass the partnerId to get the activities of that partner
    variables: {
      ...((partnerLibraryId && !typeOfActivity) || queryForPartnerId || typeOfActivity
        ? {
            filters: {
              ...(((partnerLibraryId && !typeOfActivity) || queryForPartnerId) && {
                partnerId: partnerLibraryId || queryForPartnerId,
              }),
              ...(typeOfActivity && { ownershipType: typeOfActivity }),
            },
          }
        : {}),
    },
    skip: skipQuery,
  });

  const {
    data: partnerData,
    loading: partnerLoading,
    error: partnerError,
  } = useQuery<{ partner: Partner }>(GET_PARTNER, {
    skip: !isForSpecificPartner && typeOfActivity !== ActivityType.INTERNAL,
    variables: {
      id: partnerLibraryId || companiesPartnerId,
    },
  });

  const [searchTerm = '', setSearchTerm] = useQueryParam(appQueryParams.query, StringParam);

  const activities = useMemo(
    () => (data?.activities ? removeGraphConnections(data.activities) : []),
    [data?.activities]
  );

  const filteredActivities = useMemo(() => {
    if (!searchTerm?.length) return activities;

    return activities.filter(({ title }) =>
      title.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  }, [activities, searchTerm]);

  const handleCreateActivity = () =>
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: {
        mode: 'add-activity',
        partner:
          isForSpecificPartner || typeOfActivity === ActivityType.INTERNAL
            ? partnerData?.partner
            : undefined,
        hidePartnerSelector: isForSpecificPartner,
      },
    });

  const handleEditActivity = (activity: IActivity) => {
    openDialog({
      type: 'ADD_EDIT_ACTIVITY',
      props: {
        mode: 'edit-activity',
        activity,
        hidePartnerSelector: isForSpecificPartner,
      },
    });
  };

  const handleSearchTermChange = (searchTerm: string) => {
    logEvent('ACTIVITY_SEARCHED', { searchTerm });
    setSearchTerm(searchTerm);
  };

  return {
    activities,
    filteredActivities,
    loading: loading || partnerLoading,
    error: error || partnerError,
    searchTerm,
    partner: partnerData?.partner,
    isPartner,
    handleSearchTermChange,
    handleCreateActivity,
    handleEditActivity,
  };
};

export default useActivities;
