import { styled } from '@mui/material/styles';
import { Trash } from '@styled-icons/bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import { getEmptyRow, isRowEmpty } from 'components/DataImport/utils/dataImport.utils';
import React, { FC } from 'react';

const DeleteContainer = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    cursor: 'pointer',
  },
}));

const DeleteCellRenderer: FC<ICellRendererParams> = ({ data, api, context }) => {
  return (
    <DeleteContainer>
      {!isRowEmpty(data) && (
        <Trash
          size={15}
          onClick={() => {
            api.applyTransaction({ remove: [data] });
            api.applyTransaction({
              add: [getEmptyRow({ ...context.settings, autoAssignIds: false })],
            });
          }}
        />
      )}
    </DeleteContainer>
  );
};

export default DeleteCellRenderer;
