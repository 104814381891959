import { Box, Fade, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import pulseLine from 'assets/img/welcomeSlides/pulse-line-1.svg';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { TextControlBar } from 'components/WelcomeSlides';
import { ThemeTypography } from 'designSystem';
import React, { FC, Fragment } from 'react';
import { getWelcomeItems } from 'utils/slideUtils';
import { Headline, ImageContainer, PageContainer, TextContainer } from './styles';
import PartnerUiWelcomeImage from 'assets/img/welcomeSlides/partner-ui-welcome-slide.png';

const StyledPulse = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '380px !important',
  top: theme.spacing(8),
  left: theme.spacing(4),
}));

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  background: '#fff',
  alignItems: 'center',
  padding: theme.spacing(2.5),
  justifySelf: 'center',
  width: 220,
  borderRadius: '12px',
  boxShadow: theme.custom.shadows[3],
  marginBottom: theme.spacing(2),
}));

const ItemIcon = styled('img')<{ width: number }>(({ width = 40 }) => ({
  maxWidth: width,
}));

const ItemText = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.secondary.main,
  margin: 0,
}));

const ItemIconContainer = styled('div')(() => ({
  width: 60,
}));

const PartnerImage = styled('img')(() => ({
  width: '300px !important',
}));

const Welcome: FC<{ active: boolean }> = ({ active }) => {
  const { plan, isPartner } = useCompanyPlanContext();
  const welcomeItems = getWelcomeItems(plan);

  return (
    <Fragment>
      <PageContainer>
        <Zoom in={active}>
          <TextContainer>
            <Zoom in={active}>
              <StyledPulse src={pulseLine} alt="pulse" />
            </Zoom>
            <Box mt={12}>
              <Headline variant="TITLE_LARGE">Hi, and welcome!</Headline>
              <ThemeTypography variant="TITLE_EXTRA_SMALL">
                Here’s a quick introduction to <br /> the Seedtrace platform.
              </ThemeTypography>
            </Box>
          </TextContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer>
            {isPartner ? (
              <PartnerImage src={PartnerUiWelcomeImage} alt="Showing features of app" />
            ) : (
              <>
                {welcomeItems.map(({ icon, text, width }) => (
                  <Item key={text}>
                    <ItemIconContainer>
                      <ItemIcon width={width} src={icon} alt={text} />
                    </ItemIconContainer>
                    <ItemText dangerouslySetInnerHTML={{ __html: text }} />
                  </Item>
                ))}
              </>
            )}
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </Fragment>
  );
};

export default Welcome;
