import { Box, Fade, Grid } from '@mui/material';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import ClaimSelector from 'components/ImpactClaims/ClaimDialog/ClaimSelector';
import { ClaimDisclaimer } from 'components/ImpactClaims/ClaimShared';
import {
  ContentColumn,
  EmptyStateWrapper,
  SideColumn,
  SlideContentContainer,
  StyledImageText,
  SummaryBox,
} from 'components/SliderForm';
import { CardIllustration, DialogTitle, ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { IllustrationTypes } from 'types/enums';
import { ImpactCatalogItem } from 'types/impactClaimTypes';
import { CatalogItemSdgs, RequirementList } from '../../ClaimCatalog';
import OtherClaimInformation from '../../ClaimShared/OtherClaimInformation';

interface ISelectSlide {
  selectedImpactClaim: ImpactCatalogItem;
  catalogItems: ImpactCatalogItem[];
  title: string;
  handleSelectClaim: (claim: ImpactCatalogItem) => void;
}

const SelectSlide: FC<ISelectSlide> = ({
  selectedImpactClaim,
  catalogItems,
  title,
  handleSelectClaim,
}: ISelectSlide) => {
  return (
    <SlideContentContainer>
      <Fade in timeout={500}>
        <Grid container>
          <SideColumn xs={5} md={4} lg={3} item>
            <Box mb={2}>
              <DialogTitle icon={<Edit size={16} />}>{title}</DialogTitle>
            </Box>
            <ClaimSelector
              selectedItem={selectedImpactClaim}
              handleSelect={handleSelectClaim}
              data={catalogItems}
            />
          </SideColumn>
          <ContentColumn xs={7} md={8} lg={9} item>
            {selectedImpactClaim ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SummaryBox
                    title={selectedImpactClaim?.title}
                    variant={selectedImpactClaim?.iconUrl ? 'IMAGE' : 'BACKGROUND_ICON'}
                    body={selectedImpactClaim?.definition}
                    category={selectedImpactClaim?.category}
                    imageUrl={selectedImpactClaim?.iconUrl}
                    titleTestId="catalog-item-summary-title"
                  />
                  {selectedImpactClaim?.sustainableDevGoals?.length ? (
                    <Box marginTop={3}>
                      <CatalogItemSdgs sdgs={selectedImpactClaim?.sustainableDevGoals} />
                    </Box>
                  ) : null}
                  <Box mt={2}>
                    <OtherClaimInformation
                      thirdParties={selectedImpactClaim?.thirdParties || []}
                      links={selectedImpactClaim?.links || []}
                    />
                  </Box>
                  <Box mt={3}>
                    <ClaimDisclaimer />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={2}>
                    <ThemeTypography variant="BODY_LARGE_BOLD">Acceptance criteria</ThemeTypography>
                  </Box>
                  <RequirementList items={selectedImpactClaim?.acceptanceCriteria || []} />
                  <Box mt={2}>
                    <Box mb={2}>
                      <ThemeTypography variant="BODY_LARGE_BOLD">
                        Documentation requirements
                      </ThemeTypography>
                    </Box>
                    <RequirementList items={selectedImpactClaim?.docRequirements || []} />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <EmptyStateWrapper>
                <div>
                  <CardIllustration
                    image={IllustrationTypes.EMPTY_SEARCH}
                    width={260}
                    right={0}
                    top={0}
                  />
                  <StyledImageText variant="body1">
                    Select a claim to see the criteria
                  </StyledImageText>
                </div>
              </EmptyStateWrapper>
            )}
          </ContentColumn>
        </Grid>
      </Fade>
    </SlideContentContainer>
  );
};

export default SelectSlide;
