import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import defaultPartnerImage from 'assets/img/partners/default-partner-image.png';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import { IRiskPartner } from './data/SubSections/MitigationPartners';
import RiskBadge from 'designSystem/DataDisplay/RiskBadge/RiskBadge';
import { RiskLevel } from 'types/compliance.types';
import Icon from 'designSystem/Primitives/Icon/Icon';

interface IPartnerCardProps {
  partner: IRiskPartner;
  active?: boolean;
  onClick?: () => void;
}

const Container = styled(Box)<{ active?: booleanish }>(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1),
  border: `1px solid ${theme.custom.colors.lightBorderColor}`,
  borderRadius: 6,
  background: theme.custom.themeColors.white,
  position: 'relative',
  cursor: 'pointer',
  minWidth: 220,
  overflow: 'hidden',
  boxShadow: theme.custom.shadows[5],

  ':hover': {
    backgroundColor:
      active === 'true' ? theme.custom.themeColors.white : theme.custom.themeColors.grayScale[5],
  },

  ...(active === 'true' && {
    '&:before': {
      content: '""',
      display: 'block',
      width: 8,
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      borderRadius: '4px 0 0 4px',
      backgroundColor: theme.custom.themeColors.primary[100],
    },
    '&:after': {
      content: '""',
      display: 'block',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      border: `2px solid ${theme.custom.themeColors.primary[100]}`,
      borderRadius: 4,
    },
  }),
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const SubTextRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
}));

const SubText = styled(ThemeTypography)(() => ({
  marginLeft: 2,
  fontSize: '10px !important',
}));

const SubItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: theme.spacing(1),
  overflow: 'hidden',
}));

export const PartnerImage = styled('img')(({ theme }) => ({
  width: 44,
  height: 44,
  borderRadius: 4,
  objectFit: 'cover',
  marginRight: theme.spacing(1),
}));

const PartnerCard: FC<IPartnerCardProps> = ({ partner, active, onClick }) => {
  return (
    <Container active={Booleanish(active)} onClick={onClick}>
      <PartnerImage
        alt={`Logo of ${partner.partner.title}`}
        src={partner.partner.logo?.url || defaultPartnerImage}
      />
      <TextContainer>
        <Box display="flex" alignItems="center">
          <ThemeTypography variant="BODY_MEDIUM_BOLD" autoOverflow>
            {partner.partner.title}
          </ThemeTypography>
          {partner.riskLevel && (
            <Box ml={0.75} mr={1}>
              <RiskBadge riskLevel={RiskLevel[partner.riskLevel]} />
            </Box>
          )}
        </Box>
        <SubTextRow>
          <SubItem>
            <Icon name="tag-filled" size="x-small" />
            <SubText variant="LABEL_INPUT" color="GRAY_80" autoOverflow>
              Supplier
            </SubText>
          </SubItem>

          {partner.location && (
            <SubItem>
              <Icon name="activity-default" size="small" />
              <SubText variant="LABEL_INPUT" color="GRAY_80" autoOverflow>
                {partner.location}
              </SubText>
            </SubItem>
          )}
        </SubTextRow>
      </TextContainer>
    </Container>
  );
};

export default PartnerCard;
