import { SearchBox } from '@mapbox/search-js-react';
import { SearchBoxProps } from '@mapbox/search-js-react/dist/components/SearchBox';
import { styled } from '@mui/material';
import React, { FC, useState } from 'react';

type SearchBoxAdministrativeUnitTypes =
  | 'country'
  | 'region'
  | 'postcode'
  | 'district'
  | 'place'
  | 'locality'
  | 'neighborhood'
  | 'street'
  | 'address'
  | 'block';

export interface IGeolocationAutocompleteProps {
  defaultValue?: string;
  /**
   * Default all except POI
   * 'country,region,postcode,district,place,locality,neighborhood,street,address,block'
   */
  size?: 'small' | 'medium';
  language?: string;
  searchTypes?: string | Set<SearchBoxAdministrativeUnitTypes>;
  country?: string;
  onRetrieve?: SearchBoxProps['onRetrieve'];
  onChange?: (value: string) => void;
}

const SearchInputStyles = styled('div')<{ size: Required<IGeolocationAutocompleteProps['size']> }>(
  ({ theme, size }) => ({
    width: '100%',
    fontFamily: 'Lato, "Helvetica Neue", Arial, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    '& mapbox-search-box': {
      height: size === 'small' ? 30 : 40,
      '& > div': {
        boxShadow: 'none',
        border: `1px solid #EEEEEE`,
        '& input': {
          height: size === 'small' ? 30 : 40,
          border: 'none',
          borderRadius: 4,
          boxShadow: 'none',
          outline: 'none',
          paddingLeft: theme.spacing(2),
          '&:focus, &:active': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
        '&:hover': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    '& div[class*="--ResultsAttribution"]': {
      fontSize: 8,
    },
    '& div[class*="--SearchIcon"]': {
      display: 'none',
    },
  })
);

const GeoLocationAutocomplete: FC<IGeolocationAutocompleteProps> = ({
  defaultValue,
  language = 'en',
  size = 'medium',
  searchTypes = 'country,region,postcode,district,place,locality,neighborhood,street,address,block',
  country,
  onChange,
  onRetrieve,
}) => {
  const [value, setValue] = useState<string>(defaultValue || '');
  const accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

  if (!accessToken) {
    throw new Error('REACT_APP_MAP_BOX_TOKEN is not set');
  }

  const handleRetrieve: SearchBoxProps['onRetrieve'] = response => {
    if (response.features.length !== 0) {
      setValue(response.features[0].properties.full_address);
      onChange?.(response.features[0].properties.full_address);
    }
    onRetrieve?.(response);
  };

  return (
    <SearchInputStyles size={size}>
      {/* @ts-ignore known error of the SearchBox component */}
      <SearchBox
        options={{
          types: searchTypes,
          language,
          country,
        }}
        placeholder="Search for a location"
        value={value}
        accessToken={accessToken}
        onChange={onChange}
        onRetrieve={handleRetrieve}
      />
    </SearchInputStyles>
  );
};

export default GeoLocationAutocomplete;
