import React, { useCallback } from 'react';
import { BarChartHorizontal } from 'components/Product/Analytics/BarChartHorizontal';
import useMeasure from 'react-use/lib/useMeasure';
import { millisecondsToMinutes } from 'components/Product/Analytics/utils';
import { Theme, useTheme } from '@mui/material/styles';
import { ImpactClaim } from 'types/impactClaimTypes';

const MARGIN_BAR_CHART = {
  top: 0,
  right: 180,
  bottom: 0,
  left: 70,
};

type ImpactClaimAnalytics = {
  impactClaimId: string;
  impactClaim: ImpactClaim;
  total: number;
};

interface Props {
  claimAnalytics: ImpactClaimAnalytics[];
}

const TimePerImpactClaimCard: React.FC<Props> = ({ claimAnalytics }: Props) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const chartHorizontalWidth = width ? width : 464;
  const { typography } = useTheme<Theme>();

  const tickLabelPropsRight = useCallback(
    () => ({
      fontFamily: typography.fontFamily,
      fontSize: 14,
    }),
    [typography]
  );

  return (
    <div ref={ref}>
      <BarChartHorizontal
        shadowBars
        width={chartHorizontalWidth}
        margin={MARGIN_BAR_CHART}
        x={value => value.total}
        yRight={d => millisecondsToMinutes(d.total) + 'm'}
        labelPropsRight={tickLabelPropsRight}
        y={value => value.impactClaim.impactCatalogItem.title}
        data={claimAnalytics}
      />
    </div>
  );
};

export default TimePerImpactClaimCard;
