import { Box, styled } from '@mui/material';
import EudrComplianceGuideSubSection from 'components/ComplianceGuide/EudrComplianceGuideSubSection';
import { useEudrComplianceGuide } from 'components/ComplianceGuide/EudrComplianceGuideContext';
import ExcelTable, { IGridApiHandle } from 'components/DataImport/DataImportTable/ExcelTable';
import HeaderColumn from 'components/DataImport/DataImportTable/HeaderColumn';
import { ColDefWithValidator } from 'components/DataImport/DataImportTable/excelTable.types';
import { createColumnDefinition } from 'components/DataImport/utils/dataImport.utils';
import { validateNotEmpty } from 'components/DataImport/utils/dataImport.validators';
import { Loader } from 'components/Forms';
import { ErrorState } from 'components/Structure';
import DatasetItem from 'designSystem/DataDisplay/DatasetItem/DatasetItem';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { booleanish } from 'types/booleanish.types';
import { EUDRDatasetStatus } from 'types/compliance.types';

type TableCell = {
  value: string;
  error?: string;
  isValid?: boolean;
};

type TableRowData = {
  name: TableCell;
  farmId: TableCell;
  coordinates: TableCell;
  size: TableCell;
};

export const COLUMN_DEFINITIONS: ColDefWithValidator<TableRowData>[] = [
  createColumnDefinition('name', () => <HeaderColumn title="Name" />, validateNotEmpty),
  createColumnDefinition('farmId', () => <HeaderColumn title="Farm Id" />, validateNotEmpty),
  createColumnDefinition(
    'coordinates',
    () => <HeaderColumn title="Coordinates" />,
    validateNotEmpty
  ),
  createColumnDefinition('size', () => <HeaderColumn title="Size" />, validateNotEmpty),
];

const DatasetContainer = styled('div')<{ disabled?: booleanish }>(({ theme, disabled }) => ({
  cursor: disabled === 'true' ? 'initial' : 'pointer',
}));

const ValidateGeoData: FC = () => {
  const gridApiRef = useRef<IGridApiHandle>(null);
  const { statement } = useEudrComplianceGuide();
  const { error, loading } = { error: false, loading: false };

  const [selectedDatasetId, setSelectedDatasetId] = useState<string>();

  useEffect(() => {
    if (statement && statement.eudrDatasets.length) {
      setSelectedDatasetId(statement.eudrDatasets[0].id);
    }
  }, [statement]);

  const selectedDatasetRowData = useMemo(() => {
    const selectedDataset = statement?.eudrDatasets.find(({ id }) => id === selectedDatasetId);
    if (selectedDatasetId && selectedDataset) {
      return (
        selectedDataset.tableRepresentation?.[0]?.rows.map(row => ({
          name: {
            ...row.name,
            isValid: !row.coordinates.error?.length,
            error: row.name.error?.[0].errorMessage,
          },
          farmId: {
            ...row.farmId,
            isValid: !row.coordinates.error?.length,
            error: row.farmId.error?.[0].errorMessage,
          },
          coordinates: {
            ...row.coordinates,
            isValid: !row.coordinates.error?.length,
            error: row.coordinates.error?.[0].errorMessage,
          },
          size: {
            ...row.size,
            isValid: !row.coordinates.error?.length,
            error: row.size.error?.[0].errorMessage,
          },
        })) || []
      );
    }
  }, [statement, selectedDatasetId]);

  if (loading) return <Loader />;
  if (error) return <ErrorState action={undefined} />;

  return (
    <>
      <EudrComplianceGuideSubSection allowNextStepNavigation>
        <Box display="flex" gap={2} flexWrap="wrap">
          {statement?.eudrDatasets.map(
            ({ status, id, title, rawMaterial, originCountry, partner }) => (
              <DatasetContainer key={id} onClick={() => setSelectedDatasetId(id)}>
                <DatasetItem
                  title={title}
                  commodity={rawMaterial.title}
                  location={originCountry}
                  owner={partner.title}
                  active={id === selectedDatasetId}
                  status={
                    status === EUDRDatasetStatus.VALIDATED ||
                    status === EUDRDatasetStatus.NORMALISED
                      ? 'valid'
                      : 'error'
                  }
                />
              </DatasetContainer>
            )
          )}
        </Box>
      </EudrComplianceGuideSubSection>

      <Box mt={2}>
        {selectedDatasetRowData && (
          <ExcelTable<TableRowData>
            validationMode
            gridRef={gridApiRef}
            rowData={selectedDatasetRowData}
            columnDefs={COLUMN_DEFINITIONS}
          />
        )}
      </Box>
    </>
  );
};

export default ValidateGeoData;
