import React, { FC } from 'react';
import { PartnerRequest, RequestType } from 'types/partner.types';
import { ThemeButton, DialogDefault } from 'designSystem';
import { Box } from '@mui/material';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { IDefaultDialogProps } from 'types/dialog.types';
import RequestTitle from 'components/PartnerIntegration/RequestReplyDialogs/RequestTitle';
import { ReceivedRequestCard } from 'components/Home/Requests';
import { useDialog } from 'components/hooks';
import { REQUEST_STATUS_PARTNER } from 'utils/requestStatus.utils';
import { PartnerRequestStatus } from 'types/enums';
import { useNavigate } from 'react-router-dom';
import { useRequestReplyDialogContext } from 'components/PartnerIntegration/Contexts/RequestReplyDialogContext';

interface IManageRequestDialogProps extends IDefaultDialogProps {
  request: PartnerRequest;
}

const ManageRequestDialog: FC<IManageRequestDialogProps> = ({ onClose, request }) => {
  const { openDialog } = useDialog();
  const { openDismissDialog } = useRequestReplyDialogContext();
  const navigate = useNavigate();

  const getButtonEvent = (requestType: keyof typeof RequestType) => {
    switch (requestType) {
      case RequestType.DOCUMENT:
        return () => {
          openDialog({
            type: 'UPLOAD_REQUEST',
            props: { requestId: request.id },
          });
          onClose?.();
        };
      case RequestType.CHAIN:
        return () => {
          navigate(`/component-chains/${request.chainId}/editor`);
          onClose?.();
        };
      default:
        return () => onClose?.();
    }
  };

  const buttonEvent = getButtonEvent(request.requestType);

  const handleDismissRequest = () => {
    // we have to use the dialog from the request dialog context because it can be used from outside dialog context if the request is mananged via query params
    openDismissDialog(request.id);
  };

  return (
    <DialogDefault
      open
      title="Manage request"
      onClose={onClose}
      maxWidth="sm"
      iconName={
        REQUEST_STATUS_PARTNER[request.requestType][PartnerRequestStatus[request.requestStatus]]
          .iconName
      }
      data-cy="manage-request-dialog"
    >
      <Box px={3} py={1} mb={2} minWidth={480}>
        <RequestTitle request={request} />
        <Box mt={2} />
        <ReceivedRequestCard request={request} displayActions={false} />
        <Box mt={3} display="flex" alignItems="center" justifyContent="space-between">
          {/* TODO: Remove dismiss button when the feature forwarding a request is implemented */}
          <ThemeButton
            color="BLUE_ICE"
            size="large"
            data-cy="dismiss-request-btn"
            onClick={handleDismissRequest}
            startIcon={
              <Icon
                name={
                  REQUEST_STATUS_PARTNER[request.requestType][PartnerRequestStatus.DENIED].iconName
                }
              />
            }
          >
            Dismiss request
          </ThemeButton>
          <ThemeButton
            size="large"
            onClick={buttonEvent}
            data-cy="fulfill-request-btn"
            startIcon={
              <Icon
                name={
                  REQUEST_STATUS_PARTNER[request.requestType][PartnerRequestStatus.EDITED].iconName
                }
              />
            }
          >
            {
              REQUEST_STATUS_PARTNER[request.requestType][
                PartnerRequestStatus[request.requestStatus]
              ].actionText
            }
          </ThemeButton>
        </Box>
        {/* TODO: Add this back in when the feature forwarding a request is implemented */}
        {/* <Box mt={2}>
          <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_80">
            If you do not currently have this data, you can also forward this request to your
            sub-supplier or other partner, or you can respond by dismissing the request.
          </ThemeTypography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
          <ThemeButton color="BLUE_ICE" size="large" onClick={handleDismissRequest}>
            Dismiss request
          </ThemeButton>
          <ThemeButton color="BLUE_ICE" size="large" startIcon={<Icon name="document-request-forward" />}>
            Forward request
          </ThemeButton>
        </Box> */}
      </Box>
    </DialogDefault>
  );
};

export default ManageRequestDialog;
