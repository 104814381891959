import { useQuery } from '@apollo/client';
import { useUploadDialogActions } from 'components/DocumentLibrary/hooks';
import { useConfig, useDialog } from 'components/hooks';
import { GET_PARTNER_DOCUMENTS } from 'graphql/queries';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, GraphQlConnection } from 'types/types';
import { useQueryParam } from 'use-query-params';
import { filterItems } from 'utils';

const usePartnerDocuments = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const { appQueryParams } = useConfig();
  const { openDialog } = useDialog();
  const { handleDeleteDocuments } = useUploadDialogActions();
  const { data, loading, error, fetchMore } = useQuery<{
    partnerDocuments: GraphQlConnection<Document>;
  }>(GET_PARTNER_DOCUMENTS, {
    variables: {
      first: 15,
      filters: { fileExtensions: ['pdf'] },
      partnerId: id,
    },
  });

  const [searchTerm = '', setSearchTerm] = useQueryParam<string>(appQueryParams.query);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  const documents: Document[] = useMemo(() => {
    const nodeDocuments = data?.partnerDocuments?.edges || [];
    return filterItems(nodeDocuments, searchTerm);
  }, [data, searchTerm]);

  const hasNextPage: boolean =
    !!data?.partnerDocuments?.pageInfo?.hasNextPage &&
    data?.partnerDocuments?.edges?.length === documents.length;

  const endCursor: string | undefined = data?.partnerDocuments?.pageInfo?.endCursor;

  const handlePageEndReached = useCallback(() => {
    if (endCursor) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  }, [endCursor, fetchMore]);

  const handleDelete = async () => {
    await handleDeleteDocuments({
      variables: {
        ids: setSelectedDocumentIds,
      },
    });
    if (hasNextPage && endCursor) {
      await fetchMore({
        variables: {
          first: setSelectedDocumentIds.length,
          after: endCursor,
        },
      });
    }
    setSelectedDocumentIds([]);
  };

  const handleDeleteSelectedDocuments = () => {
    openDialog({
      type: 'ALERT',
      props: {
        displayCloseButton: true,
        title: `Delete ${selectedDocumentIds.length === 1 ? 'document' : 'documents'}`,
        submitText: 'Delete',
        itemTitle: selectedDocumentIds.length === 1 ? '' : `${selectedDocumentIds.length} selected`,
        text:
          selectedDocumentIds.length === 1
            ? 'Are you sure you want to delete this document? You will not be able to restore it.'
            : 'Are you sure you want to delete these documents? You will not be able to restore them.',
        onSubmit: handleDelete,
      },
    });
  };

  const allowBulkDeletion = useMemo(() => {
    const selectedDocuments = documents.filter(({ id }) => selectedDocumentIds.includes(id));
    return (
      selectedDocuments.reduce((current, { usageCount }) => current + (usageCount || 0), 0) === 0
    );
  }, [documents, selectedDocumentIds]);

  useEffect(() => {
    if (!documents?.length && hasNextPage) {
      handlePageEndReached();
    }
  }, [documents, hasNextPage, handlePageEndReached]);

  return {
    error,
    selectedDocumentIds,
    loading,
    documents,
    searchTerm,
    endCursor,
    hasNextPage,
    allowBulkDeletion,
    setSearchTerm,
    handlePageEndReached,
    setSelectedDocumentIds,
    handleDeleteSelectedDocuments,
  };
};

export default usePartnerDocuments;
