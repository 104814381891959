import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { claimStatusOptions } from 'components/ImpactClaims/constants/claimStatus';
import { ThemeTypography, Skeleton } from 'designSystem';
import PropTypes from 'prop-types';
import React from 'react';
import { getStatusSubtitle } from 'utils/impactClaim';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OpacityContainer = styled(({ transparent, ...props }) => <div {...props} />)(
  ({ transparent }) => ({
    opacity: transparent ? 0.5 : 1,
  })
);

const SubTitle = styled(Typography)(({ theme, status }) => ({
  fontSize: 13,

  color:
    status === 'APPROVED'
      ? theme.palette.success.main
      : status === 'DENIED'
      ? theme.palette.error.main
      : theme.custom.colors.textLight,
  fontWeight: 500,
}));

const CategoryIcon = styled('img')(() => ({
  position: 'absolute',
  bottom: -2,
  left: -2,
  width: 50,
}));

const ClaimIcon = styled('img')(() => ({
  width: 40,
  height: 'auto',
}));

const ClaimTitleAndStatus = ({
  impactCatalogItem,
  loading,
  transparent,
  claimStatus,
  showSeeComments,
  enlargeTitle = false,
}) => {
  // Determined either by the parent or by the claim status
  const shouldBeTransparent =
    transparent !== undefined
      ? transparent
      : ['ARCHIVED', 'CREATED', 'UPDATED', 'REQUESTED', 'GROUP'].includes(claimStatus);

  const statusAddition = showSeeComments && claimStatus === 'DENIED' ? ' - SEE COMMENT' : null;

  return (
    <OpacityContainer transparent={shouldBeTransparent}>
      <Box display="flex">
        {impactCatalogItem?.iconUrl ? (
          <Box
            height={45}
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
          >
            <ClaimIcon src={impactCatalogItem?.iconUrl} alt="claim icon" />
          </Box>
        ) : (
          <CategoryIcon src={impactCatalogItem?.categoryConfig?.icon} alt="claim icon" />
        )}
        <Box pl={7}>
          <ThemeTypography variant={enlargeTitle ? 'TITLE_SMALL' : 'ITEM_TITLE'}>
            {loading ? <Skeleton variant="text" width={150} /> : impactCatalogItem?.title}
          </ThemeTypography>
          <SubTitle status={claimStatus} data-cy="claim-status">
            {loading ? <Skeleton variant="text" width={70} /> : getStatusSubtitle(claimStatus)}
            {statusAddition}
          </SubTitle>
        </Box>
      </Box>
    </OpacityContainer>
  );
};

ClaimTitleAndStatus.propTypes = {
  impactCatalogItem: PropTypes.object,
  loading: PropTypes.bool,
  transparent: PropTypes.bool,
  claimStatus: PropTypes.oneOf(claimStatusOptions),
  showSeeComments: PropTypes.bool,
  enlargeTitle: PropTypes.bool,
};

export default ClaimTitleAndStatus;
