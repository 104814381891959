import { useQuery } from '@apollo/client';
import { GET_SITE } from 'graphql/queries/sites';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ISite } from 'types/sites.types';

const SiteTitle: FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const { data, loading } = useQuery<{
    site: ISite;
  }>(GET_SITE, { variables: { id } });

  if (loading) {
    return <span> Loading..</span>;
  }

  return <span>{data?.site.title || 'Loading..'}</span>;
};

export default SiteTitle;
