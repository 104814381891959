import { Box, TablePagination } from '@mui/material';
import { COMPONENTS_TABLE_ROWS_PER_PAGE } from 'constants/pagination';
import React, { FC, useState } from 'react';
import TableRow, { ITableRowAction } from '../TableRow';
import { IBaseChain } from 'types/chain.types';
import { COLUMNS_COMPONENT_CHAINS, COMPONENT_CHAIN_COLUMN_IDS } from '../constants/headerColumns';
import {
  PaginationContainer,
  SortableTableHead,
  StyledTable,
  StyledTableBody,
} from 'components/TableBase';

interface IComponentChainTableProps {
  columnKeys: COMPONENT_CHAIN_COLUMN_IDS[];
  componentChains: IBaseChain[];
  selectedIds?: string[];
  actions?: ITableRowAction[];
  displayTableHeader?: boolean;
  onSelectionChange?: (ids: string[]) => void;
  onRowClick: (id: string) => void;
}

const ComponentChainTable: FC<IComponentChainTableProps> = ({
  columnKeys,
  componentChains,
  selectedIds = [],
  actions,
  displayTableHeader,
  onSelectionChange,
  onRowClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleSelect = (componentId: string) => {
    const isItemSelected = selectedIds?.includes(componentId);
    if (!isItemSelected) {
      onSelectionChange?.([...selectedIds, componentId]);
    } else {
      const updatedSelection = selectedIds.filter(document => document !== componentId);
      onSelectionChange?.(updatedSelection);
    }
  };

  const shownColumns = COLUMNS_COMPONENT_CHAINS.filter(({ id }) => columnKeys?.includes(id));

  return (
    <Box>
      <StyledTable>
        {displayTableHeader && <SortableTableHead headerColumns={shownColumns} />}
        <StyledTableBody data-cy="component-chain-table">
          {componentChains
            .slice(
              currentPage * COMPONENTS_TABLE_ROWS_PER_PAGE,
              currentPage * COMPONENTS_TABLE_ROWS_PER_PAGE + COMPONENTS_TABLE_ROWS_PER_PAGE
            )
            .map(
              ({
                id,
                chainTasksDetails,
                title,
                image,
                inUse,
                partner,
                involvedPartners,
                partnerRequest,
              }) => (
                <TableRow
                  id={id}
                  key={id}
                  columnKeys={columnKeys}
                  title={title}
                  taskDetails={chainTasksDetails}
                  image={image}
                  onRowClick={() => onRowClick(id)}
                  selectedIds={selectedIds}
                  actions={actions}
                  onSelect={handleSelect}
                  inUse={inUse}
                  partner={partner}
                  isPartOfRequest={!!partnerRequest?.id}
                  involvedPartners={involvedPartners}
                />
              )
            )}
        </StyledTableBody>
      </StyledTable>
      {componentChains.length > COMPONENTS_TABLE_ROWS_PER_PAGE && (
        <PaginationContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={componentChains.length}
            rowsPerPage={COMPONENTS_TABLE_ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={(_, newPage) => setCurrentPage(newPage)}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${Math.ceil(
                componentChains.length / COMPONENTS_TABLE_ROWS_PER_PAGE
              )}`
            }
          />
        </PaginationContainer>
      )}
    </Box>
  );
};

export default ComponentChainTable;
