import React, { useRef, useMemo } from 'react';
import { PartnerRequest } from 'types/partner.types';
import { CardContainer, ThemeButton, ThemeTypography } from 'designSystem';
import { Box, Tooltip } from '@mui/material';
import { CompanyNameLogo, HTMLField } from 'components/Structure';
import { styled, useTheme } from '@mui/material/styles';
import { AvailableSizes, PartnerRequestStatus } from 'types/enums';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { useDialog } from 'components/hooks';
import { REQUEST_STATUS_PARTNER } from 'utils/requestStatus.utils';

const Dot = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  background: theme.custom.themeColors.error[100],
  borderRadius: '100%',
}));

const Description = styled(Box)<{ descriptionHeight: number | string }>(
  ({ descriptionHeight }) => ({
    position: 'relative',
    height: descriptionHeight,
    overflow: 'hidden',
  })
);

const FadeOut = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  display: 'block',
  width: '100%',
  height: '70%',
  background: `linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  )`,
}));

interface Props {
  request: PartnerRequest;
  displayActions?: boolean;
  descriptionHeight?: number | string;
  displayYourMessage?: boolean;
}

const ReceivedRequestCard: React.FC<Props> = ({
  request,
  displayActions = true,
  descriptionHeight = '100%',
  displayYourMessage = false,
}) => {
  const { requestTitle, requestMessage, requestedFromCompany } = request;
  const { openDialog } = useDialog();
  const { spacing } = useTheme();
  const measureRef = useRef<null | HTMLDivElement>(null);
  const fullTextHeight = measureRef?.current?.clientHeight || 0;

  const handleMangeRequest = () => {
    openDialog({
      type: 'MANAGE_REQUEST',
      props: { request },
    });
  };

  // Show fade and tooltip when text height is greater description component
  const displayFade = useMemo(() => {
    if (fullTextHeight !== 0 && descriptionHeight !== '100%') {
      return !(fullTextHeight < descriptionHeight);
    }
  }, [descriptionHeight, fullTextHeight]);

  return (
    <CardContainer
      key={request.id}
      maxWidth={displayActions ? 300 : undefined}
      padding={2}
      minWidth={300}
    >
      <Box display="flex" flexDirection="column" gap={0.5} height="100%">
        <Box display="flex" alignItems="center" gap={1}>
          <Dot />
          <Icon
            name={
              REQUEST_STATUS_PARTNER[request.requestType][
                PartnerRequestStatus[request.requestStatus]
              ].iconName
            }
            size="medium"
          />
          <ThemeTypography variant="ITEM_TITLE">{requestTitle}</ThemeTypography>
        </Box>

        <Tooltip
          title={displayFade ? <div dangerouslySetInnerHTML={{ __html: requestMessage }} /> : ''}
        >
          <Description descriptionHeight={descriptionHeight}>
            {displayYourMessage && (
              <ThemeTypography variant="BODY_MEDIUM_BOLD">Your message:&nbsp;</ThemeTypography>
            )}
            <ThemeTypography variant="BODY_MEDIUM" color="GRAY">
              <HTMLField ref={measureRef}>{requestMessage}</HTMLField>
            </ThemeTypography>
            {displayFade && <FadeOut />}
          </Description>
        </Tooltip>

        {displayActions && (
          <Box display="flex" justifyContent="space-between" alignItems="center" mt="auto">
            <CompanyNameLogo
              size={AvailableSizes.SMALL}
              companyName={requestedFromCompany.name}
              companyLogoUrl={requestedFromCompany.logo?.url}
            />
            <Box display="flex" gap={1}>
              <ThemeButton
                size="medium"
                startIcon={
                  <Icon
                    name={
                      REQUEST_STATUS_PARTNER[request.requestType][PartnerRequestStatus.EDITED]
                        .iconName
                    }
                  />
                }
                color="YELLOW"
                data-cy="manage-request-btn"
                onClick={handleMangeRequest}
                padding={spacing(0, 1, 0, 2)}
              >
                Manage
              </ThemeButton>
            </Box>
          </Box>
        )}
      </Box>
    </CardContainer>
  );
};

export default ReceivedRequestCard;
