import { SearchBoxProps } from '@mapbox/search-js-react/dist/components/SearchBox';
import { Box, Link } from '@mui/material';
import { ReactComponent as Pin } from 'assets/img/icons/pin.svg';
import SelectableMap from 'components/Forms/SelectableMap';
import { ThemeTypography } from 'designSystem';
import CoordinatesInput from 'designSystem/Formik/CoordinatesInputField/CoordinatesInputField';
import GeoLocationAutocompleteField from 'designSystem/Formik/GeoLocationAutocompleteField/GeoLocationAutocompleteField';
import { Field, useField, useFormikContext } from 'formik';
import React, { FC, useMemo, useState } from 'react';
import { themeColors } from 'styles/themeColors';
import { Coordinates } from 'types/types';
import { COUNTRIES } from 'constants/countries';

interface ILocationSelectorBlockProps {
  showOnlyCountry?: boolean;
  defaultCountry?: string;
}

const LocationSelectorBlock: FC<ILocationSelectorBlockProps> = ({
  showOnlyCountry,
  defaultCountry,
}) => {
  const [mapOpen, setMapOpen] = useState<boolean>(false);
  const formikContext = useFormikContext();

  const [, , { setValue: setLocationCoordinates }] = useField<Coordinates | null>(
    'locationCoordinates'
  );
  const [, , { setValue: setMapBoxId }] = useField<string | null>('mapboxId');

  const language = formikContext?.status?.lang;

  const handleGeocodingRetrieve: SearchBoxProps['onRetrieve'] = response => {
    if (response.features.length > 0) {
      setMapBoxId(response.features[0].properties.mapbox_id);
      const coordinates = response.features[0].geometry.coordinates;
      setLocationCoordinates({ lat: coordinates[1], lng: coordinates[0] });
    }
  };

  const defaultCountryCode = useMemo(() => {
    if (!defaultCountry) return undefined;

    return COUNTRIES.find(
      country => country.label.toUpperCase() === defaultCountry?.toLocaleUpperCase()
    )?.code;
  }, [defaultCountry]);

  return (
    <Box>
      <Box display="flex" alignItems="end" mb={0.5}>
        <ThemeTypography variant="BODY_MEDIUM_BOLD">
          {showOnlyCountry ? 'Country' : 'Location'}
          <span style={{ color: themeColors.primary[40] }}>&nbsp;*</span>&nbsp;&nbsp;
        </ThemeTypography>
        {!showOnlyCountry &&
          (mapOpen ? (
            <>
              <ThemeTypography variant="BODY_SMALL">
                Can&apos;t get the right location?&nbsp;
              </ThemeTypography>
              <ThemeTypography variant="BODY_SMALL_BOLD">
                <Link onClick={() => setMapOpen(false)}>Search address instead</Link>
              </ThemeTypography>
            </>
          ) : (
            <>
              <ThemeTypography variant="BODY_SMALL">
                Can&apos;t find the location? &nbsp;
              </ThemeTypography>
              <ThemeTypography variant="BODY_SMALL_BOLD">
                <Link onClick={() => setMapOpen(true)}>Select on the map or add coordinates</Link>
              </ThemeTypography>
            </>
          ))}
      </Box>
      <Box>
        {mapOpen ? (
          <Box>
            <Box display="flex" gap={1} alignItems="center" width="100%" mb={1.5} mt={0.5}>
              <Pin width={16} />
              <Field
                component={CoordinatesInput}
                name="locationCoordinates"
                locationNameKey="locationName"
              />
            </Box>
            <Box height={300}>
              <Field
                component={SelectableMap}
                name="locationCoordinates"
                locationNameKey="locationName"
              />
            </Box>
          </Box>
        ) : (
          <Box display="flex" gap={1} alignItems="center" maxHeight={30}>
            <Pin width={16} />
            <Field
              fullWidth
              component={GeoLocationAutocompleteField}
              name="locationName"
              size="small"
              language={language}
              variant="outlined"
              searchTypes={showOnlyCountry ? 'country' : undefined}
              country={defaultCountryCode ? defaultCountryCode : undefined}
              onRetrieve={handleGeocodingRetrieve}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LocationSelectorBlock;
