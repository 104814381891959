import authResolvers from './auth';
import imageResolvers from './images';
import mapboxResolvers from './mapbox';
import companyResolvers from './company';
import qrCodeResolvers from './qrCode';
import productResolver from './product';
import merge from 'lodash/merge';
import imageConverterResolvers from './imageConverter';
import chainsResolver from './chains';

export default merge(
  authResolvers,
  imageResolvers,
  mapboxResolvers,
  companyResolvers,
  qrCodeResolvers,
  productResolver,
  imageConverterResolvers,
  chainsResolver
);
