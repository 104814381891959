import { CompanyFeature } from 'types/companyTypes';
import useUserData from './useUserData';

const useCompanyFeatureFlags = () => {
  const { company } = useUserData();

  // TODO: CompanyFeature should be a enum
  const isCompanyFeatureEnabled = (feature: keyof CompanyFeature) => {
    return !!company?.features?.[feature];
  };

  return { isCompanyFeatureEnabled };
};

export default useCompanyFeatureFlags;
