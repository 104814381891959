import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT, COMPANY_DESIGN_FRAGMENT } from 'graphql/fragments';

export const GET_COMPANY_DETAILS = gql`
  query GetCompanyDetails {
    company {
      ...companyValues
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_DESIGN = gql`
  query GetCompanyDetails {
    company {
      id
      design {
        ...companyDesignValues
      }
    }
  }
  ${COMPANY_DESIGN_FRAGMENT}
`;

export const GET_FONTS = gql`
  query GetFonts {
    getFonts @client(always: true) {
      fontNames
    }
  }
`;

export const GET_INTEGRATION_SETTINGS = gql`
  query IntegrationSettings {
    company {
      id
      apiKey
      integrationSettings {
        widgetType
        showClaims
      }
    }
  }
`;

export const GET_CONNECTED_COMPANIES = gql`
  query GetConnectedCompanies {
    connectedCompanies {
      edges {
        node {
          ...companyValues
        }
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;
