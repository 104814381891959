import { useQuery } from '@apollo/client';
import { useProductActions } from 'components/hooks';
import { GET_PRODUCT_AND_BASE_CHAIN } from 'graphql/queries/products';
import { useParams } from 'react-router-dom';
import { Product } from 'types/types';

const useSupplyChain = () => {
  const { productId } = useParams<{ productId: string }>();
  const { handleEditProduct: editProduct } = useProductActions();

  const { data, loading, error } = useQuery<{
    product: Product;
  }>(GET_PRODUCT_AND_BASE_CHAIN, { variables: { id: productId } });

  const product = data?.product;

  const handleEditProduct = async () => {
    if (!product) {
      // eslint-disable-next-line no-console
      console.error('No product found');
      return;
    }
    editProduct(product);
  };

  return {
    product,
    loading,
    error,
    handleEditProduct,
  };
};

export default useSupplyChain;
