import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { RequestType } from 'types/partner.types';
import { REQUEST_STATUS_CLIENT } from 'utils/requestStatus.utils';
import { PartnerRequestStatus } from 'types/enums';

interface IDismissedTitleProps {
  title: string;
  requestType: RequestType;
}

const DismissedText = styled('div')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  color: theme.custom.themeColors.error[100],
  textTransform: 'uppercase',
}));

const DismissedTitle: FC<IDismissedTitleProps> = ({ title, requestType }) => {
  return (
    <Box display="flex" alignItems="center" gap={1.5} mb={1}>
      <Icon
        size="xx-large"
        name={REQUEST_STATUS_CLIENT[requestType][PartnerRequestStatus.DENIED].iconName}
      />
      <Box display="flex" flexDirection="column">
        <ThemeTypography variant="ITEM_TITLE">{title}</ThemeTypography>
        <Box display="flex" alignItems="center" component="span">
          <DismissedText>Dismissed by partner</DismissedText>
        </Box>
      </Box>
    </Box>
  );
};

export default DismissedTitle;
