import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DocumentViewer from 'designSystem/DataDisplay/DocumentViewer/DocumentViewer';
import { Box, Grid } from '@mui/material';
import { PageContainer, PageTitle, ErrorState } from 'components/Structure';
import { useQuery, useMutation } from '@apollo/client';
import { GET_DOCUMENT } from 'graphql/queries';
import { Loader } from 'components/Forms';
import EditForm from 'components/DocumentLibrary/Forms/EditForm';
import { DocumentInput } from 'types/graphql';
import { Document } from 'types/types';
import { useMessages, useDialog } from 'components/hooks';
import { UPDATE_DOCUMENT } from 'graphql/mutations';
import { DocumentCategories, DocumentOverviewType } from 'types/enums';
import { OTHER_CERTIFICATE_ID } from 'constants/documents';
import { useUploadDialogActions } from 'components/DocumentLibrary/hooks';
import { useNavigate } from 'react-router-dom';

interface IDocumentOverview {
  documentType: keyof typeof DocumentOverviewType;
}

const DocumentOverview: FC<IDocumentOverview> = ({ documentType }) => {
  const { documentId, id: partnerId } = useParams<{ id?: string; documentId: string }>();
  const { error, loading, data } = useQuery<{ document: Document }>(GET_DOCUMENT, {
    variables: { id: documentId },
  });
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { openDialog } = useDialog();
  const { handleDeleteDocuments } = useUploadDialogActions();
  const navigate = useNavigate();

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: ({ updateDocument: { document } }) => {
      // open the request certification dialog if document is certification but has no id
      if (document.category === 'CERTIFICATE' && !document.certificateCatalogItem) {
        openDialog({ type: 'REQUEST_CERTIFICATION', props: { documentTitle: document.title } });
      }
      setSuccessMessage('Document updated successfully');
    },
    onError: error => {
      if (error.message === 'DOCUMENT_UPDATE_FORBIDDEN_IF_USED') {
        setErrorMessage('You cannot update this document because it is already used in a request.');
      } else {
        setErrorMessage('There was an error updating your Document');
      }
    },
  });

  const handleDeleteDocument = async (id: string) => {
    await handleDeleteDocuments({ variables: { ids: [id] } });
    navigate(`/documents/${DocumentOverviewType[documentType]}`);
  };

  const handleSave = async (values: DocumentInput) => {
    const selectedCategory = Object.keys(DocumentCategories).find(
      key =>
        DocumentCategories[key as keyof typeof DocumentCategories] ===
        (values.category as DocumentCategories)
    );
    const fileName = values.title?.indexOf('.pdf') === -1 ? values.title + '.pdf' : values.title;

    await updateDocument({
      variables: {
        id: data?.document.id,
        input: {
          title: fileName,
          fileName,
          expiryDate: values.expiryDate,
          issuedDate: values.issuedDate,
          category: selectedCategory,
          visibility: values.visibility,
          certificateCatalogItemId:
            !values.certificateCatalogItemId?.length ||
            values.certificateCatalogItemId === OTHER_CERTIFICATE_ID
              ? null
              : values.certificateCatalogItemId,
        },
      },
    });
  };

  const getBackUrl: () => string = () => {
    if (documentType === 'PARTNER') {
      return `/partners/${partnerId}/documents`;
    }

    return `/documents/${DocumentOverviewType[documentType]}`;
  };

  if (!data && loading) return <Loader />;
  if (error || !data?.document) return <ErrorState />;

  const document = data.document;

  return (
    <PageContainer>
      <PageTitle
        title="Document overview"
        titleExtension={document.title}
        goBackLabel="All Document"
        goBackUrl={`${getBackUrl()}`}
      />
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DocumentViewer file={document.url} displayLink />
          </Grid>
          <Grid item xs={6}>
            {document && (
              <EditForm
                onSubmit={handleSave}
                onDelete={handleDeleteDocument}
                document={document}
                documentType={documentType}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default DocumentOverview;
