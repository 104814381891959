import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { EditorPreview } from 'components/Product/Preview';
import React, { FC, PropsWithChildren } from 'react';
import EditorHeaderControls from './EditorHeaderControls';
import { Summary } from './FormParts';
import { useMenu } from './hooks';
import Menu from './Menu/Menu';

const Container = styled('div')(() => ({}));
const MENU_WIDTH = 220;

const MenuContainer = styled(Box)(() => ({
  flexBasis: MENU_WIDTH,
  minWidth: MENU_WIDTH,
  maxWidth: MENU_WIDTH,
}));

const FormContainer = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  maxWidth: `calc(100% - ${MENU_WIDTH}px - ${theme.spacing(3)})`,
  flexBasis: `calc(100% - ${MENU_WIDTH}px - ${theme.spacing(3)})`,
  minHeight: 600,
}));

const EditorLayout: FC<PropsWithChildren> = ({ children }) => {
  const { activeMenu, previewIsActive } = useMenu() || {};
  const { formWide } = activeMenu;
  const theme = useTheme();
  const { plan } = useCompanyPlanContext();
  const isSlimPlan = plan === 'SLIM_PLAN';
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const hidePreviewButton = isSlimPlan && mediumScreen;

  return (
    <Container>
      <EditorHeaderControls />
      <Grid container spacing={3}>
        <Grid item sm={12} lg={previewIsActive ? 8 : 12}>
          <Grid container spacing={3} style={{ overflow: 'hidden' }}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={isSlimPlan ? 12 : 9}
                  md={isSlimPlan ? 12 : 8}
                  lg={previewIsActive ? 12 : 8}
                >
                  <Summary />
                </Grid>
                {!previewIsActive && !hidePreviewButton && (
                  <Grid item xs={3} md={4}>
                    <EditorPreview />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={previewIsActive || formWide ? 12 : 8}>
              <Box display="flex" gap={3}>
                <MenuContainer maxWidth={240}>
                  <Menu />
                </MenuContainer>
                <FormContainer>{children}</FormContainer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {previewIsActive && (
          <Grid item sm={false} lg={4}>
            <EditorPreview />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default EditorLayout;
