import React from 'react';
import { ColoredDot, ClaimLogDate, NameBox, AuthorIcon } from './styles';
import { getStatusTitle } from 'utils/impactClaim';
import Moment from 'react-moment';
import { Box, Tooltip, useTheme } from '@mui/material';
import { ImpactClaimLog } from 'types/impactClaimTypes';
import { ThemeTypography } from 'designSystem';
import { getAuthor } from 'utils/impactClaimUtils';

interface Props {
  logItem: ImpactClaimLog;
  showStatusColor?: boolean;
  showAuthor?: boolean;
}

const ClaimHistoryItem: React.FC<Props> = ({
  logItem,
  showStatusColor = false,
  showAuthor = false,
}) => {
  const theme = useTheme();
  const author = getAuthor(logItem);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      data-cy="claim-history-item"
    >
      <Box display="flex">
        <Tooltip title={`${logItem.user.firstName} ${logItem.user.lastName}`}>
          <ColoredDot
            color={
              showStatusColor
                ? theme.statusColor[logItem.action]
                : theme.custom.themeColors.grayScale[10]
            }
          />
        </Tooltip>
        <Box>
          <ThemeTypography variant="BODY_MEDIUM">{getStatusTitle(logItem.action)}</ThemeTypography>
          {showAuthor && (
            <Box mt={0.5}>
              <NameBox>
                <AuthorIcon src={author.icon} />
                <ThemeTypography variant="BODY_MEDIUM">{author.name}</ThemeTypography>
              </NameBox>
            </Box>
          )}
        </Box>
      </Box>
      <ClaimLogDate>
        <Moment format="MMMM DD, yyyy">{logItem.timestamp}</Moment>
      </ClaimLogDate>
    </Box>
  );
};

export default ClaimHistoryItem;
