import { useMutation } from '@apollo/client';
import { useCompanyPlanContext } from 'contexts/CompanyPlanContext';
import { Autosave } from 'components/Product/MultiStep';
import { getContentBlocks } from 'components/Product/MultiStep/constants/menu';
import { useEditorState, useMenu } from 'components/Product/MultiStep/hooks';
import { UPDATE_CONTENT_BLOCKS_ORDER } from 'graphql/mutations';
import find from 'lodash/find';
import React, { Fragment, useMemo, useState } from 'react';

import DragAndDropMenu from './DragAndDropMenu';
import StaticMenu from './StaticMenu';

const ContentBlockMenu = () => {
  const {
    version: { contentBlocks: remoteContentBlocks },
  } = useMenu();

  const { plan } = useCompanyPlanContext();
  const [contentBlocks, setContentBlocks] = useState([...remoteContentBlocks]);
  const [dirty, setDirty] = useState(false);
  const [handleSave] = useMutation(UPDATE_CONTENT_BLOCKS_ORDER, {
    // The cache is not properly updated cause we don't get back the full version content blocks list
    refetchQueries: ['GetLatestVersion'],
  });
  const { showValidation } = useEditorState();

  const contentBlockMenu = useMemo(() => {
    return getContentBlocks(plan);
  }, [plan]);

  const items = useMemo(() => {
    return contentBlocks.map(contentBlock => {
      const { contentType } = contentBlock;

      const { enabled, validation } = find(remoteContentBlocks, ['contentType', contentType]);
      const menuItem = find(contentBlockMenu, ['id', contentType]);

      // Means there's a mismatch between the remove content blocks stored on the server and the content blocks available from the menu
      // Likely a difference between the Slim version and Full version blocks being returned.
      if (!menuItem) return null;

      return {
        ...menuItem,
        ...contentBlock,
        enabled,
        validation,
      };
    });
  }, [contentBlocks, remoteContentBlocks, contentBlockMenu]);

  const values = useMemo(
    () => ({
      contentBlocksOrder: contentBlocks.map(({ contentType }) => ({
        contentType,
      })),
    }),
    [contentBlocks]
  );

  return (
    <Fragment>
      {plan === 'SLIM_PLAN' ? (
        <StaticMenu items={items} showValidation={showValidation} />
      ) : (
        <DragAndDropMenu
          items={items}
          contentBlocks={contentBlocks}
          setDirty={setDirty}
          setContentBlocks={setContentBlocks}
          showValidation={showValidation}
        />
      )}
      <Autosave handleSave={handleSave} values={values} dirty={dirty} delay={750} />
    </Fragment>
  );
};

export default ContentBlockMenu;
