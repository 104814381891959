import { gql } from '@apollo/client';
import { DOCUMENT_FRAGMENT } from 'graphql/fragments';
import { Document, GraphQlConnection } from 'types/types';

export interface GetDocumentsValues {
  documents: GraphQlConnection<Document>;
  pageInfo: {
    endCursor: string;
  };
}

export const GET_DOCUMENTS = gql`
  query GetDocuments($first: Int, $after: String, $filters: DocumentsFilters) {
    documents(first: $first, after: $after, filters: $filters) {
      edges {
        node {
          ...documentsValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_SHARED_DOCUMENTS = gql`
  query GetSharedDocuments($first: Int, $after: String, $filters: DocumentsFilters) {
    sharedDocuments(first: $first, after: $after, filters: $filters) {
      edges {
        node {
          ...documentsValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_PARTNER_DOCUMENTS = gql`
  query GetPartnerDocuments($partnerId: UUID!, $filters: DocumentsFilters) {
    partnerDocuments(id: $partnerId, filters: $filters) {
      edges {
        node {
          ...documentsValues
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const GET_DOCUMENT = gql`
  query GetDocument($id: UUID!) {
    document(id: $id) {
      ...documentsValues
    }
  }
  ${DOCUMENT_FRAGMENT}
`;
