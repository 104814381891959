import { Box, Typography, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoTooltip from 'designSystem/DataDisplay/InfoTooltip/InfoTooltip';
import { useFormikContext } from 'formik';
import React, { useState, FC } from 'react';
import { UPDATE_CONTENT_BLOCK_ENABLED } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { useMessages } from 'components/hooks';
import ImportContentBlock from '../ImportContentBlock';
import { ContentType } from 'types/contentBlocks';

interface IHeaderProps {
  title: string;
  showEnabled?: boolean;
  helperText?: string;
  contentBlockId: string;
  contentType?: ContentType;
  setContent?: (data: unknown) => void;
}

const Enabled = styled(Typography)(() => ({
  fontSize: 14,
}));

interface SwitchInputProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-cy'?: string;
}

const Header: FC<IHeaderProps> = ({
  title,
  showEnabled,
  helperText,
  contentBlockId,
  contentType,
  setContent,
}) => {
  const { values } = useFormikContext();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  // @ts-ignore
  const [enabled, setEnabled] = useState<boolean>(values?.enabled);
  const [updateContentBlockEnabled, { loading }] = useMutation(UPDATE_CONTENT_BLOCK_ENABLED, {
    // The cache is not properly updated cause we don't get back the full version content blocks list
    refetchQueries: ['GetLatestVersion'],
    onError: () => {
      setErrorMessage(
        `There was an error ${enabled ? 'enabling' : 'disabling'} your content block`
      );
      setEnabled(!enabled);
    },
    onCompleted: () => {
      setSuccessMessage(`Successfully  ${enabled ? 'enabled' : 'disabled'} content block`);
    },
  });

  const handleUpdateContentBlockEnabled = async () => {
    const contentBlockEnabled = !enabled;
    setEnabled(contentBlockEnabled);
    await updateContentBlockEnabled({
      variables: {
        id: contentBlockId,
        enabled: contentBlockEnabled,
      },
    });
  };

  return (
    <Box display="flex" alignItems="start" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{title}</Typography>
          {helperText && <InfoTooltip text={helperText} />}
        </Box>
        {showEnabled && (
          <Box display="flex" alignItems="center" pt="4px">
            <Switch
              checked={enabled}
              disabled={loading}
              onChange={handleUpdateContentBlockEnabled}
              name="checked"
              inputProps={
                {
                  'data-cy': 'field-switch-enabled',
                } as SwitchInputProps
              }
            />
            <Enabled variant="h6">{enabled ? 'Enabled' : 'Disabled'}</Enabled>
          </Box>
        )}
      </Box>
      {contentType && setContent && (
        <ImportContentBlock
          contentType={contentType}
          // @ts-ignore
          setContent={newValues => setContent({ enabled, id: values?.id, ...newValues })}
        />
      )}
    </Box>
  );
};

export default Header;
