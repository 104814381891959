import { Box, Checkbox } from '@mui/material';
import { CardContainer, ThemeTypography } from 'designSystem';
import React, { FC } from 'react';
import { ISite } from 'types/sites.types';
import Image from 'designSystem/DataDisplay/Image/Image';
import Icon from 'designSystem/Primitives/Icon/Icon';
import useMeasure from 'react-use/lib/useMeasure';
import { SiteLabelKeys } from 'utils/site.utils';

interface ISiteItemProps {
  site: ISite;
  selected: boolean;
  onSelect: () => void;
}

const SiteItem: FC<ISiteItemProps> = ({ site, selected, onSelect }) => {
  const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();

  return (
    <Box ref={containerRef} display="flex" alignItems="center" gap={1}>
      <Checkbox checked={selected} onChange={onSelect} />
      <CardContainer padding={1.5} onClick={onSelect} blueHover>
        <Box display="flex" alignItems="center">
          {!!site.image ? (
            <Image width={44} height={44} image={site.image} />
          ) : (
            <Box display="flex" bgcolor="white" borderRadius="6px" padding="4px">
              <Icon name="site-placeholder" size="xx-large" color="blue-ice" />
            </Box>
          )}
          <Box ml={1.5}>
            <ThemeTypography variant="ITEM_TITLE">{site.title}</ThemeTypography>
            <Box display="flex" alignItems="center">
              <Icon name="tag-filled" size="small" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography variant="LABEL_INPUT">
                {SiteLabelKeys[site.siteType]}
              </ThemeTypography>
              <Box ml={1} />
              <Icon name="location" size="small" color="gray-60" />
              <Box ml={0.5} />
              <ThemeTypography maxWidth={containerWidth - 210} autoOverflow variant="LABEL_INPUT">
                {site.locationName}
              </ThemeTypography>
            </Box>
          </Box>
        </Box>
      </CardContainer>
    </Box>
  );
};

export default SiteItem;
