import { styled, useTheme } from '@mui/material/styles';
import { useLogEvent } from 'components/hooks';
import clamp from 'lodash/clamp';
import React, { FC, PropsWithChildren, createContext, useState } from 'react';
import Tour, { ReactourStep } from 'reactour';
import { OnboardingTour } from 'types/companyTypes';

const StyledTour = styled(Tour)(({ theme }) => ({
  color: '#fff',
  margin: theme.spacing(2, 0),
  background: theme.palette.secondary.main,
  // important needed to override the padding of the container which otherwise gets priority.
  padding: '35px 15px 15px 15px !important',
  '& .reactour__close': {
    top: '10px',
    right: '15px',
    fontSize: '16px',
    color: theme.palette.text.disabled,
  },

  '& [data-tour-elem="controls"]': {
    justifyContent: 'space-between',
  },

  '& [data-tour-elem="right-arrow"]': {
    margin: 0,
  },

  '& [data-tour-elem="left-arrow"]': {
    marginRight: theme.spacing(1),
  },

  '& [data-tour-elem="badge"]': {
    left: 'calc(50% - 30px)',
    top: '-15px',
    background: theme.palette.secondary.main,
    fontSize: '12px',
    fontFamily: theme.custom.fontFamilyTitle,
    height: '30px',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
  },

  '& .mask': {
    opacity: 0.5,
  },
}));

interface OnboardingTourContextValues {
  openOnboardingTour: () => void;
  startTour: (tour: OnboardingTour) => void;
}

const PrimaryButton = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(1.5, 2),
  background: theme.palette.primary.main,
  fontWeight: 600,
  borderRadius: 4,
}));

const PrevButton = styled(PrimaryButton)(({ theme }) => ({
  background: theme.custom.colors.backgroundLight,
  // reacttour adds a very annoying cursor-not-allowed to the previous button if the index === 0
  // we have to do this annoying trick to make the "skip" button possible when the tour is started initially.
  cursor: 'default !important',
  '&:hover': {
    cursor: 'pointer !important',
  },
}));

const OnboardingTourContext = createContext<OnboardingTourContextValues>({
  startTour: () => {
    console.error('unimplemented context tour context');
  },
  openOnboardingTour: () => {
    console.error('unimplemented context tour context');
  },
});

const OnboardingTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const { logEvent } = useLogEvent();
  const { palette } = useTheme();

  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [tour, setTour] = useState<OnboardingTour | null>(null);
  const steps: ReactourStep[] = tour?.tourSteps || [];

  const handleClose = () => {
    if (currentStep === steps.length - 1) {
      completeTour();
    } else {
      setCurrentStep(steps.length - 1);
    }
  };

  const completeTour = () => {
    logEvent(tour?.amplitudeEvent || '');
    setCurrentStep(0);
    setOpen(false);

    tour?.onCompleted();

    setTour(null);
  };

  const nextStep = () => {
    setCurrentStep(clamp(currentStep + 1, 0, steps.length - 1));
  };

  const prevStep = () => {
    if (!steps) return;
    setCurrentStep(clamp(currentStep - 1, 0, steps.length - 1));
  };

  const startTour = (tour: OnboardingTour) => {
    setTour(tour);
    setOpen(true);
  };

  /**
   * Workaround to remove the disabled attribute on the back button on the first step
   * Since it is also used to show the skip tour button it needs to be clickable which is achieved by removing the disabled attribute
   */
  const handleAfterOpen = () => {
    setTimeout(() => {
      const backButton = document.querySelector('[data-tour-elem=left-arrow]');
      backButton?.removeAttribute('disabled');
    }, 100);
  };

  return (
    <OnboardingTourContext.Provider
      value={{
        openOnboardingTour: () => setOpen(true),
        startTour,
      }}
    >
      {children}

      <StyledTour
        startAt={0}
        onRequestClose={handleClose}
        onAfterOpen={handleAfterOpen}
        steps={steps}
        goToStep={currentStep}
        isOpen={open}
        rounded={5}
        maskSpace={5}
        accentColor={palette.primary.main}
        showNavigation={false}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        nextStep={nextStep}
        prevStep={prevStep}
        disableInteraction
        lastStepNextButton={<PrimaryButton data-cy="tour_finish_button">Got it!</PrimaryButton>}
        prevButton={
          currentStep !== 0 ? (
            <PrevButton color="inherit" data-cy="tour_prev_button">
              Back
            </PrevButton>
          ) : (
            <PrevButton color="inherit" data-cy="tour_skip_button" onClick={handleClose}>
              Skip
            </PrevButton>
          )
        }
        nextButton={
          <PrimaryButton data-cy="tour_next_button">
            {currentStep === 0 && tour?.firstStepButtonText ? tour.firstStepButtonText : 'Next'}
          </PrimaryButton>
        }
      />
    </OnboardingTourContext.Provider>
  );
};

export { OnboardingTourProvider };

export default OnboardingTourContext;
